import React from 'react';

type Props = {
  major: string;
  minor: string;
  uuid: string;
};

const Beacon = ({ major, minor, uuid }: Props) => {
  return (
    <>
      <div>
        <label className="inline-label">
          Major: <span>{major}</span>
        </label>
      </div>
      <div>
        <label className="inline-label">
          Minor: <span>{minor}</span>
        </label>
      </div>
      <label className="label-text">
        UUID:
        <span>{uuid}</span>
      </label>
      <p className="label-text">{uuid}</p>
    </>
  );
};

export default Beacon;
