import React, { useState, useEffect } from 'react';
import './AddUser.scss';
import { createUser, editUserMetaData } from 'helpers/AdminAPIHelper';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import { Form, Modal, Col, Button } from 'react-bootstrap';
import Alert from 'components/dummy/Alert';
import { validateEmail } from '../../helpers/StringHelper';
import { ContainerData, ROLES, RoleValue, sanitize, User } from 'goodmaps-utils';
import {
  getCustomerList,
  getPartnerList,
  getCampusList,
  getBuildingList,
  getContainerList,
} from 'helpers/BuildingManagerHelper';
import './AddUser.scss';

type Props = { existingUser?: User; onSubmitCallback: () => void; onClose: () => void };

const AddUser = ({ existingUser, onSubmitCallback, onClose }: Props) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showValidation, setShowValidation] = useState(false);
  const [submitResponse, setSubmitResponse] = useState({ text: '', status: '' });

  const [partners, setPartnerList] = useState<{ parentId: string; id: string; name: string }[]>([]);
  const [customers, setCustomerList] = useState<{ parentId: string; id: string; name: string }[]>(
    []
  );
  const [campuses, setCampusList] = useState<{ parentId: string; id: string; name: string }[]>([]);
  const [buildings, setBuildingList] = useState([]);
  const [partnersLoading, setPartnersLoading] = useState(true);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [campusesLoading, setCampusesLoading] = useState(false);
  const [buildingsLoading, setBuildingsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [userObject, setUserObject] = useState({
    email: '',
    role: undefined,
    partner: '',
    customer: '',
    campus: '',
    building: '',
  });

  const [containers, setConatinerList] = useState<ContainerData[]>([]);
  const [userIsSetForEditing, setUserForEditing] = useState(false);

  useEffect(() => {
    getPartners();
  }, []);

  useEffect(() => {
    if (existingUser) {
      const getRole = () => {
        if (existingUser?.auth?.buildings[0]?.roles?.length > 0)
          return existingUser.auth.buildings[0].roles[0];
        if (existingUser?.auth?.campuses[0]?.roles?.length > 0)
          return existingUser.auth.campuses[0].roles[0];
        if (existingUser?.auth?.customers[0]?.roles?.length > 0)
          return existingUser.auth.customers[0].roles[0];
        if (existingUser?.auth?.partners[0]?.roles?.length > 0)
          return existingUser.auth.partners[0].roles[0];
        return RoleValue.NoAccess;
      };

      let childId;
      let child: ContainerData;

      const role = getRole();

      try {
        if (existingUser?.auth) {
          if (existingUser?.auth.buildings[0]?.id) {
            childId = existingUser?.auth.buildings[0]?.id;
          } else if (existingUser?.auth.campuses[0]?.id) {
            childId = existingUser?.auth.campuses[0]?.id;
          } else if (existingUser?.auth.customers[0]?.id) {
            childId = existingUser?.auth.customers[0]?.id;
          } else if (existingUser?.auth.partners[0]?.id) {
            childId = existingUser?.auth.partners[0]?.id;
          }

          if (childId) {
            const childType = childId.split('.')[0];
            switch (childType) {
              case 'Partner':
                child = containers.find((c) => c.id === childId);
                break;
              case 'Customer':
                child = containers.find((c) => c.id === childId);
                break;
              case 'Campus':
                child = containers.find((c) => c.id === childId);
                break;
              case 'Building':
                child = containers.find((b) => b.id === childId);
                break;
              default:
                break;
            }
          }
        }

        const campus = child?.ancestors?.[2];
        const customer = child?.ancestors?.[1];
        const partner = child?.ancestors?.[0];

        setUserObject({
          building: existingUser?.auth.buildings[0]?.id || '',
          campus: existingUser?.auth.campuses[0]?.id || campus || '',
          customer: existingUser?.auth.customers[0]?.id || customer || '',
          partner: existingUser?.auth.partners[0]?.id || partner || '',
          role: role,
          email: existingUser?.id,
        });
      } catch (e) {
        setUserObject({
          building: '',
          campus: '',
          customer: '',
          partner: '',
          role: role,
          email: existingUser?.id,
        });
        console.log('Unable to retrieve user roles. ', e);
      }
      setUserForEditing(true);
    }
  }, [containers, existingUser]);

  useEffect(() => {
    // If field is changed after saving
    setSubmitResponse({ text: '', status: '' });

    if (!userIsSetForEditing && containers.length === 0) {
      getAllContainers();
    }

    if (userObject.partner !== '') {
      getCustomers(userObject.partner);
    }

    if (userObject.customer !== '') {
      getCampuses(userObject.customer);
    }

    if (userObject.campus !== '') {
      getBuildings(userObject.campus);
    }

    if (userObject.partner) {
      setIsSubmitDisabled(false);
      return;
    }

    if (!isSubmitDisabled) setIsSubmitDisabled(true);
  }, [
    isSubmitDisabled,
    setIsSubmitDisabled,
    userObject.partner,
    userObject.customer,
    userObject.campus,
    userObject.building,
    containers.length,
    userIsSetForEditing,
  ]);

  const getPartners = async () => {
    setBuildingList([]);
    setCampusList([]);
    setCustomerList([]);
    setPartnersLoading(true);
    const { message: partners } = await getPartnerList();
    setPartnerList(partners.sort((a, b) => a.name.localeCompare(b.name)));
    setPartnersLoading(false);
  };

  const getCustomers = async (partnerId) => {
    setBuildingList([]);
    setCampusList([]);
    setCustomersLoading(true);
    const { message: customers } = await getCustomerList(partnerId);
    setCustomerList(customers.sort((a, b) => a.name.localeCompare(b.name)));
    setCustomersLoading(false);
  };

  const getCampuses = async (customerId) => {
    setBuildingList([]);
    setCampusesLoading(true);
    const { message: campuses } = await getCampusList(customerId);
    setCampusList(campuses.sort((a, b) => a.name.localeCompare(b.name)));
    setCampusesLoading(false);
  };

  const getBuildings = async (campusId) => {
    setBuildingsLoading(true);
    const { message: buildings } = await getBuildingList(campusId);
    const modifiedBuildingList = buildings
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((b) => {
        return { parentId: campusId, id: b.id, name: b.name };
      });
    setBuildingList(modifiedBuildingList);
    setBuildingsLoading(false);
  };

  const getAllContainers = async () => {
    const { message: containers } = await getContainerList();
    setConatinerList(containers);
  };

  const getRoleText = () => {
    let roleText = '';
    const { role, partner, customer, campus, building } = userObject;
    if (!role) return '';

    try {
      switch (role) {
        case ROLES.goodmapsAdmin:
          roleText = 'GoodMaps Admin';
          break;
        case ROLES.member:
          roleText = 'Customer Manager';
          break;
        case ROLES.mobileOnly:
          roleText = 'Mobile Viewer';
          break;
      }
      if (building && buildings.length !== 0) {
        return `The user will be added as a ${roleText} for the building "${
          buildings.find((p) => p.id === building).name
        }."`;
      }
      if (campus && campuses.length !== 0) {
        return `The user will be added as a ${roleText} for buildings under the "${
          campuses.find((p) => p.id === campus).name
        }" campus.`;
      }
      if (customer && customers.length !== 0) {
        return `The user will be added as a ${roleText} for all buildings under the "${
          customers.find((p) => p.id === customer).name
        }" customer.`;
      }
      if (partner && partners.length !== 0) {
        return `The user will be added as a ${roleText} for all buildings under the "${
          partners.find((p) => p.id === partner).name
        }" partner.`;
      }
    } catch (e) {
      console.log(e);
    }
    return '';
  };

  const isEmailValid = () => {
    return validateEmail(sanitize(userObject.email));
  };

  const isFormValid = () => {
    if (!isEmailValid() || !userObject.role || !userObject.partner) return false;
    return true;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setSubmitLoading(true);

    // If any of the user object is empty return
    if (!isFormValid()) return;

    if (existingUser) {
      if (!isSubmitDisabled) {
        let roleAdded = false;
        const auth = { partners: [], customers: [], campuses: [], buildings: [] };

        if (userObject.building) {
          auth.buildings = [{ roles: [+userObject.role], id: userObject.building }];
          roleAdded = true;
        }

        if (userObject.campus) {
          auth.campuses = roleAdded ? [] : [{ roles: [+userObject.role], id: userObject.campus }];
          roleAdded = true;
        }

        if (userObject.customer) {
          auth.customers = roleAdded
            ? []
            : [{ roles: [+userObject.role], id: userObject.customer }];
          roleAdded = true;
        }

        if (userObject.partner) {
          auth.partners = roleAdded ? [] : [{ roles: [+userObject.role], id: userObject.partner }];
          roleAdded = true;
        }

        const response = await editUserMetaData(existingUser.email, auth);

        if (response.status === 200)
          setSubmitResponse({
            status: '200',
            text: `Success! User has been updated`,
          });
        onSubmitCallback();
        setSubmitLoading(false);
      } else {
        setShowValidation(true);
        setSubmitLoading(false);
      }
    } else {
      try {
        const auth = { partners: [], customers: [], campuses: [], buildings: [] };

        if (userObject.building) {
          auth.buildings.push({ roles: [userObject.role], id: userObject.building });
        } else if (userObject.campus) {
          auth.campuses.push({ roles: [userObject.role], id: userObject.campus });
        } else if (userObject.customer) {
          auth.customers.push({ roles: [userObject.role], id: userObject.customer });
        } else if (userObject.partner) {
          auth.partners.push({ roles: [userObject.role], id: userObject.partner });
        }

        const sanitizedEmail = sanitize(userObject.email);

        const response = await createUser(sanitizedEmail, auth);

        if (response.status === 200)
          setSubmitResponse({ status: '200', text: 'Success! The user has been created.' });

        onSubmitCallback();
        setSubmitLoading(false);
        return;
      } catch (error) {
        console.error(error);
        setSubmitResponse({
          status: '500',
          text: `Error adding user, please try again. Error: ${(error as any).message}`,
        });
        setSubmitLoading(false);
      }
      setShowValidation(true);
    }
  };

  return (
    <>
      <Modal.Body>
        {submitResponse.text ? (
          <Alert variant={submitResponse.status === '200' ? 'success' : 'danger'}>
            {submitResponse.text}
          </Alert>
        ) : null}
        <Form noValidate id="add-user" onSubmit={onSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <h1>1. Email</h1>
              <p>
                The email of the user to be invited. If set as a GoodMaps Admin or Customer Admin,
                the user will complete their signup in Studio. If set as a Mobile Viewer, the user
                will complete their signup in Explore.
              </p>
              <Form.Control
                type="email"
                isInvalid={!isEmailValid() && showValidation}
                placeholder="Enter user's email"
                value={userObject.email}
                onChange={(event) => {
                  setUserObject({ ...userObject, email: event.target.value });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {!validateEmail(userObject.email)
                  ? 'Please provide a valid email.'
                  : 'Email already exists.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <hr></hr>
          <h1>2. Role</h1>
          <p>
            The role of the user to be invited. This describes what the user can do in Studio or
            Explore.
          </p>
          <div className="role selection-table">
            <div
              onClick={() => {
                setUserObject({ ...userObject, role: ROLES.goodmapsAdmin });
              }}
              className={`selectable-element ${
                userObject.role === ROLES.goodmapsAdmin ? 'selected' : ''
              }`}
            >
              <h3>GoodMaps Admin</h3>
              User will have access to Studio to view and edit all buildings, as well as manage all
              users. User will also be able to log into GoodMaps Explore and see private or
              unpublished buildings.
            </div>
            <div
              onClick={() => {
                setUserObject({ ...userObject, role: ROLES.member });
              }}
              className={`selectable-element ${userObject.role === ROLES.member ? 'selected' : ''}`}
            >
              <h3>Customer Manager</h3>
              User will have access to Studio to view and edit buildings they have access to. User
              will also be able to log into GoodMaps Explore and see private or unpublished
              buildings that they have access to.
            </div>
            <div
              onClick={() => {
                setUserObject({ ...userObject, role: ROLES.mobileOnly });
              }}
              className={`selectable-element ${
                userObject.role === ROLES.mobileOnly ? 'selected' : ''
              }`}
            >
              <h3>Mobile Viewer</h3>
              User will not have access to Studio. User will be able to log into GoodMaps Explore
              and see private or unpublished buildings that they have access to.
            </div>
          </div>
          <hr></hr>
          <h1>3. Access Level</h1>
          <p>
            The amount of data the user will have access to. Selecting an element here means the
            user will have access to <i>everything</i> under that element.
          </p>
          <div className="sections">
            <div className="section">
              <h2>Partner</h2>
              <div className="selection-table">
                {partnersLoading ? (
                  <div className="selection-table-loader">
                    <CircularProgressIndicator />
                  </div>
                ) : null}
                {partners.map((p) => (
                  <div
                    key={p.id}
                    className={`selectable-element ${
                      userObject.partner === p.id ? 'selected' : ''
                    }`}
                    onClick={() => {
                      getCustomers(p.id);
                      setUserObject({
                        ...userObject,
                        partner: p.id,
                        customer: '',
                        campus: '',
                        building: '',
                      });
                    }}
                  >
                    {p.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="section">
              {customers.length || customersLoading ? (
                <>
                  <h2>Customer</h2>
                  <div className={`selection-table ${!userObject.customer ? 'all-selected' : ''}`}>
                    {customersLoading ? (
                      <div className="selection-table-loader">
                        <CircularProgressIndicator />
                      </div>
                    ) : null}
                    {customers.map((p) => (
                      <div
                        key={p.id}
                        className={`selectable-element ${
                          userObject.customer === p.id ? 'selected' : ''
                        }`}
                        onClick={() => {
                          getCampuses(p.id);
                          setUserObject({
                            ...userObject,
                            customer: p.id,
                            campus: '',
                            building: '',
                          });
                        }}
                      >
                        {p.name}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            <div className="section">
              {campuses.length || campusesLoading ? (
                <>
                  <h2>Campus</h2>
                  <div className={`selection-table ${!userObject.campus ? 'all-selected' : ''}`}>
                    {campusesLoading ? (
                      <div className="selection-table-loader">
                        <CircularProgressIndicator />
                      </div>
                    ) : null}
                    {campuses.map((p) => (
                      <div
                        key={p.id}
                        className={`selectable-element ${
                          userObject.campus === p.id ? 'selected' : ''
                        }`}
                        onClick={() => {
                          setUserObject({ ...userObject, campus: p.id, building: '' });
                          getBuildings(p.id);
                        }}
                      >
                        {p.name}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            <div className="section">
              {buildings.length || buildingsLoading ? (
                <>
                  <h2>Building</h2>
                  <div className={`selection-table ${!userObject.building ? 'all-selected' : ''}`}>
                    {buildingsLoading ? (
                      <div className="selection-table-loader">
                        <CircularProgressIndicator />
                      </div>
                    ) : null}
                    {buildings.map((p) => (
                      <div
                        key={p.id}
                        className={`selectable-element ${
                          userObject.building === p.id ? 'selected' : ''
                        }`}
                        onClick={() => {
                          setUserObject({ ...userObject, building: p.id });
                        }}
                      >
                        {p.name}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </Form>
        {getRoleText() ? <Alert variant="warning">{getRoleText()}</Alert> : null}
      </Modal.Body>
      <Modal.Footer>
        {submitResponse.status !== '' ? (
          <Button
            variant={submitResponse.status === '200' ? 'success' : 'danger'}
            onClick={onClose}
          >
            Close
          </Button>
        ) : null}

        {submitResponse.status !== '200' ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {submitLoading ? <CircularProgressIndicator /> : null}
            <Button
              style={{ marginLeft: 10 }}
              disabled={!isFormValid() || submitLoading}
              variant="primary"
              type="submit"
              form="add-user"
            >
              Save
            </Button>
          </div>
        ) : null}
      </Modal.Footer>
    </>
  );
};

export default AddUser;
