import React, { useEffect } from 'react';
import FloorSelector from '../dummy/FloorSelector';
import './BuildingControls.scss';
import ZoomControls from './Zoom';
import Floor from 'components/floorplan/structures/Floor';
import { useDispatch, useSelector } from 'react-redux';
import { EditModeType } from 'globals/Types';
import { hideContextMenu } from 'actions/EditEntityActions';

type Props = {
  onFloorSelected: (number) => void;
  currentFloor: number;
  floors: Floor[];
  editMode: EditModeType;
};

const BuildingControls = ({ onFloorSelected, currentFloor, floors, editMode }: Props) => {
  const dispatch = useDispatch();
  const contextMenuIsVisible: boolean = useSelector((state) => state.viewer.contextMenuIsVisible);

  useEffect(() => {
    const buildingControlsElement = document.getElementsByClassName(
      'building-viewer-controls'
    )[0] as HTMLElement;
    if (buildingControlsElement) {
      const onMouseDown = (e) => {
        e.preventDefault();
        dispatch(hideContextMenu());
      };
      buildingControlsElement.addEventListener('mousedown', onMouseDown);

      return () => {
        buildingControlsElement.removeEventListener('mousemove', onMouseDown);
      };
    }
  }, [contextMenuIsVisible, dispatch]);

  return editMode === 'none' ? (
    <>
      <div className="building-viewer-controls">
        <FloorSelector
          selectedFloor={currentFloor}
          floors={floors}
          onFloorSelected={(level) => {
            onFloorSelected(level);
          }}
        />
        <ZoomControls />
      </div>
    </>
  ) : null;
};

export default BuildingControls;
