import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './DropdownOption.scss';

export default ({ value, selected = false, children }) => (
  <Dropdown.Item
    className={selected ? 'selected-item' : null}
    key={value.toString()}
    eventKey={value}
  >
    {children}
  </Dropdown.Item>
);
