import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './TopNav.scss';
import StudioLogo from 'components/StudioLogo';
import UserDropdown from './UserDropdown';
import { Navbar, Nav } from 'react-bootstrap';
import Tab from './Tab';
import { userIsGoodMapsAdmin, userIsCustomerAdmin, User } from 'goodmaps-utils';
export const DRAWER_WIDTH = 288;

const TopNav = () => {
  const history = useHistory();
  const user: User = useSelector((state) => state.session.user);

  return (
    <Navbar bg="dark" variant="dark">
      <div className="top-nav flex flex-row fixed w-100 top-0 left-0 white items-center justify-between ph3">
        <div className="flex flex-row items-center h-100">
          <Nav.Link onClick={() => history.push('/')}>
            <div className="studio-logo">
              <StudioLogo color="#e7e7e7" fontSize={20.5} />
            </div>
          </Nav.Link>
          <div className="separator" />
          {userIsGoodMapsAdmin(user.auth) ? (
            <div className="tabs flex flex-row h-100">
              <Tab path="/">Building Management</Tab>
              <Tab path="/users">User Management</Tab>
              <Tab path="/partners">Management</Tab>
            </div>
          ) : null}
          {userIsCustomerAdmin(user.auth) ? (
            <div className="tabs flex flex-row h-100">
              <Tab path="/">Building Management</Tab>
              <Tab path="/users">User Management</Tab>
            </div>
          ) : null}
        </div>
        <div className="flex flex-row items-center">
          <UserDropdown />
        </div>
      </div>
    </Navbar>
  );
};

export default TopNav;
