import React, { useState } from 'react';
import { Button, Modal, Form, Col, Alert } from 'react-bootstrap';
import { STATUSES, LANGUAGES } from 'globals/Constants';
import {
  BuildingStatus,
  LocalizationType,
  STATES,
  CANADIANTERRITORIES,
  COUNTRIES,
  BuildingData,
  Localization,
} from 'goodmaps-sdk';
import { BuildingManagementMode } from 'globals/Types';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import { addBuilding, editBuilding } from 'helpers/BuildingManagerHelper';

type props = {
  building?: BuildingData;
  title: string;
  mode: BuildingManagementMode;
  onClose: () => void;
  onSuccess: () => Promise<void>;
  parentId?: string;
};

const INITIAL_ADDRESS = {
  streetAddress: '',
  locality: '',
  administrativeArea: '',
  postalCode: '',
  country: '',
};

const INITIAL_LOCALIZATION: Localization = {
  types: [LocalizationType.CPS],
  cpsMapId: '',
  immersalMapId: 0,
  fantasmoDuplicateId: '',
  buildingType: 'f',
};

const INITIAL_BUILDING: BuildingData = {
  id: '',
  parentId: '',
  name: '',
  lat: null,
  lon: null,
  modifiedAtISO: '',
  modifiedByUserId: '',
  address: INITIAL_ADDRESS,
  localization: INITIAL_LOCALIZATION,
  defaultLanguage: 'en',
  published: false,
  status: BuildingStatus.Pipeline,
  imported: false,
  region: '',
  createdAtISO: `${Date.now()}`,
  createdByUserId: '',
  private: false,
  ancestors: [],
  distance: null,
};

const BuildingsModal = ({
  building = INITIAL_BUILDING,
  title,
  mode,
  onClose,
  onSuccess,
  parentId,
}: props) => {
  const [isFormValidated, setFormIsValidated] = useState(false);
  const [buildingChanges, setBuildingChanges] = useState<BuildingData>(building);
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    setIsLoading(true);

    if (error) setError('');
    if (response) setResponse('');

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setFormIsValidated(true);
      return;
    }

    try {
      if (mode === BuildingManagementMode.add) {
        await addBuilding(parentId, buildingChanges);
        setResponse(`${building.name} was successfully created!`);
      }
      if (mode === BuildingManagementMode.edit) {
        await editBuilding(parentId, buildingChanges);
        setResponse(`${building.name} was successfully updated!`);
      }
    } catch (error) {
      setError('There was an issue with the building: ' + error);
    } finally {
      setIsLoading(false);
    }

    // Callback
    onSuccess();
  };

  return (
    <>
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <CircularProgressIndicator />
        ) : (
          <>
            {error ? <Alert variant="danger">{error}</Alert> : null}
            {response ? <Alert variant="success">{response}</Alert> : null}
            <Form
              noValidate
              id="building-form"
              validated={isFormValidated} // form has been validated - true/false
              onSubmit={onSubmit}
            >
              <Form.Row>
                <Form.Group as={Col} md="8">
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    required
                    as="input"
                    value={buildingChanges.name}
                    onChange={(event) => {
                      setBuildingChanges({ ...buildingChanges, name: event.target.value });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Default Language*</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    value={buildingChanges.defaultLanguage}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        defaultLanguage: event.target.value,
                      })
                    }
                  >
                    {LANGUAGES.map((language) => {
                      return (
                        <option key={language.code} value={language.code}>
                          {language.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please choose a default language.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Bucket Region</Form.Label>
                  <Form.Control
                    disabled={mode === BuildingManagementMode.edit}
                    as="select"
                    value={buildingChanges.region}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        region: event.target.value,
                      })
                    }
                  >
                    <option key="default" value="">
                      Default
                    </option>
                    <option key="ca" value="ca">
                      Canada
                    </option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <hr></hr>
              <Form.Group>
                <Form.Label>Address*</Form.Label>
                <Form.Control
                  required
                  value={buildingChanges.address.streetAddress}
                  onChange={(event) =>
                    setBuildingChanges({
                      ...buildingChanges,
                      address: { ...buildingChanges.address, streetAddress: event.target.value },
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid street address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>City*</Form.Label>
                  <Form.Control
                    required
                    value={buildingChanges.address.locality}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        address: { ...buildingChanges.address, locality: event.target.value },
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid city.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>State/Territory</Form.Label>
                  <Form.Control
                    as="select"
                    value={buildingChanges.address.administrativeArea}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        address: {
                          ...buildingChanges.address,
                          administrativeArea: event.target.value,
                        },
                      })
                    }
                  >
                    <option value="">None</option>
                    <optgroup label="U.S. States/Territories">
                      {STATES.map((state) => {
                        return (
                          <option key={state.code} value={state.code}>
                            {state.name}
                          </option>
                        );
                      })}
                    </optgroup>
                    <optgroup label="Canadian Provinces">
                      {CANADIANTERRITORIES.map((territory) => {
                        return (
                          <option key={territory.code} value={territory.code}>
                            {territory.name}
                          </option>
                        );
                      })}
                    </optgroup>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please choose a state/territory.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Zip*</Form.Label>
                  <Form.Control
                    required
                    type="string"
                    value={buildingChanges.address.postalCode}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        address: {
                          ...buildingChanges.address,
                          postalCode: event.target.value.trim(),
                        },
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid postal code.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="9">
                  <Form.Label>Country*</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    value={buildingChanges.address.country}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        address: { ...buildingChanges.address, country: event.target.value },
                      })
                    }
                  >
                    <option value="">Select A Country</option>
                    <optgroup label="Countries">
                      {COUNTRIES.map((country) => {
                        return (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        );
                      })}
                    </optgroup>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please choose a country.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <div className="column">
                    <Form.Label>Search</Form.Label>
                    <Button
                      style={{ display: 'block' }}
                      variant="primary"
                      onClick={() => {
                        window.open(
                          `https://www.google.com/maps/place/${buildingChanges.address.streetAddress} ${buildingChanges.address.locality} ${buildingChanges.address.administrativeArea} ${buildingChanges.address.postalCode} ${buildingChanges.address.country}`
                        );
                      }}
                    >
                      Google Maps
                    </Button>
                  </div>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    value={buildingChanges.lat || ''}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        lat: +event.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    value={buildingChanges.lon || ''}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        lon: +event.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Form.Row>
              <hr></hr>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    value={buildingChanges.localization.buildingType}
                    onChange={(event) => {
                      let buildingType: 'f' | 'fi' | 'li' = 'f';
                      switch (event.target.value) {
                        case 'fi':
                          buildingType = 'fi';
                          break;
                        case 'li':
                          buildingType = 'li';
                          break;
                        default:
                          buildingType = 'f';
                          break;
                      }
                      setBuildingChanges({
                        ...buildingChanges,
                        localization: {
                          ...buildingChanges.localization,
                          buildingType,
                        },
                      });
                    }}
                  >
                    <option>Select a Type</option>
                    <option key="f" value="f">
                      F
                    </option>
                    <option key="fi" value="fi">
                      FI
                    </option>
                    <option key="li" value="li">
                      LI
                    </option>
                  </Form.Control>
                  <Form.Label>Immersal Region</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    value={buildingChanges.cpsRegion}
                    onChange={(event) => {
                      let cpsRegion: 'us' | 'uk' | 'az' | '' = '';
                      switch (event.target.value) {
                        case 'us':
                          cpsRegion = 'us';
                          break;
                        case 'uk':
                          cpsRegion = 'uk';
                          break;
                        case 'az':
                          cpsRegion = 'az';
                          break;
                        default:
                          cpsRegion = '';
                          break;
                      }
                      setBuildingChanges({
                        ...buildingChanges,
                        cpsRegion: cpsRegion,
                      });
                    }}
                  >
                    <option>Select a Type</option>
                    <option key="us" value="us">
                      us
                    </option>
                    <option key="uk" value="uk">
                      uk
                    </option>
                    <option key="az" value="az">
                      az
                    </option>
                    <option key="" value="">
                      clear
                    </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="9">
                  <Form.Label>Fantasmo Map ID</Form.Label>
                  <Form.Text>
                    Will be used temporarily during the transition off Fantasmo for maps that have
                    not been migrated. Will also be used by users who have not yet received the
                    Immersal update.
                  </Form.Text>
                  <Form.Control
                    value={buildingChanges.localization.cpsMapId}
                    onChange={(event) =>
                      setBuildingChanges({
                        ...buildingChanges,
                        localization: {
                          ...buildingChanges.localization,
                          cpsMapId: event.target.value,
                        },
                      })
                    }
                  />
                  {buildingChanges.localization.buildingType === 'fi' ||
                  buildingChanges.localization.buildingType === 'li' ? (
                    <>
                      <br />
                      <Form.Label>Immersal Map ID</Form.Label>
                      <Form.Text>
                        Only used for FI buildings. LI buildings will have the map IDs embedded in
                        the levels and this does NOT need to be set.
                      </Form.Text>
                      <Form.Control
                        type="number"
                        value={buildingChanges.localization.immersalMapId}
                        onChange={(event) =>
                          setBuildingChanges({
                            ...buildingChanges,
                            localization: {
                              ...buildingChanges.localization,
                              immersalMapId: parseInt(event.target.value, 10),
                            },
                          })
                        }
                      />
                      <br />
                      <Form.Label>Fantasmo Duplicate</Form.Label>
                      <Form.Text>
                        The building with this ID will not be shown to mobile users on
                        FI/LI-supported devices so as to prevent duplicates. This is typically for
                        buildings that existed as F buildings but were rescanned to be LI buildings.
                      </Form.Text>
                      <Form.Control
                        value={buildingChanges.localization.fantasmoDuplicateId}
                        onChange={(event) =>
                          setBuildingChanges({
                            ...buildingChanges,
                            localization: {
                              ...buildingChanges.localization,
                              fantasmoDuplicateId: event.target.value,
                            },
                          })
                        }
                      />
                    </>
                  ) : null}
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid CPS Map ID.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <hr></hr>
              {mode === BuildingManagementMode.add ? null : (
                <>
                  <Form.Group>
                    <Form.Label>Building Status</Form.Label>
                    <Form.Control
                      custom
                      as="select"
                      value={buildingChanges.status}
                      onChange={(event) => {
                        setBuildingChanges({
                          ...buildingChanges,
                          status: parseInt(event.target.value),
                        });
                      }}
                    >
                      {STATUSES.map((status) => {
                        return (
                          <option key={status.key} value={status.key}>
                            {status.value}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      type="switch"
                      id="custom-switch-published"
                      label="Published"
                      checked={buildingChanges.published}
                      onChange={(event) => {
                        setBuildingChanges({
                          ...buildingChanges,
                          published: event.target.checked,
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Check
                      type="switch"
                      id="custom-switch-private"
                      label="Private"
                      checked={buildingChanges.private}
                      onChange={(event) => {
                        setBuildingChanges({
                          ...buildingChanges,
                          private: event.target.checked || false,
                        });
                      }}
                    />
                  </Form.Group>
                </>
              )}
            </Form>
            {mode === BuildingManagementMode.edit ? (
              <p style={{ fontSize: 12 }}>
                {building.id} last modified at {building.modifiedAtISO} by{' '}
                {building.modifiedByUserId}
              </p>
            ) : null}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          Close
        </Button>

        <Button variant="primary" type="submit" form="building-form">
          Save
        </Button>
      </Modal.Footer>
    </>
  );
};

export default BuildingsModal;
