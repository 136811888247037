import React from 'react';
import Dropzone from 'react-dropzone';
import './DragAndDrop.scss';

type Props = {
  onFileDrop: (file: any, filename: string) => void;
};

const DragAndDrop = ({ onFileDrop }: Props) => {
  const handleDrop = (acceptedFiles) => {
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      const file = reader.result as string;
      onFileDrop(file, acceptedFiles[0].name);
    };
    reader.readAsText(acceptedFiles[0]);
  };

  return (
    <div>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag and drop or click to select files</p>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default DragAndDrop;
