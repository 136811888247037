import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Auth from '@aws-amplify/auth';
import { GenericObject } from 'globals/Types';

const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    console.log(error.response.status, error.response.data.message);
    throw new Error(`${error.response.status} - ${error.response.data.message}`);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    throw new Error('Bad request');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    throw new Error(error.message);
  }
};

export const get = async (endpoint: string) => {
  const apiConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.get(endpoint, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const post = async (endpoint: string, data: GenericObject) => {
  const apiConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.post(endpoint, data, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const put = async (endpoint: string, data: GenericObject) => {
  const apiConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.put(endpoint, data, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const remove = async (endpoint: string) => {
  const apiConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.delete(endpoint, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};
