import React, { useState } from 'react';
import './SavePrompt.scss';
import { SaveStateType } from 'globals/Types';
import { Button } from 'react-bootstrap';
import { InfoOutlined } from '@material-ui/icons';

type Props = {
  onButtonClick?: () => void;
  onCancelClick?: () => void;
  saveState: SaveStateType;
  errorMessage?: string;
};

const DEFAULT_MESSAGE = 'Changes have not been saved';
const DISABLED_MESSAGE = 'Changes cannot be saved.';

const SavePrompt = ({ onButtonClick, onCancelClick, saveState = 'none', errorMessage }: Props) => {
  const [showMessage, setShowMessage] = useState(false);

  return saveState !== 'none' ? (
    <div className="popup-box flex">
      <div className="popup-message">
        {saveState === 'disabled' ? (
          <div>
            {DISABLED_MESSAGE}{' '}
            <InfoOutlined
              style={{ height: 20, width: 20 }}
              onClick={() => {
                setShowMessage((state) => !state);
              }}
            />
            {showMessage ? <div className="message-box">{errorMessage}</div> : null}
          </div>
        ) : (
          DEFAULT_MESSAGE
        )}
      </div>

      <div className="entityCloseButton">
        <Button disabled={saveState === 'saving'} variant="light" onClick={() => onCancelClick()}>
          CANCEL
        </Button>
      </div>

      <div className="save-button-box flex">
        <Button
          disabled={saveState === 'disabled' || saveState === 'saving'}
          variant="warning"
          onClick={() => (saveState !== 'disabled' ? onButtonClick() : null)}
        >
          SAVE
        </Button>
      </div>
    </div>
  ) : null;
};

export default SavePrompt;
