import React from 'react';
import Directory from '../dummy/Directory';
import { Link } from 'globals/Types';

export type DirectoryProps = {
  links: Link[];
  title?: string;
};

export default (props: DirectoryProps) => {
  return <Directory {...props} />;
};
