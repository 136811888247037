import { Link } from 'globals/Types';

// const getLinks(data (floors), callback): Link[] => removeZeroCountLinks(link[]) => Link[]

export const removeZeroCountLinks = (links: Link[]): Link[] =>
  links.filter(
    (link) =>
      // exclude links that have a count of 0
      !link.hasOwnProperty('count') || (link.hasOwnProperty('count') && link.count !== 0)
  );
