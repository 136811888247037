import React from 'react';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import { RequireNewPassword } from 'aws-amplify-react';
import { ArrowBack } from '@material-ui/icons';
import './SignIn.scss';
import ScrollingIcons from './ScrollingIcons';
import StudioLogo from 'components/StudioLogo';

class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ['requireNewPassword'];
  }
  private formRef = React.createRef<HTMLFormElement>();

  showComponent() {
    // Re-implementation of RequireNewPassword function, change(), into a function that does not call checkContact()
    const changePassword = () => {
      const user = this.props.authData;
      const { password } = this.inputs;
      const { requiredAttributes } = user.challengeParam;
      const attrs = objectWithProperties(this.inputs, requiredAttributes);

      if (!Auth || typeof Auth.completeNewPassword !== 'function') {
        throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
      }
      Auth.completeNewPassword(user, password, attrs);
      this.setState((prevState) => ({ ...prevState, setupComplete: true }));
    };

    const objectWithProperties = (obj, keys) => {
      const target = {};
      for (const key in obj) {
        if (keys.indexOf(key) === -1) {
          continue;
        }
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          continue;
        }
        target[key] = obj[key];
      }
      return target;
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      this.setState((prevState) => ({ ...prevState, submitAttempted: true }));

      if (
        //@ts-ignore
        this.inputs.password === this.state?.newPassword &&
        this.inputs.password !== undefined &&
        this.inputs.password !== '' &&
        this.inputs.password.length >= 8 &&
        /\d/.test(this.inputs.password) &&
        this.inputs.name !== undefined &&
        this.inputs.name !== ''
      ) {
        changePassword();
      }
    };

    //
    const handleNewPwdInputChange = (e) => {
      e.persist();
      this.setState((prevState) => ({ ...prevState, newPassword: e.target?.value || null }));
    };

    const showErrorMessage = (input: string) => {
      // @ts-ignore
      if (!this.state?.submitAttempted) return null;

      const password: string = this.inputs.password;

      switch (input) {
        case 'password':
          if (password === undefined || password === '') {
            return <div className="error-label">Password field cannot be blank.</div>;
            // @ts-ignore
          } else if (password !== this.state?.newPassword) {
            return <div className="error-label">Passwords don't match.</div>;
          } else if (password.length < 8) {
            return <div className="error-label">Password is too short.</div>;
          } else if (!password.match(/\d/)) {
            return <div className="error-label">Password must contain a number.</div>;
          }
          return null;
        case 'name':
          if (this.inputs.name === '' || this.inputs.name === undefined) {
            return <div className="error-label">Name field cannot be blank.</div>;
          }
          return null;
        default:
          return null;
      }
    };

    const showAccountSetup = () => {
      return (
        <form name="login-form" ref={this.formRef}>
          <ScrollingIcons />
          <div className="form">
            <div className="back-arrow-button" onClick={() => this.changeState('signIn')}>
              <ArrowBack style={{ fontSize: 18 }} />
            </div>
            <div className="form-header">
              <div className="header-label">Account Setup</div>
              <div className="subheader-label">
                Your new password must be 8 characters and contain at least 1 number.
              </div>
            </div>
            <div className="form-body">
              <div className="form-formfield flex">
                <div className="input-label">Name</div>
                <input
                  className="email-input"
                  id="name"
                  key="name"
                  name="name"
                  onChange={this.handleInputChange}
                  type="text"
                  placeholder="Enter your first and last name"
                />
                <div className="error-label-box flex">{showErrorMessage('name')}</div>
              </div>
              <div className="form-formfield flex">
                <div className="input-label">New Password</div>
                <input
                  placeholder="Enter your password"
                  type="password"
                  name="new-password"
                  id="new-password"
                  className="password-input"
                  onChange={(e) => handleNewPwdInputChange(e)}
                />
                <div className="error-label-box flex">{showErrorMessage('password')}</div>
              </div>
              <div className="form-formfield flex">
                <div className="input-label">Confirm New Password</div>
                <input
                  placeholder="Re-enter your password"
                  type="password"
                  name="password"
                  id="password"
                  className="password-input"
                  onChange={this.handleInputChange}
                />
                <div className="error-label-box flex">{showErrorMessage('password')}</div>
              </div>
            </div>
            <div className="sign-in-footer-section flex">
              <span className="footer-content">
                <button type="submit" className="sign-in-button" onClick={(e) => handleSubmit(e)}>
                  SUBMIT
                </button>
              </span>
            </div>
          </div>
        </form>
      );
    };

    const showAccountConfirmation = () => {
      return (
        <form name="login-form" ref={this.formRef}>
          <ScrollingIcons />
          <div className="form">
            <div className="form-header">
              <div className="header-label">Your account is ready</div>
              <div className="subheader-label">
                You can now sign in with your email and new password.
              </div>
            </div>
            <div className="sign-in-footer-section flex">
              <span className="footer-content">
                <button
                  type="submit"
                  className="sign-in-button"
                  onClick={() => this.changeState('signIn')}
                >
                  GO TO SIGN IN
                </button>
              </span>
            </div>
          </div>
        </form>
      );
    };

    return (
      <div className="login-container">
        <div className="login-body">
          <div className="login-title">
            <StudioLogo fontSize={72} color="white" signInScreen />
          </div>
          {
            // @ts-ignore
            !this.state?.setupComplete ? showAccountSetup() : showAccountConfirmation()
          }
        </div>
        <div className="footer-text">
          <p>
            Proudly built by{' '}
            <a target="blank" href="https://www.goodmaps.com/">
              GoodMaps
            </a>{' '}
            in Louisville, Kentucky
          </p>
        </div>
      </div>
    );
  }
}

export default CustomRequireNewPassword;
