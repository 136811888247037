import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import Alert from 'components/dummy/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import config from '../../globals/config/config';
import { Environment } from 'goodmaps-utils';
import { ICONS } from 'globals/Icons';

Auth.configure({
  userPoolId: config.userPoolId,
  userPoolWebClientId: config.userPoolWebClientId,
  region: config.region,
});

const SignUpConfirmation = () => {
  const { location } = useHistory();
  const [username, setUsername] = React.useState('');
  const [cognitoUser, setCognitoUser] = React.useState({});
  const [error, setError] = React.useState('');
  const [accountConfirmed, setAccountConfirmed] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password is too short.')
        .required('Password field cannot be blank.')
        .matches(/\d/, 'Password must contain a number.'),
      confirmPassword: Yup.string()
        .min(8, 'Password is too short.')
        .required('Password field cannot be blank.')
        .matches(/\d/, 'Password must contain a number.')
        .oneOf([Yup.ref('password'), null], "Passwords don't match"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await Auth.completeNewPassword(cognitoUser, values.password, { name: ' ' });
        setAccountConfirmed(true);
        await Auth.signOut();
        setSubmitting(false);
      } catch (e) {
        // (e as any)?.message
        setError(
          'Unable to create password. Please try again. If the problem continues contact support@goodmaps.com.'
        );
        setSubmitting(false);
      }
    },
  });

  React.useEffect(() => {
    const loginUser = async () => {
      const { username, code }: { username: string; code: string } = location.search
        .substring(1, location.search.length)
        .split('&')
        .reduce(
          (acc, curr: string) => {
            const resultArr = curr.split('=');
            acc[resultArr[0]] = resultArr[1];
            return acc;
          },

          { username: '', code: '' }
        );

      if (!username || !code) {
        setError('There was an error. Please contact support@goodmaps.com');
        return;
      }

      setUsername(username);

      try {
        const signedUser = await Auth.signIn(username, code);
        setCognitoUser(signedUser);

        if (signedUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
          setAccountConfirmed(true);
        }
      } catch (error) {
        // This handles when a user has created an account and has clicked a link
        // to confirm their code.
        // Would be better to know from the link if this is from an invite vs signup
        // so we don't have to catch this as an error
        if ((error as any).code === 'UserNotConfirmedException') {
          const result = await Auth.confirmSignUp(username, code);

          if (result === 'SUCCESS') setAccountConfirmed(true);
          else {
            setError(
              'We were unable to confirm your account. Try requesting another code and if you still have difficulties contact support@goodmaps.com'
            );
          }
          return;
        }

        setError((error as any).message);
      }
    };

    loginUser();
  }, [location.search]);

  const showAccountSetup = () => {
    return (
      <form name="login-form" onSubmit={formik.handleSubmit} role="main">
        <div className="form">
          <div className="form-header">
            <h1 className="header-label">Create Password</h1>
            <h2 className="subheader-label">
              Your new password must be 8 characters and contain at least 1 number.
            </h2>
          </div>
          <div className="form-body">
            <div className="form-formfield flex">
              <label className="input-label" htmlFor="password">
                Password
              </label>
              <div className="overlay-container">
                <input
                  placeholder="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  className="password-input"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {showPassword ? (
                  <img
                    src={ICONS.misc.non_visible_eye}
                    role="button"
                    alt="hide password"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                ) : (
                  <img
                    src={ICONS.misc.visible_eye}
                    role="button"
                    alt="reveal password"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                )}
              </div>

              {formik.touched.password && formik.errors.password ? (
                <div className="error-label flex">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="form-formfield flex">
              <label className="input-label" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <div className="overlay-container">
                <input
                  placeholder="Re-enter your password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  className="password-input"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {showConfirmPassword ? (
                  <img
                    src={ICONS.misc.non_visible_eye}
                    role="button"
                    alt="hide password"
                    onClick={() => setShowConfirmPassword((prevState) => !prevState)}
                  />
                ) : (
                  <img
                    src={ICONS.misc.visible_eye}
                    role="button"
                    alt="reveal password"
                    onClick={() => setShowConfirmPassword((prevState) => !prevState)}
                  />
                )}
              </div>

              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className="error-label flex">{formik.errors.confirmPassword}</div>
              ) : null}
            </div>
          </div>
          <div className="sign-in-footer-section flex" style={{ marginBottom: 20 }}>
            <span className="footer-content">
              <button type="submit" className="sign-in-button">
                Create Account
              </button>
            </span>
          </div>
          {error ? <Alert variant="danger">{error}</Alert> : null}
        </div>
      </form>
    );
  };

  const showAccountConfirmation = () => {
    return (
      <form name="login-form" role="main">
        <div className="form">
          <div className="form-header">
            <h1 className="header-label">Account Creation Complete</h1>
            <h2 className="subheader-label">Now, you can sign in with your email and password.</h2>
          </div>
          <div className="sign-in-footer-section flex" style={{ marginBottom: 20 }}>
            <a
              className="sign-in-button"
              href={`https://goodmaps.app.link/T0UXeaJVKkb?username=${username}`}
            >
              OPEN GOODMAPS EXPLORE
            </a>
            {config.ENV_ID !== Environment.Prod ? (
              <a
                className="sign-in-button"
                href={`https://goodmapsindoorguide.app.link/nygYnzRQ1kb?username=${username}`}
              >
                OPEN GOODMAPS INDOOR GUIDE
              </a>
            ) : null}
          </div>

          {error ? <Alert variant="danger">{error}</Alert> : null}
        </div>
      </form>
    );
  };

  return (
    <div className="login-container" style={{ paddingTop: 100 }}>
      {Object.keys(cognitoUser).length === 0 && !error && !accountConfirmed ? (
        <CircularProgressIndicator />
      ) : accountConfirmed ? (
        showAccountConfirmation()
      ) : (
        showAccountSetup()
      )}
    </div>
  );
};

export default SignUpConfirmation;
