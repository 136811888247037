import Beacon from 'components/floorplan/entities/Beacon';
import {
  ConnectionType,
  ElementType,
  DoorEntrance,
  MLString,
  RoomType,
  Position,
  EIURL,
} from 'goodmaps-sdk';
import { Vector2 } from 'three';
import React from 'react';

export type EntityProps = {
  id: string;
  description: string;
  blindDescription: string;
  authList: number[];
  level: number;
  levelName: string;
  name?: string;
  mlName?: MLString;
  shortName?: string;
  mlShortName?: MLString;
  // url?: string;
  mlUrl?: EIURL[];
  genInfo?: string;
  mlGenInfo?: MLString;
  snapshot?: string;
  mlSnapshot?: MLString;
};

export interface Base {
  props: EntityProps;
  // need to know what the values can be here, BaseType from utils?
  // No, but probably should be. It's set in the GoodMapsHelper using numbers
  // that no longer match up with data
  entityType: number;
}

export interface Element extends Base {
  points: Vector2[];
  elementType: number;
}

export interface Wall extends Element {}

export interface Room extends Element {
  roomType: RoomType;
  holes: Vector2[][];
}

export interface Corridor extends Element {}

export interface Fixture extends Element {}

export interface Area extends Element {
  holes: Vector2[][];
}

export interface Connection extends Base {
  connectionType: ConnectionType;
  levelList: number[];
  points: Vector2[];
}

export interface POI extends Base {
  poiType: number;
  startingPoint: boolean;
  position: Position;
}

export interface Door extends Base {
  position: Position;
  entrance: DoorEntrance;
}

export type Elements = {
  1: Room[];
  2: Area[];
  3: Wall[];
  4: Corridor[];
  [ElementType.Fixture]: Fixture[];
};

export type ErrorType = { code: string; description: string };

export type ActiveEntity = POI | Connection | Element | Door | Beacon | undefined;

export type SaveStateType = 'none' | 'disabled' | 'save' | 'saving' | 'saved';

export interface Change {
  mlName: MLString;
  mlShortName: MLString;
  mlUrl: EIURL[];
  mlGenInfo: MLString;
  authList?: number[];
}

export interface POIChange extends Change {
  poiType: number;
}

export interface RoomChange extends Change {
  roomType: RoomType;
  mlSnapshot?: MLString;
}

export interface ConnectionChange extends Change {
  connectionType: ConnectionType;
}

export type EditModeType = 'none' | 'add' | 'move' | 'edit';

export type ZoomLevelType = {
  distance: number;
  level: number;
  dimensions: {
    width: number;
    height: number;
  };
};

export type Link = {
  primary: string;
  secondary?: string;
  to: string;
  level?: string;
  count?: number;
  levelNum?: number;
  color?: string;
  onClick?: () => void;
};

export type Auth = {
  buildings: AuthObject[];
  campuses: AuthObject[];
  partners: AuthObject[];
  customers: AuthObject[];
};

export type AuthObject = {
  id: string;
  roles: number[];
};

export type Option = { value: number | string; text: string };

export type FancyOption = Option & {
  display?: React.ReactNode;
};

export enum BuildingManagementMode {
  'none' = 0,
  'add',
  'edit',
  'delete',
  'import',
  'deploy',
  'imdf',
}

export type GenericObject = {
  [key: string]: any;
};
