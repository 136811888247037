import * as gm from 'goodmaps-sdk';
import Floor from 'components/floorplan/structures/Floor';
import { EIURL, MLString } from 'goodmaps-sdk';

export const USER_LOADED = 'USER_LOADED';

export const GET_ALL_COMPLEXES = 'GET_ALL_COMPLEXES';
export const GET_ALL_BUILDINGS = 'GET_ALL_BUILDINGS';
export const RESET_VIEWER_STATE = 'RESET_VIEWER_STATE';
export const UPDATE_BUILDING = 'UPDATE_BUILDING';
export const SET_CURRENT_BUILDING = 'SET_CURRENT_BUILDING';
export const SET_CURRENT_FLOOR = 'SET_CURRENT_FLOOR';
export const CLEAR_BUILDING_SELECTION = 'CLEAR_BUILDING_SELECTION';
export const GET_ALL_CAMPUSES = 'GET_ALL_CAMPUSES';

export const SHOW_BEACONS = 'SHOW_BEACONS';
export const SHOW_POIS = 'SHOW_POIS';
export const SHOW_DOORS = 'SHOW_DOORS';
export const SHOW_ROOMS = 'SHOW_ROOMS';
export const SHOW_TESTPOINTS = 'SHOW_TESTPOINTS';
export const SHOW_ALL_POIS = 'SHOW_ALL_POIS';
export const SHOW_ROUTES = 'SHOW_ROUTES';
export const SHOW_GRID = 'SHOW_GRID';
export const SHOW_MAP_CONTROLS = 'SHOW_MAP_CONTROLS';

export const TOP_VIEW_TOGGLED = 'TOP_VIEW_TOGGLED';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_NEW_POI_COORDS = 'SET_NEW_POI_COORDS';
export const CLEAR_NEW_POI_COORDS = 'CLEAR_NEW_POI_COORDS';
export const FLOOR_SELECTED = 'FLOOR_SELECTED';
export const CLEAR_SELECTED_FLOOR = 'CLEAR_SELECTED_FLOOR';
export const CLEAR_SELECTED_ENTITY = 'CLEAR_SELECTED_ENTITY';
export const SET_LABEL_GROUPS = 'SET_LABEL_GROUPS';
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL';
export const SHOW_DELETE_POI_DIALOGUE = 'SHOW_DELETE_POI_DIALOGUE';
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const SET_HIGHLIGHTED_ENTITY = 'SET_HIGHLIGHTED_ENTITY';
export const CLEAR_HIGHLIGHTED_ENTITY = 'CLEAR_HIGHLIGHTED_ENTITY';

export const SET_ENTITY_STATE = 'SET_ENTITY_STATE';
export const OPEN_EDIT_DRAWER = 'OPEN_EDIT_DRAWER';
export const SET_SELECTED_ENTITY = 'SET_SELECTED_ENTITY';

export const SET_EVENT_OBJECT = 'SET_EVENT_OBJECT';
export const SET_IS_FORM_DIRTY = 'SET_IS_FORM_DIRTY';

export type GOODMAPS_DATA_TYPE = {
  isStackedView: boolean;
  birdEyeView: boolean;
  buildings: gm.BuildingList;
  currentBuildingName: string;
  currentBuildingID: string;
  buildingUuid: string;
  floors: Floor[];
  currentFloor: Floor;
  showBeacons: boolean;
  showRooms: boolean;
  showDoors: boolean;
  showPois: boolean;
  showRoutes: boolean;
  showTestPoints: boolean;
  minPosition: { x: number; y: number };
  centerPosition: { x: number; y: number };
  maxPosition: { x: number; y: number };
};

export type SAVE_BUILDING_CHANGES_TYPE = {
  id: string;
  mlName: MLString;
  mlShortName: MLString;
  authRole: number[];
  //TODO: Swap out these extra info fields with one extraInfo
  mlUrl: EIURL[];
  mlGenInfo: MLString;
  mlSnapshot?: MLString;
  poiType?: gm.POIType;
  type: number;
};
