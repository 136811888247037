const path = '/';

export const ICONS = {
  BATHROOM: `${path}bathroom.png`,
  BEDROOM: `${path}bedroom.png`,
  BEACON: `${path}beacon.png`,
  CONFERENCE_ROOM: `${path}conference_room.png`,
  DEFAULT: `${path}generic.png`,
  DOOR: `${path}door.png`,
  ELEVATOR: `${path}elevator.png`,
  ENTRANCE: `${path}entrance.png`,
  ESCALATOR: `${path}escalator.png`,
  KITCHEN: `${path}kitchen.png`,
  RESTAURANT: `${path}restaurant.png`,
  RESTROOM: `${path}restroom.png`,
  RESTROOM_FAMILY: `${path}family_restroom.png`,
  RESTROOM_MEN: `${path}men_restroom.png`,
  RESTROOM_WOMEN: `${path}woman_restroom.png`,
  SELECTED: `${path}selected.png`,
  STAIRS: `${path}stairs.png`,
  STORE: `${path}store.png`,
  WAITING_AREA: `${path}waiting_area.png`,
  POI: {
    AMENITY: `${path}shop_amenity.png`,
    EXHIBIT: `${path}exhibit.png`,
    INFO: `${path}info.png`,
    OTHER: `${path}generic_poi.png`,
    SHOP: `${path}shop_amenity.png`,
    WATER: `${path}water.png`,
  },
};
