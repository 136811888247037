import React from 'react';
import Directory from 'components/smart/Directory';
import EditorPage from '../smart/EditorPage';
import { Link } from 'globals/Types';

type Props = {
  title: string;
  links: Link[];
  icon?: string;
};

// Pull sub cat from url and use that value to find icon
const SubCategory = ({ title, links, icon = '' }: Props) => {
  return (
    <EditorPage title={title} icon={icon}>
      <Directory links={links} />
    </EditorPage>
  );
};

export default SubCategory;
