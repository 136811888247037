import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import BuildingsModal from './BuildingsModal';
import DeleteModal from './DeleteModal';
import ImportModal from './ImportModal';
import { Table, Alert, Modal } from 'react-bootstrap';
import './ParentList.scss';
import DeployModal from './DeployModal';
import { BuildingManagementMode } from 'globals/Types';
import ImdfModal from './ImdfModal';
import {
  getBuildingList,
  deleteBuilding,
  exportBuildingOrCampusFile,
} from 'helpers/BuildingManagerHelper';
import { BuildingData, BuildingStatus, userIsGoodMapsAdmin, User } from 'goodmaps-sdk';
import HoverMenu from 'components/dummy/HoverMenu';
import { ICONS } from 'globals/Icons';
import FileCopy from '@material-ui/icons/FileCopy';

type Props = {
  partnerId: string;
  customerId: string;
  campusId: string;
  campusName: string;
};

const BuildingList = ({ partnerId, customerId, campusId, campusName }: Props) => {
  const history = useHistory();
  const user: User = useSelector((state) => state.session.user);
  const [buildingList, setBuildingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [building, setBuilding] = useState<BuildingData>(null);
  const [error, setError] = useState<string>('');
  const [mode, setMode] = useState<BuildingManagementMode>(BuildingManagementMode.none);

  useEffect(() => {
    if (userIsGoodMapsAdmin(user.auth)) {
      const getBuildings = async () => {
        try {
          const { message: buildingList } = await getBuildingList(campusId);
          setBuildingList(buildingList);
        } catch (e) {
          setError(`The building list is not available. ${e}`);
        } finally {
          setIsLoading(false);
        }
      };
      getBuildings();
    }
  }, [partnerId, history, customerId, campusId, user.auth]);

  const openModal = (mode: BuildingManagementMode, building: BuildingData = null) => {
    setBuilding(building);
    setMode(mode);
  };

  const closeModal = () => {
    setMode(BuildingManagementMode.none);
  };

  // refresh building list
  const refreshBuildingList = async () => {
    setIsLoadingTable(true);

    try {
      const { message: listOfBuildings } = await getBuildingList(campusId);
      setBuildingList(listOfBuildings);
    } catch (err) {
      setError(`The building list failed to refresh. ${err}`);
    } finally {
      setIsLoadingTable(false);
    }
  };

  const tableItems = buildingList
    .sort((a: BuildingData, b: BuildingData) => a.name.localeCompare(b.name))
    .map((building: BuildingData) => {
      let typeColor = '#888';
      switch (building.localization.buildingType) {
        case 'f':
          typeColor = '#FBB122';
          break;
        case 'fi':
          typeColor = '#188A59';
          break;
        case 'li':
          typeColor = '#1D4776';
          break;
        default:
          typeColor = '#888';
          break;
      }
      let cpsRegionColor = '#888';
      switch (building.cpsRegion) {
        case 'us':
          cpsRegionColor = '#03cffc';
          break;
        case 'uk':
          cpsRegionColor = '#c2bd2b';
          break;
        case 'az':
          cpsRegionColor = '#6203fc';
          break;
        default:
          cpsRegionColor = '#888';
          break;
      }
      return (
        <tr key={building.id}>
          <td>
            {building.name}
            <br />
            <FileCopy
              className="copy-icon"
              onClick={() => navigator.clipboard.writeText(building.id)}
            />
            <span style={{ fontSize: 10 }}>{building.id}</span>
          </td>
          <td style={{ fontWeight: 'bold', background: typeColor, color: 'white' }}>
            {building.localization?.buildingType?.toUpperCase()}
          </td>
          <td style={{ fontWeight: 'bold', background: cpsRegionColor, color: 'white' }}>
            {building.cpsRegion?.toUpperCase()}
          </td>
          <td className={building.published ? 'published' : 'not-published'}>
            {building.published ? 'YES' : 'NO'}
          </td>
          <td className={building.private ? 'published' : 'not-published'}>
            {building.private ? 'YES' : 'NO'}
          </td>
          <td>{BuildingStatus[building.status]}</td>
          <td style={{ padding: 0, verticalAlign: 'middle' }}>
            <HoverMenu
              menuOptions={{
                iconSrc: ICONS.misc.three_dot_menu,
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                },
              }}
              menuItems={[
                {
                  displayText: `Edit`,
                  onClick: () => openModal(BuildingManagementMode.edit, building),
                },
                {
                  displayText: `Delete`,
                  onClick: () => openModal(BuildingManagementMode.delete, building),
                },
                {
                  displayText: `Import`,
                  onClick: () => openModal(BuildingManagementMode.import, building),
                },
                {
                  displayText: `Deploy`,
                  onClick: () => openModal(BuildingManagementMode.deploy, building),
                },
                {
                  displayText: 'IMDF',
                  onClick: () => openModal(BuildingManagementMode.imdf, building),
                },
                {
                  displayText: `Export`,
                  onClick: async () => {
                    try {
                      const result = await exportBuildingOrCampusFile(building.id);
                      var link = document.createElement('a');
                      link.download = `${building.name}-export.osm`;
                      var blob = new Blob([result.message], { type: 'text/plain' });
                      link.href = window.URL.createObjectURL(blob);
                      link.click();
                    } catch (e) {
                      console.error(e);
                      setError('Unable to export building.');
                    }
                  },
                },
                {
                  displayText: 'List POIs',
                  onClick: () => {
                    console.log(campusId, building.id);
                    history.push(`/partners/poiList/${building.id}`);
                  },
                },
              ]}
            />
          </td>
        </tr>
      );
    });

  return isLoading ? (
    <CircularProgressIndicator />
  ) : (
    <div>
      <div className="table-header">
        <h2>Buildings</h2>
        <button
          className="table-button backgrounded"
          onClick={() => openModal(BuildingManagementMode.add)}
        >
          + Add
        </button>
      </div>
      {error ? (
        <div>
          <Alert variant="danger">{error}</Alert>
        </div>
      ) : null}
      {isLoadingTable ? (
        <CircularProgressIndicator />
      ) : (
        <div>
          <Table>
            <thead>
              <tr>
                <th>Building Name</th>
                <th style={{ width: 60 }}>Type</th>
                <th style={{ width: 60 }}>CPS Region</th>
                <th style={{ width: 100 }}>Published</th>
                <th style={{ width: 100 }}>Private</th>
                <th style={{ width: 100 }}>Status</th>
                <th style={{ width: 37 }}></th>
              </tr>
            </thead>
            <tbody>
              {tableItems.length === 0 ? (
                <tr>
                  <td colSpan={6}>
                    <div className="empty-table">
                      <h6>Empty "{campusName}" Building Table</h6>
                    </div>
                  </td>
                </tr>
              ) : (
                tableItems
              )}
            </tbody>
          </Table>
        </div>
      )}
      <Modal
        size="lg"
        centered={true}
        show={mode === BuildingManagementMode.add}
        onHide={closeModal}
      >
        <BuildingsModal
          title={'Add Building'}
          mode={mode}
          onClose={closeModal}
          onSuccess={refreshBuildingList}
          parentId={campusId}
        />
      </Modal>

      <Modal
        size="lg"
        centered={true}
        show={mode === BuildingManagementMode.edit}
        onHide={closeModal}
      >
        <BuildingsModal
          title={`Edit ${building?.name}`}
          mode={mode}
          building={building}
          onClose={closeModal}
          onSuccess={refreshBuildingList}
          parentId={campusId}
        />
      </Modal>

      <Modal centered={true} show={mode === BuildingManagementMode.delete} onHide={closeModal}>
        <DeleteModal
          name={building?.name}
          onClose={closeModal}
          onSuccess={refreshBuildingList}
          onDelete={async () => await deleteBuilding(building.parentId, building.id)}
        />
      </Modal>

      <Modal
        centered={true}
        show={mode === BuildingManagementMode.import}
        onHide={closeModal}
        className="b-modal"
        size="xl"
      >
        <ImportModal
          name={building?.name}
          onClose={closeModal}
          onSuccess={refreshBuildingList}
          type="Building"
          dataType="building"
          id={building?.id}
        />
      </Modal>

      <Modal centered={true} show={mode === BuildingManagementMode.deploy} onHide={closeModal}>
        <DeployModal
          name={building?.name}
          id={building?.id}
          onClose={closeModal}
          onSuccess={refreshBuildingList}
        />
      </Modal>
      <Modal centered={true} show={mode === BuildingManagementMode.imdf} onHide={closeModal}>
        <ImdfModal
          name={building?.name}
          id={building?.id}
          onClose={closeModal}
          onSuccess={refreshBuildingList}
        />
      </Modal>
    </div>
  );
};

export default BuildingList;
