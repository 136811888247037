import React from 'react';
// import { Hub, Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { ForgotPassword } from 'aws-amplify-react';
import './SignIn.scss';
import ScrollingIcons from './ScrollingIcons';
import StudioLogo from '../../components/StudioLogo';
import ResetConfirmationScreen from './ResetConfirmationScreen';
import SendCodeScreen from './SendCodeScreen';
import NewPasswordScreen from './NewPasswordScreen';
import { sanitize } from 'goodmaps-utils';
class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ['forgotPassword'];
  }
  private formRef = React.createRef<HTMLFormElement>();
  sendCodeInputRef = React.createRef<HTMLInputElement>();

  showComponent() {
    const showPasswordErrorMessage = () => {
      // @ts-ignore
      if (!this.state?.submitAttempted) return null;

      if (this.inputs.password === undefined || this.inputs.password === '') {
        return <div className="error-label">Password field cannot be blank.</div>;
        // @ts-ignore
      } else if (this.inputs.password !== this.state?.newPassword) {
        return <div className="error-label">Passwords don't match.</div>;
      } else if (this.inputs.password.length < 8 || !/\d/.test(this.inputs.password)) {
        return (
          <div className="error-label">
            Passwords must be at least 8 characters and at least 1 number.
          </div>
        );
      }
      return null;
    };

    const showCodeErrorMessage = () => {
      // @ts-ignore
      if (!this.state?.codeErrorMsg) {
        return null;
      }
      if (this.inputs.code === '' || this.inputs.code === undefined) {
        return <div className="error-label">Code field cannot be blank.</div>;
      }
      // @ts-ignore
      return <div className="error-label">{this.state?.codeErrorMsg || null}</div>;
    };

    const showAttemptsErrorMessage = () => {
      // @ts-ignore
      if (!this.state?.attemptsErrorMsg) {
        return null;
      }
      // @ts-ignore
      return <div className="error-label">{this.state?.attemptsErrorMsg || null}</div>;
    };

    const showResetPasswordErrorMsg = () => {
      // @ts-ignore
      if (!this.state?.sendCodeErrorMsg) {
        return null;
      }
      // sendCodeErrorMsg is not defined in Props but exists when send button is clicked
      // @ts-ignore
      return <div className="error-label">{this.state?.sendCodeErrorMsg || null}</div>;
    };

    const handleNewPwdInputChange = (e) => {
      e.persist();
      this.setState((prevState) => ({ ...prevState, newPassword: e.target?.value || null }));
    };

    const resendCode = () => {
      super.send();
      this.setState((prevState) => ({ ...prevState, codeSent: true }));
    };

    const handleSend = (event) => {
      if (event) {
        event.preventDefault();
      }
      Hub.listen('auth', (res) => {
        const { payload } = res;
        const code = payload.data?.code || null;
        if (code === 'UserNotFoundException') {
          this.setState((prevState) => ({
            ...prevState,
            sendAttempted: true,
            sendCodeErrorMsg: 'Email not found.',
          }));
        } else if (code === 'LimitExceededException') {
          this.setState((prevState) => ({
            ...prevState,
            sendAttempted: true,
            sendCodeErrorMsg: 'You have tried that too many times. Please try again in 1 hour.',
          }));
        } else
          this.setState((prevState) => ({
            ...prevState,
            sendAttempted: true,
            sendCodeErrorMsg: payload.data?.message,
          }));
      });

      if (this.sendCodeInputRef.current?.value !== '') {
        Auth.forgotPassword(sanitize(this.getUsernameFromInput()));
        this.setState((prevState) => ({
          ...prevState,
          sendAttempted: true,
          delivery: {
            AttributeName: 'email',
            DeliveryMedium: 'EMAIL',
            Destination: sanitize(this.getUsernameFromInput()),
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          sendAttempted: true,
          sendCodeErrorMsg: 'Email field cannot be empty.',
        }));
      }
    };

    const submit = () => {
      const { authData = {} } = this.props;
      const { code, password } = this.inputs;
      const username = this.getUsernameFromInput() || authData.username;
      const sanitizedUsername = sanitize(username);

      if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
        throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
      }

      Auth.forgotPasswordSubmit(sanitizedUsername, code, password)
        .then((data) => {
          this.setState({ delivery: null });
          this.setState((prevState) => ({ ...prevState, passwordReset: true }));
        })
        .catch((err) => {
          codeMismatchHandler(err.code);
          limitExceededHandler(err.code);
          this.error(err);
        });
    };

    const codeMismatchHandler = (errCode) => {
      if (errCode === 'CodeMismatchException')
        this.setState((prevState) => ({
          ...prevState,
          sendAttempted: true,
          codeErrorMsg: 'Invalid verification code.',
        }));
      else
        this.setState((prevState) => ({
          ...prevState,
          sendAttempted: true,
          // No errors founds
          codeErrorMsg: null,
        }));
    };

    const limitExceededHandler = (errCode) => {
      if (errCode === 'LimitExceededException')
        this.setState((prevState) => ({
          ...prevState,
          sendAttempted: true,
          attemptsErrorMsg: 'Attempt limit exceeded, please try after some time.',
        }));
      else
        this.setState((prevState) => ({
          ...prevState,
          sendAttempted: true,
          // No errors founds
          attemptsErrorMsg: null,
        }));
    };

    const handleSubmit = (event) => {
      if (event) {
        event.preventDefault();
      }
      this.setState((prevState) => ({ ...prevState, submitAttempted: true }));
      Hub.listen('auth', (res) => {
        const { payload } = res;
        const errCode = payload.data.code;
        codeMismatchHandler(errCode);
        limitExceededHandler(errCode);
      });

      if (
        //@ts-ignore
        this.inputs.password === this.state?.newPassword &&
        this.inputs.password !== undefined &&
        /\d/.test(this.inputs.password) &&
        this.inputs.password !== ''
      ) {
        submit();
      }
    };

    const renderForm = () => {
      // @ts-ignore
      if (!this.state?.passwordReset) {
        return this.state.delivery ? (
          <NewPasswordScreen
            // @ts-ignore
            codeSentState={this.state?.codeSent}
            resendCode={resendCode}
            showPasswordErrorMessage={showPasswordErrorMessage}
            showCodeErrorMessage={showCodeErrorMessage}
            showAttemptsErrorMessage={showAttemptsErrorMessage}
            changeState={(state) => {
              this.setState((prevState) => ({ ...prevState, delivery: null }));
              this.changeState(state);
            }}
            handleInputChange={this.handleInputChange}
            handleSubmit={handleSubmit}
            handleNewPwdInputChange={handleNewPwdInputChange}
          />
        ) : (
          <SendCodeScreen
            emailRef={this.sendCodeInputRef}
            showErrorMessage={showResetPasswordErrorMsg}
            changeState={this.changeState}
            handleInputChange={this.handleInputChange}
            handleSend={handleSend}
          />
        );
      }
      return (
        <ResetConfirmationScreen
          changeState={() => {
            this.setState((prevState) => ({
              ...prevState,
              delivery: null,
              newPassword: null,
              sendAttempted: false,
              submitAttempted: false,
              passwordReset: false,
            }));
            this.changeState('signIn');
          }}
        />
      );
    };
    return (
      <div className="login-container">
        <div className="login-body">
          <div className="login-title">
            <StudioLogo fontSize={72} color="white" />
          </div>

          <form name="login-form" ref={this.formRef}>
            <ScrollingIcons />
            {renderForm()}
          </form>
        </div>
        <div className="footer-text">
          <p>
            Proudly built by{' '}
            <a target="blank" href="https://www.goodmaps.com/">
              GoodMaps
            </a>{' '}
            in Louisville, Kentucky
          </p>
        </div>
      </div>
    );
  }
}

export default CustomForgotPassword;
