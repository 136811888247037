interface Language {
  name: string;
  order: number;
}

interface LanguageDictionary {
  [locale: string]: Language;
}

const dictionary: LanguageDictionary = {
  en: { name: 'English', order: 1 },
  fr: { name: 'Canadian French', order: 2 },
  es: { name: 'Spanish', order: 3 },
};

export default dictionary;
