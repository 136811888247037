import React from 'react';
import { AuthRole } from 'goodmaps-utils';
import Label from 'components/dummy/Label';
import { Form } from 'react-bootstrap';

type Props = {
  entityTypeName: string;
  authRole: AuthRole[];
  onToggle: (value: boolean) => void;
  // doorTypeName: string; TODO: Need to add support for this
  // dimensions: string; TODO: Need to add support for this
};

export default ({ entityTypeName, authRole, onToggle }: Props) => {
  return (
    <>
      <div>
        <label className="inline-label">
          Entity Type: <span>{entityTypeName}</span>
        </label>
      </div>
      <div>
        <label className="inline-label">
          Door Type: <span>Not supported</span>
        </label>
      </div>
      <div>
        <label className="inline-label">
          Dimensions: <span>Not supported</span>
        </label>
      </div>
      <Label key="security">Security Role</Label>
      <Form.Check
        type="switch"
        id="role-switch"
        onChange={(event) => {
          onToggle(event.target.checked);
        }}
        checked={authRole[0] === AuthRole.Private}
        label={authRole[0] === AuthRole.Private ? AuthRole[1] : AuthRole[0]}
      />
    </>
  );
};
