import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Alert } from 'react-bootstrap';
import { deployBuildingData } from 'helpers/BuildingManagerHelper';
import CircularLoadingIndicator from 'components/dummy/CircularProgressIndicator';

type props = {
  id: string;
  name: string;
  onClose: () => void;
  onSuccess?: () => Promise<void>;
};

const DeployModal = ({ id, name, onClose, onSuccess }: props) => {
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { email }: { email: string } = useSelector((state) => state.session.user);

  const deploy = async () => {
    try {
      setIsLoading(true);
      if (error) setError('');
      if (response) setResponse('');

      const timestamp = localStorage.getItem(id.split('.')[1]);

      if (!timestamp)
        throw new Error('No reference file found, make sure your import was successful');

      const result = await deployBuildingData({ id, timestamp, userId: email });

      console.log(result);

      result.status === 201 &&
        setResponse(`The last imported osm file for ${name} was successfully deployed.`);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal.Header closeButton={true}>
        <Modal.Title>Deploy Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <CircularLoadingIndicator />
        ) : (
          <>
            {error ? <Alert variant="danger">{error}</Alert> : null}
            {response ? <Alert variant="success">{response}</Alert> : null}
            Do you want to deploy to {name}?
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="danger" onClick={deploy}>
          Deploy
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DeployModal;
