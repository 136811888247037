import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

import './CircularProgressIndicator.scss';

const style = {
  loader: {
    backgroundColor: 'blue',
    color: '#414143',
  },
};

const CircularProgressIndicator = ({ classes, full = false }) => {
  return (
    <div className="circular-progress" style={{ minHeight: full ? '100vh' : 0 }}>
      <CircularProgress size={30} classes={{ circle: classes.loader }} color="secondary" />
      {/* Loading... */}
    </div>
  );
};

export default withStyles(style)(CircularProgressIndicator);
