import { EntityProps, Base } from '../../../globals/Types';

export default class Beacon implements Base {
  uuid: string;
  major: number;
  minor: number;
  distance: number;
  position: { x: number; y: number };
  props: EntityProps;
  entityType: number;

  constructor(
    uuid: string,
    major: number,
    minor: number,
    position: { x: number; y: number },
    props: EntityProps,
    entityType = 4
  ) {
    this.uuid = uuid;
    this.major = major;
    this.minor = minor;
    this.position = position;
    this.props = props;
    this.entityType = entityType;
  }

  setDistanceFromRSSI(rssi: number) {
    const a0 = -65;
    const indice = (rssi - a0) / -20;
    const d = 10 ** indice;
    this.distance = d;
  }
}
