import React, { useState } from 'react';
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { ExtraInfoType, EIURL } from 'goodmaps-utils';
import LanguageDictionary from 'globals/Languages';
import { getMatchingArrayValues } from 'helpers/EditorHelper';
import './URLExtraInfo.scss';

const EMPTY_URL_OBJ: EIURL = {
  type: ExtraInfoType.WebURL,
  name: '',
  url: '',
  mlUrl: {},
  mlName: {},
};

type Props = {
  selectedLanguage: string;
  defaultLanguage: string;
  initialValue: EIURL[];
  onChange: (eiURLs: EIURL[]) => void;
};

const URLExtraInfo = ({ selectedLanguage, initialValue, onChange }: Props) => {
  const [extraInfoURL, setExtraInfoURL] = useState<EIURL[]>([EMPTY_URL_OBJ]);

  React.useEffect(() => {
    if (initialValue.length === 0) setExtraInfoURL([EMPTY_URL_OBJ]);
    else setExtraInfoURL(initialValue);
  }, [initialValue]);

  const updateExtraInfoURL = (indexToMatch: number, value: string, propToReplace: string) => {
    // if previous value was an empty string ignore the new value
    if (Object.keys(extraInfoURL[indexToMatch][propToReplace]).length === 0 && value === '') return;

    const newURL: EIURL = {
      ...extraInfoURL[indexToMatch],
      [propToReplace]: { ...extraInfoURL[indexToMatch][propToReplace], [selectedLanguage]: value },
    };

    // Reset values
    if (newURL[propToReplace][selectedLanguage] === '')
      delete newURL[propToReplace][selectedLanguage];
    if (propToReplace.includes('Name') && value === '') newURL.name = '';
    if (propToReplace.includes('Url') && value === '') newURL.url = '';

    const newEIUrl = extraInfoURL.map((url, i) => (i !== indexToMatch ? url : newURL));

    onChange(newEIUrl);
    setExtraInfoURL(newEIUrl);
  };

  const renderURLFields = () => {
    return extraInfoURL.map((urlInfo, index) => {
      return (
        <FormGroup controlId={`url-control-${index}`} key={`url-control-${index}`}>
          <FormLabel className="extra-info-header">URL</FormLabel>
          <div className="extra-info-sub-header">
            <FormLabel>
              Display Text
              {urlInfo.mlUrl[selectedLanguage] || urlInfo.mlName[selectedLanguage] ? (
                <span className="required">*</span>
              ) : null}
            </FormLabel>
            {index !== 0 ? (
              <Button
                variant="link"
                className="clear"
                onClick={() => {
                  const tempInfo = [...extraInfoURL];
                  tempInfo.splice(index, 1);
                  setExtraInfoURL(tempInfo);
                  onChange(tempInfo);
                }}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="link"
                className="clear"
                onClick={() => {
                  const tempInfo = [...extraInfoURL];
                  tempInfo.splice(index, 1);
                  setExtraInfoURL(tempInfo);
                  onChange(tempInfo);
                }}
              >
                Clear
              </Button>
            )}
          </div>
          <FormControl
            name="mlUrlName"
            className="entityInputField"
            placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Display Text`}
            value={urlInfo.mlName[selectedLanguage] || ''}
            onChange={(e) => {
              updateExtraInfoURL(index, e.target.value as string, 'mlName');
            }}
            onBlur={(e) => {
              updateExtraInfoURL(index, e.target.value as string, 'mlName');
            }}
            aria-label="entityNameLabel"
            aria-placeholder={`Display Text`}
            autoComplete="new-password"
          />
          <FormLabel className="extra-info-sub-header">
            Link Address
            {urlInfo.mlUrl[selectedLanguage] || urlInfo.mlName[selectedLanguage] ? (
              <span className="required">*</span>
            ) : null}
          </FormLabel>
          <FormControl
            name="mlUrl"
            className="entityInputField"
            placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Link Address`}
            value={urlInfo.mlUrl[selectedLanguage] || ''}
            onChange={(e) => {
              updateExtraInfoURL(index, e.target.value as string, 'mlUrl');
            }}
            onBlur={(e) => {
              updateExtraInfoURL(index, e.target.value as string, 'mlUrl');
            }}
            aria-label="entityNameLabel"
            aria-placeholder={`Link Address`}
            autoComplete="new-password" // Turns off chrome auto complete (for the moment). This was trying to auto fill with an address for some reason
          />
        </FormGroup>
      );
    });
    // }
  };

  return (
    <div id="extraInfoURL">
      {renderURLFields()}

      <Button
        className="add-more-info"
        variant="link"
        onClick={() => {
          setExtraInfoURL([...extraInfoURL, EMPTY_URL_OBJ]);
        }}
      >
        Add additional URL
      </Button>
    </div>
  );
};

export const isURLValid = (eiURLs: EIURL[]) => {
  // An empty array is an acceptable value
  if (eiURLs.length === 0) return true;

  let isValid = true;
  eiURLs.forEach((eiURL) => {
    // An empty URL object is not an acceptable value, means it was not constructed correctly
    if (Object.keys(eiURL).length === 0) {
      // console.log('No eiUrl object');
      isValid = false;
      return;
    }

    // TODO: Make a mlString validation function?
    const { mlName, mlUrl } = eiURL;

    if (mlName == null || mlUrl == null) {
      // console.log('mlName or mlUrl is null');
      isValid = false;
      return;
    }

    const nameKeys = Object.keys(mlName);
    const urlKeys = Object.keys(mlUrl);

    if (nameKeys.length === 0 && urlKeys.length === 0) {
      isValid = false;
      return;
    }

    // Non-matching pair, not valid
    if (nameKeys.length !== urlKeys.length) {
      // console.log('non-matching pair');
      isValid = false;
    } else {
      const result = getMatchingArrayValues(nameKeys, urlKeys);

      if (result.length !== nameKeys.length) {
        isValid = false;
        return;
      }

      result.forEach((key) => {
        const name = mlName[key];
        const url = mlUrl[key];

        // console.log('EI name and url', name, url);

        // one key is null so don't save
        if (name == null || url == null) {
          // console.log('name or url is null');
          isValid = false;
          return;
        }

        // one is an empty string, but how do we override values?
        // Need to do compare values against previous record, we need to allow empty strings
        // for removal purposes
        // How do we pull out the relevant info to compare? Name changes, index can also change.
        // if(name === '' && url === '') {
        //   isValid = false;
        //   return;
        // }

        // We only want this to trigger false when one field is filled and the other is an empty string or a space.
        // Example, name === 'website' (url === ' ' || url === '') returns false

        /**
         * Used when you want to see if a string is empty, str === ''
         * @param str string
         * @returns true if string is empty
         */
        const isEmptyString = (str: string) => !str || str.length === 0;

        /**
         * Used when you want to see if a string is empty OR contains only whitespaces
         * @param str string
         * @returns true if string is empty or only contains whitespaces
         */
        const isBlankString = (str: string) => !str || /^\s*$/.test(str);

        if (
          !(
            (!isBlankString(name) && !isBlankString(url)) ||
            (isEmptyString(name) && isEmptyString(url))
          )
        ) {
          isValid = false;
          return;
        }
      });
    }
  });

  return isValid;
};

export default URLExtraInfo;
