import * as gm from 'goodmaps-sdk';

export default class Route {
  id: string;
  level: number;
  routeType: number;
  points: { level: number; position: gm.Position; connectionType: gm.ConnectionType }[];
  constructor(
    id: string,
    level: number,
    routeType: gm.RouteType,
    points: { level: number; position: gm.Position; connectionType: gm.ConnectionType }[]
  ) {
    this.id = id;
    this.routeType = routeType;
    this.level = level;
    this.points = points;
  }
}
