import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import './SignIn.scss';

const NewPasswordScreen = ({
  resendCode,
  handleInputChange,
  handleNewPwdInputChange,
  changeState,
  handleSubmit,
  showPasswordErrorMessage,
  showCodeErrorMessage,
  showAttemptsErrorMessage,
  codeSentState,
}) => {
  return (
    <div className="form">
      <div className="back-arrow-button" onClick={() => changeState('forgotPassword')}>
        <ArrowBack style={{ fontSize: 18 }} />
      </div>
      <div className="form-header">
        <div className="header-label">Check your email</div>
        <div className="subheader-label">A code has been sent to the email you provided.</div>
      </div>
      <div className="form-body">
        <div className="form-formfield flex">
          <div className="input-label">Verification Code</div>
          <input
            className="email-input"
            id="code"
            key="code"
            name="code"
            onChange={handleInputChange}
            type="text"
            placeholder="Enter your code"
          />
          <span
            style={{
              color: codeSentState ? '#56bd38' : '#0937bf',
            }}
            className="resend-link"
            data-test="resend-link"
            onClick={resendCode}
          >
            {!codeSentState ? 'Resend code?' : 'Code resent'}
          </span>
          <div className="sub-label-box flex">{showAttemptsErrorMessage()}</div>
          <div className="sub-label-box flex">{showCodeErrorMessage()}</div>
        </div>
        <div className="form-header">
          <div className="subheader-label">
            Your password must be 8 characters and contain at least 1 number.
          </div>
        </div>
        <div className="form-formfield flex">
          <div className="input-label">New Password</div>
          <input
            placeholder="Enter your password"
            type="password"
            name="new-password"
            id="new-password"
            className="password-input"
            onChange={(e) => handleNewPwdInputChange(e)}
          />
          <div className="sub-label-box flex">{showPasswordErrorMessage()}</div>
        </div>
        <div className="form-formfield flex">
          <div className="input-label">Confirm New Password</div>
          <input
            placeholder="Re-enter your password"
            type="password"
            name="password"
            id="password"
            className="password-input"
            onChange={handleInputChange}
          />
          <div className="sub-label-box flex">{showPasswordErrorMessage()}</div>
        </div>
      </div>
      <div className="sign-in-footer-section flex">
        <span className="footer-content">
          <button type="submit" className="sign-in-button" onClick={(e) => handleSubmit(e)}>
            SUBMIT
          </button>
        </span>
      </div>
    </div>
  );
};

export default NewPasswordScreen;
