import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import ParentModal from './ParentModal';
import DeleteModal from './DeleteModal';
import { Table, Modal, Alert } from 'react-bootstrap';
import './ParentList.scss';
import { BuildingManagementMode } from 'globals/Types';
import CustomerList from './CustomerList';
import { ChevronRight } from '@material-ui/icons';
import { User, userIsGoodMapsAdmin, PartnerData } from 'goodmaps-sdk';
import {
  getPartnerList,
  addPartner,
  deletePartner,
  editPartner,
} from 'helpers/BuildingManagerHelper';
import HoverMenu from 'components/dummy/HoverMenu';
import { ICONS } from 'globals/Icons';

const PartnerList = () => {
  const history = useHistory();
  const user: User = useSelector((state) => state.session.user);
  const [partnerList, setPartnerList] = useState<PartnerData[]>([]);
  const [expandedPartnerList, setExpandedPartnerList] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [partner, setPartner] = useState<PartnerData>({
    id: '',
    name: '',
    parentId: '',
    createdAtISO: '',
    createdByUserId: '',
    modifiedAtISO: '',
    modifiedByUserId: '',
    ancestors: [],
  });
  const [mode, setMode] = useState<BuildingManagementMode>(0);
  const [error, setError] = useState('');
  const overlayContainer = useRef(null);

  useEffect(() => {
    if (userIsGoodMapsAdmin(user.auth)) {
      const getPartners = async () => {
        try {
          const { message: partnerList } = await getPartnerList();
          setPartnerList(partnerList);
          setExpandedPartnerList(new Array<boolean>(partnerList.length).fill(false));
        } catch (e) {
          setError(`The partner list is not available. ${e}`);
        } finally {
          setIsLoading(false);
        }
      };
      getPartners();
    }
  }, [history, user.auth]);

  const openModal = (
    mode: BuildingManagementMode,
    partner: PartnerData = {
      id: '',
      name: '',
      parentId: '',
      createdAtISO: '',
      createdByUserId: '',
      modifiedAtISO: '',
      modifiedByUserId: '',
      ancestors: [],
    }
  ) => {
    setMode(mode);
    setPartner(partner);
  };

  const closeModal = () => {
    setMode(BuildingManagementMode.none);
  };

  const updatePartnersList = async (newPartner?: PartnerData) => {
    setIsTableLoading(true);

    try {
      if (mode === BuildingManagementMode.add) {
        await addPartner(newPartner);
      } else if (mode === BuildingManagementMode.edit) {
        await editPartner({ ...partner, name: newPartner.name });
      } else if (mode === BuildingManagementMode.delete) {
        await deletePartner(partner.id);
      }

      const { message: partnerList } = await getPartnerList();
      setPartnerList(partnerList);
      setExpandedPartnerList(new Array<boolean>(partnerList.length).fill(false));
    } catch (e) {
      setError(`The partner list failed to refresh. ${e}`);
    } finally {
      setIsTableLoading(false);
    }
  };

  const showListOfCustomers = async (index) => {
    // history.push(`/partners/${partner.id}/customers`);
    setExpandedPartnerList([
      ...expandedPartnerList.slice(0, index),
      !expandedPartnerList[index],
      ...expandedPartnerList.slice(index + 1),
    ]);
  };

  const tableItems = partnerList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((partner, index) => {
      return (
        <React.Fragment key={partner.id}>
          <tr className={expandedPartnerList[index] ? 'expanded' : ''}>
            <td>
              <div onClick={() => showListOfCustomers(index)}>
                <ChevronRight className="chevron" /> {partner.name}
              </div>
            </td>
            <td style={{ padding: 0, verticalAlign: 'middle' }}>
              <HoverMenu
                menuOptions={{
                  iconSrc: ICONS.misc.three_dot_menu,
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                }}
                menuItems={[
                  {
                    displayText: `Edit`,
                    onClick: () => openModal(BuildingManagementMode.edit, partner),
                  },
                  {
                    displayText: `Delete`,
                    onClick: () => openModal(BuildingManagementMode.delete, partner),
                  },
                ]}
              />
            </td>
          </tr>
          {expandedPartnerList[index] && (
            <tr key={index + partner.id + 'child'}>
              <td className="child-container" colSpan={2}>
                <CustomerList partnerId={partner.id} partnerName={partner.name} />
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });

  return isLoading ? (
    <CircularProgressIndicator full />
  ) : (
    <div className="partner-table-wrapper" ref={overlayContainer}>
      <div className="partner-table">
        <div className="partner-table-header flex flex-row items-center space-between">
          <div id="table-header" className="flex flex-row">
            <h1>Partners</h1>
            <div className="button-action flex items-center justify-center">
              <button
                className="table-button backgrounded"
                onClick={() => openModal(BuildingManagementMode.add)}
              >
                + Add
              </button>
            </div>
          </div>
        </div>
        {error ? (
          <div>
            <Alert variant="danger" style={{ marginBottom: 0 }}>
              {error}
            </Alert>
          </div>
        ) : null}
        {isTableLoading ? (
          <CircularProgressIndicator full />
        ) : (
          <Table bordered className="background" size="sm">
            <thead>
              <tr>
                <th>Partner Name</th>
                <th style={{ width: 37 }}></th>
              </tr>
            </thead>
            <tbody>
              {tableItems.length === 0 ? (
                <tr>
                  <td colSpan={2}>
                    <div className="empty-table">
                      <h6>Empty Partners Table</h6>
                    </div>
                  </td>
                </tr>
              ) : (
                tableItems
              )}
            </tbody>
          </Table>
        )}
      </div>

      <Modal centered show={mode === BuildingManagementMode.delete} onHide={closeModal}>
        <DeleteModal name={partner.name} onClose={closeModal} onSuccess={updatePartnersList} />
      </Modal>

      <Modal centered show={mode === BuildingManagementMode.add} onHide={closeModal}>
        <ParentModal
          name={partner?.name}
          title={'Add'}
          onClose={closeModal}
          updateObjectList={(name: string) => updatePartnersList({ ...partner, name })}
        />
      </Modal>

      <Modal centered show={mode === BuildingManagementMode.edit} onHide={closeModal}>
        <ParentModal
          name={partner?.name}
          title={'Edit'}
          onClose={closeModal}
          updateObjectList={(name: string) => updatePartnersList({ ...partner, name })}
        />
      </Modal>
    </div>
  );
};

export default PartnerList;
