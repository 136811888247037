import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import ParentModal from './ParentModal';
import DeleteModal from './DeleteModal';
import { Table, Modal, Alert } from 'react-bootstrap';
import './ParentList.scss';
import { BuildingManagementMode } from 'globals/Types';
import CampusList from './CampusList';
import { ChevronRight } from '@material-ui/icons';
import { CustomerData, User, userIsGoodMapsAdmin } from 'goodmaps-sdk';
import {
  getCustomerList,
  addCustomer,
  deleteCustomer,
  editCustomer,
} from 'helpers/BuildingManagerHelper';
import HoverMenu from 'components/dummy/HoverMenu';
import { ICONS } from 'globals/Icons';

type Props = {
  partnerId: string;
  partnerName: string;
};

const CustomerList = ({ partnerId, partnerName }: Props) => {
  const history = useHistory();
  const user: User = useSelector((state) => state.session.user);
  const [customerList, setCustomerList] = useState<CustomerData[]>([]);
  const [expandedCustomerList, setExpandedCustomerList] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [customer, setCustomer] = useState<CustomerData>({
    id: '',
    name: '',
    parentId: '',
    createdAtISO: '',
    createdByUserId: '',
    modifiedAtISO: '',
    modifiedByUserId: '',
    ancestors: [],
  });
  const [mode, setMode] = useState<BuildingManagementMode>(BuildingManagementMode.none);
  const [error, setError] = useState('');

  useEffect(() => {
    if (userIsGoodMapsAdmin(user.auth)) {
      const getCustomers = async () => {
        try {
          const { message: customerList } = await getCustomerList(partnerId);
          setCustomerList(customerList);
          setExpandedCustomerList(new Array<boolean>(customerList.length).fill(false));
        } catch (e) {
          setError(`The customer list is not available. ${e}`);
        } finally {
          setIsLoading(false);
        }
      };
      getCustomers();
    }
  }, [history, partnerId, user.auth]);

  const openModal = (
    mode: BuildingManagementMode,
    customer: CustomerData = {
      id: '',
      name: '',
      parentId: '',
      createdAtISO: '',
      createdByUserId: '',
      modifiedAtISO: '',
      modifiedByUserId: '',
      ancestors: [],
    }
  ) => {
    setMode(mode);
    setCustomer(customer);
  };

  const closeModal = () => {
    setMode(BuildingManagementMode.none);
  };

  const updateCustomersList = async (newCustomer?: CustomerData) => {
    setIsTableLoading(true);
    try {
      if (mode === BuildingManagementMode.add) {
        await addCustomer(partnerId, newCustomer);
      } else if (mode === BuildingManagementMode.edit) {
        await editCustomer(partnerId, { ...customer, name: newCustomer.name });
      } else if (mode === BuildingManagementMode.delete) {
        await deleteCustomer(partnerId, customer.id);
      }

      const { message: listOfCustomers } = await getCustomerList(partnerId);
      setCustomerList(listOfCustomers);
      setExpandedCustomerList(new Array<boolean>(listOfCustomers.length).fill(false));
    } catch (e) {
      setError(`The customer list failed to refresh. ${e}`);
    } finally {
      setIsTableLoading(false);
    }
  };

  const showListOfCampuses = (index: number) => {
    setExpandedCustomerList([
      ...expandedCustomerList.slice(0, index),
      !expandedCustomerList[index],
      ...expandedCustomerList.slice(index + 1),
    ]);
  };

  const tableItems = customerList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((customer, index) => {
      return (
        <React.Fragment key={customer.id}>
          <tr className={expandedCustomerList[index] ? 'expanded' : ''}>
            <td>
              <div onClick={() => showListOfCampuses(index)}>
                <ChevronRight className="chevron" />
                {customer.name}
              </div>
            </td>
            <td style={{ padding: 0, verticalAlign: 'middle', width: 37 }}>
              <HoverMenu
                menuOptions={{
                  iconSrc: ICONS.misc.three_dot_menu,
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                }}
                menuItems={[
                  {
                    displayText: `Edit`,
                    onClick: () => openModal(BuildingManagementMode.edit, customer),
                  },
                  {
                    displayText: `Delete`,
                    onClick: () => openModal(BuildingManagementMode.delete, customer),
                  },
                ]}
              />
              {/* <div>
              <button
                className="table-button"
                onClick={() => openModal(BuildingManagementMode.edit, customer)}
              >
                Edit
              </button>
              <button
                className="table-button"
                onClick={() => openModal(BuildingManagementMode.delete, customer)}
              >
                Delete
              </button>
            </div> */}
            </td>
          </tr>
          {expandedCustomerList[index] && (
            <tr>
              <td className="child-container alt" colSpan={2}>
                <CampusList
                  customerName={customer.name}
                  customerId={customer.id}
                  partnerId={partnerId}
                />
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });

  return isLoading ? (
    <CircularProgressIndicator />
  ) : (
    <div>
      {isTableLoading ? (
        <CircularProgressIndicator />
      ) : (
        <>
          <div className="table-header">
            <h2>Customers</h2>
            <button
              className="table-button backgrounded"
              onClick={() => openModal(BuildingManagementMode.add)}
            >
              + Add
            </button>
          </div>
          {error ? (
            <div>
              <Alert variant="danger">{error}</Alert>
            </div>
          ) : null}
          <Table>
            <tbody>
              {tableItems.length === 0 ? (
                <tr>
                  <td colSpan={2}>
                    <div className="empty-table">
                      <h6>Empty "{partnerName}" Customer Table</h6>
                    </div>
                  </td>
                </tr>
              ) : (
                tableItems
              )}
            </tbody>
          </Table>
        </>
      )}

      <Modal centered={true} show={mode === BuildingManagementMode.delete} onHide={closeModal}>
        <DeleteModal name={customer?.name} onClose={closeModal} onSuccess={updateCustomersList} />
      </Modal>

      <Modal centered show={mode === BuildingManagementMode.add} onHide={closeModal}>
        <ParentModal
          name={customer?.name}
          title={'Add'}
          onClose={closeModal}
          updateObjectList={(name: string) => updateCustomersList({ ...customer, name })}
        />
      </Modal>

      <Modal centered show={mode === BuildingManagementMode.edit} onHide={closeModal}>
        <ParentModal
          name={customer?.name}
          title={'Edit'}
          onClose={closeModal}
          updateObjectList={(name: string) => updateCustomersList({ ...customer, name })}
        />
      </Modal>
    </div>
  );
};

export default CustomerList;
