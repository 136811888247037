import React from 'react';
import Link from 'components/dummy/Link';
import './Directory.scss';
import { DirectoryProps } from '../smart/Directory';

const Directory = ({ links, title = 'Directory' }: DirectoryProps) => {
  const trimmedTitle = title.trim() || 'Directory';
  return (
    <div className="directory-container">
      <h3 className="directory-title">{trimmedTitle}</h3>
      <div className="link-container">
        {links.map((link, index) => {
          return <Link key={link.primary + link.to + index} {...link} />;
        })}
      </div>
    </div>
  );
};

export default Directory;
