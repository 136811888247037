const MyTheme = {
  toast: {
    display: 'none',
    // fontSize: '16px',
    // color: 'white',
    // backgroundColor: '#364cd9',
  },

  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    margin: '0',
    background:
      'linear-gradient( rgb(0, 51, 186), rgb(53, 75, 216) 33%, rgb(54, 76, 217) 33%, rgb(91, 93, 238), rgb(111, 112, 255))',
  },

  button: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: 'rgb(0, 51, 186)',
    border: 'none',
    borderRadius: '5px',
  },
};

export default MyTheme;
