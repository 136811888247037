import {
  FLOOR_SELECTED,
  TOP_VIEW_TOGGLED,
  CLEAR_SELECTED_ENTITY,
  SHOW_MAP_CONTROLS,
  SET_EDIT_MODE,
  SET_NEW_POI_COORDS,
  SET_SELECTED_ENTITY,
  SHOW_DELETE_POI_DIALOGUE,
  CLEAR_SELECTED_FLOOR,
  CLEAR_NEW_POI_COORDS,
  RESET_VIEWER_STATE,
} from './types';
import { ActiveEntity, EditModeType } from '../globals/Types';
import { Position, BaseType } from 'goodmaps-sdk';
import { getEntityPath } from 'helpers/goodmaps-helper/GoodMaps';
import {
  selectEntity,
  flyFloorOutAsync,
  renderFloor,
  flyBuildingPlanInAsync,
  deselectEntity,
  renderRoutes,
  renderTestPoints,
  renderUnnamedPois,
  flyBuildingPlanOutAsync,
} from 'renderer/Renderer';
import { Entities } from 'helpers/goodmaps-helper/GoodMaps';
import * as LabelRenderer from '../renderer/LabelRenderer';
import { ICONS } from 'globals/Icons';
import { fetchBuilding } from './SessionActions';
import * as FunctionQueue from 'helpers/FunctionQueueHelper';

let canvasElementHovered = null;
let htmlElementHovered = null;

export const resetViewerState = () => {
  return { type: RESET_VIEWER_STATE };
};

export const getCanvasHoveredElement = () => {
  return canvasElementHovered;
};

export const setCanvasHoveredElement = (entity: ActiveEntity) => {
  canvasElementHovered = entity;
};

export const getHTMLHoveredElement = () => {
  return htmlElementHovered;
};

export const setHTMLHoveredElement = (entity: ActiveEntity) => {
  htmlElementHovered = entity;
};

export const setSelectedEntity = (entityId, history, mapClick = false) => {
  return (dispatch, getState) => {
    const { allEntities, buildings, isFormDirty } = getState().session;
    const { editMode, newPOICoords } = getState().viewer;

    if (editMode === 'move') return;
    if (entityId === 'newPOI') return;

    const entity = allEntities.find((entity) => entity.props.id === entityId);
    const building = buildings.find((building) => building.id === entityId);
    const splitPath = history.location.pathname.split('/');

    if (!entity && building) {
      fetchBuilding(building.id);
      history.replace({
        pathname: `/`,
        state: { previousScreen: window.location.pathname.split('/').length },
      });
      history.replace({
        pathname: `/building/${building.id}`,
        state: { previousScreen: window.location.pathname.split('/').length },
      });
      return;
    }

    if (mapClick) {
      if (isFormDirty) FunctionQueue.pause();
      if (newPOICoords != null) {
        FunctionQueue.enqueue(() => dispatch(clearNewPOICoords()));
      }
    }

    FunctionQueue.enqueue(() => selectEntity(entityId, mapClick ? 'reduced' : 'full'));

    FunctionQueue.enqueue(() => dispatch({ type: SET_SELECTED_ENTITY, payload: entity }));

    // Prevent second navigation to entity in editor page if selection was made in the editor page
    if (!mapClick) {
      return;
    } else {
      history.push({
        pathname:
          `/${splitPath[1]}/${splitPath[2]}/level/${entity.props.level}/` +
          getEntityPath(entity as ActiveEntity),
        state: { previousScreen: window.location.pathname.split('/').length },
      });
    }
  };
};

export const clearSelectedEntity = () => {
  deselectEntity();
  return { type: CLEAR_SELECTED_ENTITY };
};

export const selectFloor = (
  level: number,
  history: any,
  selectedEntityFromEditor?: { id: string }
) => {
  return async (dispatch, getState) => {
    const { currentFloor, selectedEntity } = getState().viewer;
    const { selectedBuildingUUID, isFormDirty } = getState().session;

    if (isFormDirty) FunctionQueue.pause();

    if (selectedEntity) FunctionQueue.enqueue(() => dispatch(clearSelectedEntity()));

    FunctionQueue.enqueue(() => dispatch({ type: FLOOR_SELECTED, payload: level }));

    if (selectedEntityFromEditor) {
      FunctionQueue.enqueue(async () => {
        await flyBuildingPlanOutAsync(level);
        const { id } = selectedEntityFromEditor;
        dispatch(setSelectedEntity(id, history));
      });
    } else if (!selectedEntityFromEditor && currentFloor === null) {
      FunctionQueue.enqueue(() => flyBuildingPlanOutAsync(level));

      history.push({
        pathname: `/building/${selectedBuildingUUID}/level/${level}`,
        state: {
          previousScreen: history.location?.pathname
            ? history.location.pathname.split('/').length
            : 0,
        },
      });
    } else {
      FunctionQueue.enqueue(() => renderFloor(level));
      FunctionQueue.enqueue(() => dispatch(animateFloorSelect(level, currentFloor)));

      history.push({
        pathname: `/building/${selectedBuildingUUID}/level/${level}`,
        state: { previousScreen: history.location.pathname.split('/').length },
      });
    }
  };
};

export const clearSelectedFloor = () => {
  return async (dispatch, getState) => {
    const { currentFloor } = getState().viewer;

    flyBuildingPlanInAsync(currentFloor);
    dispatch({ type: CLEAR_SELECTED_FLOOR });
  };
};

export const animateFloorSelect = (level: number, currentFloor: number) => {
  return async (dispatch) => {
    const direction = currentFloor > level ? 'up' : 'down';
    flyFloorOutAsync(direction, level);
  };
};

export const toggleTopViewShortcut = () => {
  return { type: TOP_VIEW_TOGGLED };
};

export const checkRoutesVisibility = () => {
  return async (dispatch, getState) => {
    const { currentFloor, showRoutes } = getState().viewer;
    showRoutes ? renderRoutes(currentFloor) : renderRoutes(null);
  };
};

export const checkTestPointsVisibility = () => {
  return async (dispatch, getState) => {
    const { currentFloor, showTestPoints } = getState().viewer;
    showTestPoints ? renderTestPoints(currentFloor) : renderTestPoints(null);
  };
};

export const checkUnnamedPoiVisibility = () => {
  return async (dispatch, getState) => {
    const { currentFloor, showUnnamedPois } = getState().viewer;
    if (currentFloor !== null) renderUnnamedPois(showUnnamedPois);
  };
};

export const toggleMapControls = () => {
  return { type: SHOW_MAP_CONTROLS };
};

export const setEditMode = (editMode: EditModeType) => {
  return { type: SET_EDIT_MODE, payload: editMode };
};

export const setNewPOICoords = (coords: Position, history: any, currentPOI?: string) => {
  if (currentPOI === undefined) {
    const { selected: marker } = ICONS.editor;
    const { x, y, level } = coords;
    const splitPath = history.location.pathname.split('/');

    if (splitPath[splitPath.length - 1] !== 'new') {
      history.push({
        pathname: `/building/${splitPath[2]}/level/${level}/${Entities.pois}/new`,
      });
    }

    LabelRenderer.addLabel({ x, y }, 'New POI', marker, '', '', 'newPOI', BaseType.POI);
    LabelRenderer.updateLabelState('newPOI', { selected: true });
  }

  return { type: SET_NEW_POI_COORDS, payload: coords };
};

export const cancelCreateNewPOI = () => {
  return async (dispatch, getState) => {
    const { newPOICoords } = getState().viewer;
    if (newPOICoords != null) dispatch(clearNewPOICoords());
  };
};

export const clearNewPOICoords = () => {
  LabelRenderer.removeLabel('newPOI');
  return { type: CLEAR_NEW_POI_COORDS };
};

export const setShowDeletePOIDialogue = (state: boolean) => {
  return { type: SHOW_DELETE_POI_DIALOGUE, payload: state };
};
