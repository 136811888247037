import React from 'react';
import Directory from 'components/smart/Directory';
import RowCheckbox from 'components/map-editor/RowCheckbox';
import EditorPage from 'components/smart/EditorPage';
import { Link } from 'globals/Types';

type Props = {
  title: string;
  icon: string;
  showBeacons: boolean;
  links: Link[];
  onClick: () => void;
  isBeacon: () => boolean;
};

const Category = ({ icon, title, showBeacons, links, isBeacon, onClick }: Props) => {
  return (
    <EditorPage title={title} icon={icon}>
      {isBeacon() && (
        <RowCheckbox
          label="Display beacons"
          toggle={showBeacons}
          containerStyle={{ marginBottom: 30, marginTop: 0 }}
          onButtonClick={() => onClick()}
        />
      )}

      <Directory links={links || []} />
    </EditorPage>
  );
};

export default Category;
