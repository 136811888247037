import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setNewPOICoords, setEditMode, setShowDeletePOIDialogue } from 'actions/ViewerActions';
import './ContextMenu.scss';
import { hideContextMenu } from 'actions/EditEntityActions';
import { convertClientCoords } from 'helpers/EditorHelper';

type Props = {
  xPos: number;
  yPos: number;
};

const ContextMenu = ({ xPos, yPos }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentFloor: number = useSelector((state) => state.viewer.currentFloor);
  const shouldShowExpandedMenu: boolean = useSelector(
    (state) => state.viewer.shouldShowExpandedMenu
  );

  const coords = convertClientCoords(xPos, yPos, 384, 10);

  if (currentFloor == null) return null;

  return (
    <div className="context-menu-container" style={{ top: yPos, left: xPos }}>
      {shouldShowExpandedMenu ? (
        <>
          <div
            className="context-menu-option"
            onClick={() => {
              dispatch(setEditMode('move'));
              dispatch(hideContextMenu(true));
            }}
          >
            <label className="menu-option-label">Move POI</label>
          </div>
          <div
            className="context-menu-option"
            onClick={() => {
              dispatch(setShowDeletePOIDialogue(true));
              dispatch(hideContextMenu(true));
            }}
          >
            <label className="menu-option-label">Delete POI</label>
          </div>
        </>
      ) : null}
      <div
        className="context-menu-option"
        onClick={() => {
          dispatch(
            setNewPOICoords(
              {
                x: coords.x,
                y: coords.y,
                z: 0,
                level: currentFloor,
              },
              history
            )
          );
          dispatch(hideContextMenu());
        }}
      >
        <label className="menu-option-label">Add POI</label>
      </div>
    </div>
  );
};

export default ContextMenu;
