import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditorPage from 'components/smart/EditorPage';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import '../building-viewer/EditPanel/EditorRoot.scss';
import './LandingPage.scss';
import Directory from 'components/smart/Directory';
import Floor from 'components/floorplan/structures/Floor';
import { clearBuildingSelection } from 'actions/SessionActions';
import { BuildingMetaData } from 'goodmaps-utils';
import { Link } from 'globals/Types';

const LandingPage = () => {
  const history = useHistory();
  const buildings: BuildingMetaData[] = useSelector((state) => state.session.buildings);
  const campuses: BuildingMetaData[] = useSelector((state) => state.session.campuses);
  const floors: Floor[] = useSelector((state) => state.session.floors);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    if (floors.length !== 0) dispatch(clearBuildingSelection());
  }, [dispatch, floors.length]);

  //at initial useEffect Call buildings.length = 0
  React.useEffect(() => {
    if (buildings.length === 1) {
      history.push(`/building/${buildings[0].id}`);
    } else if (buildings.length !== 0) {
      setIsLoading(false);
    }

    setIsLoading(false);
  }, [buildings, history]);

  const links = React.useMemo((): Link[] => {
    const buildingToCampusCountArr: { id: string; count: number }[] = buildings.reduce(
      (arr, val) => {
        const ancestors = val.ancestors.filter((anc) => anc.includes('Campus'));
        ancestors.forEach((anc) => {
          const ancestorId = anc.slice(anc.indexOf('.') + 1);
          const idIndex = arr.findIndex((x) => x.id === ancestorId);
          if (idIndex !== -1) arr[idIndex].count += 1;
          else {
            arr.push({ id: anc.slice(anc.indexOf('.') + 1), count: 1 });
          }
        });
        return arr;
      },
      []
    );

    return [
      ...campuses.map((b) => ({
        primary: b.name,
        secondary: `Campus with ${
          buildingToCampusCountArr.find((x) => x.id === b.id)?.count || 0
        } Buildings`,
        to: `/campus/${b.id}/level/0`,
      })),
      ...buildings.map((b) => ({
        primary: b.name,
        secondary: 'Building',
        to: `/building/${b.id}`,
        color: '#ffdafa',
      })),
    ];
  }, [buildings, campuses]);

  const filteredLinks = React.useMemo(
    () => links.filter((link) => link.primary.toLowerCase().includes(search.toLowerCase())),
    [search, links]
  );

  return isLoading ? (
    <CircularProgressIndicator full />
  ) : (
    <div className="landing-page-container vh-100 w-100 flex items-center flex-column">
      <div className="editor-root">
        <EditorPage title="Choose a Building" showSearch search={search} setSearch={setSearch}>
          <Directory links={search ? filteredLinks : links} />
          {filteredLinks.length === 0 || links.length === 0
            ? 'No buildings or campuses found'
            : null}
        </EditorPage>
      </div>
      <div className="landing-page-background absolute w-100 h-100 cover no-repeat bg-bottom" />
    </div>
  );
};

export default LandingPage;
