import {
  SAVE_BUILDING_CHANGES_TYPE,
  SET_CURRENT_BUILDING,
  SHOW_CONTEXT_MENU,
  CLEAR_HIGHLIGHTED_ENTITY,
  SET_HIGHLIGHTED_ENTITY,
} from './types';
import { Entities, getEntityPath } from 'helpers/goodmaps-helper/GoodMaps';
import * as Renderer from '../renderer/Renderer';
import {
  updateLabelText,
  updateLabelIcon,
  refreshLabelById,
  removeLabel,
  addLabel,
  setupGroups,
  updateLabelState,
} from '../renderer/LabelRenderer';
import { ActiveEntity, POI } from 'globals/Types';
import {
  Building,
  POIType,
  Change,
  EIMarkupText,
  EIURL,
  ExtraInfoType,
  createChange,
  Position,
  Connection,
  ConnectionType,
  BaseType,
} from 'goodmaps-sdk';
import { clearNewPOICoords } from './ViewerActions';
import { getBuildingFloors } from 'helpers/goodmaps-helper/GoodMapsHelper';
import { getBuildingManager } from 'helpers/goodmaps-helper/BuildingManager';
import { getMapIcon } from 'helpers/goodmaps-helper/GoodMaps';

export const showContextMenu = (
  xPos: number,
  yPos: number,
  clickedExistingPOI: boolean = false
) => {
  return async (dispatch) => {
    await dispatch({
      type: SHOW_CONTEXT_MENU,
      payload: {
        contextMenuIsVisible: true,
        contextMenuCoords: { x: xPos, y: yPos },
        shouldShowExpandedMenu: clickedExistingPOI,
      },
    });
  };
};

// shouldDeselectLabel seems to be doing the opposite here NOT de-selecting when set to true. Leaving alone for now
export const hideContextMenu = (shouldDeselectLabel?: boolean) => {
  return async (dispatch, getState) => {
    const { contextMenuIsVisible } = getState().viewer;
    if (!contextMenuIsVisible) return;

    if (!shouldDeselectLabel) Renderer.deselectEntity();
    await dispatch({
      type: SHOW_CONTEXT_MENU,
      payload: {
        contextMenuIsVisible: false,
        contextMenuCoords: { x: null, y: null },
        shouldShowExpandedMenu: false,
      },
    });
  };
};

export const setHighlightedEntity = (entityId: string, history) => {
  return async (dispatch, getState) => {
    const { newPOICoords } = getState().viewer;
    const { allEntities } = getState().session;
    const entity = allEntities.find((entity) => entity.props.id === entityId);

    const splitPath = history.location.pathname.split('/');

    if (newPOICoords != null) {
      dispatch(clearNewPOICoords());
      history.replace({
        pathname:
          `/building/${splitPath[2]}/level/${entity.props.level}/` +
          getEntityPath(entity as ActiveEntity),
        state: { previousScreen: window.location.pathname.split('/').length },
      });
    } else {
      history.push({
        pathname:
          `/building/${splitPath[2]}/level/${entity.props.level}/` +
          getEntityPath(entity as ActiveEntity),
        state: { previousScreen: window.location.pathname.split('/').length },
      });
    }

    await dispatch({
      type: SET_HIGHLIGHTED_ENTITY,
      payload: {
        highlightedEntity: entity,
      },
    });
  };
};

export const clearHighlightedEntity = () => {
  return async (dispatch) => {
    await dispatch({
      type: CLEAR_HIGHLIGHTED_ENTITY,
    });
  };
};

export const resetFloorLabels = (building, level, entityId?) => {
  return async (dispatch) => {
    Renderer.setBuilding(building);

    Renderer.renderFloorLabels(level);

    // Select POI
    entityId !== null && Renderer.selectEntity(entityId, 'reduced');
  };
};

export const createPOI = (
  change: Change,
  history: any,
  level: number,
  poiType: POIType,
  position: Position
) => {
  return async (dispatch) => {
    let entityId = null;
    try {
      const result = await change.save();
      console.log(result, change);

      entityId = result.entityId;
    } catch (e) {
      throw Error(
        'Unable to create new POI. Please try again. If the problem continues contact support@goodmaps.com.'
      );
    }

    try {
      const buildingManager = getBuildingManager();
      const { buildings } = await buildingManager.getBuildings();
      const buildingMetaData = buildings.find((building) => building.id === change.building.uuid);
      const building = await buildingManager.createBuilding(buildingMetaData);

      const floors = getBuildingFloors(building);

      await dispatch({
        type: SET_CURRENT_BUILDING,
        payload: {
          floors,
          building,
          buildingUUID: building.uuid,
          buildingName: building.name,
        },
      });

      const { x, y } = position;

      // add new poi to renderers and recalc label groups
      addLabel(
        { x, y },
        change.data.postLoad.mlName.en,
        getMapIcon(Entities.pois, poiType),
        '',
        '',
        entityId,
        BaseType.POI
      );
      Renderer.addObject(entityId);
      setupGroups();

      // set new poi as the selected entity
      Renderer.selectEntity(entityId, 'none');

      // set updated building
      Renderer.setBuilding(building);

      //return createdPoiId
      return entityId;
    } catch (e) {
      throw Error(
        'Unable to create new POI. Please try again. If the problem continues contact support@goodmaps.com. Error2'
      );
    }
  };
};

export const updateEntity = (
  entity: SAVE_BUILDING_CHANGES_TYPE,
  userId: string,
  building: Building,
  buildingLevel?: number
) => {
  return async (dispatch, getState) => {
    try {
      const extraInfo: (EIMarkupText | EIURL)[] = entity.mlUrl
        ? [...entity.mlUrl.filter((ei) => ei.mlName?.en !== '')]
        : [];

      if (
        entity?.mlGenInfo &&
        Object.keys(entity.mlGenInfo).length &&
        entity.mlGenInfo?.en !== ''
      ) {
        extraInfo.push({
          type: ExtraInfoType.MarkupText,
          name: '',
          mlName: {
            en: 'General Information',
          },
          markup: '',
          mlMarkup: {
            ...entity.mlGenInfo,
          },
        });
      }

      if (
        entity?.mlSnapshot &&
        Object.keys(entity.mlSnapshot).length &&
        entity.mlSnapshot?.en !== ''
      ) {
        extraInfo.push({
          type: ExtraInfoType.MarkupText,
          name: '',
          mlName: {
            en: 'Snapshot',
          },
          markup: '',
          mlMarkup: {
            ...entity.mlSnapshot,
          },
        });
      }

      const change: Change = createChange()
        .initUpdate(userId, Date.now(), entity.id, building)
        .setName({ ...entity.mlName })
        .setExtraInfo(extraInfo)
        .setAuthList(entity.authRole)
        .setShortName({ ...entity.mlShortName });

      // @ts-ignore
      if (entity?.poiType) {
        change.setPOIType(entity.poiType);
        updateLabelIcon(entity.id, getMapIcon(Entities.pois, entity.poiType));
      }

      let oldEntity;
      if (entity.type === Entities.elements)
        oldEntity = building.getElements().find((element) => element.id === entity.id);
      if (entity.type === Entities.connections)
        oldEntity = building.getConnections().find((element) => element.id === entity.id);
      if (entity.type === Entities.pois)
        oldEntity = building.getPOIs().find((element) => element.id === entity.id);
      if (entity.type === Entities.doors)
        oldEntity = building.getDoors().find((element) => element.id === entity.id);

      // Need to know what language to update to. I'll assume english for now
      // Use selected language but that would need to be an app wide setting
      // else use default building language
      if (oldEntity?.mlName.en !== entity.mlName.en) updateLabelText(oldEntity, entity.mlName.en);
      refreshLabelById(entity.id);

      //if showUnnamedPois is set, and an unnamed poi was just edited the name must be filled, update label states
      const { showUnnamedPois } = getState().viewer;
      if (showUnnamedPois && !oldEntity?.mlName.en && entity.type === Entities.pois) {
        updateLabelState(entity.id, { alwaysShown: false, outlined: false });
      }

      // remove texture if base type element, auth roles don't match, and the new one is public
      // TODO: Fixtures are pulled from a buildings .getElements() function and yet we have a BaseType
      //   defined for them. This is confusing. Are they a BaseType or a subType of the ElementType?
      //   This, and inside the LabelRenderer, seems to be the only places where we use the BaseType
      //     One more spot in Map.tsx when hovering over a POI, checks for POI type
      if (
        (oldEntity.baseType === BaseType.Element || oldEntity.baseType === BaseType.Fixture) &&
        oldEntity.authList[0] !== entity.authRole[0] &&
        entity.authRole[0] === 0
      )
        Renderer.updateSelectedObjectTexture(entity.id, '');

      // add texture if base type element, auth roles don't match, and the new one is not public
      if (
        (oldEntity.baseType === BaseType.Element || oldEntity.baseType === BaseType.Fixture) &&
        oldEntity.authList[0] !== entity.authRole[0] &&
        entity.authRole[0] !== 0
      ) {
        Renderer.updateSelectedObjectTexture(entity.id, 'security');
      }

      // remove security texture if base type Connection && stairs, auth roles don't match, and the new one is public
      if (
        oldEntity.baseType === BaseType.Connection &&
        (oldEntity as Connection).connectionType === ConnectionType.Stairs &&
        oldEntity.authList[0] !== entity.authRole[0] &&
        entity.authRole[0] === 0
      ) {
        Renderer.updateSelectedObjectTexture(entity.id, 'stairs');
      }
      // remove texture if base type Connection && !stairs, auth roles don't match, and the new one is public
      if (
        oldEntity.baseType === BaseType.Connection &&
        (oldEntity as Connection).connectionType !== ConnectionType.Stairs &&
        oldEntity.authList[0] !== entity.authRole[0] &&
        entity.authRole[0] === 0
      ) {
        Renderer.updateSelectedObjectTexture(entity.id, '');
      }
      // add texture if base type Connection, auth roles don't match, and the new one is private
      if (
        oldEntity.baseType === BaseType.Connection &&
        oldEntity.authList[0] !== entity.authRole[0] &&
        entity.authRole[0] !== 0
      ) {
        Renderer.updateSelectedObjectTexture(entity.id, 'stairs-security');
      }

      await change.save();
    } catch (e) {
      throw Error(
        'Unable to update entity. Please try again. If the problem continues contact support@goodmaps.com.'
      );
    }

    try {
      const buildingManager = getBuildingManager();
      const { buildings } = await buildingManager.getBuildings();
      const buildingMetaData = buildings.find((b) => b.id === building.uuid);
      const newBuilding = await buildingManager.createBuilding(buildingMetaData);

      const floors = getBuildingFloors(newBuilding);

      await dispatch({
        type: SET_CURRENT_BUILDING,
        payload: {
          floors,
          building: newBuilding,
          buildingUUID: building.uuid,
          buildingName: building.name,
          level: buildingLevel,
        },
      });

      // set updated building
      Renderer.setBuilding(newBuilding);
    } catch (e) {
      throw Error(
        'Unable to update entity. Please try again. If the problem continues contact support@goodmaps.com. Error2'
      );
    }
  };
};

// save new poi position
export const movePOI = (
  pos: Position,
  entity: ActiveEntity,
  userId: string,
  level: number,
  building: Building
) => {
  return async (dispatch) => {
    try {
      const change: Change = createChange()
        .initUpdate(userId, Date.now(), entity.props.id, building)
        .setPosition(pos);

      await change.save();
    } catch (e) {
      throw Error(
        'Unable to move POI. Please try again. If the problem continues contact support@goodmaps.com.'
      );
    }

    try {
      const buildingManager = getBuildingManager();
      const { buildings } = await buildingManager.getBuildings();
      const buildingMetaData = buildings.find((b) => b.id === building.uuid);
      const newBuilding = await buildingManager.createBuilding(buildingMetaData);

      const floors = getBuildingFloors(newBuilding);

      await dispatch({
        type: SET_CURRENT_BUILDING,
        payload: {
          floors,
          building: newBuilding,
          buildingUUID: building.uuid,
          buildingName: building.name,
          level: level,
        },
      });

      updateLabelIcon(entity.props.id, getMapIcon(entity.entityType, (entity as POI).poiType));

      // set updated building
      Renderer.setBuilding(newBuilding);
    } catch (e) {
      throw Error(
        'Unable to move POI. Please try again. If the problem continues contact support@goodmaps.com. Error2'
      );
    }
  };
};

export const deletePOI = (
  entity: ActiveEntity,
  userId: string,
  building: Building,
  history: any
) => {
  return async (dispatch) => {
    try {
      const change: Change = createChange()
        .initUpdate(userId, Date.now(), entity.props.id, building)
        .markForDelete();

      await change.save();
    } catch (e) {
      throw Error(
        'Unable to delete POI. Please try again. If the problem continues contact support@goodmaps.com.'
      );
    }

    try {
      const buildingManager = getBuildingManager();
      const { buildings } = await buildingManager.getBuildings();
      const buildingMetaData = buildings.find((b) => b.id === building.uuid);
      const newBuilding = await buildingManager.createBuilding(buildingMetaData);

      const floors = getBuildingFloors(newBuilding);

      await dispatch({
        type: SET_CURRENT_BUILDING,
        payload: {
          floors,
          building: newBuilding,
          buildingUUID: building.uuid,
          buildingName: building.name,
          level: entity.props.level,
        },
      });

      const splitPath = history.location.pathname.split('/');

      history.push({
        pathname: `/${splitPath[1]}/${splitPath[2]}/level/${splitPath[4]}`,
      });

      // from here map changes go into effect

      removeLabel(entity.props.id);

      // set updated building
      Renderer.setBuilding(newBuilding);
    } catch (e) {
      throw Error(
        'Unable to delete POI. Please try again. If the problem continues contact support@goodmaps.com. Error2'
      );
    }
  };
};
