import {
  SHOW_BEACONS,
  SHOW_ROOMS,
  SHOW_POIS,
  SHOW_DOORS,
  SHOW_ROUTES,
  SHOW_TESTPOINTS,
  SHOW_ALL_POIS,
  SHOW_GRID,
  FLOOR_SELECTED,
  TOP_VIEW_TOGGLED,
  CLEAR_SELECTED_ENTITY,
  RESET_VIEWER_STATE,
  SET_ZOOM_LEVEL,
  SHOW_MAP_CONTROLS,
  SET_EDIT_MODE,
  SET_NEW_POI_COORDS,
  SET_SELECTED_ENTITY,
  SHOW_DELETE_POI_DIALOGUE,
  CLEAR_NEW_POI_COORDS,
  CLEAR_SELECTED_FLOOR,
  SHOW_CONTEXT_MENU,
  SET_HIGHLIGHTED_ENTITY,
  CLEAR_HIGHLIGHTED_ENTITY,
} from '../actions/types';
import { ActiveEntity, ErrorType, ZoomLevelType } from 'globals/Types';
import { Position } from 'goodmaps-sdk';
import { EditModeType } from 'globals/Types';
import { renderRoutes, renderTestPoints, toggleTopView } from 'renderer/Renderer';

export type ViewerState = {
  selectedEntity: ActiveEntity;
  error: ErrorType;
  editMode: EditModeType;
  newPOICoords: Position;
  isTopView: boolean;
  currentFloor: number;
  showBeacons: boolean;
  showRooms: boolean;
  showDoors: boolean;
  showPois: boolean;
  showRoutes: boolean;
  showTestPoints: boolean;
  showUnnamedPois: boolean;
  showGrid: boolean;
  zoomLevel: ZoomLevelType;
  showMapControls: boolean;
  showDeletePOIDialogue: boolean;
  contextMenuIsVisible: boolean;
  contextMenuCoords: { x: number; y: number };
  shouldShowExpandedMenu: boolean;
  highlightedEntity: HTMLElement;
};

const initialState: ViewerState = {
  selectedEntity: null,
  error: null,
  editMode: 'none',
  newPOICoords: null,
  isTopView: false,
  currentFloor: null,
  showBeacons: false,
  showRooms: true,
  showDoors: false,
  showPois: false,
  showGrid: false,
  showRoutes: false,
  showTestPoints: false,
  showUnnamedPois: false,
  zoomLevel: { dimensions: { width: 100, height: 100 }, distance: 120, level: 0 },
  showMapControls: true,
  showDeletePOIDialogue: false,
  contextMenuIsVisible: false,
  contextMenuCoords: { x: null, y: null },
  shouldShowExpandedMenu: false,
  highlightedEntity: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_VIEWER_STATE: {
      return {
        ...initialState,
      };
    }

    case CLEAR_SELECTED_ENTITY: {
      return { ...state, selectedEntity: initialState.selectedEntity };
    }

    case SET_SELECTED_ENTITY: {
      return { ...state, selectedEntity: action.payload };
    }

    case FLOOR_SELECTED: {
      return {
        ...state,
        currentFloor: action.payload,
      };
    }

    case CLEAR_SELECTED_FLOOR: {
      return {
        ...state,
        currentFloor: initialState.currentFloor,
        showRoutes: initialState.showRoutes,
        showTestPoints: initialState.showTestPoints,
      };
    }

    case TOP_VIEW_TOGGLED: {
      toggleTopView(!state.isTopView);
      return { ...state, isTopView: !state.isTopView };
    }

    case SET_EDIT_MODE: {
      return { ...state, editMode: action.payload };
    }

    case SET_NEW_POI_COORDS: {
      return { ...state, newPOICoords: action.payload };
    }

    case CLEAR_NEW_POI_COORDS: {
      return { ...state, newPOICoords: null };
    }

    case SHOW_BEACONS:
      return { ...state, showBeacons: action.payload };

    case SHOW_DOORS:
      if (state.showDoors === action.payload) return state;
      return { ...state, showDoors: action.payload };

    case SHOW_POIS:
      return { ...state, showPois: action.payload };

    case SHOW_ROOMS:
      return { ...state, showRooms: action.payload };

    case SHOW_TESTPOINTS:
      !state.showTestPoints ? renderTestPoints(state.currentFloor) : renderTestPoints(null);
      return { ...state, showTestPoints: !state.showTestPoints };

    case SHOW_ROUTES:
      !state.showRoutes ? renderRoutes(state.currentFloor) : renderRoutes(null);
      return { ...state, showRoutes: !state.showRoutes };
    case SHOW_ALL_POIS:
      return { ...state, showUnnamedPois: !state.showUnnamedPois };
    case SHOW_GRID:
      return { ...state, showGrid: !state.showGrid };

    case SET_ZOOM_LEVEL:
      return { ...state, zoomLevel: action.payload };

    case SHOW_MAP_CONTROLS:
      return { ...state, showMapControls: !state.showMapControls };

    case SHOW_DELETE_POI_DIALOGUE:
      return { ...state, showDeletePOIDialogue: action.payload };

    case SHOW_CONTEXT_MENU:
      return {
        ...state,
        contextMenuIsVisible: action.payload.contextMenuIsVisible,
        contextMenuCoords: action.payload.contextMenuCoords,
        shouldShowExpandedMenu: action.payload.shouldShowExpandedMenu,
      };
    case SET_HIGHLIGHTED_ENTITY:
      return {
        ...state,
        highlightedEntity: action.payload.highlightedEntity,
      };

    case CLEAR_HIGHLIGHTED_ENTITY:
      return {
        ...state,
        highlightedEntity: initialState.highlightedEntity,
      };

    default:
      return state;
  }
};
