import React, { useEffect } from 'react';
import { useSelector, useDispatch, ReactReduxContext, Provider } from 'react-redux';
import './BuildingViewer.scss';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { fetchBuilding, fetchCampus } from '../../actions/SessionActions';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import BuildingControls from 'components/smart/BuildingControls';
import EditorRoot from './EditPanel/EditorRoot';
import EditModeBanner from 'components/smart/EditModeBanner';
import { EditModeType } from 'globals/Types';
import DeletePOIDialogue from './EditPanel/DeletePOIDialogue';
import Map from './Map';
import MapControls from './MapControls';
import ContextMenu from './EditPanel/ContextMenu';
import { Modal } from 'react-bootstrap';
import { setShowDeletePOIDialogue } from 'actions/ViewerActions';

const BuildingViewer = () => {
  const dispatch = useDispatch();
  const { id: currentBuildingID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const selectedBuildingUUID: string = useSelector((state) => state.session.selectedBuildingUUID);

  useEffect(() => {
    if (currentBuildingID.includes('?') || selectedBuildingUUID === currentBuildingID) return;

    const splitPath: string[] = location.pathname.split('/');

    const entityId = splitPath[splitPath.length - 1].includes('gm.')
      ? splitPath[splitPath.length - 1]
      : null;

    const level = splitPath.length >= 5 && splitPath[3].includes('level') ? splitPath[4] : null;

    const params = {
      level,
      entityId,
      history,
    };
    if (splitPath[1] === 'building') {
      dispatch(fetchBuilding(currentBuildingID, params));
    } else {
      dispatch(fetchCampus(currentBuildingID, params));
    }
  }, [currentBuildingID, dispatch, history, location.pathname, selectedBuildingUUID]);

  return (
    <>
      <Controls />
      <Map />
    </>
  );
};

const Controls = () => {
  const dispatch = useDispatch();
  const buildingLoading: boolean = useSelector((state) => state.session.buildingLoading);
  const editMode: EditModeType = useSelector((state) => state.viewer.editMode);
  const contextMenuIsVisible: boolean = useSelector((state) => state.viewer.contextMenuIsVisible);
  const contextMenuCoords: { x: number; y: number } = useSelector(
    (state) => state.viewer.contextMenuCoords
  );
  const showDeletePOIDialogue: boolean = useSelector((state) => state.viewer.showDeletePOIDialogue);

  if (buildingLoading) return <CircularProgressIndicator full />;
  return (
    <div className="building-viewer" onContextMenu={(e) => e.preventDefault()}>
      <BuildingControls />
      <MapControls />
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <Provider store={store}>
            {contextMenuIsVisible && (
              <ContextMenu xPos={contextMenuCoords.x} yPos={contextMenuCoords.y} />
            )}
            <Modal
              size="sm"
              centered={true}
              show={showDeletePOIDialogue}
              onHide={() => dispatch(setShowDeletePOIDialogue(false))}
            >
              <DeletePOIDialogue />
            </Modal>
            <EditorRoot />
            {editMode === 'move' ? <EditModeBanner /> : null}
          </Provider>
        )}
      </ReactReduxContext.Consumer>
      )
    </div>
  );
};

export default BuildingViewer;
