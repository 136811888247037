import React from 'react';
import { Alert as AlertComponent } from 'react-bootstrap';

type Props = {
  children: React.ReactNode;
  variant?: string;
};

const Alert = ({ children, variant = 'primary' }: Props) => {
  return <AlertComponent variant={variant}>{children}</AlertComponent>;
};

export default Alert;
