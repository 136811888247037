import React from 'react';
import './EditModeBanner.scss';
import { ActiveEntity, EditModeType } from 'globals/Types';
import { Position } from 'goodmaps-sdk';

type Props = {
  editMode: EditModeType;
  newPOICoords: Position;
  highlightedEntity: ActiveEntity;
  onCancelButtonClick: () => void;
  onSavePOIButtonClick: () => void;
};

const EditModeBanner = ({
  editMode,
  newPOICoords,
  highlightedEntity,
  onCancelButtonClick,
  onSavePOIButtonClick,
}: Props) => {
  const renderMoveBanner = () => {
    return (
      <div className="edit-mode-banner">
        <div className="edit-mode-text">
          Click anywhere on the map to move "{highlightedEntity.props.name}" or{' '}
        </div>
        <div className="edit-banner-button" onClick={() => onCancelButtonClick()}>
          CANCEL
        </div>
        <div
          className={`edit-banner-button ${newPOICoords ? 'enabled' : 'disabled'}`}
          onClick={newPOICoords ? () => onSavePOIButtonClick() : null}
        >
          SAVE NEW POI LOCATION
        </div>
      </div>
    );
  };

  return editMode === 'move' ? renderMoveBanner() : null;
};

export default EditModeBanner;
