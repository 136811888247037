import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { User } from 'goodmaps-utils';
import { adminDeleteUser } from 'helpers/AdminAPIHelper';
import Alert from 'components/dummy/Alert';

type Props = {
  user: User;
  onSubmitCallback: () => void;
  onClose: () => void;
};

const DeleteModal = ({ user, onSubmitCallback, onClose }: Props) => {
  const [response, setResponse] = React.useState({ text: '', status: '' });

  const onSubmit = async () => {
    try {
      const result = await adminDeleteUser(user.email);

      if (result.status === 200)
        setResponse({ text: 'Success! The user has been deleted.', status: '200' });
      else throw new Error('There was a problem deleting the user. Please try again');

      onSubmitCallback();
    } catch (err) {
      setResponse({
        text: `There was a problem deleting the user. Please try again. Error: ${
          (err as Error).message
        }`,
        status: '',
      });
    }
  };

  return (
    <>
      <Modal.Body>
        <p>Are you sure you want to delete the user named "{user.name || user.email}"?</p>
        {response.text ? (
          <Alert variant={response.status === '200' ? 'success' : 'danger'}>{response.text}</Alert>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        {response.status === '200' ? (
          <Button variant="success" onClick={onClose}>
            Close
          </Button>
        ) : (
          <Button variant="danger" onClick={onSubmit}>
            Confirm
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default DeleteModal;
