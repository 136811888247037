import React, { useState, useEffect } from 'react';
import EditorPage from 'components/smart/EditorPage';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Prompt } from 'react-router-dom';
import { updateEntity } from 'actions/EditEntityActions';
import { ActiveEntity, Room, RoomChange } from 'globals/Types';
import ReactGA from 'react-ga';
import {
  getEntityTypeAsString,
  getDefaultEntityIcon,
  EntityInfo,
  Entities,
} from 'helpers/goodmaps-helper/GoodMaps';
import LanguageDictionary from 'globals/Languages';
import { FormLabel, FormControl, Button, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import Label from 'components/dummy/Label';
import Dropdown from 'components/dummy/Dropdown';
import DropdownOption from 'components/dummy/DropdownOption';
import URLExtraInfo, { isURLValid } from './URLExtraInfo';
import {
  userIsGoodMapsAdmin,
  User,
  isEqual,
  AuthType,
  RoomType,
  SnapShotOptions,
  SnapShotRequestData,
  EIURL,
} from 'goodmaps-utils';
import { requestSnapShot } from 'helpers/BuildingManagerHelper';
import { ICONS } from 'globals/Icons';
import { InfoOutlined } from '@material-ui/icons';
import { Building } from 'goodmaps-sdk';
import { setIsFormDirty } from 'actions/SessionActions';
import { BEFORE_UNLOAD_MESSAGE } from 'globals/Constants';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { getValidAuthList } from 'helpers/EditorHelper';

//TODO: consolidate AuthType with the type (AuthRole) used in the poiEditor and connectionEditor

const defaultLanguage = 'en';

// Lobby, Bathrooms, Atriums (lobby == atrium)
const ALLOWED_SUBCATS_FOR_SNAPSHOT = [
  RoomType.Lobby,
  RoomType.Bathroom,
  RoomType.Restroom,
  RoomType.Restroom_Family,
  RoomType.Restroom_Female,
  RoomType.Restroom_Male,
  RoomType.Restroom_Unisex,
];

const ElementEditor = () => {
  const dispatch = useDispatch();
  const {
    catId,
    entityId,
    subCatId,
  }: { catId: string; subCatId: string; entityId: string; entityType: string } = useParams();
  const [selectedLanguage, setSelectedLanguage] = useState<'en' | 'fr' | 'es'>('en');
  const [change, setChange] = useState<RoomChange>({
    mlName: {},
    mlShortName: {},
    mlUrl: [],
    mlGenInfo: {},
    mlSnapshot: {},
    authList: [AuthType.Public],
    roomType: null,
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [formWasChanged, setFormWasChanged] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [languageWasChanged, setLanguageWasChanged] = useState({
    en: false,
    fr: false,
    es: false,
  });
  const currentFloor: number = useSelector((state) => state.session.currentFloor);
  const selectedBuildingUUID: string = useSelector((state) => state.session.selectedBuildingUUID);
  const allEntities: ActiveEntity[] = useSelector((state) => state.session.allEntities);
  const user: User = useSelector((state) => state.session.user);
  const originalChangeRef = React.useRef<RoomChange>({
    mlName: {},
    mlShortName: {},
    mlUrl: [],
    mlGenInfo: {},
    mlSnapshot: {},
    authList: [AuthType.Public],
    roomType: null,
  });
  const [snapShotResponse, setSnapShotResponse] = useState({ status: 0, message: '' });
  const [snapShotLoading, setSnapShotLoading] = useState(false);
  const building: Building = useSelector((state) => state.session.building);

  useBeforeUnload({
    when: formWasChanged,
    message: BEFORE_UNLOAD_MESSAGE,
  });

  useEffect(() => {
    const e = allEntities.find((entity) => entity.props.id === entityId);
    // if no default language set then try and set a default
    if (!e?.props?.mlName[defaultLanguage])
      e.props.mlName[defaultLanguage] = e.props.mlName?.default;
    if (!e?.props?.mlShortName[defaultLanguage]) e.props.mlShortName[defaultLanguage] = '';

    // if no mlGenInfo or no default Language then setup ml object with empty string for default
    if (!e?.props.mlGenInfo || !e?.props?.mlGenInfo[defaultLanguage])
      e.props.mlGenInfo = { [defaultLanguage]: '' };

    // if no mlSnapshot or no default Language then setup ml object with empty string for default
    if (!e?.props.mlSnapshot || !e?.props?.mlSnapshot[defaultLanguage])
      e.props.mlSnapshot = { [defaultLanguage]: '' };

    const defaultChange = {
      mlName: e?.props.mlName || {},
      mlShortName: e?.props.mlShortName || {},
      mlUrl: e?.props.mlUrl || [],
      mlGenInfo: e?.props.mlGenInfo || {},
      mlSnapshot: e?.props.mlSnapshot || {},
      authList: getValidAuthList(e?.props.authList),
      roomType: (e as Room)?.roomType != null ? (e as Room).roomType : null, //or null by default
    };

    setChange(defaultChange);

    originalChangeRef.current = defaultChange;
  }, [allEntities, catId, entityId]);

  const validate = (newChange: RoomChange, language?: string) => {
    const formChanged = !isEqual(newChange, originalChangeRef.current);

    setFormWasChanged(formChanged);

    if (formChanged) {
      setLanguageWasChanged({ ...languageWasChanged, [language]: true });
      setFormIsValid(isURLValid(newChange.mlUrl));
      dispatch(setIsFormDirty(true));
    } else {
      setLanguageWasChanged({ ...languageWasChanged, [language]: false });
      dispatch(setIsFormDirty(false));
    }
  };

  const resetForm = () => {
    setChange({
      mlName:
        {
          ...originalChangeRef.current.mlName,
        } || {},
      mlShortName:
        {
          ...originalChangeRef.current.mlShortName,
        } || {},
      mlUrl: originalChangeRef.current.mlUrl,
      mlGenInfo:
        {
          ...originalChangeRef.current.mlGenInfo,
        } || {},
      mlSnapshot:
        {
          ...originalChangeRef.current.mlSnapshot,
        } || {},
      authList: originalChangeRef.current.authList,
      roomType: originalChangeRef.current.roomType || null, //or null by default
    });

    setLanguageWasChanged({
      en: false,
      fr: false,
      es: false,
    });
    setFormWasChanged(false);
    dispatch(setIsFormDirty(false));
  };

  const renderLanguageFieldText = (key: string) => {
    const { name } = LanguageDictionary[key];

    // if default language, missing required, unsaved changes
    if (
      key === defaultLanguage &&
      change.mlName[defaultLanguage] === '' &&
      languageWasChanged[key]
    ) {
      return (
        <>
          {name}{' '}
          <span className="sub-label-italicized">
            (Default Language, (1) required field, Unsaved changes)
          </span>
        </>
      );
    }

    // if default language, unsaved changes
    if (key === defaultLanguage && languageWasChanged[key]) {
      return (
        <>
          {name} <span className="sub-label-italicized">(Default Language, Unsaved changes)</span>
        </>
      );
    }

    // if default language and missing required fields - name, icon
    if (key === defaultLanguage && change.mlName[defaultLanguage] === '') {
      return (
        <>
          {name}{' '}
          <span className="sub-label-italicized">(Default Language, (1) required field)</span>
        </>
      );
    }

    // if default language
    if (key === defaultLanguage)
      return (
        <>
          {name} <span className="sub-label-italicized">(Default Language)</span>
        </>
      );

    // if unsaved changes
    if (languageWasChanged[key])
      return (
        <>
          {name} <span className="sub-label-italicized">(Unsaved changes)</span>
        </>
      );

    return name;
  };

  const getSaveButtonState = () => {
    if (!formWasChanged) return 'none';
    if (formIsLoading) return 'saving';

    if (!formIsValid) return 'disabled';

    return 'save';
  };

  const renderSnapshot = () => {
    if (userIsGoodMapsAdmin(user.auth)) {
      return (
        <>
          <Button
            className="blue-link left no-margin-top margin-bottom"
            variant="link"
            onClick={() => {
              window.open(
                'https://accessexplorer.sharepoint.com/:w:/r/sites/GoodMaps/_layouts/15/doc2.aspx?sourcedoc=%7B162c5ce8-5c1e-4830-ad22-e97cb8370462%7D&action=edit&cid=a07a40d0-625e-4227-91e3-8e78e98c4d7c'
              );
            }}
          >
            How to create a Snapshot
          </Button>

          <FormControl
            name="mlSnapshot"
            className="entityInputField"
            as="textarea"
            style={{ height: '96px' }}
            placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} text`}
            value={change.mlSnapshot[selectedLanguage] || ''}
            onChange={(e) => {
              const newChange = {
                ...change,
                mlSnapshot: { ...change.mlSnapshot, [selectedLanguage]: e.target.value },
              };
              validate(newChange);
              setChange(newChange);
            }}
            // onBlur={(e) => {
            //   const newChange = {
            //     ...change,
            //     mlSnapshot: {
            //       ...change.mlSnapshot,
            //       [selectedLanguage]: e.target.value.trim(),
            //     },
            //   };

            //   validate(newChange, selectedLanguage);
            //   setChange(newChange);
            // }}
            aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Snapshot`}
          />
        </>
      );
    } else {
      return change.mlSnapshot[selectedLanguage] ? (
        <FormControl
          name="mlSnapshot"
          className="entityInputField disabled"
          as="textarea"
          style={{ height: '96px' }}
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} text`}
          value={change.mlSnapshot[selectedLanguage] || ''}
          disabled
        />
      ) : (
        <p style={{ marginBottom: '12px' }}>
          Add a Snapshot to this room!
          <br /> A Snapshot is a brief description of key landmarks in the room, always presented
          left to right in clockwise order. This feature helps people who are blind understand the
          layout of their environment immediately and adds an element of safety while navigating. To
          add a Snapshot to this room, click the request button below and a GoodMaps staff member
          will be in touch.
        </p>
      );
    }
  };

  const renderSnapshotRequest = () => {
    if (userIsGoodMapsAdmin(user.auth)) return;

    const getRequestText = (isLoading, hasSnapshot) => {
      if (isLoading) return 'Sending Request';

      if (hasSnapshot) return 'Request changes';

      return 'Request a Snapshot';
    };

    if (snapShotResponse.status === 0)
      return (
        <Button
          className={`blue-link right`}
          variant="link"
          onClick={async () => {
            if (snapShotLoading) return;

            setSnapShotLoading(true);

            const request: SnapShotRequestData = {
              entityId,
              link: window.location.href,
              type: change?.mlSnapshot[selectedLanguage]
                ? SnapShotOptions.Edit
                : SnapShotOptions.Add,
              email: user.email || user.id,
              name: user.name,
            };

            try {
              const result = await requestSnapShot(request);
              setSnapShotResponse(result);
              setSnapShotLoading(false);
            } catch (error) {
              console.log(error);
              setSnapShotResponse({
                message: (error as any)?.message,
                status: 500,
              });
              setSnapShotLoading(false);
            }
          }}
        >
          {getRequestText(snapShotLoading, change?.mlSnapshot[selectedLanguage])}
        </Button>
      );

    if (snapShotResponse.status === 200)
      return (
        <p className="snapShotSuccess right">
          <img src={ICONS.editor.green_check} alt="green check mark" /> Request received
        </p>
      );

    if (snapShotResponse.status === 500)
      return (
        <>
          <Button
            className="blue-link right"
            variant="link"
            onClick={async () => {
              if (snapShotLoading) return;

              setSnapShotLoading(true);

              const request: SnapShotRequestData = {
                entityId,
                link: window.location.href,
                type: change?.mlSnapshot[selectedLanguage]
                  ? SnapShotOptions.Edit
                  : SnapShotOptions.Add,
                email: user.email || user.id,
                name: user.name,
              };

              try {
                const result = await requestSnapShot(request);
                setSnapShotResponse(result);
                setSnapShotLoading(false);
              } catch (error) {
                console.log(error);
                setSnapShotResponse({
                  message: (error as any)?.message,
                  status: 500,
                });
              } finally {
                setSnapShotLoading(false);
              }
            }}
          >
            {getRequestText(snapShotLoading, change?.mlSnapshot[selectedLanguage])}
          </Button>
          <div className="snapShotError">
            <span className="bold">Something went wrong.</span>{' '}
            <span>
              Please try again to request a Snapshot. If the problem continues, contact{' '}
              <span className="underline">support@goodmaps.com</span>.
            </span>
          </div>
        </>
      );
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="extra-info-tooltip" className="left-align" {...props}>
        Announced in GoodMaps Explore as “extra information” and shown with an information icon.
      </Tooltip>
    );
  };

  return (
    <EditorPage
      title={change.mlName.en || getEntityTypeAsString(0, +catId)}
      icon={getDefaultEntityIcon(
        Entities.elements.toString(),
        catId,
        change.roomType != null ? change.roomType.toString() : null
      )}
      saveState={getSaveButtonState()}
      saveStateErrorMessage={`You must complete required fields before submitting in English`}
      onConfirmSave={async () => {
        setFormIsLoading(true);
        // Update
        ReactGA.event({
          category: 'Engagement',
          action: 'Saved building changes',
          label: `${user.name} updated building ${selectedBuildingUUID}, entity ${entityId}`,
        });

        try {
          await dispatch(
            updateEntity(
              {
                id: entityId,
                mlName: change.mlName,
                mlShortName: change.mlShortName,
                authRole: change.authList,
                mlUrl: change.mlUrl,
                mlGenInfo: change.mlGenInfo,
                mlSnapshot: change.mlSnapshot,
                type: Entities.elements,
                // roomType: change.roomType, TODO: Implement ability to change room type
              },
              user.email,
              building,
              currentFloor
            )
          );

          setFormWasChanged(false);
          setLanguageWasChanged({
            en: false,
            fr: false,
            es: false,
          });
        } catch (e) {
          window.alert(e);
        } finally {
          setFormIsLoading(false);
          dispatch(setIsFormDirty(false));
        }
      }}
      onCancelClick={resetForm}
    >
      <Prompt when={formWasChanged} message={BEFORE_UNLOAD_MESSAGE} />
      <div className="entity-body">
        <>
          <div>
            <label className="inline-label">
              Entity Type: <span>{getEntityTypeAsString(Entities.elements, +catId)}</span>
            </label>
          </div>
          {/* Only show this for rooms */}
          {subCatId ? (
            <>
              <Label>
                <span>{getEntityTypeAsString(Entities.elements, +catId)} Type</span>
                <span className="required">*</span>{' '}
                <span className="sub-label-italicized">(Required)</span>
              </Label>
              <Dropdown
                title={getEntityTypeAsString(Entities.elements, +catId, change.roomType) || ''}
                onSelect={(value) => {
                  const newChange = {
                    ...change,
                    roomType: value as number,
                  };

                  validate(newChange);

                  setChange(newChange);
                }}
                disabled
              >
                {Object.entries(EntityInfo[Entities.elements].type[+catId].type).map((entry) => {
                  return (
                    <DropdownOption key={entry[0]} value={entry[0]}>
                      {entry[1].name}
                    </DropdownOption>
                  );
                })}
              </Dropdown>
            </>
          ) : null}

          <Label>Security Role</Label>
          <Form.Check
            type="switch"
            id="role-switch"
            onChange={(event) => {
              const newChange = {
                ...change,
                authList: event.target.checked ? [AuthType.Private] : [AuthType.Public],
              };
              validate(newChange);
              setChange(newChange);
              ReactGA.event({
                category: 'Engagement',
                action: 'Edit security role',
                label: `${user.name} updated building ${selectedBuildingUUID}, Entity ${entityId}`,
              });
            }}
            checked={change.authList[0] === AuthType.Private}
            label={change.authList[0] === AuthType.Private ? AuthType[1] : AuthType[0]}
          />
        </>

        <hr />

        <p>
          Choose a language. You must enter required information in{' '}
          {LanguageDictionary[defaultLanguage].name} to save.
        </p>

        <Dropdown
          onSelect={(value) => {
            setSelectedLanguage(value as any);
          }}
          title={renderLanguageFieldText(selectedLanguage)}
        >
          {Object.keys(LanguageDictionary).map((key) => (
            <DropdownOption key={key} value={key} selected={key === selectedLanguage}>
              {renderLanguageFieldText(key)}
            </DropdownOption>
          ))}
        </Dropdown>

        <p style={{ marginTop: '-13px', marginBottom: '21px' }}>
          Define the fields below in {LanguageDictionary[selectedLanguage].name}.
        </p>

        <FormLabel className="label-text" id="entityNameLabel">
          Name
        </FormLabel>
        <FormControl
          name="mlName"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Name`}
          value={change.mlName[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlName: { ...change.mlName, [selectedLanguage]: e.target.value },
            };
            validate(newChange);

            setChange(newChange);
          }}
          // onBlur={(e) => {
          //   const newChange = {
          //     ...change,
          //     mlName: { ...change.mlName, [selectedLanguage]: e.target.value.trim() },
          //   };

          //   validate(newChange, selectedLanguage);
          //   setChange(newChange);
          // }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Name`}
        />

        <FormLabel className="label-text" id="entitySimplifiedNameLabel">
          Simplified Name
        </FormLabel>
        <FormControl
          name="mlSimplifiedName"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Simplified Name`}
          value={change.mlShortName[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlShortName: { ...change.mlShortName, [selectedLanguage]: e.target.value },
            };
            validate(newChange);

            setChange(newChange);
          }}
          // onBlur={(e) => {
          //   const newChange = {
          //     ...change,
          //     mlShortName: { ...change.mlShortName, [selectedLanguage]: e.target.value.trim() },
          //   };

          //   validate(newChange, selectedLanguage);
          //   setChange(newChange);
          // }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Simplified Name`}
        />

        <div>
          <FormLabel
            className="label-text no-bottom-margin"
            id="GenInfoLabel"
            style={{ marginRight: '8px' }}
          >
            Extra Information
          </FormLabel>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 250 }}
            overlay={renderTooltip}
          >
            <InfoOutlined style={{ height: 20, width: 20 }} />
          </OverlayTrigger>
        </div>

        <FormLabel className="extra-info-header" id="GenInfoLabel">
          General Information
        </FormLabel>

        <FormControl
          as="textarea"
          name="mlGenInfo"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} General Information`}
          value={change.mlGenInfo[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlGenInfo: { ...change.mlGenInfo, [selectedLanguage]: e.target.value },
            };
            validate(newChange);
            setChange(newChange);
          }}
          // onBlur={(e) => {
          //   const newChange = {
          //     ...change,
          //     mlGenInfo: { ...change.mlGenInfo, [selectedLanguage]: e.target.value.trim() },
          //   };

          //   validate(newChange, selectedLanguage);
          //   setChange(newChange);
          // }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} General Information`}
        />

        {ALLOWED_SUBCATS_FOR_SNAPSHOT.includes(+subCatId) ? (
          <>
            <FormLabel className="extra-info-header" id="entitySnapshotLabel">
              Snapshot
            </FormLabel>

            {renderSnapshot()}

            {renderSnapshotRequest()}
          </>
        ) : null}

        <URLExtraInfo
          defaultLanguage={defaultLanguage}
          selectedLanguage={selectedLanguage}
          initialValue={change.mlUrl}
          onChange={(eiURLs: EIURL[]) => {
            const newChange = {
              ...change,
              mlUrl: eiURLs,
            };

            validate(newChange, selectedLanguage);
            setChange(newChange);
          }}
        />
      </div>
    </EditorPage>
  );
};

export default ElementEditor;
