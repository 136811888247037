import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setEditMode, clearNewPOICoords } from 'actions/ViewerActions';
import { Building, Position } from 'goodmaps-sdk';
import { ActiveEntity, EditModeType } from 'globals/Types';
import { movePOI, resetFloorLabels, clearHighlightedEntity } from 'actions/EditEntityActions';
import EditModeBanner from '../dummy/EditModeBanner';
import { User } from 'goodmaps-sdk';

export default () => {
  const editMode: EditModeType = useSelector((state) => state.viewer.editMode);
  const newPOICoords: Position = useSelector((state) => state.viewer.newPOICoords);
  const highlightedEntity: ActiveEntity = useSelector((state) => state.viewer.highlightedEntity);
  const currentFloor: number = useSelector((state) => state.viewer.currentFloor);
  const building: Building = useSelector((state) => state.session.building);
  const user: User = useSelector((state) => state.session.user);
  const dispatch = useDispatch();

  const onCancelButtonClick = () => {
    dispatch(setEditMode('none'));
    dispatch(clearNewPOICoords());
    dispatch(resetFloorLabels(building, currentFloor, highlightedEntity.props.id));
    dispatch(clearHighlightedEntity());
  };

  const onSavePOIButtonClick = async () => {
    try {
      await dispatch(movePOI(newPOICoords, highlightedEntity, user.email, currentFloor, building));
      dispatch(clearNewPOICoords());
      dispatch(setEditMode('none'));
    } catch (e) {
      window.alert(e);
    }
  };

  return (
    <EditModeBanner
      editMode={editMode}
      newPOICoords={newPOICoords}
      highlightedEntity={highlightedEntity}
      onCancelButtonClick={onCancelButtonClick}
      onSavePOIButtonClick={onSavePOIButtonClick}
    />
  );
};
