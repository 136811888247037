import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring } from 'react-spring';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import { toggleTopViewShortcut } from 'actions/ViewerActions';
import {
  handleRoutesVisibility,
  handleTestPointsVisibility,
  handleUnnamedPoisVisibility,
} from 'actions/SessionActions';
import './MapControls.scss';
import { User, userIsGoodMapsAdmin } from 'goodmaps-sdk';

const MapControls = () => {
  const dispatch = useDispatch();
  const user: User = useSelector((state) => state.session.user);
  const isTopView: boolean = useSelector((state) => state.viewer.isTopView);
  const currentFloor: number = useSelector((state) => state.viewer.currentFloor);
  const showMapControls: boolean = useSelector((state) => state.viewer.showMapControls);
  const showRoutes: boolean = useSelector((state) => state.viewer.showRoutes);
  const showTestPoints: boolean = useSelector((state) => state.viewer.showTestPoints);
  const showUnnamedPois: boolean = useSelector((state) => state.viewer.showUnnamedPois);
  const [OS, setOS] = useState('');

  const openedHeight = userIsGoodMapsAdmin(user.auth) ? '550px' : '500px';

  const { width, height, paddingBottom, borderRadius } = useSpring({
    width: !showMapControls ? '154px' : '800px',
    height: !showMapControls ? '34px' : openedHeight,
    paddingBottom: !showMapControls ? '0px' : '30px',
    borderRadius: !showMapControls ? '10px' : '16px',
    config: { mass: 5, tension: 400, friction: 80 },
  });

  const keySpringConfig = {
    mass: 5,
    tension: 1000,
    friction: 100,
  };
  const shortcutKeySprings = {
    topViewBoxSpring: useSpring({
      background: isTopView ? '#979797' : '#f9f9f9',
      config: keySpringConfig,
    }),
    topViewKeyTextSpring: useSpring({
      color: isTopView ? '#ffffff' : '#000000',
      config: keySpringConfig,
    }),
    routesBoxSpring: useSpring({
      background: showRoutes ? '#979797' : '#f9f9f9',
      config: keySpringConfig,
    }),
    routesKeyTextSpring: useSpring({
      color: showRoutes ? '#ffffff' : '#000000',
      config: keySpringConfig,
    }),
    testPointsBoxSpring: useSpring({
      background: showTestPoints ? '#979797' : '#f9f9f9',
      config: keySpringConfig,
    }),
    testPointsKeyTextSpring: useSpring({
      color: showTestPoints ? '#ffffff' : '#000000',
      config: keySpringConfig,
    }),
    showAllPoisBoxSpring: useSpring({
      background: showUnnamedPois ? '#979797' : '#f9f9f9',
      config: keySpringConfig,
    }),
    showAllPoisKeyTextSpring: useSpring({
      color: showUnnamedPois ? '#ffffff' : '#000000',
      config: keySpringConfig,
    }),
  };

  const detectOS = () => {
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

    if (macosPlatforms.indexOf(platform) !== -1) {
      setOS('Mac OS');
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      setOS('Windows');
    }
  };

  useEffect(() => {
    detectOS();
  }, []);

  const topViewKey = ['V'];
  const routesKey = ['R'];
  const testPointsKey = ['T'];
  const poisKey = ['H'];

  const handleTopViewShortcut = useCallback(
    (topViewKey) => {
      dispatch(toggleTopViewShortcut());
    },
    [dispatch]
  );

  const handleRoutesShortcut = useCallback(
    (routesKey) => {
      currentFloor !== null && dispatch(handleRoutesVisibility());
    },
    [currentFloor, dispatch]
  );

  const handleTestPointsShortcut = useCallback(
    (testPointsKey) => {
      currentFloor !== null && dispatch(handleTestPointsVisibility());
    },
    [currentFloor, dispatch]
  );

  const handlePOIsShortcut = useCallback(
    (poisKey) => {
      //add auth
      currentFloor !== null &&
        userIsGoodMapsAdmin(user.auth) &&
        dispatch(handleUnnamedPoisVisibility());
    },
    [currentFloor, user, dispatch]
  );

  useKeyboardShortcut(topViewKey, handleTopViewShortcut);
  useKeyboardShortcut(routesKey, handleRoutesShortcut);
  useKeyboardShortcut(testPointsKey, handleTestPointsShortcut);
  useKeyboardShortcut(poisKey, handlePOIsShortcut);

  return (
    <>
      <div
        className="controls-box"
        style={{
          width: width,
          height: height,
          paddingBottom: paddingBottom,
          borderRadius: borderRadius,
        }}
      >
        <div className="controls">Map Controls</div>
        <div className="info-box">
          <div className="shortcut-box" style={{ width: 198 }}>
            <div className="label-box">
              <label className="label">Pan</label>
            </div>
            <div className="action-box">
              <div className="button-box">
                <div className="button">
                  <div className="label">Click</div>
                </div>
                <div className="label plus">+</div>
                <div className="button">
                  <div className="label">drag</div>
                </div>
              </div>
              <div className="button-box">
                <div className="button">
                  <div className="label">Left - click</div>
                </div>
                <div className="label plus">+</div>
                <div className="button">
                  <div className="label">drag</div>
                </div>
              </div>
            </div>
          </div>
          <div className="shortcut-box" style={{ width: 240 }}>
            <div className="label-box">
              <label className="label">Zoom</label>
            </div>
            <div className="action-box">
              <div className="button-box">
                <div className="button">
                  <div className="label">Two finger swipe up/down</div>
                </div>
              </div>
              <div className="button-box">
                <div className="button">
                  <div className="label">Mouse wheel up/down</div>
                </div>
              </div>
              <div className="button-box">
                <div className="button">
                  <div className="label">Pinch with two fingers</div>
                </div>
              </div>
            </div>
          </div>
          <div className="shortcut-box" style={{ width: 201 }}>
            <div className="label-box">
              <label className="label">Rotate</label>
            </div>
            <div className="action-box">
              <div className="button-box">
                <div className="button">
                  <div className="label">{OS === 'Mac OS' ? '⌘ Cmd' : 'Ctrl'}</div>
                </div>
                <div className="label plus">+</div>
                <div className="button">
                  <div className="label">drag</div>
                </div>
              </div>
              <div className="button-box">
                <div className="button">
                  <div className="label">Right - click</div>
                </div>
                <div className="label plus">+</div>
                <div className="button">
                  <div className="label">drag</div>
                </div>
              </div>
            </div>
          </div>
          <div className="shortcut-box" style={{ width: 178 }}>
            <div className="label-box">
              <label className="label">On map POI options</label>
            </div>
            <div className="action-box">
              <div className="button-box">
                <div className="button">
                  <div className="label">Right - click</div>
                </div>
              </div>
              <div className="button-box">
                <div className="button">
                  <div className="label">^ Ctrl</div>
                </div>
                <div className="label plus">+</div>
                <div className="button">
                  <div className="label">Click</div>
                </div>
              </div>
            </div>
          </div>
          <div className="shortcut-box" style={{ width: 203 }}>
            <div className="hotkey-box" style={{ marginTop: -3 }}>
              <div className="button-box">
                <label className="label">Top view</label>
                <div className="button" style={shortcutKeySprings.topViewBoxSpring}>
                  <div className="label" style={shortcutKeySprings.topViewKeyTextSpring}>
                    V
                  </div>
                </div>
              </div>
            </div>
            <div className="hotkey-box">
              <div className="button-box">
                <label className="label">Display routes</label>
                <div className="button" style={shortcutKeySprings.routesBoxSpring}>
                  <div className="label" style={shortcutKeySprings.routesKeyTextSpring}>
                    R
                  </div>
                </div>
              </div>
            </div>
            {userIsGoodMapsAdmin(user.auth) ? (
              <>
                <div className="hotkey-box">
                  <div className="button-box">
                    <label className="label">Display test points</label>
                    <div className="button" style={shortcutKeySprings.testPointsBoxSpring}>
                      <div className="label" style={shortcutKeySprings.testPointsKeyTextSpring}>
                        T
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hotkey-box">
                  <div className="button-box">
                    <label className="label">Display Unnamed POIs</label>
                    <div className="button" style={shortcutKeySprings.showAllPoisBoxSpring}>
                      <div className="label" style={shortcutKeySprings.showAllPoisKeyTextSpring}>
                        H
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MapControls;
