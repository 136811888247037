import React, { useState, useEffect } from 'react';
import EditorPage from 'components/smart/EditorPage';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Prompt } from 'react-router-dom';
import { updateEntity } from 'actions/EditEntityActions';
import { ActiveEntity, ConnectionChange, Connection } from 'globals/Types';
import ReactGA from 'react-ga';
import {
  getEntityTypeAsString,
  Entities,
  getDefaultEntityIcon,
} from 'helpers/goodmaps-helper/GoodMaps';
import LanguageDictionary from 'globals/Languages';
import { FormLabel, FormControl, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import Label from 'components/dummy/Label';
import Dropdown from 'components/dummy/Dropdown';
import DropdownOption from 'components/dummy/DropdownOption';
import URLExtraInfo, { isURLValid } from './URLExtraInfo';
import { EIURL, isEqual, ConnectionType, AuthRole } from 'goodmaps-utils';
import { InfoOutlined } from '@material-ui/icons';
import { Building, User } from 'goodmaps-sdk';
import { BEFORE_UNLOAD_MESSAGE } from 'globals/Constants';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { setIsFormDirty } from 'actions/SessionActions';
import { getValidAuthList } from 'helpers/EditorHelper';

const defaultLanguage = 'en';

const ConnectionEditor = () => {
  const dispatch = useDispatch();
  const {
    catId,
    entityId,
  }: { catId: string; subCatId: string; entityId: string; entityType: string } = useParams();

  const [selectedLanguage, setSelectedLanguage] = useState<'en' | 'fr' | 'es'>('en');
  const [change, setChange] = useState<ConnectionChange>({
    mlName: {},
    mlShortName: {},
    mlUrl: [],
    mlGenInfo: {},
    authList: [AuthRole.Public],
    connectionType: ConnectionType.Other,
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [formWasChanged, setFormWasChanged] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [languageWasChanged, setLanguageWasChanged] = useState({
    en: false,
    fr: false,
    es: false,
  });
  const currentFloor: number = useSelector((state) => state.session.currentFloor);
  const selectedBuildingUUID: string = useSelector((state) => state.session.selectedBuildingUUID);
  const building: Building = useSelector((state) => state.session.building);
  const allEntities: ActiveEntity[] = useSelector((state) => state.session.allEntities);
  const user: User = useSelector((state) => state.session.user);
  const originalConnectionRef = React.useRef<ConnectionChange>({
    mlName: {},
    mlShortName: {},
    mlUrl: [],
    mlGenInfo: {},
    authList: [AuthRole.Public],
    connectionType: ConnectionType.Other,
  });

  useBeforeUnload({
    when: formWasChanged,
    message: BEFORE_UNLOAD_MESSAGE,
  });

  useEffect(() => {
    const e = allEntities.find((entity) => entity.props.id === entityId);

    // if no default language set then try and set a default
    if (!e?.props.mlName[defaultLanguage])
      e.props.mlName[defaultLanguage] = e.props.mlName?.default;
    if (!e?.props.mlShortName[defaultLanguage]) e.props.mlShortName[defaultLanguage] = '';

    // if no mlUrl or no default Language then setup ml object with empty string for default
    if (!e?.props.mlGenInfo || !e?.props?.mlGenInfo[defaultLanguage])
      e.props.mlGenInfo = { [defaultLanguage]: '' };

    const defaultChange = {
      mlName: e?.props.mlName || {},
      mlShortName: e?.props.mlShortName || {},
      mlUrl: e?.props.mlUrl || [],
      mlGenInfo: e?.props.mlGenInfo || {},
      authList: getValidAuthList(e?.props.authList),
      connectionType: (e as Connection)?.connectionType || ConnectionType.Other, //or other by default
    };

    setChange({ ...defaultChange });

    originalConnectionRef.current = defaultChange;
  }, [allEntities, catId, entityId]);

  const resetForm = () => {
    setChange({
      mlName:
        {
          ...change.mlName,
          [selectedLanguage]: originalConnectionRef.current.mlName[selectedLanguage],
        } || {},
      mlShortName:
        {
          ...change.mlShortName,
          [selectedLanguage]: originalConnectionRef.current.mlShortName[selectedLanguage],
        } || {},
      mlUrl: originalConnectionRef.current.mlUrl,
      mlGenInfo:
        {
          ...change.mlGenInfo,
          [selectedLanguage]: originalConnectionRef.current.mlGenInfo[selectedLanguage],
        } || {},
      authList: originalConnectionRef.current.authList,
      connectionType: originalConnectionRef.current.connectionType || ConnectionType.Other, //or null by default
    });

    setLanguageWasChanged({
      en: false,
      fr: false,
      es: false,
    });
    setFormWasChanged(false);
    dispatch(setIsFormDirty(false));
  };

  const renderLanguageFieldText = (key: string) => {
    const { name } = LanguageDictionary[key];

    // if default language, missing required, unsaved changes
    if (
      key === defaultLanguage &&
      change.mlName[defaultLanguage] === '' &&
      languageWasChanged[key]
    ) {
      return (
        <>
          {name}{' '}
          <span className="sub-label-italicized">
            (Default Language, (1) required field, Unsaved changes)
          </span>
        </>
      );
    }

    // if default language, unsaved changes
    if (key === defaultLanguage && languageWasChanged[key]) {
      return (
        <>
          {name} <span className="sub-label-italicized">(Default Language, Unsaved changes)</span>
        </>
      );
    }

    // if default language and missing required fields - name, icon
    if (key === defaultLanguage && change.mlName[defaultLanguage] === '') {
      return (
        <>
          {name}{' '}
          <span className="sub-label-italicized">(Default Language, (1) required field)</span>
        </>
      );
    }

    // if default language
    if (key === defaultLanguage)
      return (
        <>
          {name} <span className="sub-label-italicized">(Default Language)</span>
        </>
      );

    // if unsaved changes
    if (languageWasChanged[key])
      return (
        <>
          {name} <span className="sub-label-italicized">(Unsaved changes)</span>
        </>
      );

    return name;
  };

  const getSaveButtonState = () => {
    if (!formWasChanged) return 'none';
    if (formIsLoading) return 'saving';

    if (!formIsValid) return 'disabled';

    return 'save';
  };

  const validate = (newChange: ConnectionChange, language?: string) => {
    const formChanged = !isEqual(newChange, originalConnectionRef.current);

    setFormWasChanged(formChanged);

    if (formChanged) {
      setLanguageWasChanged({ ...languageWasChanged, [language]: true });
      setFormIsValid(isURLValid(newChange.mlUrl));
      dispatch(setIsFormDirty(true));
    } else {
      setLanguageWasChanged({ ...languageWasChanged, [language]: false });
      dispatch(setIsFormDirty(true));
    }
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="extra-info-tooltip" className="left-align" {...props}>
        Announced in GoodMaps Explore as “extra information” and shown with an information icon.
      </Tooltip>
    );
  };

  return (
    <EditorPage
      title={change.mlName.en || getEntityTypeAsString(1, +catId)}
      icon={getDefaultEntityIcon(Entities.connections.toString(), change.connectionType.toString())}
      saveState={getSaveButtonState()}
      saveStateErrorMessage={`You must complete required fields before submitting in English`}
      onConfirmSave={async () => {
        setFormIsLoading(true);
        // Update
        ReactGA.event({
          category: 'Engagement',
          action: 'Saved building changes',
          label: `${user.name} updated building ${selectedBuildingUUID}, entity ${entityId}`,
        });

        try {
          await dispatch(
            updateEntity(
              {
                id: entityId,
                mlName: change.mlName,
                mlShortName: change.mlShortName,
                authRole: change.authList,
                mlUrl: change.mlUrl,
                mlGenInfo: change.mlGenInfo,
                type: Entities.connections,
              },
              user.email,
              building,
              currentFloor
            )
          );
          setFormWasChanged(false);
          setLanguageWasChanged({
            en: false,
            fr: false,
            es: false,
          });
        } catch (e) {
          window.alert(e);
        } finally {
          setFormIsLoading(false);
          dispatch(setIsFormDirty(false));
        }
      }}
      onCancelClick={resetForm}
    >
      <Prompt when={formWasChanged} message={BEFORE_UNLOAD_MESSAGE} />
      <div className="entity-body">
        <>
          <div>
            <label className="inline-label">
              Entity Type: <span>{getEntityTypeAsString(Entities.connections)}</span>
            </label>
          </div>

          <Label>Security Role</Label>
          <Form.Check
            type="switch"
            id="role-switch"
            onChange={(event) => {
              const newChange = {
                ...change,
                authList: event.target.checked ? [AuthRole.Private] : [AuthRole.Public],
              };
              validate(newChange);
              setChange(newChange);
              ReactGA.event({
                category: 'Engagement',
                action: 'Edit security role',
                label: `${user.name} updated building ${selectedBuildingUUID}, Entity ${entityId}`,
              });
            }}
            checked={change.authList[0] === AuthRole.Private}
            label={change.authList[0] === AuthRole.Private ? AuthRole[1] : AuthRole[0]}
          />
        </>

        <hr />

        <p>
          Choose a language. You must enter required information in{' '}
          {LanguageDictionary[defaultLanguage].name} to save.
        </p>

        <Dropdown
          onSelect={(value) => {
            setSelectedLanguage(value as any);
          }}
          title={renderLanguageFieldText(selectedLanguage)}
        >
          {Object.keys(LanguageDictionary).map((key) => (
            <DropdownOption key={key} value={key} selected={key === selectedLanguage}>
              {renderLanguageFieldText(key)}
            </DropdownOption>
          ))}
        </Dropdown>

        <p style={{ marginTop: '-13px' }}>
          Define the fields below in {LanguageDictionary[selectedLanguage].name}.
        </p>

        <FormLabel className="label-text" id="entityNameLabel">
          Name
        </FormLabel>
        <FormControl
          name="mlName"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Name`}
          value={change.mlName[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlName: { ...change.mlName, [selectedLanguage]: e.target.value },
            };
            validate(newChange);

            setChange(newChange);
          }}
          // onBlur={(e) => {
          //   const newChange = {
          //     ...change,
          //     mlName: { ...change.mlName, [selectedLanguage]: e.target.value.trim() },
          //   };

          //   validate(newChange, selectedLanguage);
          //   setChange(newChange);
          // }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Name`}
        />

        <FormLabel className="label-text" id="entitySimplifiedNameLabel">
          Simplified Name
        </FormLabel>
        <FormControl
          name="mlSimplifiedName"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Simplified Name`}
          value={change.mlShortName[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlShortName: { ...change.mlShortName, [selectedLanguage]: e.target.value },
            };
            validate(newChange);

            setChange(newChange);
          }}
          // onBlur={(e) => {
          //   const newChange = {
          //     ...change,
          //     mlShortName: { ...change.mlShortName, [selectedLanguage]: e.target.value.trim() },
          //   };

          //   validate(newChange, selectedLanguage);
          //   setChange(newChange);
          // }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Simplified Name`}
        />

        <div>
          <FormLabel
            className="label-text no-bottom-margin"
            id="GenInfoLabel"
            style={{ marginRight: '8px' }}
          >
            Extra Information
          </FormLabel>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 250 }}
            overlay={renderTooltip}
          >
            <InfoOutlined style={{ height: 20, width: 20 }} />
          </OverlayTrigger>
        </div>

        <FormLabel className="extra-info-header" id="GenInfoLabel">
          General Information
        </FormLabel>

        <FormControl
          as="textarea"
          name="mlGenInfo"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} General Information`}
          value={change.mlGenInfo[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlGenInfo: { ...change.mlGenInfo, [selectedLanguage]: e.target.value },
            };
            validate(newChange);
            setChange(newChange);
          }}
          // onBlur={(e) => {
          //   const newChange = {
          //     ...change,
          //     mlGenInfo: { ...change.mlGenInfo, [selectedLanguage]: e.target.value.trim() },
          //   };

          //   validate(newChange, selectedLanguage);
          //   setChange(newChange);
          // }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} General Information`}
        />

        <URLExtraInfo
          defaultLanguage={defaultLanguage}
          selectedLanguage={selectedLanguage}
          initialValue={change.mlUrl}
          onChange={(eiURLs: EIURL[]) => {
            const newChange = {
              ...change,
              mlUrl: eiURLs,
            };

            validate(newChange, selectedLanguage);
            setChange(newChange);
          }}
        />
      </div>
    </EditorPage>
  );
};

export default ConnectionEditor;
