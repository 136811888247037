import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import './SignIn.scss';

const SendCodeScreen = ({
  emailRef,
  handleInputChange,
  changeState,
  handleSend,
  showErrorMessage,
}) => {
  return (
    <div className="form">
      <div className="back-arrow-button" onClick={() => changeState('signIn')}>
        <ArrowBack style={{ fontSize: 18 }} />
      </div>
      <div className="form-header">
        <div className="header-label">Reset Password</div>
        <div className="subheader-label">A verification code will be sent to your email.</div>
      </div>
      <div className="form-body">
        <div className="form-formfield flex">
          <div className="input-label">Email</div>
          <input
            className="email-input"
            id="email"
            key="email"
            name="email"
            ref={emailRef}
            onChange={handleInputChange}
            type="text"
            placeholder="Enter your email"
          />
          <div className="error-label-box flex">{showErrorMessage()}</div>
        </div>
      </div>
      <div className="sign-in-footer-section flex">
        <span className="footer-content">
          <button type="submit" className="sign-in-button" onClick={(e) => handleSend(e)}>
            SEND CODE
          </button>
        </span>
      </div>
    </div>
  );
};

export default SendCodeScreen;
