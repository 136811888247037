const editorPath = '/editor_icons';
const mapPath = '/icons/map-icons';
const miscPath = '/icons';

export const ICONS = {
  editor: {
    bathroom: `${editorPath}/bathroom.svg`,
    beacons: `${editorPath}/beacon.svg`,
    bedroom: `${editorPath}/bedroom.svg`,
    conference: `${editorPath}/conference.svg`,
    door: `${editorPath}/door.svg`,
    elevator: `${editorPath}/elevator.svg`,
    entrance: `${editorPath}/entrance.svg`,
    escalator: `${editorPath}/escalator.svg`,
    exhibit: `${editorPath}/exhibit.svg`,
    green_check: `${editorPath}/green-check.svg`,
    restroom_family: `${editorPath}/restroom-family.svg`,
    info: `${editorPath}/information.svg`,
    kitchen: `${editorPath}/kitchen.svg`,
    restroom_male: `${editorPath}/restroom-male.svg`,
    other: `${editorPath}/other.svg`,
    public: `${editorPath}/public.svg`,
    food_service: `${editorPath}/restaurant-foodservice.svg`,
    red_x: `${editorPath}/red-x.svg`,
    restroom: `${editorPath}/restroom-unisex.svg`,
    shop_amenity: `${editorPath}/shop-amenity.svg`,
    shop: `${editorPath}/shop.svg`,
    stair: `${editorPath}/stairs.svg`,
    waiting_area: `${editorPath}/lobby-lounge-waiting.svg`,
    lobby: `${editorPath}/lobby-lounge-waiting.svg`,
    lounge: `${editorPath}/lobby-lounge-waiting.svg`,
    water: `${editorPath}/water.svg`,
    restroom_female: `${editorPath}/restroom-female.svg`,
    selected: `${editorPath}/selected.svg`,
    add_poi: `${editorPath}/add-poi.svg`,
    link: `${editorPath}/link.svg`,
    textbox: `${editorPath}/card-text.svg`,
  },
  map: {
    bathroom: `${mapPath}/pin-bathroom@2x.png`,
    beacons: `${mapPath}/pin-beacon@2x.png`,
    bedroom: `${mapPath}/pin-bedroom@2x.png`,
    conference: `${mapPath}/pin-conference@2x.png`,
    door: `${mapPath}/pin-door@2x.png`,
    elevator: `${mapPath}/pin-elevator@2x.png`,
    entrance: `${mapPath}/pin-entrance@2x.png`,
    escalator: `${mapPath}/pin-escalator@2x.png`,
    exhibit: `${mapPath}/pin-exhibit@2x.png`,
    restroom_family: `${mapPath}/pin-restroom-family@2x.png`,
    info: `${mapPath}/pin-information@2x.png`,
    kitchen: `${mapPath}/pin-kitchen@2x.png`,
    restroom_male: `${mapPath}/pin-restroom-male@2x.png`,
    other: `${mapPath}/pin-other@2x.png`,
    public: `${mapPath}/pin-public@2x.png`,
    food_service: `${mapPath}/pin-foodservice@2x.png`,
    restroom: `${mapPath}/pin-restroom-unisex@2x.png`,
    selected: `${mapPath}/pin-selected@2x.png`,
    shop_amenity: `${mapPath}/pin-shop-amenity@2x.png`,
    shop: `${mapPath}/pin-shop@2x.png`,
    stair: `${mapPath}/pin-stairs@2x.png`,
    waiting_area: `${mapPath}/pin-lobby-lounge-waiting@2x.png`,
    lobby: `${mapPath}/pin-lobby-lounge-waiting@2x.png`,
    lounge: `${mapPath}/pin-lobby-lounge-waiting@2x.png`,
    water: `${mapPath}/pin-water@2x.png`,
    restroom_female: `${mapPath}/pin-restroom-female@2x.png`,
  },
  misc: {
    tip: `${miscPath}/tip.svg`,
    visible_eye: `${miscPath}/eye.svg`,
    non_visible_eye: `${miscPath}/eye-slash.svg`,
    three_dot_menu: `${miscPath}/three-dots.svg`,
  },
};
