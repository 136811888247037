import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import DeleteModal from './DeleteModal';
import { Table, Modal, Alert } from 'react-bootstrap';
import './ParentList.scss';
import { BuildingManagementMode } from 'globals/Types';
import ImportModal from './ImportModal';
import DeployModal from './DeployModal';
import BuildingList from './BuildingsList';
import { ChevronRight } from '@material-ui/icons';
import CampusModal from './CampusModal';
import { exportBuildingOrCampusFile } from 'helpers/BuildingManagerHelper';
import { User, BuildingStatus, CampusData, userIsGoodMapsAdmin } from 'goodmaps-sdk';
import { getCampusList, deleteCampus } from 'helpers/BuildingManagerHelper';
import HoverMenu from 'components/dummy/HoverMenu';
import { ICONS } from 'globals/Icons';
import FileCopy from '@material-ui/icons/FileCopy';

type Props = {
  partnerId: string;
  customerId: string;
  customerName: string;
};

const CampusList = ({ partnerId, customerId, customerName }: Props) => {
  const history = useHistory();
  const user: User = useSelector((state) => state.session.user);
  const [campusList, setCampusList] = useState<CampusData[]>([]);
  const [expandedCampusList, setExpandedCampusList] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [campus, setCampus] = useState<CampusData>({
    id: '',
    name: '',
    parentId: '',
    createdAtISO: '',
    createdByUserId: '',
    modifiedAtISO: '',
    modifiedByUserId: '',
    ancestors: [],
    status: 0,
    lat: null,
    lon: null,
  });
  const [mode, setMode] = useState<BuildingManagementMode>(BuildingManagementMode.none);
  const [error, setError] = useState('');

  useEffect(() => {
    if (userIsGoodMapsAdmin(user.auth)) {
      const getCampuses = async () => {
        try {
          const { message: campusList } = await getCampusList(customerId);
          setCampusList(campusList);
          setExpandedCampusList(new Array<boolean>(campusList.length).fill(false));
        } catch (e) {
          setError(`The campus list is not available. ${e}`);
        } finally {
          setIsLoading(false);
        }
      };
      getCampuses();
    }
  }, [customerId, history, user.auth]);

  const onOpen = (
    mode: BuildingManagementMode,
    campus: CampusData = {
      id: '',
      name: '',
      parentId: '',
      createdAtISO: '',
      createdByUserId: '',
      modifiedAtISO: '',
      modifiedByUserId: '',
      ancestors: [],
      status: 0,
      lat: null,
      lon: null,
    }
  ) => {
    setMode(mode);
    setCampus(campus);
  };

  const onClose = () => {
    setMode(BuildingManagementMode.none);
  };

  const updateCampusList = async (newCampus?: CampusData) => {
    setIsTableLoading(true);

    try {
      const { message: campusList } = await getCampusList(customerId);
      setCampusList(campusList);
      setExpandedCampusList(new Array<boolean>(campusList.length).fill(false));
    } catch (e) {
      setError(`The campus list failed to refresh. ${e}`);
    } finally {
      setIsTableLoading(false);
    }
  };

  const showListOfBuildings = (index: number) => {
    setExpandedCampusList([
      ...expandedCampusList.slice(0, index),
      !expandedCampusList[index],
      ...expandedCampusList.slice(index + 1),
    ]);
  };

  const tableItems = campusList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((campus, index) => {
      return (
        <React.Fragment key={campus.id}>
          <tr className={expandedCampusList[index] ? 'expanded' : ''}>
            <td>
              <div onClick={() => showListOfBuildings(index)}>
                <div>
                  <ChevronRight className="chevron" />
                  {campus.name}
                  <br />
                  <FileCopy
                    className="copy-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(campus.id);
                    }}
                  />
                  <span style={{ fontSize: 10 }}>{campus.id}</span>
                </div>
              </div>
            </td>
            <td className={campus.published ? 'published' : 'not-published'}>
              {campus.published ? 'YES' : 'NO'}
            </td>
            <td className={campus.private ? 'published' : 'not-published'}>
              {campus.private ? 'YES' : 'NO'}
            </td>
            <td>{BuildingStatus[campus.status]}</td>
            <td style={{ padding: 0, verticalAlign: 'middle' }}>
              <HoverMenu
                menuOptions={{
                  iconSrc: ICONS.misc.three_dot_menu,
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                }}
                menuItems={[
                  {
                    displayText: `Edit`,
                    onClick: () => onOpen(BuildingManagementMode.edit, campus),
                  },
                  {
                    displayText: `Delete`,
                    onClick: () => onOpen(BuildingManagementMode.delete, campus),
                  },
                  {
                    displayText: `Import`,
                    onClick: () => onOpen(BuildingManagementMode.import, campus),
                  },
                  {
                    displayText: `Deploy`,
                    onClick: () => onOpen(BuildingManagementMode.deploy, campus),
                  },
                  {
                    displayText: `Export`,
                    onClick: async () => {
                      try {
                        const result = await exportBuildingOrCampusFile(campus.id);
                        console.log(result);
                        var link = document.createElement('a');
                        link.download = `${campus.name}-export.osm`;
                        var blob = new Blob([result.message], { type: 'text/plain' });
                        link.href = window.URL.createObjectURL(blob);
                        link.click();
                      } catch (e) {
                        console.error(e);
                        setError('Unable to export campus.');
                      }
                    },
                  },
                ]}
              />
            </td>
          </tr>
          {expandedCampusList[index] && (
            <tr>
              <td className="child-container" colSpan={5}>
                <BuildingList
                  campusId={campus.id}
                  partnerId={partnerId}
                  customerId={customerId}
                  campusName={campus.name}
                />
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });

  return isLoading ? (
    <CircularProgressIndicator />
  ) : (
    <div>
      {isTableLoading ? (
        <CircularProgressIndicator />
      ) : (
        <div>
          <div className="table-header">
            <h2>Campuses</h2>
            <button
              className="table-button backgrounded"
              onClick={() => onOpen(BuildingManagementMode.add)}
            >
              + Add
            </button>
          </div>
          {error ? (
            <div>
              <Alert variant="danger">{error}</Alert>
            </div>
          ) : null}

          <Table>
            <thead>
              <tr>
                <th>Campus Name</th>
                <th style={{ width: 100 }}>Published</th>
                <th style={{ width: 100 }}>Private</th>
                <th style={{ width: 100 }}>Status</th>
                <th style={{ width: 37 }}></th>
              </tr>
            </thead>
            <tbody>
              {tableItems.length === 0 ? (
                <tr>
                  <td colSpan={5}>
                    <div className="empty-table">
                      <h6>Empty "{customerName}" Campus Table</h6>
                    </div>
                  </td>
                </tr>
              ) : (
                tableItems
              )}
            </tbody>
          </Table>
        </div>
      )}
      <Modal centered show={mode === BuildingManagementMode.delete} onHide={onClose}>
        <DeleteModal
          name={campus?.name}
          onClose={onClose}
          onSuccess={updateCampusList}
          onDelete={async () => await deleteCampus(customerId, campus.id)}
        />
      </Modal>

      <Modal centered show={mode === BuildingManagementMode.add} onHide={onClose}>
        <CampusModal
          title={'Add'}
          onClose={onClose}
          mode={mode}
          onSuccess={updateCampusList}
          parentId={customerId}
        />
      </Modal>

      <Modal centered show={mode === BuildingManagementMode.edit} onHide={onClose}>
        <CampusModal
          title={'Edit'}
          onClose={onClose}
          mode={mode}
          onSuccess={updateCampusList}
          parentId={customerId}
          campus={campus}
        />
      </Modal>

      <Modal
        centered={true}
        show={mode === BuildingManagementMode.import}
        onHide={onClose}
        className="b-modal"
        size="xl"
      >
        <ImportModal
          name={campus?.name}
          onClose={onClose}
          onSuccess={updateCampusList}
          type="Campus"
          dataType="campus"
          id={campus?.id}
        />
      </Modal>

      <Modal centered={true} show={mode === BuildingManagementMode.deploy} onHide={onClose}>
        <DeployModal
          name={campus?.name}
          id={campus?.id}
          onClose={onClose}
          onSuccess={updateCampusList}
        />
      </Modal>
    </div>
  );
};

export default CampusList;
