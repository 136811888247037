import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowRight } from '@material-ui/icons';
import './Link.scss';

type Props = {
  primary: string;
  to: string;
  secondary?: string;
  color?: string;
  count?: number;
  icon?: string;
  onClick?: () => void;
};

const Link = ({
  primary,
  to,
  secondary,
  color = '#ffffff',
  count = null,
  icon = '',
  onClick,
}: Props) => {
  const showCount = () => {
    return count != null ? <div className="count-container">({count})</div> : null;
  };

  return (
    <RouterLink style={{ textDecoration: 'none' }} to={to}>
      <div className="link" style={{ backgroundColor: color }} onClick={onClick}>
        {icon ? <img src={icon} alt="icon" className="link-icon" /> : null}
        <div>
          <div className="label">{primary}</div>
          <div className="sub-label">{secondary}</div>
        </div>

        <div className="count-box">
          {showCount()}
          <ArrowRight style={{ color: '#000000' }} />
        </div>
      </div>
    </RouterLink>
  );
};

export default Link;
