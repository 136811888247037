import * as THREE from 'three';
import * as gm from 'goodmaps-sdk';

/**
 * Converts from degrees to radians and then stores that value
 * @param thetaAngle angle in degrees
 */
// Not used, remove?
export const convertDegToRad = (thetaAngle: number): number => {
  const convertedAngle = THREE.MathUtils.degToRad(thetaAngle);
  return convertedAngle;
};

// Only used internally
export const convertRadToDeg = (radians: number): number => {
  const convertedAngle = THREE.MathUtils.radToDeg(radians);
  return convertedAngle;
};

export const getCenter = (points: THREE.Vector2[]) => {
  const sortedX = points.map((p) => p.x).sort((a, b) => a - b);
  const sortedY = points.map((p) => p.y).sort((a, b) => a - b);

  const minX = sortedX[0];
  const maxX = sortedX[sortedX.length - 1];
  const minY = sortedY[0];
  const maxY = sortedY[sortedX.length - 1];

  const x = minX + (maxX - minX) / 2;
  const y = minY + (maxY - minY) / 2;
  return new THREE.Vector2(x, y);
};

export const getDistanceBetweenPoints = (
  from: THREE.Vector3 | THREE.Vector2,
  to: THREE.Vector3 | THREE.Vector2
): number => {
  const difference = getVectorBetweenPoints(from, to);
  return difference.length();
};

// Not used, remove?
export const getAngleBetweenPoints = (
  from: THREE.Vector3 | THREE.Vector2,
  to: THREE.Vector3 | THREE.Vector2
): number => {
  const difference = getVectorBetweenPoints(from, to);
  let angle = convertRadToDeg(Math.atan2(difference.y, difference.x));
  if (angle < 0) angle += 360;
  return angle;
};

// TODO: Can remove once we remove RoomObject.tsx
export const getVectorBetweenPoints = (
  from: THREE.Vector3 | THREE.Vector2,
  to: THREE.Vector3 | THREE.Vector2
): THREE.Vector2 => {
  const a2D = new THREE.Vector2(from.x, from.y);
  const b2D = new THREE.Vector2(to.x, to.y);
  const difference = b2D.sub(a2D);
  return difference;
};

/**
 * finds mininum x coordinate from list coordinates (returns coordinate with mininum x coordinate )
 * @param points array of three 2d vectors
 */

export const minPointX = (points: THREE.Vector2[]) => {
  const minX = Math.min(...points.map(({ x }) => x));
  const p = points.find(({ x }) => x === minX);
  return new THREE.Vector3(p.x, p.y, 0.5);
};

export const maxPointX = (points: THREE.Vector2[]) => {
  const maxX = Math.max(...points.map(({ x }) => x));
  const p = points.find(({ x }) => x === maxX);
  return new THREE.Vector3(p.x, p.y, 0.5);
};

export const minPointY = (points: THREE.Vector2[]) => {
  const minY = Math.min(...points.map(({ y }) => y));
  const p = points.find(({ y }) => y === minY);
  return new THREE.Vector3(p.x, p.y, 0.5);
};

export const maxPointY = (points: THREE.Vector2[]) => {
  const maxY = Math.max(...points.map(({ y }) => y));
  const p = points.find(({ y }) => y === maxY);
  return new THREE.Vector3(p.x, p.y, 0.5);
};

export const getDirectionFromPoints = (points: THREE.Vector2[]) => {
  const A = minPointX(points);
  const B = minPointY(points);
  const C = maxPointY(points);

  // Determine orientation of the stairs
  const distance1 = getDistanceBetweenPoints(A, B);
  const distance2 = getDistanceBetweenPoints(A, C);

  const stairDirection =
    distance1 < distance2
      ? getVectorBetweenPoints(findMidPoint(B, A), findMidPoint(B, C))
      : getVectorBetweenPoints(findMidPoint(B, C), findMidPoint(A, C));

  return Math.atan2(stairDirection.y, stairDirection.x);
};

export const findMidPoint = (
  from: THREE.Vector3 | THREE.Vector2,
  to: THREE.Vector3 | THREE.Vector2
) => {
  return new THREE.Vector2((from.x + to.x) / 2, (from.y + to.y) / 2);
};

export const transformPointPosition = (pos: gm.Position, orientation: number) => {
  const transformedVector = new THREE.Vector2(pos.x, -pos.z).rotateAround(
    new THREE.Vector2(0, 0),
    -orientation
  );

  return {
    x: transformedVector.x,
    y: transformedVector.y,
    z: 0,
    level: pos.level,
  };
};

// TODO: Write tests for this
export const isStringNumeric = (str: string) => {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(+str) && // use type coercion to parse the _entirety_ of the string, does not catch whitespace
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  );
};
