import { combineReducers } from 'redux';
import SessionState from './SessionReducer';
import EventState from './EventReducer';
import ViewerReducer from './ViewerReducer';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

const reducers = combineReducers({
  session: SessionState,
  viewer: ViewerReducer,
  events: EventState,
});

export const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
