import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectFloor } from 'actions/ViewerActions';
import Floor from 'components/floorplan/structures/Floor';
import { EditModeType } from 'globals/Types';
import BuildingControls from '../dummy/BuildingControls';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentFloor: number = useSelector((state) => state.viewer.currentFloor);
  const floors: Floor[] = useSelector((state) => state.session.floors);

  const editMode: EditModeType = useSelector((state) => state.viewer.editMode);

  const onFloorSelected = React.useCallback(
    (level: number) => {
      if (currentFloor === level) return;
      dispatch(selectFloor(level, history));
    },
    [currentFloor, dispatch, history]
  );

  return (
    <BuildingControls
      onFloorSelected={onFloorSelected}
      currentFloor={currentFloor}
      floors={floors}
      editMode={editMode}
    />
  );
};
