import React, { useState } from 'react';
import { useParams, Prompt } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEntity } from 'actions/EditEntityActions';
import Door from '../dummy/Door';
import { useSelector } from 'react-redux';
import { ActiveEntity, Door as DoorType } from 'globals/Types';
import EditorPage from '../smart/EditorPage';
import { Entities, EntityInfo, getEntityTypeAsString } from 'helpers/goodmaps-helper/GoodMaps';
import { AuthRole } from 'goodmaps-utils';
import { Building, User } from 'goodmaps-sdk';
import { BEFORE_UNLOAD_MESSAGE } from 'globals/Constants';
import useBeforeUnload from 'hooks/useBeforeUnload';
import 'components/dummy/EditorPage.scss';
import ReactGA from 'react-ga';
import { setIsFormDirty } from 'actions/SessionActions';

export default () => {
  const dispatch = useDispatch();
  const allEntities: ActiveEntity[] = useSelector((state) => state.session.allEntities);
  const { entityId } = useParams();
  const entity: DoorType = allEntities.find((e) => e.props.id === entityId) as DoorType;
  const [authRole, setAuthRole] = useState<AuthRole[]>(entity.props.authList);
  const user: User = useSelector((state) => state.session.user);
  const selectedBuildingUUID: string = useSelector((state) => state.session.selectedBuildingUUID);
  const building: Building = useSelector((state) => state.session.building);
  const [formWasChanged, setFormWasChanged] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);

  useBeforeUnload({
    when: formWasChanged,
    message: BEFORE_UNLOAD_MESSAGE,
  });

  const getSaveButtonState = () => {
    if (!formWasChanged) return 'none';
    if (formIsLoading) return 'saving';

    return 'save';
  };

  const validate = (value: number) => {
    if (entity.props.authList[0] !== value) {
      setFormWasChanged(true);
      dispatch(setIsFormDirty(true));
    } else {
      setFormWasChanged(false);
      dispatch(setIsFormDirty(false));
    }
  };

  const resetForm = () => {
    setAuthRole(entity.props.authList);
    setFormWasChanged(false);
    dispatch(setIsFormDirty(false));
  };

  return (
    <EditorPage
      title={entity.props.name || getEntityTypeAsString(entity.entityType)}
      icon={EntityInfo[Entities.doors].icon.editor}
      saveState={getSaveButtonState()}
      onCancelClick={resetForm}
      onConfirmSave={async () => {
        setFormIsLoading(true);
        ReactGA.event({
          category: 'Engagement',
          action: 'Saved building changes',
          label: `${user.name} updated building ${selectedBuildingUUID}, entity ${entityId}`,
        });

        try {
          await dispatch(
            updateEntity(
              {
                id: entityId,
                mlName: entity.props.mlName,
                mlShortName: entity.props.mlShortName,
                authRole: authRole,
                mlUrl: entity.props.mlUrl,
                mlGenInfo: entity.props.mlGenInfo,
                type: Entities.doors,
              },
              user.email,
              building,
              entity.props.level
            )
          );

          setFormWasChanged(false);
        } catch (e) {
          window.alert(e);
        } finally {
          setFormIsLoading(false);
          dispatch(setIsFormDirty(false));
        }
      }}
    >
      <Prompt when={formWasChanged} message={BEFORE_UNLOAD_MESSAGE} />
      <Door
        entityTypeName={EntityInfo[entity.entityType].name}
        authRole={authRole}
        onToggle={(isChecked: boolean) => {
          setAuthRole(isChecked ? [AuthRole.Private] : [AuthRole.Public]);
          validate(isChecked ? AuthRole.Private : AuthRole.Public);
        }}
      />
    </EditorPage>
  );
};
