import React from 'react';
import './HoverMenu.scss';
import CSS from 'csstype';

export interface MenuItem {
  displayText: string;
  onClick: () => void;
}

type Props = {
  menuItems: MenuItem[];
  menuOptions?: MenuContainerOptions;
  itemOptions?: ItemContainerOptions;
};

interface MenuContainerOptions {
  iconSrc?: string;
  iconStyle?: CSS.Properties;
  style?: CSS.Properties;
}

interface ItemContainerOptions {
  style?: CSS.Properties;
}

const HoverMenu = ({ menuItems, menuOptions, itemOptions }: Props) => {
  return (
    <div
      className="menuContainer menu-container-three-dot"
      style={menuOptions?.style}
      role="button"
    >
      {menuOptions.iconSrc ? <img src={menuOptions.iconSrc} alt="menu button" /> : null}

      <div className="itemContainer" style={itemOptions?.style}>
        {menuItems.map((item, index) => (
          <div
            key={`${item.displayText}-${index}`}
            className="menu-item flex"
            onClick={item.onClick}
          >
            {item.displayText}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HoverMenu;
