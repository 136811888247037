import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { useSelector } from 'react-redux';
import { ArrowDropDownCircle, ExitToApp } from '@material-ui/icons';
import './UserDropdown.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import * as FunctionQueue from 'helpers/FunctionQueueHelper';

export default () => {
  const history = useHistory();
  const container = useRef(null);
  const { name } = useSelector((state) => state.session.user);
  const isFormDirty = useSelector((state) => state.session.isFormDirty);

  return (
    <div ref={container} className="relative user-dropdown">
      <DropdownButton
        menuAlign="right"
        title={
          <>
            {name} <ArrowDropDownCircle style={{ fontSize: 15, marginLeft: 12 }} />
          </>
        }
        className="user-b-dropdown"
      >
        <div className="dropdown-style">
          <Dropdown.Item
            eventKey="1"
            onClick={() => {
              if (isFormDirty) FunctionQueue.pause();
              history.push('/');

              FunctionQueue.enqueue(
                async () => await Auth.signOut().catch((err) => console.log(err))
              );
            }}
          >
            <div className="inner flex flex-row items-center ">
              <ExitToApp
                className="black"
                style={{ fontSize: 18, marginRight: 15, marginLeft: 21 }}
              />
              <div className="black">Sign out</div>
            </div>
          </Dropdown.Item>
        </div>
      </DropdownButton>
    </div>
  );
};
