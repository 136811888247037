import React from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';

type props = {
  name: string;
  onClose: () => void;
  onSuccess?: (event?: any) => Promise<void>;
  onDelete?: () => Promise<{ message: any; status: any }>;
};

const DeleteModal = ({ name, onClose, onSuccess, onDelete }: props) => {
  const [error, setError] = React.useState('');
  const [response, setResponse] = React.useState('');

  const deleteBuilding = async () => {
    if (error) setError('');
    if (response) setResponse('');

    try {
      onDelete && (await onDelete());
      onSuccess && (await onSuccess());

      setResponse('Delete was successful!');
    } catch (error) {
      setError('There was an issue deleting the building. ' + error);
    }
  };

  return (
    <>
      <Modal.Header closeButton={true}>
        <Modal.Title>Delete {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? <Alert variant="danger">{error}</Alert> : null}
        {response ? (
          <Alert variant="success">{response}</Alert>
        ) : (
          <p>Are you sure you want to delete "{name}"?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="danger" onClick={deleteBuilding}>
          Confirm
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DeleteModal;
