import { AuthType } from 'goodmaps-utils';
import * as LabelRenderer from '../renderer/LabelRenderer';
import * as Renderer from '../renderer/Renderer';

export const convertClientCoords = (
  xPos: number,
  yPos: number,
  offsetX: number = 384,
  offsetY: number = 64
) => {
  const clientDimensions = LabelRenderer.getLabelRendererClient();
  const { width, height } = clientDimensions;

  const x = ((xPos - offsetX) / width) * 2 - 1;
  const y = -((yPos - offsetY) / height) * 2 + 1;

  const coords = Renderer.getCoordinatesFromRaycaster(x, y);

  return coords;
};

export const getMatchingArrayValues = (arr1: string[], arr2: string[]) => {
  return arr1.filter((nameKey) => arr2.includes(nameKey));
};

export const getValidAuthList = (authList: number[]) => {
  // authList should contain either public = 0 or private = 1

  // Anything not Public (0) should map to private
  // Old AuthType enum
  //
  // {
  //   Public,
  //   Employee,
  //   Maintenance,
  //   FirstResponder,
  //   Admin,
  // }

  // authList should only have one number
  // This shouldn't really happen but we'll guard against it
  if (authList && authList.length > 1) return [AuthType.Public];
  // anything greater than 1 is invalid and the authList needs to be set to Private
  else if (authList && authList[0] >= 1) return [AuthType.Private];

  // default is public
  return [AuthType.Public];
};
