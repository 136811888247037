import React, { useState } from 'react';
import { Button, Modal, Form, Col, Alert } from 'react-bootstrap';
import { STATUSES } from 'globals/Constants';
import { BuildingStatus } from 'goodmaps-sdk';
import { BuildingManagementMode } from 'globals/Types';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import { CampusData } from 'goodmaps-utils';
import { addCampus, editCampus } from 'helpers/BuildingManagerHelper';

type props = {
  campus?: CampusData;
  title: string;
  mode: BuildingManagementMode;
  onClose: () => void;
  onSuccess: () => Promise<void>;
  parentId?: string;
};

const INITIAL_CAMPUS: CampusData = {
  id: '',
  parentId: '',
  name: '',
  modifiedAtISO: '',
  modifiedByUserId: '',
  published: false,
  status: BuildingStatus.Pipeline,
  region: '',
  createdAtISO: `${Date.now()}`,
  createdByUserId: '',
  private: false,
  ancestors: [],
  lat: null,
  lon: null,
};

const CampusModal = ({
  campus = INITIAL_CAMPUS,
  title,
  mode,
  onClose,
  onSuccess,
  parentId = null,
}: props) => {
  const [isFormValidated, setFormIsValidated] = useState(false);
  const [campusChanges, setCampusChanges] = useState<CampusData>(campus);
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    setIsLoading(true);

    if (error) setError('');
    if (response) setResponse('');

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setFormIsValidated(true);
      return;
    }

    try {
      console.log(mode);
      if (mode === BuildingManagementMode.add) {
        await addCampus(parentId, campusChanges);
        setResponse('The campus was successfully added!');
      }
      if (mode === BuildingManagementMode.edit) {
        await editCampus(parentId, campusChanges);
        setResponse('The campus was successfully updated!');
      }
    } catch (error) {
      setError('There was an issue with the campus, ' + error);
    } finally {
      setIsLoading(false);
    }

    // Callback
    onSuccess();
  };

  return (
    <>
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <CircularProgressIndicator />
        ) : (
          <>
            {error ? <Alert variant="danger">{error}</Alert> : null}
            {response ? <Alert variant="success">{response}</Alert> : null}
            <Form
              noValidate
              id="campus-form"
              validated={isFormValidated} // form has been validated - true/false
              onSubmit={onSubmit}
            >
              <Form.Row>
                <Form.Group as={Col} md="8">
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    required
                    as="input"
                    value={campusChanges.name}
                    onChange={(event) => {
                      setCampusChanges({ ...campusChanges, name: event.target.value });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    value={campusChanges.lat || ''}
                    onChange={(event) =>
                      setCampusChanges({
                        ...campusChanges,
                        lat: +event.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    value={campusChanges.lon || ''}
                    onChange={(event) =>
                      setCampusChanges({
                        ...campusChanges,
                        lon: +event.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Bucket Region</Form.Label>
                  <Form.Control
                    disabled={mode === BuildingManagementMode.edit}
                    as="select"
                    value={campusChanges.region}
                    onChange={(event) =>
                      setCampusChanges({
                        ...campusChanges,
                        region: event.target.value,
                      })
                    }
                  >
                    <option key="default" value="">
                      Default
                    </option>
                    <option key="ca" value="ca">
                      Canada
                    </option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <hr></hr>
              <Form.Group>
                <Form.Check
                  type="switch"
                  id="custom-switch-private"
                  label="Private"
                  checked={campusChanges.private}
                  onChange={(event) => {
                    setCampusChanges({
                      ...campusChanges,
                      private: event.target.checked || false,
                    });
                  }}
                />
              </Form.Group>
              {mode === BuildingManagementMode.add ? (
                <>
                  <Form.Group>
                    <Form.Check disabled type="switch" id="disabled-switch" label="Published" />
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group>
                    <Form.Label>Campus Status</Form.Label>
                    <Form.Control
                      custom
                      as="select"
                      value={campusChanges.status}
                      onChange={(event) => {
                        setCampusChanges({
                          ...campusChanges,
                          status: parseInt(event.target.value),
                        });
                      }}
                    >
                      {STATUSES.map((status) => {
                        return (
                          <option key={status.key} value={status.key}>
                            {status.value}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      type="switch"
                      id="custom-switch-published"
                      label="Published"
                      checked={campusChanges.published}
                      onChange={(event) => {
                        setCampusChanges({
                          ...campusChanges,
                          published: event.target.checked,
                        });
                      }}
                    />
                  </Form.Group>
                </>
              )}
            </Form>
            {mode === BuildingManagementMode.edit ? (
              <p style={{ fontSize: 12 }}>
                {campus.id} last modified at {campus.modifiedAtISO} by {campus.modifiedByUserId}
              </p>
            ) : null}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          Close
        </Button>

        <Button variant="primary" type="submit" form="campus-form">
          Save
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CampusModal;
