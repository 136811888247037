import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Alert, Table } from 'react-bootstrap';
import DragAndDrop from 'components/DragAndDrop';
import XMLParser from 'react-xml-parser';
import {
  deployBuildingData,
  getLint,
  transformBuildingData,
  uploadBuildingData,
} from 'helpers/BuildingManagerHelper';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import './ParentList.scss';

type props = {
  name: string;
  type: string;
  onClose: () => void;
  onSuccess?: () => Promise<void>;
  dataType: 'building' | 'campus';
  id: string;
};

// Taken from building management services, return type of linting
type checkItem = {
  name: string;
  status: boolean;
  note: string;
};

const ImportModal = ({ name, type, onClose, onSuccess, dataType, id }: props) => {
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState('');
  const [buildingUUID, setBuildingUUID] = useState<string>(null);
  const [osmData, setOSMData] = useState('');
  const [lintResults, setLintResults] = useState<checkItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { email }: { email: string } = useSelector((state) => state.session.user);
  const [idError, setIdError] = useState(false);
  const [response, setResponse] = useState('');

  const prepareOSMDataForUpload = (osmXML: string) => {
    setOSMData(osmXML);

    // Retrieving the buidlingUUID from OSM file
    const osmJSON = new XMLParser().parseFromString(osmXML);
    osmJSON.getElementsByTagName('way').forEach((way) => {
      if (
        way.children.some(
          (child) =>
            child.name === 'tag' &&
            child.attributes.k === 'boundary' &&
            child.attributes.v === 'GoodMaps'
        )
      ) {
        const uuid = way.children.find(
          (child) => child.name === 'tag' && child.attributes.k === 'gmUuid'
        ).attributes.v;
        setIdError(id.split('.')[1] !== uuid);
        setBuildingUUID(uuid);
        return;
      }
    });
  };

  const renderLintingResults = () => {
    lintResults.sort((r) => (!r.status ? -1 : 1));
    return (
      <Table bordered hover size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          {lintResults.map((item) => (
            <tr key={item.name} className={item.status ? 'lint-correct' : 'lint-error'}>
              <td>{item.name}</td>
              <td className="display-linebreak">{item.note}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const updateIds = () => {
    setOSMData(osmData.replaceAll(buildingUUID, id.split('.')[1]));
    setBuildingUUID(id.split('.')[1]);
  };

  const importBuildingData = async () => {
    // Upload, Transform, Lint, pull down updated building list
    try {
      setIsLoading(true);
      const uploadResponse = await uploadBuildingData(
        osmData,
        `${type}.${buildingUUID}`,
        email,
        dataType
      );

      await transformBuildingData({
        id: uploadResponse.message.id,
        timestamp: uploadResponse.message.timestamp,
        userId: email,
        removeBuildingData: dataType === 'campus',
      });

      const lintResults = await getLint(
        uploadResponse.message.id,
        uploadResponse.message.timestamp
      );

      setLintResults(lintResults.message);

      localStorage.setItem(`${buildingUUID}`, uploadResponse.message.timestamp);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deployData = async () => {
    try {
      setIsLoading(true);
      if (error) setError('');
      if (response) setResponse('');

      const timestamp = localStorage.getItem(id.split('.')[1]);

      if (!timestamp)
        throw new Error('No reference file found, make sure your import was successful');

      const result = await deployBuildingData({ id, timestamp, userId: email });

      result.status === 201 &&
        setResponse(`The last imported osm file for ${name} was successfully deployed.`);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onModalClose = () => {
    onClose();
    onSuccess();
  };

  return (
    <>
      <Modal.Header closeButton={true}>
        <Modal.Title>Import Data</Modal.Title>
      </Modal.Header>
      {isLoading ? (
        <CircularProgressIndicator />
      ) : (
        <Modal.Body className={'importModalBody'}>
          {error ? <Alert variant="danger">{error}</Alert> : null}
          {response ? <Alert variant="success">{response}</Alert> : null}

          {fileName == null ? (
            <>
              <p>Drag and drop data file for "{name}"?</p>
              <DragAndDrop
                onFileDrop={(file, filename) => {
                  try {
                    setIsLoading(true);
                    prepareOSMDataForUpload(file);
                    setFileName(filename);
                  } catch (error) {
                    setError('There was an issue uploading the file: ' + error.message);
                  } finally {
                    setIsLoading(false);
                  }
                }}
              />
            </>
          ) : (
            <div>{fileName}</div>
          )}

          {lintResults.length > 0 && renderLintingResults()}
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button variant="secondary" onClick={onModalClose}>
          Close
        </Button>
        {lintResults.length === 0 ? (
          <>
            <Button variant="danger" onClick={importBuildingData}>
              Confirm
            </Button>
          </>
        ) : null}
        {lintResults.length > 0 ? (
          <Button variant="secondary" onClick={deployData}>
            Deploy
          </Button>
        ) : null}
      </Modal.Footer>

      <Modal show={idError} onHide={() => setIdError(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ID mismatch!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{`The uploaded file has an id of ${buildingUUID} but you are trying to upload it to a ${
          id.split('.')[0]
        } with an id of ${
          id.split('.')[1]
        }. Are you sure you want to continue? \n\n Selecting continue will allow you to upload the file with the id of ${
          id.split('.')[1]
        }`}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIdError(false);
              onClose();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setIdError(false);
              updateIds();
            }}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportModal;
