import * as gm from 'goodmaps-sdk';
import { Vector2 } from 'three';
import Floor from 'components/floorplan/structures/Floor';
import Beacon from 'components/floorplan/entities/Beacon';
import Route from 'components/floorplan/entities/Route';
import TestPoint from 'components/floorplan/entities/TestPoint';
import _ from 'lodash';
import { Elements } from 'globals/Types';
import { ElementType } from 'goodmaps-sdk';

// This is the structure of elements
// this.elements = {
//   1: [],
//   2: [],
//   3: [],
//   4: []
// }
const initElements = () =>
  Object.keys(ElementType)
    .filter((key) => Number.isInteger(+key))
    .reduce((acc, cur) => {
      acc[cur] = [];
      return acc;
    }, {} as Elements);

const getLevelName = (gmLevel: gm.Level[]) => {
  if (gmLevel.length === 1) return gmLevel[0]?.name || `${gmLevel[0].level}`;

  return Array.from(new Set(gmLevel.map((level) => level?.name || `${level.level}`))).join(', ');
};

const convertToVector2 = (node: gm.Position) => {
  const { x, y } = node;

  return new Vector2(x, y);
};

export const getBuildingFloors = (building: gm.Building) => {
  // TODO: Add support for sidewalks since we now have one in KSB
  let orientation = 0;
  const floors: Floor[] = [];
  if (building.orientation) {
    const vectors = building.orientation.getNodes().map((n) => {
      const { x, y } = building.calcProjection(n);
      return new Vector2(x, y);
    });
    const v = vectors[1].sub(vectors[0]).normalize();
    const angle = Math.atan2(v.x, v.y) + Math.PI / 2;
    orientation = angle;
  }

  const groupedLevels: { [key: number]: gm.Level[] } = _.groupBy(
    (building as gm.Building).getDetailLevels(),
    (detailLevel) => detailLevel.level
  );

  Object.values(groupedLevels)
    .sort((a, b) => a[0].level - b[0].level)
    .forEach((gmLevel) => {
      const level = gmLevel[0].level;

      const pois = [];
      const elements = initElements();
      const doors = [];
      const beacons = [];
      const stairs = [];
      const elevators = [];
      const escalators = [];
      const otherConnections = [];
      const testPoints = [];
      const routes = [];

      let outline = [];

      gmLevel.forEach((level, index) => {
        if (level && level.getNodes) {
          outline[index] = level.getNodes().map((n) => {
            const proj = building.calcProjection(n);
            return new Vector2(proj.x, proj.y);
          });
        }
      });

      const levelNames = getLevelName(gmLevel);

      building.getRoutes(level).forEach((route) => {
        if (route.name !== 'microRoute' && route.levelList && route.levelList.length > 1) {
          return [];
        }
        const nodes = (route as any).getNodes();
        const points: {
          level: number;
          position: gm.Position;
          connectionType: gm.ConnectionType;
        }[] = [];
        for (let i = 0; i < nodes.length; i += 1) {
          const node = nodes[i];
          if (!node) continue;
          points.push({
            level:
              typeof node.level !== 'undefined' && !Number.isNaN(node.level)
                ? node.level
                : route.level,
            position: building.calcProjection(node),
            connectionType: route.connectionType,
          });
        }

        routes.push(new Route(route.id, level, route.routeType, points));
      });

      // setting rooms
      building.getElements(level).forEach((element) => {
        let eiUrlArr: gm.EIURL[];
        let eiGenInfo: gm.EIMarkupText;
        let eiSnapshot: gm.EIMarkupText;

        // TODO: Could set defaults here instead of handling them in ElementEditor
        if (element?.extraInfo) {
          // TODO: turn this into an array and map over aray to display results in extra info
          eiUrlArr = element.extraInfo.filter(
            (x) => x.type === gm.ExtraInfoType.WebURL
          ) as gm.EIURL[];

          // TODO: Potential breaking change, going forward the name field will be filled out
          // so we might be able to assume a MarkupText without a name is the genInfo
          eiGenInfo = (element.extraInfo.find(
            (x) => x.type === gm.ExtraInfoType.MarkupText && x.mlName?.en === 'General Information'
          ) as gm.EIMarkupText) || {
            markup: '',
            name: 'General Information',
            type: gm.ExtraInfoType.MarkupText,
          };

          eiSnapshot = (element.extraInfo.find(
            (x) => x.type === gm.ExtraInfoType.MarkupText && x.mlName?.en === 'Snapshot'
          ) as gm.EIMarkupText) || {
            markup: '',
            name: 'Snapshot',
            type: gm.ExtraInfoType.MarkupText,
          };
        }

        switch (element.elementType) {
          case ElementType.Wall:
            elements[ElementType.Wall].push({
              entityType: 0,
              points: element
                .getNodes()
                .map((node: any) => convertToVector2(building.calcProjection(node))),
              elementType: ElementType.Wall,
              props: {
                id: element.id,
                name: element.name,
                mlName: element.mlName,
                shortName: element.shortName,
                description: element.description,
                blindDescription: element.blindDescription,
                authList: element.authList,
                level,
                levelName: levelNames,
              },
            });
            break;
          case ElementType.Room:
            elements[ElementType.Room].push({
              entityType: 0,
              elementType: element.elementType,
              points: element
                .getNodes()
                .map((node: any) => convertToVector2(building.calcProjection(node))),
              props: {
                id: element.id,
                name: element.name,
                mlName: element.mlName,
                shortName: element.shortName,
                mlShortName: element?.mlShortName,
                description: element.description,
                blindDescription: element.blindDescription,
                authList: element.authList,
                mlUrl: eiUrlArr,
                genInfo: eiGenInfo?.markup || '',
                mlGenInfo: eiGenInfo?.mlMarkup,
                snapshot: eiSnapshot?.markup || '',
                mlSnapshot: eiSnapshot?.mlMarkup,
                level,
                levelName: levelNames,
              },
              roomType: (element as gm.Room).roomType || 0,
              holes: element.getInnerWays
                ? element.getInnerWays().map((way) => {
                    return way.getNodes().map((node) => {
                      const { x, y } = building.calcProjection(node);
                      return new Vector2(x, y);
                    });
                  })
                : [],
            });
            break;
          case ElementType.Corridor:
            elements[ElementType.Corridor].push({
              entityType: 0,
              elementType: element.elementType,
              points: element
                .getNodes()
                .map((node: any) => convertToVector2(building.calcProjection(node))),
              props: {
                id: element.id,
                name: element.name,
                mlName: element.mlName,
                shortName: element.shortName,
                mlShortName: element?.mlShortName,
                description: element.description,
                blindDescription: element.blindDescription,
                authList: element.authList,
                mlUrl: eiUrlArr,
                genInfo: eiGenInfo?.markup || '',
                mlGenInfo: eiGenInfo?.mlMarkup,
                level,
                levelName: levelNames,
              },
            });
            break;
          case ElementType.Area:
            elements[ElementType.Area].push({
              entityType: 0,
              elementType: element.elementType,
              points: element
                .getNodes()
                .map((node: any) => convertToVector2(building.calcProjection(node))),
              props: {
                id: element.id,
                name: element.name,
                mlName: element.mlName,
                shortName: element.shortName,
                mlShortName: element?.mlShortName,
                description: element.description,
                blindDescription: element.blindDescription,
                authList: element.authList,
                mlUrl: eiUrlArr,
                genInfo: eiGenInfo?.markup || '',
                mlGenInfo: eiGenInfo?.mlMarkup,
                level,
                levelName: levelNames,
              },
              holes: element.getInnerWays
                ? element.getInnerWays().map((way) => {
                    return way.getNodes().map((node) => {
                      const { x, y } = building.calcProjection(node);
                      return new Vector2(x, y);
                    });
                  })
                : [],
            });
            break;
          case ElementType.Fixture:
            elements[ElementType.Fixture].push({
              entityType: 0,
              elementType: element.elementType,
              points: element
                .getNodes()
                .map((node: any) => convertToVector2(building.calcProjection(node))),
              props: {
                id: element.id,
                name: element.name,
                mlName: element.mlName,
                shortName: element.shortName,
                mlShortName: element?.mlShortName,
                description: element.description,
                blindDescription: element.blindDescription,
                authList: element.authList,
                mlUrl: eiUrlArr,
                genInfo: eiGenInfo?.markup || '',
                mlGenInfo: eiGenInfo?.mlMarkup,
                level,
                levelName: levelNames,
              },
            });
            break;
          default:
            break;
        }
      });

      // setting stairs
      (building as gm.Building).getConnections(level, 1).forEach((stair) => {
        let eiUrlArr: gm.EIURL[];
        let eiGenInfo: gm.EIMarkupText;

        if (stair?.extraInfo) {
          eiUrlArr = stair.extraInfo.filter(
            (x) => x.type === gm.ExtraInfoType.WebURL
          ) as gm.EIURL[];

          eiGenInfo = stair.extraInfo.find(
            (x) => x.type === gm.ExtraInfoType.MarkupText
          ) as gm.EIMarkupText;
        }

        stairs.push({
          entityType: 1,
          connectionType: stair.connectionType,
          levelList: stair.levelList,
          points: stair
            .getNodes()
            .map((node: any) => convertToVector2(building.calcProjection(node))),
          props: {
            id: stair.id,
            name: stair.name,
            mlName: stair.mlName,
            shortName: stair.shortName,
            mlShortName: stair?.mlShortName,
            description: stair.description,
            blindDescription: stair.blindDescription,
            authList: stair.authList,
            level,
            levelName: levelNames,
            mlUrl: eiUrlArr,
            genInfo: eiGenInfo?.markup || '',
            mlGenInfo: eiGenInfo?.mlMarkup,
          },
        });
      });

      // setting elevator
      (building as gm.Building).getConnections(level, 2).forEach((elevator) => {
        let eiUrlArr: gm.EIURL[];
        let eiGenInfo: gm.EIMarkupText;

        if (elevator?.extraInfo) {
          eiUrlArr = elevator.extraInfo.filter(
            (x) => x.type === gm.ExtraInfoType.WebURL
          ) as gm.EIURL[];

          eiGenInfo = elevator.extraInfo.find(
            (x) => x.type === gm.ExtraInfoType.MarkupText
          ) as gm.EIMarkupText;
        }

        elevators.push({
          entityType: 1,
          connectionType: elevator.connectionType,
          levelList: elevator.levelList,
          points: elevator
            .getNodes()
            .map((node: any) => convertToVector2(building.calcProjection(node))),
          props: {
            id: elevator.id,
            name: elevator.name,
            mlName: elevator.mlName,
            shortName: elevator.shortName,
            mlShortName: elevator?.mlShortName,
            description: elevator.description,
            blindDescription: elevator.blindDescription,
            authList: elevator.authList,
            level,
            levelName: levelNames,
            mlUrl: eiUrlArr,
            genInfo: eiGenInfo?.markup || '',
            mlGenInfo: eiGenInfo?.mlMarkup,
          },
        });
      });

      // setting escalators
      (building as gm.Building).getConnections(level, 3).forEach((escalator) => {
        let eiUrlArr: gm.EIURL[];
        let eiGenInfo: gm.EIMarkupText;

        if (escalator?.extraInfo) {
          eiUrlArr = escalator.extraInfo.filter(
            (x) => x.type === gm.ExtraInfoType.WebURL
          ) as gm.EIURL[];

          eiGenInfo = escalator.extraInfo.find(
            (x) => x.type === gm.ExtraInfoType.MarkupText
          ) as gm.EIMarkupText;
        }

        escalators.push({
          entityType: 1,
          connectionType: escalator.connectionType,
          levelList: escalator.levelList,
          points: escalator
            .getNodes()
            .map((node: any) => convertToVector2(building.calcProjection(node))),
          props: {
            id: escalator.id,
            name: escalator.name,
            mlName: escalator.mlName,
            shortName: escalator.shortName,
            mlShortName: escalator?.mlShortName,
            description: escalator.description,
            blindDescription: escalator.blindDescription,
            authList: escalator.authList,
            level,
            levelName: levelNames,
            mlUrl: eiUrlArr,
            genInfo: eiGenInfo?.markup || '',
            mlGenInfo: eiGenInfo?.mlMarkup,
          },
        });
      });

      // setting other types of connections
      (building as gm.Building).getConnections(level, 4).forEach((other) => {
        let eiUrlArr: gm.EIURL[];
        let eiGenInfo: gm.EIMarkupText;

        if (other?.extraInfo) {
          eiUrlArr = other.extraInfo.filter(
            (x) => x.type === gm.ExtraInfoType.WebURL
          ) as gm.EIURL[];

          eiGenInfo = other.extraInfo.find(
            (x) => x.type === gm.ExtraInfoType.MarkupText
          ) as gm.EIMarkupText;
        }

        otherConnections.push({
          entityType: 1,
          connectionType: other.connectionType,
          levelList: other.levelList,
          points: other
            .getNodes()
            .map((node: any) => convertToVector2(building.calcProjection(node))),
          props: {
            id: other.id,
            name: other.name,
            mlName: other.mlName,
            shortName: other.shortName,
            mlShortName: other?.mlShortName,
            description: other.description,
            blindDescription: other.blindDescription,
            authList: other.authList,
            level,
            levelName: levelNames,
            mlUrl: eiUrlArr,
            genInfo: eiGenInfo?.markup || '',
            mlGenInfo: eiGenInfo?.mlMarkup,
          },
        });
      });

      building.getDoors(level).forEach((door) => {
        if (door.level === level) {
          let eiUrlArr: gm.EIURL[];
          let eiGenInfo: gm.EIMarkupText;

          if (door?.extraInfo) {
            eiUrlArr = door.extraInfo.filter(
              (x) => x.type === gm.ExtraInfoType.WebURL
            ) as gm.EIURL[];

            eiGenInfo = door.extraInfo.find(
              (x) => x.type === gm.ExtraInfoType.MarkupText
            ) as gm.EIMarkupText;
          }

          const d = {
            entityType: 3,
            position: building.calcProjection(door),
            entrance: door.entrance,
            props: {
              id: door.id,
              name: door.name,
              mlName: door.mlName,
              shortName: door.shortName,
              mlShortName: door?.mlShortName,
              description: door.description,
              blindDescription: door.blindDescription,
              authList: door.authList,
              level,
              levelName: levelNames,
              mlUrl: eiUrlArr,
              genInfo: eiGenInfo?.markup || '',
              mlGenInfo: eiGenInfo?.mlMarkup,
            },
          };
          doors.push(d);
        }
      });

      // setting beacons
      (building as gm.Building).getBeacons(level).forEach((beacon) => {
        beacons.push(
          new Beacon(beacon.uuid, beacon.major, beacon.minor, building.calcProjection(beacon), {
            id: beacon.id,
            name: beacon.name,
            mlName: beacon.mlName,
            shortName: beacon.shortName,
            description: beacon.description,
            blindDescription: beacon.blindDescription,
            authList: beacon.authList,
            level,
            levelName: levelNames,
          })
        );
      });

      // setting pois
      building.getPOIs(level).forEach((poi) => {
        let eiUrlArr: gm.EIURL[];
        let eiGenInfo: gm.EIMarkupText;

        if (poi?.extraInfo) {
          eiUrlArr = poi.extraInfo.filter((x) => x.type === gm.ExtraInfoType.WebURL) as gm.EIURL[];

          eiGenInfo = poi.extraInfo.find(
            (x) => x.type === gm.ExtraInfoType.MarkupText
          ) as gm.EIMarkupText;
        }

        pois.push({
          entityType: 2,
          poiType: poi.poiType,
          startingPoint: !!poi.virtualStart,
          position: building.calcProjection(poi),
          props: {
            id: poi.id,
            name: poi.name,
            mlName: poi.mlName,
            shortName: poi.shortName,
            mlShortName: poi?.mlShortName,
            description: poi.description,
            blindDescription: poi.blindDescription,
            authList: poi.authList,
            level,
            levelName: levelNames,
            mlUrl: eiUrlArr,
            genInfo: eiGenInfo?.markup || '',
            mlGenInfo: eiGenInfo?.mlMarkup,
          },
        });
      });

      // setting test points
      building.getTestPoints(level).forEach((point) => {
        if (point.level === level)
          testPoints.push(
            new TestPoint(point.id, point.level, point.testType, building.calcProjection(point))
          );
      });

      floors.push(
        new Floor(
          level,
          levelNames,
          outline,
          orientation,
          pois,
          doors,
          beacons,
          stairs,
          elevators,
          escalators,
          otherConnections,
          routes,
          testPoints,
          elements
        )
      );
    });

  return floors;
};

export const getCampusData = (campus: gm.Campus) => {
  let orientation = 0;

  const pois = [];
  const elements = initElements();
  const doors = [];
  const beacons = [];
  const stairs = [];
  const elevators = [];
  const escalators = [];
  const testPoints = [];
  const routes = [];

  if (campus.orientation) {
    const vectors = campus.orientation.getNodes().map((n) => {
      const { x, y } = campus.calcProjection(n);
      return new Vector2(x, y);
    });
    const v = vectors[1].sub(vectors[0]).normalize();
    const angle = Math.atan2(v.x, v.y) + Math.PI / 2;
    orientation = angle;
  }

  campus.getRoutes().forEach((route) => {
    if (route.name !== 'microRoute' && route.levelList && route.levelList.length > 1) {
      return [];
    }
    const nodes = (route as any).getNodes();
    const points: {
      level: number;
      position: gm.Position;
      connectionType: gm.ConnectionType;
    }[] = [];

    for (let i = 0; i < nodes.length; i += 1) {
      const node = nodes[i];
      if (!node) continue;
      points.push({
        level:
          typeof node.level !== 'undefined' && !Number.isNaN(node.level) ? node.level : route.level,
        position: campus.calcProjection(node),
        connectionType: route.connectionType,
      });
    }

    routes.push(new Route(route.id, 0, route.routeType, points));
  });

  // setting rooms
  campus.getElements().forEach((element) => {
    let eiUrlArr: gm.EIURL[];
    let eiGenInfo: gm.EIMarkupText;
    let eiSnapshot: gm.EIMarkupText;

    // TODO: Could set defaults here instead of handling them in ElementEditor
    if (element?.extraInfo) {
      eiUrlArr = element.extraInfo.filter((x) => x.type === gm.ExtraInfoType.WebURL) as gm.EIURL[];

      // TODO: Potential breaking change, going forward the name field will be filled out
      // so we might be able to assume a MarkupText without a name is the genInfo
      eiGenInfo = (element.extraInfo.find(
        (x) => x.type === gm.ExtraInfoType.MarkupText && x.mlName?.en === 'General Information'
      ) as gm.EIMarkupText) || {
        markup: '',
        name: 'General Information',
        type: gm.ExtraInfoType.MarkupText,
      };

      eiSnapshot = (element.extraInfo.find(
        (x) => x.type === gm.ExtraInfoType.MarkupText && x.mlName?.en === 'Snapshot'
      ) as gm.EIMarkupText) || {
        markup: '',
        name: 'Snapshot',
        type: gm.ExtraInfoType.MarkupText,
      };
    }

    switch (element.elementType) {
      case ElementType.Wall:
        elements[ElementType.Wall].push({
          entityType: 0,
          points: element
            .getNodes()
            .map((node: any) => convertToVector2(campus.calcProjection(node))),
          elementType: ElementType.Wall,
          props: {
            id: element.id,
            name: element.name,
            mlName: element.mlName,
            shortName: element.shortName,
            description: element.description,
            blindDescription: element.blindDescription,
            authList: element.authList,
            level: 0,
            levelName: '',
          },
        });
        break;
      case ElementType.Room:
        elements[ElementType.Room].push({
          entityType: 0,
          elementType: element.elementType,
          points: element
            .getNodes()
            .map((node: any) => convertToVector2(campus.calcProjection(node))),
          props: {
            id: element.id,
            name: element.name,
            mlName: element.mlName,
            shortName: element.shortName,
            mlShortName: element?.mlShortName,
            description: element.description,
            blindDescription: element.blindDescription,
            authList: element.authList,
            mlUrl: eiUrlArr,
            genInfo: eiGenInfo?.markup || '',
            mlGenInfo: eiGenInfo?.mlMarkup,
            snapshot: eiSnapshot?.markup || '',
            mlSnapshot: eiSnapshot?.mlMarkup,
            level: 0,
            levelName: '',
          },
          roomType: (element as gm.Room).roomType || 0,
          holes: element.getInnerWays
            ? element.getInnerWays().map((way) => {
                return way.getNodes().map((node) => {
                  const { x, y } = campus.calcProjection(node);
                  return new Vector2(x, y);
                });
              })
            : [],
        });
        break;
      case ElementType.Corridor:
        elements[ElementType.Corridor].push({
          entityType: 0,
          elementType: element.elementType,
          points: element
            .getNodes()
            .map((node: any) => convertToVector2(campus.calcProjection(node))),
          props: {
            id: element.id,
            name: element.name,
            mlName: element.mlName,
            shortName: element.shortName,
            mlShortName: element?.mlShortName,
            description: element.description,
            blindDescription: element.blindDescription,
            authList: element.authList,
            mlUrl: eiUrlArr,
            genInfo: eiGenInfo?.markup || '',
            mlGenInfo: eiGenInfo?.mlMarkup,
            level: 0,
            levelName: '',
          },
        });
        break;
      case ElementType.Area:
        elements[ElementType.Area].push({
          entityType: 0,
          elementType: element.elementType,
          points: element
            .getNodes()
            .map((node: any) => convertToVector2(campus.calcProjection(node))),
          props: {
            id: element.id,
            name: element.name,
            mlName: element.mlName,
            shortName: element.shortName,
            mlShortName: element?.mlShortName,
            description: element.description,
            blindDescription: element.blindDescription,
            authList: element.authList,
            mlUrl: eiUrlArr,
            genInfo: eiGenInfo?.markup || '',
            mlGenInfo: eiGenInfo?.mlMarkup,
            level: 0,
            levelName: '',
          },
          holes: element.getInnerWays
            ? element.getInnerWays().map((way) => {
                return way.getNodes().map((node) => {
                  const { x, y } = campus.calcProjection(node);
                  return new Vector2(x, y);
                });
              })
            : [],
        });
        break;
      default:
        break;
    }
  });

  campus.getDoors().forEach((door) => {
    let eiUrlArr: gm.EIURL[];
    let eiGenInfo: gm.EIMarkupText;

    if (door?.extraInfo) {
      eiUrlArr = door.extraInfo.filter((x) => x.type === gm.ExtraInfoType.WebURL) as gm.EIURL[];

      eiGenInfo = door.extraInfo.find(
        (x) => x.type === gm.ExtraInfoType.MarkupText
      ) as gm.EIMarkupText;
    }

    const d = {
      entityType: 3,
      position: campus.calcProjection(door),
      entrance: door.entrance,
      props: {
        id: door.id,
        name: door.name,
        mlName: door.mlName,
        shortName: door.shortName,
        mlShortName: door?.mlShortName,
        description: door.description,
        blindDescription: door.blindDescription,
        authList: door.authList,
        level: 0,
        levelName: '',
        mlUrl: eiUrlArr,
        genInfo: eiGenInfo?.markup || '',
        mlGenInfo: eiGenInfo?.mlMarkup,
      },
    };
    doors.push(d);
  });

  campus.getPOIs().forEach((poi) => {
    let eiUrlArr: gm.EIURL[];
    let eiGenInfo: gm.EIMarkupText;

    if (poi?.extraInfo) {
      eiUrlArr = poi.extraInfo.filter((x) => x.type === gm.ExtraInfoType.WebURL) as gm.EIURL[];

      eiGenInfo = poi.extraInfo.find(
        (x) => x.type === gm.ExtraInfoType.MarkupText
      ) as gm.EIMarkupText;
    }

    pois.push({
      entityType: 2,
      poiType: poi.poiType,
      startingPoint: !!poi.virtualStart,
      position: campus.calcProjection(poi),
      props: {
        id: poi.id,
        name: poi.name,
        mlName: poi.mlName,
        shortName: poi.shortName,
        mlShortName: poi?.mlShortName,
        description: poi.description,
        blindDescription: poi.blindDescription,
        authList: poi.authList,
        level: 0,
        levelName: '',
        mlUrl: eiUrlArr,
        genInfo: eiGenInfo?.markup || '',
        mlGenInfo: eiGenInfo?.mlMarkup,
      },
    });
  });

  return new Floor(
    0,
    'ground',
    null,
    orientation,
    pois,
    doors,
    beacons,
    stairs,
    elevators,
    escalators,
    [],
    routes,
    testPoints,
    elements
  );
};
