import {
  GET_ALL_BUILDINGS,
  SET_CURRENT_BUILDING,
  USER_LOADED,
  GET_ALL_COMPLEXES,
  CLEAR_BUILDING_SELECTION,
  GET_ALL_CAMPUSES,
  SET_IS_FORM_DIRTY,
} from '../actions/types';
import Floor from 'components/floorplan/structures/Floor';
import { generateLabelGroups } from '../helpers/LabelHelper';
import Beacon from 'components/floorplan/entities/Beacon';
import { Room, Door, POI, Element } from 'globals/Types';
import { Building, BuildingMetaData, ComplexMetaData, User } from 'goodmaps-sdk';

export type SessionState = {
  user: User;
  building: Building;
  buildings: BuildingMetaData[];
  complexes: ComplexMetaData[];
  selectedBuildingName: string;
  selectedBuildingUUID: string;
  floors: Floor[];
  allEntities: (Element | POI | Beacon | Door)[];
  allRooms: Room[];
  allPOIs: POI[];
  buildingLoading: boolean;
  labelGroups: any[];
  campuses: BuildingMetaData[];
  isFormDirty: boolean;
};

const initialState: SessionState = {
  user: {
    id: '',
    name: '',
    email: '',
    complexId: '',
    buildingId: '',
    auth: { campuses: [], customers: [], buildings: [], partners: [] },
  },
  building: null,
  buildings: [],
  complexes: [],
  selectedBuildingName: '',
  selectedBuildingUUID: '',
  floors: [],
  allEntities: [],
  allRooms: [],
  allPOIs: [],
  buildingLoading: true,
  labelGroups: [],
  campuses: [],
  isFormDirty: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADED: {
      return { ...state, user: action.payload };
    }

    case GET_ALL_BUILDINGS:
      return {
        ...state,
        buildings: action.payload,
      };

    case GET_ALL_COMPLEXES:
      return {
        ...state,
        complexes: action.payload,
      };

    case CLEAR_BUILDING_SELECTION:
      return {
        ...state,
        selectedBuildingName: '',
        selectedBuildingUUID: '',
        floors: [],
        allEntities: [],
        allRooms: [],
        allPOIs: [],
        buildingLoading: true,
        labelGroups: [],
      };

    case SET_CURRENT_BUILDING:
      return {
        ...state,
        building: action.payload.building,
        selectedBuildingUUID: action.payload.buildingUUID,
        selectedBuildingName: action.payload.buildingName,
        floors: action.payload.floors,
        allEntities: action.payload.floors
          .map((floor: Floor) => [
            ...Object.values(floor.elements).flat(),
            ...floor.pois,
            ...floor.beacons,
            ...floor.doors,
            ...floor.stairs,
            ...floor.elevators,
            ...floor.escalators,
          ])
          .flat(),
        allRooms: action.payload.floors.map((floor) => floor.rooms).flat(),
        allPOIs: action.payload.floors.map((floor) => floor.pois).flat(),
        buildingLoading: false,
        labelGroups: generateLabelGroups(action.payload.floors),
      };
    case GET_ALL_CAMPUSES: {
      return { ...state, campuses: action.payload };
    }

    case SET_IS_FORM_DIRTY: {
      return { ...state, isFormDirty: action.payload };
    }
    default:
      return state;
  }
};
