import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowBackIos, Home } from '@material-ui/icons';
import './NavigationHeader.scss';
import {
  cancelCreateNewPOI,
  clearSelectedEntity,
  clearSelectedFloor,
  setEditMode,
} from 'actions/ViewerActions';
import Floor from 'components/floorplan/structures/Floor';
import * as FunctionQueue from 'helpers/FunctionQueueHelper';

type Props = {
  isHomeDisabled: boolean;
  isBackDisabled: boolean;
  setPreviousScreen: (previousURL: string) => void;
};

const NavigationHeader = ({ isHomeDisabled, isBackDisabled, setPreviousScreen }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const selectedBuildingUUID = useSelector((state) => state.session.selectedBuildingUUID);
  const selectedEntity = useSelector((state) => state.viewer.selectedEntity);
  const currentFloor = useSelector((state) => state.viewer.currentFloor);
  const editMode = useSelector((state) => state.viewer.editMode);
  const floors: Floor[] = useSelector((state) => state.session.floors);
  const dispatch = useDispatch();
  const isFormDirty = useSelector((state) => state.session.isFormDirty);

  const buttonStyle = {
    color: '#0075fe',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    color: '#c4c4c4',
    cursor: null,
  };

  const backButtonClass = !isBackDisabled ? 'back-button' : 'back-button-disabled';
  const homeButtonClass = !isHomeDisabled ? 'home-button' : 'home-button-disabled';

  const onBackButtonPress = () => {
    if (isFormDirty) FunctionQueue.pause();

    if (location.pathname === `/building/${selectedBuildingUUID}`) {
      history.push('/');
      return;
    }
    //if the back button is pressed while in the process of adding a POI
    FunctionQueue.enqueue(() => dispatch(cancelCreateNewPOI()));

    //edge case for pressing the back button when only a building was selected, return to main directory

    const splitPath = location.pathname.split('/');
    //edge case for navigating back to central building directory back after just selecting a level
    if (splitPath.length === 5 && splitPath[splitPath.length - 2] === 'level') {
      //future url when back button is pressed has a length of 3 when .split('/'),
      //  this value must be less than previous screen to indicate a back transition -reason for extra / at the end of setPreviousScreen below
      FunctionQueue.enqueue(() => {
        setPreviousScreen(`/building/${selectedBuildingUUID}/`);
        dispatch(clearSelectedFloor()); // If this is too broad of a reset then determine which pieces of state should be reset and call them individually
      });

      history.push({
        pathname: `/building/${selectedBuildingUUID}`,
        state: { previousScreen: location.pathname.split('/').length },
      });
      return;
    }

    //remove a selected entity when navigating back from an entity screen
    if (selectedEntity) {
      FunctionQueue.enqueue(() => dispatch(clearSelectedEntity()));
    }
    //if navigating back to selected level view, zoom map out so user can see the whole level
    // if (splitPath[splitPath.length - 3] === 'level') {
    //   // setCameraPosition(new THREE.Vector3(0, 0, maxZoom));
    //   //TODO: reset zoom here
    // }
    //if navigating back to central building directory, show the user the stacked view
    if (!splitPath.includes('level') && splitPath.length <= 9 && currentFloor != null) {
      FunctionQueue.enqueue(() => dispatch(clearSelectedFloor()));
    }

    FunctionQueue.enqueue(() => setPreviousScreen(location.pathname));
    history.goBack();
  };

  const onHomeButtonPress = () => {
    if (isFormDirty) FunctionQueue.pause();

    if (editMode === 'move') {
      FunctionQueue.enqueue(() => dispatch(setEditMode('none')));
    }
    // Needed to corectly guide a user back to level home for buildings that only have one level
    if (floors.length === 1) {
      history.push({
        pathname: `/building/${selectedBuildingUUID}/level/0`,
        state: { previousScreen: location.pathname.split('/').length - 1 },
      });

      if (selectedEntity) FunctionQueue.enqueue(() => dispatch(clearSelectedEntity()));

      return;
    }

    if (currentFloor != null) FunctionQueue.enqueue(() => dispatch(clearSelectedFloor()));

    //future url when home button is pressed has a length of 3 when .split('/'),
    //  this value must be less than previous screen to indicate a back transition
    //  this is the reason for extra / at the end of setPreviousScreen below
    FunctionQueue.enqueue(() => setPreviousScreen(`/building/${selectedBuildingUUID}/`));
    history.push({
      pathname: `/building/${selectedBuildingUUID}`,
      state: { previousScreen: location.pathname.split('/').length },
    });

    if (selectedEntity) FunctionQueue.enqueue(() => dispatch(clearSelectedEntity()));
  };

  return (
    <div className="nav-buttons-box">
      <div
        onClick={isBackDisabled ? null : onBackButtonPress}
        style={isBackDisabled ? disabledButtonStyle : buttonStyle}
        className={`${backButtonClass} flex`}
      >
        <ArrowBackIos />
      </div>
      <div
        onClick={isHomeDisabled ? null : onHomeButtonPress}
        style={isHomeDisabled ? disabledButtonStyle : buttonStyle}
        className={`${homeButtonClass} flex`}
      >
        <Home />
      </div>
    </div>
  );
};

export default NavigationHeader;
