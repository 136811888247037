import Auth from '@aws-amplify/auth';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { get, post, remove, put } from 'helpers/goodmaps-helper/AxiosHelper';
import config from 'globals/config/config';
import { SnapShotRequestData, ConvertToImdfRequest, ContainerData } from 'goodmaps-utils';
import { BuildingData, CampusData, CustomerData, PartnerData } from 'goodmaps-sdk';

// errors come across as type any
const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    // console.log(error.response.status, error.response.data.message);
    throw new Error(`${error.response.status} - ${error.response.data.message}`);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request);
    throw new Error('Bad request');
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message);
    throw new Error(error.message);
  }
};

export const uploadBuildingData = async (
  osmData,
  buildingId: string,
  userId: string,
  dataType: string
) => {
  const endpoint = `${config.BUILDING_URL}/upload`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  const data = {
    userId,
    id: buildingId,
    osmData: osmData,
    dataType: dataType,
  };

  try {
    const response: AxiosResponse<any> = await axios.post(endpoint, data, apiConfig);

    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const transformBuildingData = async (transformData: {
  id: string;
  userId: string;
  timestamp: string;
  removeBuildingData: boolean;
}) => {
  const endpoint = `${config.BUILDING_URL}/transform`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.post(endpoint, transformData, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const getLint = async (buildingId: string, timestamp: string) => {
  const endpoint = `${config.LINT_BUILDING_URL}/lint/${buildingId}/${timestamp}`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };
  const disconnectedRoutesEndpoint = `${config.LINT_BUILDING_URL}/disconnectedRoutes/${buildingId}/${timestamp}`;

  try {
    const response: AxiosResponse<any> = await axios.get(endpoint, apiConfig);
    const disconnectedRoutesResponse: AxiosResponse<any> = await axios.get(
      disconnectedRoutesEndpoint,
      apiConfig
    );
    return {
      message: [...disconnectedRoutesResponse.data.message, ...response.data.message],
      status: response.status,
    };
  } catch (error) {
    handleError(error);
  }
};

interface DeployRequest {
  userId?: string;
  id: string;
  timestamp: string;
}

export const deployBuildingData = async (deployRequest: DeployRequest) => {
  const endpoint = `${config.BUILDING_URL}/deploy`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.post(endpoint, deployRequest, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const requestSnapShot = async (requestData: SnapShotRequestData) => {
  const endpoint = `${config.API_URL}/requestSnapShot`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.post(endpoint, requestData, apiConfig);
    console.log(response);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    console.log(error);
    handleError(error);
  }
};

export const exportBuildingOrCampusFile = async (id: string) => {
  const endpoint = `${config.BUILDING_URL}/export/${id}`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };
  try {
    const response: AxiosResponse<any> = await axios.get(endpoint, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const convertToImdf = async (requestData: ConvertToImdfRequest) => {
  const endpoint = `${config.IMDF_BUILDING_URL}/imdf/convertImdf`;
  const apiConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.post(endpoint, requestData, apiConfig);

    return { message: response.data.message, status: response.status, data: response.data };
  } catch (error) {
    console.log(error);
    handleError(error);
  }
};

export const base64ToBlob = (base64) => {
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;

  let ab = new ArrayBuffer(binaryLen);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < binaryLen; i++) {
    ia[i] = binaryString.charCodeAt(i);
  }

  let bb = new Blob([ab]);

  return bb;
};

export const getContainerList = async (): Promise<{ message: ContainerData[]; status: number }> => {
  const endpoint = `${config.API_URL}/containers`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const getPartnerList = async (): Promise<{ message: PartnerData[]; status: number }> => {
  const endpoint = `${config.API_URL}/root/partners`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const getPartner = async (
  partnerId: string
): Promise<{ message: PartnerData; status: number }> => {
  const endpoint = `${config.API_URL}/root/partners/${partnerId}`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const addPartner = async (
  newPartnerData: PartnerData
): Promise<{ message: PartnerData; status: number }> => {
  const endpoint = `${config.API_URL}/root/partners`;

  try {
    const result = await post(endpoint, newPartnerData);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const editPartner = async (
  newPartnerData: PartnerData
): Promise<{ message: PartnerData; status: number }> => {
  const endpoint = `${config.API_URL}/root/partners/${newPartnerData.id}`;

  try {
    const result = await put(endpoint, newPartnerData);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const deletePartner = async (
  partnerId: string
): Promise<{ message: PartnerData; status: number }> => {
  const endpoint = `${config.API_URL}/root/partners/${partnerId}`;

  try {
    const result = await remove(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const getCustomerList = async (
  partnerId: string
): Promise<{ message: CustomerData[]; status: number }> => {
  const endpoint = `${config.API_URL}/${partnerId}/customers`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const getCustomer = async (
  partnerId: string,
  customerId: string
): Promise<{ message: CustomerData; status: number }> => {
  const endpoint = `${config.API_URL}/${partnerId}/customers/${customerId}`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const addCustomer = async (
  partnerId: string,
  newCustomerData: CustomerData
): Promise<{ message: CustomerData; status: number }> => {
  const endpoint = `${config.API_URL}/${partnerId}/customers`;

  try {
    const result = await post(endpoint, newCustomerData);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const editCustomer = async (
  partnerId: string,
  newCustomerData: CustomerData
): Promise<{ message: CustomerData; status: number }> => {
  const endpoint = `${config.API_URL}/${partnerId}/customers/${newCustomerData.id}`;

  try {
    const result = await put(endpoint, newCustomerData);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const deleteCustomer = async (
  partnerId: string,
  customerId: string
): Promise<{ message: CustomerData; status: number }> => {
  const endpoint = `${config.API_URL}/${partnerId}/customers/${customerId}`;

  try {
    const result = await remove(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const getCampusList = async (
  customerId: string
): Promise<{ message: CampusData[]; status: number }> => {
  const endpoint = `${config.API_URL}/${customerId}/campuses`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const getCampus = async (
  customerId: string,
  campusId: string
): Promise<{ message: CampusData; status: number }> => {
  const endpoint = `${config.API_URL}/${customerId}/campuses/${campusId}`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const addCampus = async (
  customerId: string,
  newCampusData: CampusData
): Promise<{ message: CampusData; status: number }> => {
  const endpoint = `${config.API_URL}/${customerId}/campuses`;

  try {
    const result = await post(endpoint, newCampusData);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const editCampus = async (
  customerId: string,
  newCampusData: CampusData
): Promise<{ message: CampusData; status: number }> => {
  const endpoint = `${config.API_URL}/${customerId}/campuses/${newCampusData.id}`;

  try {
    const result = await put(endpoint, newCampusData);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const deleteCampus = async (
  customerId: string,
  campusId: string
): Promise<{ message: CampusData; status: number }> => {
  const endpoint = `${config.API_URL}/${customerId}/campuses/${campusId}`;

  try {
    const result = await remove(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const getBuildingList = async (
  campusId: string
): Promise<{ message: BuildingData[]; status: number }> => {
  const endpoint = `${config.API_URL}/${campusId}/buildings`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const getBuilding = async (
  campusId: string,
  buildingId: string
): Promise<{ message: BuildingData; status: number }> => {
  const endpoint = `${config.API_URL}/${campusId}/buildings/${buildingId}`;

  try {
    const result = await get(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const addBuilding = async (
  campusId: string,
  newBuildingData: BuildingData
): Promise<{ message: BuildingData; status: number }> => {
  const endpoint = `${config.API_URL}/${campusId}/buildings`;

  try {
    const result = await post(endpoint, newBuildingData);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const editBuilding = async (
  campusId: string,
  newBuildingData: BuildingData
): Promise<{ message: BuildingData; status: number }> => {
  const endpoint = `${config.API_URL}/${campusId}/buildings/${newBuildingData.id}`;

  try {
    const result = await put(endpoint, newBuildingData);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};

export const deleteBuilding = async (
  campusId: string,
  buildingId: string
): Promise<{ message: BuildingData; status: number }> => {
  const endpoint = `${config.API_URL}/${campusId}/buildings/${buildingId}`;

  try {
    const result = await remove(endpoint);
    return result;
  } catch (e) {
    throw new Error((e as any).message);
  }
};
