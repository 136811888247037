import React, { useState } from 'react';
import EditorPage from 'components/smart/EditorPage';
import Directory from 'components/smart/Directory';
import { Link } from 'globals/Types';

type Props = {
  selectedCampusName: string;
  buildingLinks: Link[];
  catLinks: Link[];
  searchLinks: Link[];
};

const CampusHome = ({ selectedCampusName, buildingLinks, catLinks, searchLinks }: Props) => {
  const [search, setSearch] = useState('');

  return (
    <EditorPage
      showSearch
      search={search}
      setSearch={setSearch}
      title={selectedCampusName}
      icon="/editor_icons/info-icon.png"
    >
      {search ? (
        <Directory
          links={searchLinks.filter((link) =>
            link.primary.toLowerCase().includes(search.toLowerCase())
          )}
          title="Search Results"
        />
      ) : (
        <>
          <Directory links={catLinks} title="Entities" />
          <Directory links={buildingLinks} title="Buildings" />
        </>
      )}
    </EditorPage>
  );
};

export default CampusHome;
