import { EventEmitter } from 'events';
import { Scene, Vector2, Object3D, PerspectiveCamera } from 'three';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { getDistanceBetweenPoints } from 'helpers/MathHelper';
import ZOOMLEVELS from 'globals/ZoomLevels';
import * as Renderer from './Renderer';
import { ZoomLevelType } from 'globals/Types';
import { Connection, POI, Position, Element, Room, Door, BaseType } from 'goodmaps-sdk';
import { Entities, getMapIcon } from 'helpers/goodmaps-helper/GoodMaps';
interface Label {
  position: Vector2;
  textObject: CSS2DObject;
  iconObject: CSS2DObject;
  alwaysShown: boolean;
  selected: boolean;
  highlighted: boolean;
  outlined?: boolean;
  group: number;
  baseType: BaseType;
}
interface LabelMap {
  [labelId: string]: Label;
}

let labelRenderer: CSS2DRenderer;
let labelMap: LabelMap = {};

/////////
let labelsContainer: Object3D;
let currentZoomLevel: ZoomLevelType = null;
let emitter: EventEmitter = new EventEmitter();
let clientDimensions: { width: number; height: number };

let cleanup = () => {};
export { cleanup };

export const getEmitter = () => emitter;

export const init = (container: HTMLElement, scn: Scene, labelScene: Object3D) => {
  labelRenderer = new CSS2DRenderer();
  labelsContainer = labelScene;
  const { clientWidth, clientHeight } = container;
  clientDimensions = { width: clientWidth, height: clientHeight };
  labelRenderer.setSize(clientWidth, clientHeight);
  labelRenderer.domElement.className = 'label-renderer';

  container.appendChild(labelRenderer.domElement);

  const onResize = () => {
    const { clientWidth, clientHeight } = container;
    labelRenderer.setSize(clientWidth, clientHeight);
  };
  window.addEventListener('resize', onResize);

  Renderer.getEmitter().addListener('onZoom', (currentZoom: number) => {
    const zoomLevel = getZoomLevel(currentZoom);

    if (zoomLevel.level !== currentZoomLevel?.level) {
      currentZoomLevel = zoomLevel;

      refreshAllLabels(zoomLevel.level);
    }
  });

  cleanup = () => {
    emitter.removeAllListeners();
    window.removeEventListener('resize', onResize);
    removeAllLabels();
  };
};

export const render = (scene: Scene, camera: PerspectiveCamera) => {
  if (labelRenderer) labelRenderer.render(scene, camera);
};

export const getLabelRenderer = () => {
  return labelRenderer;
};

export const addLabel = (
  point: { x: number; y: number },
  text: string,
  icon: string,
  labelClass: string,
  iconClass: string,
  id: string,
  baseType: number
) => {
  try {
    removeLabel(id);
    let textObject, iconObject;

    const textEl = document.createElement('div');
    textEl.className = `map-label ${labelClass}`;
    textEl.id = `label-${id}`;
    textEl.textContent = text;
    textEl.onmouseenter = (e) => {
      emitter.emit('labelMouseIn', id);
    };
    textEl.onmouseleave = (e) => {
      emitter.emit('labelMouseOut', id);
    };
    textEl.onclick = (e) => {
      e.stopPropagation();
      emitter.emit('labelSelected', id);
    };
    textObject = new CSS2DObject(textEl);
    textObject.position.set(point.x, point.y, 0);
    textObject.name = `label-${id}`;
    labelsContainer.add(textObject);

    if (icon) {
      const iconEl = document.createElement('img');
      iconEl.id = `icon-${id}`;
      iconEl.src = icon;
      iconEl.className = `map-icon ${iconClass} ${text ? '' : 'no-text'}`;
      iconEl.onmouseenter = (e) => {
        emitter.emit('labelMouseIn', id);
      };
      iconEl.onmouseleave = (e) => {
        emitter.emit('labelMouseOut', id);
      };
      iconEl.onclick = (e) => {
        e.stopPropagation();
        emitter.emit('labelSelected', id);
      };

      iconObject = new CSS2DObject(iconEl);
      iconObject.position.set(point.x, point.y, 0);
      iconObject.name = `icon-${id}`;
      labelsContainer.add(iconObject);
    }

    labelMap[id] = {
      textObject,
      iconObject,
      alwaysShown: false,
      selected: false,
      highlighted: false,
      position: new Vector2(point.x, point.y),
      group: -1,
      baseType,
    };
  } catch (e) {
    console.log(e);
  }
};

export const removeLabel = (id: string) => {
  try {
    labelsContainer.remove(labelMap[id].textObject);
    labelsContainer.remove(labelMap[id].iconObject);
    delete labelMap[id];
  } catch (e) {}
};

export const removeAllLabels = () => {
  Object.keys(labelMap).forEach((key) => removeLabel(key));
};

export const updateLabelText = (entity: Element | Connection | POI | Door, newText: string) => {
  if (labelMap[entity.id]) {
    labelMap[entity.id].textObject.element.textContent = newText;
  } else {
    // if a label does not exist we need to generate one and add it to the label groups
    const point = { x: entity.x, y: entity.y };
    let mapIcon = '';

    // baseType uses a different enum than Studio.
    // TODO: Update so we can use the same enums throughout
    //        Will require swapping out all spots where the Entities enum is used
    if (entity.baseType === BaseType.Element) {
      const catId = (entity as Element).elementType;

      let roomType = null;
      if ((entity as Room)?.roomType && (entity as Room).roomType != null)
        roomType = (entity as Room).roomType || 0;

      mapIcon = getMapIcon(Entities.elements, catId, roomType);
    }
    if (entity.baseType === BaseType.Connection) {
      const catId = (entity as Connection).connectionType;

      mapIcon = getMapIcon(Entities.elements, catId);
    }
    if (entity.baseType === BaseType.POI) {
      const catId = (entity as POI).poiType;

      mapIcon = getMapIcon(Entities.elements, catId);
    }
    if (entity.baseType === BaseType.Door) {
      mapIcon = getMapIcon(Entities.doors);
    }

    if (entity.baseType === BaseType.Fixture) {
      const catId = (entity as Element).elementType;
      mapIcon = getMapIcon(Entities.elements, catId);
    }

    addLabel(point, newText, mapIcon, '', '', entity.id, entity.baseType);

    Renderer.selectEntity(entity.id, 'none');
  }
  //label groups need to update based on label addition or name change
  setupGroups();
};

export const updateLabelIcon = (id: string, iconSrc: string) => {
  (labelMap[id].iconObject.element as any).src = iconSrc;
};

export const updateLabelPosition = (id: string, position: Position) => {
  labelMap[id].textObject.position.set(position.x, position.y, 0);
  labelMap[id].iconObject.position.set(position.x, position.y, 0);
};

export const updateLabelState = (
  id: string,
  update: { selected?: boolean; alwaysShown?: boolean; highlighted?: boolean; outlined?: boolean }
) => {
  labelMap[id] = { ...labelMap[id], ...update };
  refreshLabel(labelMap[id], currentZoomLevel.level);
};

export const refreshLabelById = (id: string, zoomLevel?: number) => {
  refreshLabel(labelMap[id], zoomLevel);
};

const refreshLabel = (label: Label, zoomLevel?: number) => {
  if (label.selected) {
    label.textObject?.element?.classList.add('selected');
    label.iconObject?.element?.classList.add('selected');
  } else {
    label.textObject?.element?.classList.remove('selected');
    label.iconObject?.element?.classList.remove('selected');
  }

  if (label.highlighted) {
    label.textObject?.element?.classList.add('highlighted');
    label.iconObject?.element?.classList.add('highlighted');
  } else {
    label.textObject?.element?.classList.remove('highlighted');
    label.iconObject?.element?.classList.remove('highlighted');
  }

  if (label.outlined) {
    label.textObject?.element?.classList.add('outlined');
    label.iconObject?.element?.classList.add('outlined');
  } else {
    label.textObject?.element?.classList.remove('outlined');
    label.iconObject?.element?.classList.remove('outlined');
  }

  if (label.group > zoomLevel && !label.alwaysShown && !label.selected && !label.highlighted) {
    label.textObject?.element?.classList.add('hidden');
    label.iconObject?.element?.classList.add('hidden');
  } else {
    label.textObject?.element?.classList.remove('hidden');
    label.iconObject?.element?.classList.remove('hidden');
  }
};

const refreshAllLabels = (zoomLevel?: number) => {
  Object.keys(labelMap).forEach((key) => {
    refreshLabel(labelMap[key], zoomLevel);
  });
};

export const renderUnnamedPois = (showNamelessPoiLabels: boolean) => {
  const mapLabels: Label[] = Object.keys(labelMap).map((key) => labelMap[key]);
  mapLabels.forEach((label: Label) => {
    if (!label.textObject?.element?.textContent && label.baseType === BaseType.POI) {
      if (showNamelessPoiLabels) {
        //set label property so that the label is globally viewable if showNamelessPoiLabels is set
        label.alwaysShown = true;
        label.outlined = true;
      } else {
        //set label property so that the label isn't globally viewable
        label.alwaysShown = false;
        label.outlined = false;
      }
    }
  });
  refreshAllLabels(getZoomLevel(Renderer.getCurrentZoomAmount()).level);
};

export const setupGroups = () => {
  const remainingLabels: Label[] = Object.keys(labelMap).map((key) => labelMap[key]);
  const usedLabels: Label[] = [];

  ZOOMLEVELS.forEach(({ dimensions }, i) => {
    const { width, height } = dimensions;
    const MAXIMUM_DISTANCE_FROM_CENTER = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
    const MAXIMUM_DISTANCE_FROM_LABEL = MAXIMUM_DISTANCE_FROM_CENTER / 3;

    remainingLabels.forEach((label) => {
      // Don't add rooms without a name to sorted tiers of labels
      if (!label.textObject?.element?.textContent) {
        //set label group for unnamed doors to 5, and other unnamed entities to ZOOMLEVELS.length
        if (label.baseType === BaseType.Door) {
          label.group = 5;
        } else {
          label.group = ZOOMLEVELS.length;
        }
        return;
      }

      const labelWithinMaximumDistance = !!usedLabels.find(
        (obj) =>
          getDistanceBetweenPoints(obj.position, label.position) < MAXIMUM_DISTANCE_FROM_LABEL
      );

      if (labelWithinMaximumDistance) return;

      label.group = i;
      usedLabels.push(label);
    });
  });
  refreshAllLabels(getZoomLevel(Renderer.getCurrentZoomAmount()).level);
};

export const getLabelRendererClient = () => clientDimensions;

export const getZoomLevel = (zoomAmount: number) => {
  let zoomLevel: ZoomLevelType;

  ZOOMLEVELS.some((zoom, index) => {
    if (zoomAmount >= zoom.distance || index === ZOOMLEVELS.length - 1) {
      zoomLevel = zoom;
      return true;
    }

    return false;
  });

  return zoomLevel;
};
