import React from 'react';
import './Header.scss';

type Props = {
  title: string;
  icon?: string;
};

const Header = ({ title, icon }: Props) => {
  return (
    <div className="header-box">
      <div className="header">
        {icon && icon !== undefined && (
          <div className="icon">
            <img src={`${icon}`} alt="icon" />
          </div>
        )}
        <div className="title">{title !== 'Pois' ? title : 'POIs'}</div>
      </div>
    </div>
  );
};

export default Header;
