// TODO: Move to Utils?
export const STATUSES = [
  { key: 0, value: 'Pipeline' },
  { key: 1, value: 'PreScan' },
  { key: 2, value: 'Scanned' },
  { key: 3, value: 'ScanProcessing' },
  { key: 4, value: 'MapDrawing' },
  { key: 5, value: 'MapTagging' },
  { key: 6, value: 'Verification' },
  { key: 7, value: 'CustomerReview' },
  { key: 8, value: 'Published' },
];

// TODO: Move to Utils?
export const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Canadian French' },
  { code: 'es', name: 'Spanish' },
];

export const BEFORE_UNLOAD_MESSAGE = 'There are unsaved changes. Are you sure you want to leave?';
