import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import { Table, Alert } from 'react-bootstrap';
import './ParentList.scss';
import {
  userIsGoodMapsAdmin,
  User,
  POI,
  POIType,
  BaseType,
  Element,
  Door,
  ElementType,
  Connection,
  ConnectionType,
} from 'goodmaps-sdk';
import { getBuildingManager } from 'helpers/goodmaps-helper/BuildingManager';
import { ArrowForward } from '@material-ui/icons';

const BuildingPOIList = () => {
  const history = useHistory();
  const { campusId, buildingId } = useParams();
  const overlayContainer = useRef(null);
  const user: User = useSelector((state) => state.session.user);
  const [poiList, setPoiList] = useState<(POI | Element | Door | Connection)[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>('');
  const [buildingName, setBuildingName] = useState('');

  useEffect(() => {
    console.log({ campus: campusId, building: buildingId });
    const getBuildingData = async () => {
      try {
        const buildingManager = getBuildingManager();
        const { buildings } = await buildingManager.getBuildings();
        const buildingMetaData = buildings.find(
          (building) => building.id === buildingId.split('.')[1]
        );
        const createdBuilding = await buildingManager.createBuilding(buildingMetaData);
        setBuildingName(buildingMetaData.name);
        setPoiList([
          ...createdBuilding.getPOIs(),
          ...createdBuilding.getElements(),
          ...createdBuilding.getDoors(),
          ...createdBuilding.getConnections(),
        ]);
        setIsLoading(false);
      } catch (e) {
        setError(e);
      }
    };

    if (userIsGoodMapsAdmin(user.auth)) {
      getBuildingData();
    }
  }, [buildingId, campusId, history, isLoading, user.auth]);

  const navigateToEntity = (entity: POI | Element | Door | Connection) => {
    let route = '';
    switch (entity.baseType) {
      case BaseType.POI:
        route = `/building/${buildingId.split('.')[1]}/2/category/${(entity as POI).poiType}/${
          entity.id
        }`;
        break;
      case BaseType.Element:
        route = `/building/${buildingId.split('.')[1]}/0/category/${
          (entity as Element).elementType
        }/${entity.id}`;
        break;
      case BaseType.Door:
        route = `/building/${buildingId.split('.')[1]}/3/${entity.id}`;
        break;
      case BaseType.Area:
        route = `/building/${buildingId.split('.')[1]}/0/category/2/${entity.id}`;
        break;
      case BaseType.Fixture:
        route = `/building/${buildingId.split('.')[1]}/0/category/10/${entity.id}`;
        break;
      case BaseType.Connection:
        route = `/building/${buildingId.split('.')[1]}/1/category/${
          (entity as Connection).connectionType
        }/${entity.id}`;
        break;
      default:
        break;
    }
    history.push(route);
  };

  const getEntityType = (entity: POI | Element | Door | Connection) => {
    switch (entity.baseType) {
      case BaseType.POI:
        return POIType[(entity as POI).poiType];
      case BaseType.Element:
        return ElementType[(entity as Element).elementType];
      case BaseType.Door:
        return 'Door';
      case BaseType.Connection:
        return ConnectionType[(entity as Connection).connectionType];
      default:
        return BaseType[entity.baseType];
    }
  };

  const tableItems = poiList
    .filter((poi) => !!poi.name)
    .sort((a: POI | Element | Door | Connection, b: POI | Element | Door | Connection) =>
      a.name.localeCompare(b.name)
    )
    .map((poi: POI | Element | Door | Connection) => {
      return (
        <tr key={poi.id}>
          <td>
            {poi.name}
            <br />

            <span style={{ fontSize: 10 }}>{poi.id}</span>
          </td>
          <td style={{ fontWeight: 'bold' }}>{getEntityType(poi)}</td>
          <td style={{ padding: 0, verticalAlign: 'middle' }}>
            <ArrowForward onClick={() => navigateToEntity(poi)} />
          </td>
        </tr>
      );
    });

  return isLoading ? (
    <CircularProgressIndicator full />
  ) : (
    <div className="partner-table-wrapper" ref={overlayContainer}>
      <div className="partner-table">
        <div className="partner-table-header flex flex-row items-center space-between">
          <div id="table-header" className="flex flex-row">
            <h1>POIS for {buildingName}</h1>
            <div className="button-action flex items-center justify-center"></div>
          </div>
        </div>
        {error ? (
          <div>
            <Alert variant="danger" style={{ marginBottom: 0 }}>
              {error}
            </Alert>
          </div>
        ) : null}
        <Table bordered className="background" size="sm">
          <thead>
            <tr>
              <th>Entity Name</th>
              <th>Entity Type</th>
              <th style={{ width: 37 }}></th>
            </tr>
          </thead>
          <tbody>
            {tableItems.length === 0 ? (
              <tr>
                <td colSpan={2}>
                  <div className="empty-table">
                    <h6>Empty Partners Table</h6>
                  </div>
                </td>
              </tr>
            ) : (
              tableItems
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default BuildingPOIList;
