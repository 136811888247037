import { ICONS } from 'globals/Icons';
import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './ClientAdminUsers.scss';

export default () => {
  return (
    <div className="landing-page-background absolute w-100 h-100 cover no-repeat bg-bottom">
      <Container className="client-user-card-container">
        <Row>
          <Col md={12} lg={6}>
            <div className="client-admin-card">
              <h4 className="explore-card-header">Invite to Goodmaps Explore</h4>
              <p>
                Allow others to access private, secure, hidden indoor map data (in addition to the
                public data already available on Explore). Email support@goodmaps.com with email
                addresses of the people you would like to invite to access the private map data.
              </p>
            </div>
            <div className="formatting-tip">
              <img className="formatting-tip-img" src={ICONS.misc.tip} alt="tip" />
              <p>
                Formatting Tip: Submit your list by separating emails with commas and spaces, just
                commas, just spaces, bullet points, or new lines.
              </p>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className="client-admin-card">
              <h4 className="studio-card-header">Invite to GoodMaps Studio</h4>
              <p>
                Invite a team member to view and edit map data by sending their email address, with
                a subject line “GoodMaps Studio” to support@goodmaps.com.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="button-container">
            <Button className="email-support-button" href="mailto:support@goodmaps.com">
              EMAIL SUPPORT
            </Button>
          </div>
        </Row>
      </Container>
    </div>
  );
};
