import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Alert from 'components/dummy/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import config from '../../globals/config/config';
import { Environment } from 'goodmaps-utils';

Auth.configure({
  userPoolId: config.userPoolId,
  userPoolWebClientId: config.userPoolWebClientId,
  region: config.region,
});

export default () => {
  const { location } = useHistory();
  const [username, setUsername] = React.useState('');
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState('');
  const [passwordUpdated, setPasswordUpdated] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password is too short.')
        .required('Password field cannot be blank.')
        .matches(/\d/, 'Password must contain a number.'),
      confirmPassword: Yup.string()
        .min(8, 'Password is too short.')
        .required('Password field cannot be blank.')
        .matches(/\d/, 'Password must contain a number.')
        .oneOf([Yup.ref('password'), null], "Passwords don't match"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await Auth.forgotPasswordSubmit(username, code, values.password);
        setPasswordUpdated(true);
        setSubmitting(false);
      } catch (e) {
        setError(
          'Unable to set password. Please try again. If the problem persists contact support@goodmaps.com.'
        );
        setSubmitting(false);
      }
    },
  });

  React.useEffect(() => {
    const { username, code }: { username: string; code: string } = location.search
      .substring(1, location.search.length)
      .split('&')
      .reduce(
        (acc, curr: string) => {
          const resultArr = curr.split('=');
          acc[resultArr[0]] = resultArr[1];
          return acc;
        },
        { username: '', code: '' }
      );
    setUsername(username);
    setCode(code);
    console.log(username, code);
  }, [location.search]);

  const showAccountSetup = () => {
    return (
      <form name="login-form" onSubmit={formik.handleSubmit} role="main">
        <div className="form">
          <div className="form-header">
            <h1 className="header-label">Password Reset</h1>
            <h2 className="subheader-label">
              Your new password must be 8 characters and contain at least 1 number.
            </h2>
          </div>
          <div className="form-body">
            <div className="form-formfield flex">
              <label className="input-label" htmlFor="password">
                New Password
              </label>
              <input
                placeholder="Enter your password"
                type="password"
                name="password"
                id="password"
                className="password-input"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error-label flex">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="form-formfield flex">
              <label className="input-label" htmlFor="confirmPassword">
                Confirm New Password
              </label>
              <input
                placeholder="Re-enter your password"
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="password-input"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className="error-label flex">{formik.errors.confirmPassword}</div>
              ) : null}
            </div>
          </div>
          <div className="sign-in-footer-section flex" style={{ marginBottom: 20 }}>
            <span className="footer-content">
              <button type="submit" className="sign-in-button">
                SUBMIT
              </button>
            </span>
          </div>
          {error ? <Alert variant="danger">{error}</Alert> : null}
        </div>
      </form>
    );
  };

  const showAccountConfirmation = () => {
    return (
      <form name="login-form" role="main">
        <div className="form">
          <div className="form-header">
            <h1 className="header-label">Your account is ready</h1>
            <h2 className="subheader-label">You can now sign in with your new password.</h2>
          </div>
          <div className="sign-in-footer-section flex" style={{ marginBottom: 20 }}>
            <span className="footer-content">
              <a
                className="sign-in-button"
                href={`https://goodmaps.app.link/T0UXeaJVKkb?username=${username}`}
              >
                Go to GoodMaps Explore
              </a>
              {config.ENV_ID !== Environment.Prod ? (
                <a
                  className="sign-in-button"
                  href={`https://goodmapsindoorguide.app.link/nygYnzRQ1kb?username=${username}`}
                >
                  Go to GoodMaps Visual
                </a>
              ) : null}
            </span>
          </div>

          {error ? <Alert variant="danger">{error}</Alert> : null}
        </div>
      </form>
    );
  };

  return (
    <div className="login-container" style={{ paddingTop: 100 }}>
      {passwordUpdated ? showAccountConfirmation() : showAccountSetup()}
    </div>
  );
};
