/* eslint-disable */
import {
  Scene,
  WebGLRenderer,
  Mesh,
  Vector2,
  Vector3,
  MeshBasicMaterial,
  Shape,
  ShapeGeometry,
  PlaneGeometry,
  Material,
  Object3D,
  PerspectiveCamera,
  Raycaster,
  MOUSE,
  TextureLoader,
  ExtrudeGeometry,
  MeshStandardMaterial,
  DirectionalLight,
  AmbientLight,
  Color,
  RepeatWrapping,
  Geometry,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as gm from 'goodmaps-sdk';
import {
  Entities,
  getElementColor,
  getLineProps,
  getMapIcon,
} from 'helpers/goodmaps-helper/GoodMaps';
import * as LabelRenderer from './LabelRenderer';
import { ENTITY_HOVERED_COLOR, ENTITY_SELECTED_COLOR } from 'styles/materials/Materials';
import * as TWEEN from '@tweenjs/tween.js';
import { EventEmitter } from 'events';
import * as Animations from './Animations';
import * as Elements from './Elements';
import { AuthRole, ElementType } from 'goodmaps-utils';
import { getDirectionFromPoints } from 'helpers/MathHelper';
import { store } from 'reducers';

export let initialized: boolean = false;
export let snapToUser = true;
export const currentCameraTarget: Vector3 = new Vector3(0, 0, 0);

const MAX_DISTANCE = 500;
const MIN_DISTANCE = 10;

let building: gm.Building | gm.Campus;
let scene: Scene;
let camera: PerspectiveCamera;
let controls: OrbitControls;
let glRenderer: WebGLRenderer;
let raycaster: Raycaster;
let raycastingPlane: Mesh;
let emitter: EventEmitter = new EventEmitter();

let labelScene = new Object3D();
let currentFloor = new Object3D();
let previousFloor = new Object3D();
let buildingPlan = {}; // better to define a type for this
let floorRoutes = new Object3D();
let floorTestPoints = new Object3D();
let elements = {};
let textures = {};
let rooms = [];
let hoveringOverLabel = false;
let highlightedEntity = null;
let highlightedMaterial = null;
let selectedEntity = null;
let selectedMaterial = null;
let renderType: 'building' | 'campus';

let BUILDING_MAX_ZOOM = MAX_DISTANCE;
let BUILDING_DEFAULT_POSITION = new Vector3(250, 0, 20);

// Load in textures
const loader = new TextureLoader();
loader.crossOrigin = '';
textures['stairs'] = loader.load('/stairs-tileable.png');
textures['security'] = loader.load('/security-tileable-bw.png');
textures['stairs-security'] = loader.load('/stairs-security-tileable.png');
textures['connection-security'] = loader.load('/connection-security-tileable.png');

let cleanup = () => {};
export { cleanup };

export type RendererConfig = {
  width: number;
  height: number;
  enableLabels: boolean;
  domElement?: HTMLElement;
  onInit?: (scene: Scene) => void;
  onRender?: (scene: Scene, camera: PerspectiveCamera) => void;
  onMouseOverEntity?: (id: string) => void;
  onMouseOutEntity?: (id: string) => void;
  onEntityClicked?: (id: string) => void;
};

let cfg: RendererConfig = null;

export const getEmitter = () => emitter;

const makeVectorGUI = (gui, vector, folderName, onChange) => {
  const folder = gui.addFolder(folderName);
  folder.add(vector, 'x', -10, 10).onChange(onChange);
  folder.add(vector, 'y', 0, 10).onChange(onChange);
  folder.add(vector, 'z', -10, 10).onChange(onChange);
  folder.open();
};

export const init = (renderer: WebGLRenderer, config: RendererConfig) => {
  clearCanvas();
  cleanup();
  cfg = config;
  scene = new Scene();
  camera = new PerspectiveCamera(50, cfg.width / 2 / (cfg.height / 2), 1, 1000);
  raycaster = new Raycaster();
  camera.position.set(0, 0, 50);
  camera.up.set(0, 0, 1);
  camera.updateProjectionMatrix();

  glRenderer = renderer;
  glRenderer.setSize(cfg.width, cfg.height);
  glRenderer.setClearColor(0xbcbfc2, 1);
  glRenderer.shadowMap.enabled = true;

  {
    const directionalLight = new DirectionalLight(0xffffff, 1);
    directionalLight.position.set(-2, 0, 10);
    directionalLight.target.position.set(-1, 0, 0);
    scene.add(directionalLight);
    scene.add(directionalLight.target);

    // const directionalHelper = new DirectionalLightHelper(directionalLight);
    // scene.add(directionalHelper);

    function updateLight() {
      directionalLight.target.updateMatrixWorld();
      // directionalHelper.update();
    }
    updateLight();

    // const directionalGUI = new dat.GUI();
    // directionalGUI.addColor(new ColorGUIHelper(directionalLight, 'color'), 'value').name('color');
    // directionalGUI.add(directionalLight, 'intensity', 0, 1, 0.01); // object, prop, min, max, iter

    // makeVectorGUI(directionalGUI, directionalLight.position, 'position', updateLight);
    // makeVectorGUI(directionalGUI, directionalLight.target.position, 'target', updateLight);

    const ambientLight = new AmbientLight(0xffffff, 0.83);
    scene.add(ambientLight);

    // const ambientGUI = new dat.GUI();
    // ambientGUI.addColor(new ColorGUIHelper(ambientLight, 'color'), 'value').name('color');
    // ambientGUI.add(ambientLight, 'intensity', 0, 1, 0.01); // object, prop, min, max, iter
  }

  const planeGeometry = new PlaneGeometry(1000, 1000);
  const material = new MeshBasicMaterial({ color: 0xffff00, opacity: 0, transparent: true });
  raycastingPlane = new Mesh(planeGeometry, material);
  raycastingPlane.position.z = 0;
  scene.add(raycastingPlane);

  // Set up LabelRenderer
  LabelRenderer.init(cfg.domElement, scene, labelScene);
  LabelRenderer.getEmitter().addListener('labelMouseIn', (id) => {
    if (store.getState().session.isFormDirty) return;

    hoveringOverLabel = true;
    highlightEntity(id);
  });
  LabelRenderer.getEmitter().addListener('labelMouseOut', (id) => {
    hoveringOverLabel = false;
  });
  LabelRenderer.getEmitter().addListener('labelSelected', (id) => {
    if (store.getState().session.isFormDirty) return;

    // Need this so we don't retrigger selecting the same entity
    if (id !== selectedEntity?.name) config.onEntityClicked(id);
  });
  const onMouseMove = (e) => {
    if (store.getState().session.isFormDirty) return;

    if (
      (e.target as HTMLElement).classList.contains('map-label') ||
      (e.target as HTMLElement).classList.contains('map-icon')
    )
      hoveringOverLabel = true;
    else hoveringOverLabel = false;
  };
  LabelRenderer.getLabelRenderer().domElement.addEventListener('mousemove', onMouseMove);

  // Set up OrbitControls
  controls = new OrbitControls(camera, LabelRenderer.getLabelRenderer().domElement);
  controls.screenSpacePanning = false;
  controls.enableDamping = true;
  controls.dampingFactor = 0.25;
  controls.enableRotate = true;
  controls.enablePan = true;
  controls.minDistance = MIN_DISTANCE;
  controls.maxDistance = MAX_DISTANCE;
  controls.maxPolarAngle = Math.PI / 5;
  controls.target.set(0, 0, 0);
  camera.position.set(0, 0, 50);
  camera.lookAt(0, 0, 0);
  controls.update();
  controls.mouseButtons = {
    RIGHT: MOUSE.ROTATE,
    MIDDLE: MOUSE.DOLLY,
    LEFT: MOUSE.PAN,
  };

  const controlsListener = () => {
    // Keep track of position so we know when we can short circuit this?
    const { target }: { target: Vector3 } = controls;
    const { position }: { position: Vector3 } = camera;

    emitter.emit('onZoom', Math.floor(target.clone().sub(position).length()));
  };
  controls.addEventListener('change', controlsListener);

  cfg.domElement.onclick = onMapClick;
  cfg.domElement.onpointerdown = onMapClickStart;

  // Listen for resize to upadte the map
  const resizeListener = () => {
    const { clientWidth, clientHeight } = LabelRenderer.getLabelRenderer().domElement;
    renderer.setSize(clientWidth, clientHeight);
    camera.aspect = clientWidth / clientHeight;
    camera.updateProjectionMatrix();
    controls.update();
  };
  window.addEventListener('resize', resizeListener);

  // Create the render loop
  let cancelRenderLoop = false;
  const render = () => {
    if (cancelRenderLoop) return;
    TWEEN.update();
    controls.update();
    glRenderer.render(scene, camera);
    requestAnimationFrame(render);
    if (cfg.onRender) {
      cfg.onRender(scene, camera);
    }
    if (cfg.enableLabels) {
      LabelRenderer.render(scene, camera);
    }
  };

  // Remove any listeners, etc.
  cleanup = () => {
    if (glRenderer) glRenderer.dispose();

    cancelRenderLoop = true;
    controls.dispose();
    emitter.removeAllListeners();
    controls.removeEventListener('change', controlsListener);
    window.removeEventListener('resize', resizeListener);
    LabelRenderer.getLabelRenderer().domElement.removeEventListener('mousemove', onMouseMove);
    LabelRenderer.cleanup();
  };

  render();
  initialized = true;
  if (cfg.onInit) cfg.onInit(scene);
};

export const getHoveringOverLabel = () => hoveringOverLabel;

export const getRendererConfig = () => {
  return cfg;
};

export const renderBuildingPlanAsync = (
  floors: any[],
  bm: gm.Building | gm.Campus,
  type: 'building' | 'campus'
): Promise<void> => {
  return new Promise((resolve, reject) => {
    building = bm;
    renderType = type;
    try {
      const vectors = bm.orientation.getNodes().map((n) => {
        const { x, y } = bm.calcProjection(n);
        return new Vector2(x, y);
      });
      const v = vectors[1].sub(vectors[0]).normalize();
      const angle = Math.atan2(v.y, v.x) + Math.PI / 2;

      const buildingDefaultXY = new Vector2(250, 0).rotateAround(
        new Vector2(0, 0),
        angle + Math.PI / 4
      );
      BUILDING_DEFAULT_POSITION.set(buildingDefaultXY.x, buildingDefaultXY.y, 20);
      camera.position.set(
        BUILDING_DEFAULT_POSITION.x,
        BUILDING_DEFAULT_POSITION.y,
        BUILDING_DEFAULT_POSITION.z
      );
    } catch (e) {
      console.log(e);
    }

    for (let level in buildingPlan) {
      scene.remove(buildingPlan[level].levelObject);
    }
    buildingPlan = {};

    floors.forEach((f) => {
      buildingPlan[f.level] = { levelObject: new Object3D(), rooms: [] };
    });

    for (let level in buildingPlan) {
      renderFloor(parseFloat(level), true);
    }

    // Making a cover plane - The purpose of this is to simulate tweening the opacity
    // of the building when it animates into the view of the camera
    const plane = generateCoverPlane(1);
    plane.position.set(0, 0, -10);
    scene.add(plane);

    const planeOpacity = new TWEEN.Tween(plane.material)
      .to({ opacity: 0 })
      .easing(TWEEN.Easing.Quadratic.InOut);

    const planeEndPos = new Vector3(0, 0, 50);
    const planePosition = new TWEEN.Tween(plane.position)
      .to(planeEndPos)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        scene.remove(plane);
      });

    const tweens = Object.entries(buildingPlan).map((entry) => {
      const level = entry[0];
      const position = (entry[1] as any).levelObject.position;
      position.set(0, 0, -270);
      return new TWEEN.Tween(position).to(new Vector3(0, 0, parseFloat(level) * 5), 700);
    });

    planeOpacity
      .onStart(() => {
        planePosition.start();
        tweens.forEach((tween) => tween.start());
      })
      .start()
      .onComplete(() => resolve());
  });
};

let mapXStart, mapYStart;
const onMapClickStart = (e) => {
  mapXStart = e.x;
  mapYStart = e.y;
};

const onMapClick = (e) => {
  let distance = new Vector2(e.x - mapXStart, e.y - mapYStart).length();

  if (
    distance < 5 &&
    cfg.onEntityClicked &&
    highlightedEntity &&
    // Need this so we don't retrigger selecting the same entity
    highlightedEntity?.name !== selectedEntity?.name
  ) {
    cfg.onEntityClicked(highlightedEntity.name);
  }
};

export const selectEntity = (id: string, animationType: 'full' | 'reduced' | 'none' = 'full') => {
  deselectEntity();
  LabelRenderer.updateLabelState(id, { selected: true });
  const entity = elements[id];
  const gmEntity = building.getAllEntities().find((e) => e.id === id);
  if (gmEntity) {
    if (entity) {
      const selectMaterial = new MeshBasicMaterial({ color: ENTITY_SELECTED_COLOR });
      selectedEntity = entity;

      if (highlightedMaterial) {
        selectedMaterial = highlightedMaterial;
      } else {
        selectedMaterial = entity.material;
      }
      selectedEntity.material = selectMaterial;
    }

    if (animationType !== 'none') {
      try {
        flyToAsync(new Vector3(gmEntity.x, gmEntity.y, 70), animationType === 'reduced');
      } catch (e) {
        console.log(e);
      }
    }
  }
};

// This function crashes the app when selecting multiple entities
// from the Editor. deselectEntity is only called in NavigationHeader.tsx
export const deselectEntity = () => {
  if (selectedEntity) {
    LabelRenderer.updateLabelState(selectedEntity.name, { selected: false });
    if (selectedMaterial) selectedEntity.material = selectedMaterial;
    selectedEntity = null;
    selectedMaterial = null;
  }
};

export const getCamera = () => camera;

export const getCoordinatesFromRaycaster = (x: number, y: number): Vector3 => {
  try {
    raycaster.setFromCamera({ x, y }, camera);
    const intersections = raycaster.intersectObjects([raycastingPlane]);
    const roomIntersections = raycaster.intersectObjects(rooms);
    if (
      !hoveringOverLabel &&
      roomIntersections.length &&
      (roomIntersections[0].object as Mesh) !== highlightedEntity
    ) {
      highlightEntity(roomIntersections[0].object.name);
      document.body.style.cursor = 'pointer';
    } else if (!roomIntersections.length && !hoveringOverLabel && highlightedEntity) {
      unhighlightEntity();
      document.body.style.cursor = 'default';
    }
    return intersections[0].point;
  } catch (e) {
    return new Vector3(0, 0, 0);
  }
};

const highlightEntity = (id: string) => {
  if (store.getState().session.isFormDirty) return;
  // Prevents overriding the selected color

  unhighlightEntity();

  LabelRenderer.updateLabelState(id, { highlighted: true });

  const entity = elements[id];
  const selectedEntity = getSelectedEntity();

  if (entity) {
    highlightedEntity = entity;
    if (highlightedEntity.name !== selectedEntity) {
      const highlightMaterial = new MeshBasicMaterial({ color: ENTITY_HOVERED_COLOR });
      highlightedMaterial = entity.material;
      entity.material = highlightMaterial;
    }
    if (cfg.onMouseOverEntity) cfg.onMouseOverEntity(highlightedEntity.name);
  }
};

const unhighlightEntity = () => {
  if (highlightedEntity) {
    if (highlightedEntity !== selectedEntity) {
      highlightedEntity.material = highlightedMaterial;
    }
    if (cfg.onMouseOutEntity) cfg.onMouseOutEntity(highlightedEntity.name);
    LabelRenderer.updateLabelState(highlightedEntity.name, { highlighted: false });
    highlightedEntity = null;
    highlightedMaterial = null;
  }
};

export const getHighlightedEntity = () => {
  return highlightedEntity?.name;
};

export const getSelectedEntity = () => {
  return selectedEntity?.name;
};

export const getCurrentZoomAmount = () => {
  const cameraPos = getCamera().position;
  const zoom = cameraPos.distanceTo(controls.target);

  return zoom;
};

const renderBuildingOutline = (outline: Vector2[], lineWidth = 0.6, z = -0.25) => {
  const outlineObject3D = new Object3D();

  if (outline.length <= 2) {
    return outlineObject3D;
  }

  const material = new MeshBasicMaterial({ color: 0x999999, opacity: 0.5, transparent: true });
  const buildingShape = new Shape(outline);
  const buildingGeometry = new ShapeGeometry(buildingShape);
  const buildingMesh = new Mesh(buildingGeometry, material);
  buildingMesh.position.z = -0.3;

  const lineMesh = Elements.renderElementBorder(outline, lineWidth, 0xffffff, z, true);
  elements['building-floor'] = buildingMesh;
  outlineObject3D.add(buildingMesh);

  elements['building-walls'] = lineMesh;
  outlineObject3D.add(lineMesh);

  return outlineObject3D;
};

const renderBuildingOutlinesForCampus = (level: number) => {
  (building as gm.Campus).getBuildingOutlinesForCampus().forEach((outline) => {
    let outlineVectors = outline.getNodes().map((n) => {
      const { x, y } = building.calcProjection(n);
      return new Vector2(x, y);
    });

    const shape = new Shape(outlineVectors);

    const extrudeSettings = {
      steps: 2,
      depth: 32,
      bevelEnabled: false,
    };

    const geometry = new ExtrudeGeometry(shape, extrudeSettings);
    const material = new MeshStandardMaterial({});
    const mesh = new Mesh(geometry, material);
    mesh.position.z = 0.25;

    const object = Elements.renderArea(
      mesh,
      outline.mappedBuildingId ? outline.mappedBuildingId : '',
      '',
      outlineVectors,
      [],
      new Color(0xdddddd),
      0
    );

    object.container = mesh;
    object.container.name = outline.mappedBuildingId;
    buildingPlan[level].levelObject.add(object.container);
    elements[outline.mappedBuildingId] = object.container;
    rooms.push(object.floor);
  });
};

export const setBuilding = (bm: gm.Building) => {
  building = bm;
};

export const renderFloorLabels = (level: number) => {
  if (!cfg.enableLabels) return;
  LabelRenderer.removeAllLabels();

  building.getElements(level).forEach((room) => {
    if (room.elementType === ElementType.Wall) return;
    const icon = getMapIcon(Entities.elements, gm.ElementType.Room, (room as gm.Room).roomType);
    LabelRenderer.addLabel(
      { x: room.x, y: room.y },
      room.name,
      icon,
      '',
      '',
      room.id,
      gm.BaseType.Element
    );
  });

  if ((building as gm.Building).getConnections !== undefined) {
    (building as gm.Building).getConnections(level).forEach((connection) => {
      if (connection.connectionType) {
        const icon = getMapIcon(Entities.connections, connection.connectionType);
        LabelRenderer.addLabel(
          { x: connection.x, y: connection.y },
          connection.name,
          icon,
          '',
          '',
          connection.id,
          gm.BaseType.Connection
        );
      }
    });
  }

  building.getDoors(level).forEach((door) => {
    const position = building.calcProjection(door);
    LabelRenderer.addLabel(
      new Vector2(position.x, position.y),
      null,
      getMapIcon(Entities.doors),
      null,
      'door-icon',
      door.id,
      gm.BaseType.Door
    );
    // Create an empty object that can be referenced later
    const obj = new Object3D();
    obj.name = door.id;
    elements[door.id] = obj;
  });

  building.getPOIs(level).forEach((poi) => {
    const position = building.calcProjection(poi);
    LabelRenderer.addLabel(
      new Vector2(position.x, position.y),
      poi.name,
      getMapIcon(Entities.pois, poi.poiType),
      '',
      '',
      poi.id,
      gm.BaseType.POI
    );
    // Create an empty object that can be referenced later
    const obj = new Object3D();
    obj.name = poi.id;
    elements[poi.id] = obj;
  });

  if ((building as gm.Building).getBeacons !== undefined) {
    (building as gm.Building).getBeacons(level).forEach((beacon) => {
      const position = building.calcProjection(beacon);
      LabelRenderer.addLabel(
        new Vector2(position.x, position.y),
        beacon.name,
        getMapIcon(Entities.beacons),
        '',
        '',
        beacon.id,
        gm.BaseType.Beacon
      );
      // Create an empty object that can be referenced later
      const obj = new Object3D();
      obj.name = beacon.id;
      elements[beacon.id] = obj;
    });
  }

  currentFloor.add(labelScene);
  LabelRenderer.setupGroups();
};

export const addObject = (id: string) => {
  const obj = new Object3D();
  obj.name = id;
  elements[id] = obj;
};

const getElementZPosition = (element: gm.Element): number => {
  switch (element.elementType) {
    case gm.ElementType.Wall:
    case gm.ElementType.Fixture:
      return 0.02;
    case gm.ElementType.Area:
      return 0.01;
    default:
      return 0;
  }
};

export const renderFloor = (level: number, stacked: boolean = false) => {
  if (!scene) {
    return;
  }
  if (stacked) {
    let details: gm.Level[] = [];

    if ((building as gm.Building).getDetailLevels !== undefined) {
      details = (building as gm.Building).getDetailLevels().filter((d) => d.level === level);
    }

    LabelRenderer.removeAllLabels();

    rooms = [];

    // Create the rooms
    building.getElements(level).forEach((room) => {
      const points = room.getNodes().map((n) => {
        const { x, y } = building.calcProjection(n);
        return new Vector2(x, y);
      });
      let holes: Vector2[][] = room.getInnerWays
        ? room.getInnerWays().map((w) => {
            return w.getNodes().map((n) => {
              const { x, y } = building.calcProjection(n);
              return new Vector2(x, y);
            });
          })
        : [];

      let color = getElementColor(room);
      const { lineColor, lineWidth, dashed } = getLineProps(room);

      const z = getElementZPosition(room);

      const object = Elements.renderArea(
        textures,
        room.id,
        room.authList[0] === AuthRole.Public ? '' : 'security',
        points,
        holes,
        color,
        z,
        room.elementType !== gm.ElementType.Wall,
        lineWidth,
        lineColor.getHex(),
        dashed
      );

      if (room.elementType !== gm.ElementType.Wall) rooms.push(object.floor);
      elements[room.id] = object.floor;

      buildingPlan[level].levelObject.add(object.container);
    });

    // Create connections
    if ((building as gm.Building).getConnections !== undefined) {
      (building as gm.Building).getConnections(level).forEach((connection) => {
        const points = connection.getNodes().map((n) => {
          const { x, y } = building.calcProjection(n);
          return new Vector2(x, y);
        });
        if (connection.connectionType) {
          switch (connection.connectionType) {
            case gm.ConnectionType.Stairs:
              const stairObject = Elements.renderArea(
                textures,
                connection.id,
                connection.authList[0] === AuthRole.Public ? 'stairs' : 'stairs-security',
                points,
                [],
                new Color(0xffedce),
                0
              );
              rooms.push(stairObject.floor);
              elements[connection.id] = stairObject.floor;
              buildingPlan[level].levelObject.add(stairObject.container);
              break;

            case gm.ConnectionType.Elevator:
              const elevatorLines = Elements.renderElevator(points);
              const elevatorObject = Elements.renderArea(
                textures,
                connection.id,
                connection.authList[0] === AuthRole.Public ? '' : 'security',
                points,
                [],
                new Color(0xffedce),
                0
              );
              elements[connection.id] = elevatorObject.floor;
              rooms.push(elevatorObject.floor);
              buildingPlan[level].levelObject.add(elevatorLines);
              buildingPlan[level].levelObject.add(elevatorObject.container);
              break;

            default: {
              const object = Elements.renderArea(
                textures,
                connection.id,
                '',
                points,
                [],
                new Color(0xffedce),
                0
              );
              rooms.push(object.floor);
              elements[connection.id] = object.floor;
              buildingPlan[level].levelObject.add(object.container);
              break;
            }
          }
        }
      });
    }

    if ((building as gm.Campus).getBuildingOutlinesForCampus !== undefined) {
      renderBuildingOutlinesForCampus(level);
    }

    // Create the outline
    details.forEach((d) => {
      const points = d.getNodes().map((n) => {
        const { x, y } = building.calcProjection(n);
        return new Vector2(x, y);
      });
      buildingPlan[level].levelObject.add(renderBuildingOutline(points));
    });

    buildingPlan[level].rooms = rooms;
    rooms = [];
    scene.add(buildingPlan[level].levelObject);
  } else {
    previousFloor = new Object3D();
    previousFloor = currentFloor;
    currentFloor = new Object3D();
    currentFloor = buildingPlan[level].levelObject;

    const { maxLon, maxLat } = building.boundingBox;

    BUILDING_MAX_ZOOM = Math.ceil(
      building.calcProjection({
        lat: maxLat,
        lon: maxLon,
      }).y * 3
    );

    renderFloorLabels(level);
    rooms = buildingPlan[level].rooms;

    scene.add(currentFloor);
    LabelRenderer.setupGroups();
  }
};

export const renderRoutes = (level: number) => {
  floorRoutes && previousFloor.remove(floorRoutes);
  floorRoutes && currentFloor.remove(floorRoutes);

  if (level !== null) {
    const routes = renderType === 'building' ? building.getRoutes(level) : building.getRoutes();
    floorRoutes = new Object3D();
    floorRoutes.name = 'floorRoutes';

    routes.forEach((route) => {
      if (route.levelList === undefined) {
        floorRoutes.add(Elements.renderRoute(route, building));
      }
    });
    currentFloor.add(floorRoutes);
  }
};

export const renderTestPoints = (level: number) => {
  floorTestPoints && previousFloor.remove(floorTestPoints);
  floorTestPoints && currentFloor.remove(floorTestPoints);

  if (level !== null) {
    const testPoints = building.getTestPoints(level);

    floorTestPoints = new Object3D();

    testPoints.forEach((tp) => {
      floorTestPoints.add(Elements.renderTestPoint(tp, building));
    });
    currentFloor.add(floorTestPoints);
  }
};

export const renderUnnamedPois = (shouldRender: boolean) => {
  LabelRenderer.renderUnnamedPois(shouldRender);
};

export const clearCanvas = () => {
  if (!scene) {
    return;
  }

  while (scene.children.length > 0) {
    const item = scene.children[0];
    try {
      if ((item as Mesh).geometry) {
        (item as Mesh).geometry.dispose();
      }
    } catch (e) {}
    try {
      if ((item as Mesh).material) {
        ((item as Mesh).material as Material).dispose();
      }
    } catch (e) {}
    scene.remove(item);
  }

  LabelRenderer.removeAllLabels();
  elements = {};
};

export const flyToAsync = async (position: Vector3, reduceAnimation: boolean) => {
  await Animations.flyToAsync(camera, controls, position, reduceAnimation);
};

export const zoom = (direction: number) => {
  Animations.zoom(controls, direction);
};

const generateCoverPlane = (initialOpacity: number) => {
  return Animations.generateCoverPlane(initialOpacity);
};

export const toggleTopView = (toggle: boolean) => {
  Animations.toggleTopView(controls, toggle);
};

export const flyBuildingPlanInAsync = async (selectedFloor: number) => {
  await Animations.flyBuildingPlanInAsync(
    scene,
    controls,
    BUILDING_DEFAULT_POSITION,
    buildingPlan,
    currentFloor,
    floorRoutes,
    floorTestPoints,
    () => {
      currentFloor = new Object3D();
      rooms = [];
    },
    selectedFloor
  );
};

export const flyBuildingPlanOutAsync = async (selectedFloor: number, onFinished?: () => void) => {
  await Animations.flyBuildingPlanOutAsync(
    scene,
    buildingPlan,
    currentFloor,
    selectedFloor,
    (levelObject, level) => {
      currentFloor = levelObject;
      rooms = buildingPlan[level].rooms;
      renderFloorLabels(selectedFloor);
    },
    () => {}
  );
};

export const flyFloorOutAsync = async (direction: string, level: number) => {
  await Animations.flyFloorOutAsync(scene, controls, currentFloor, previousFloor, direction, level);
};

enum TextureType {
  'stairs' = 0,
  'security',
  'stairs-security',
  'connection-security',
}

export const updateSelectedObjectTexture = (id: string, textureType: string) => {
  const object = scene.getObjectByName(id);

  if (object && object.type === 'Mesh') {
    const geometry = (object as Mesh).geometry as Geometry;

    const direction = getDirectionFromPoints(
      geometry.vertices.map((geo) => new Vector2(geo.x, geo.y))
    );

    // If there is no texture type remove the texture map
    if (textureType === '') {
      selectedMaterial.map = null;
      selectedMaterial.needsUpdate = true;
      return;
    }

    selectedMaterial.map = textures[textureType];
    selectedMaterial.map.center.set(0.5, 0.5);
    selectedMaterial.map.wrapS = RepeatWrapping;
    selectedMaterial.map.wrapT = RepeatWrapping;
    selectedMaterial.map.rotation = direction;
    selectedMaterial.map.repeat.set(2, 2);
    selectedMaterial.map.needsUpdate = true;

    selectedMaterial.needsUpdate = true;
  }
};
