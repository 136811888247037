import React, { useState } from 'react';
import { Button, Modal, Alert, Form } from 'react-bootstrap';
import { base64ToBlob, convertToImdf } from 'helpers/BuildingManagerHelper';
import CircularLoadingIndicator from 'components/dummy/CircularProgressIndicator';

type props = {
  id: string;
  name: string;
  onClose: () => void;
  onSuccess?: () => Promise<void>;
};

export default ({ id, name, onClose, onSuccess }: props) => {
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [strict, setStrict] = useState(false);
  const [apple, setApple] = useState(false);
  const [useOutside, setUseOutside] = useState(false);

  const convert = async () => {
    try {
      setIsLoading(true);
      if (error) setError('');
      if (response) setResponse('');

      const result = await convertToImdf({ id, apple, strict, useOutside });

      const url = window.URL.createObjectURL(base64ToBlob(result.data));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}-imdf.zip`);
      document.body.appendChild(link);
      link.click();

      result.status === 200 && setResponse(`${name} has been converted to IMDF.`);
    } catch (err) {
      console.error(err);
      setError((err as any).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal.Header closeButton={true}>
        <Modal.Title>Convert to IMDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <CircularLoadingIndicator />
        ) : (
          <>
            {error ? <Alert variant="danger">{error}</Alert> : null}
            {response ? <Alert variant="success">{response}</Alert> : null}
            Do you want to convert {name} to IMDF?
          </>
        )}
        <Form.Group>
          <Form.Check
            type="switch"
            id="custom-switch-strict"
            label="Strict"
            checked={strict}
            onChange={(event) => {
              setStrict(!strict);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="switch"
            id="custom-switch-apple"
            label="Apple"
            checked={apple}
            onChange={(event) => {
              setApple(!apple);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="switch"
            id="custom-switch-useoutside"
            label="Use Outside"
            checked={useOutside}
            onChange={(event) => {
              setUseOutside(!useOutside);
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="danger" onClick={convert}>
          Convert
        </Button>
      </Modal.Footer>
    </>
  );
};
