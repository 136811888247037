import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Floor from 'components/floorplan/structures/Floor';
import SubCategory from '../dummy/SubCategory';
import {
  Connections,
  EntityInfo,
  getEntityTypeAsString,
  getRoomColor,
  getEditorIcon,
  getPluralString,
  Entities,
  Elements,
} from 'helpers/goodmaps-helper/GoodMaps';
import { CONNECTOR_COLOR } from 'styles/materials/Materials';
import { ActiveEntity, Link, Room } from 'globals/Types';
import { removeZeroCountLinks } from 'helpers/LinkHelper';
import { setSelectedEntity, selectFloor } from 'actions/ViewerActions';

type URLParams = {
  entityType: string;
  catId: string;
  subCatId: string;
  level: string;
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { entityType, catId, subCatId, level }: URLParams = useParams();
  const { pathname } = useLocation();
  const floors: Floor[] = useSelector((state) => state.session.floors);
  const currentFloor: number = useSelector((state) => state.viewer.currentFloor);
  const levelName = floors.find((floor) => floor.level === +level)?.name || '';
  const floorIndex = floors.findIndex((floor) => floor.level === +level);

  const onLinkClick = (element: ActiveEntity) => {
    console.log('currentFloor', currentFloor);
    if (currentFloor === null) {
      dispatch(selectFloor(element.props.level, history, { id: element.props.id }));
      return;
    }

    dispatch(setSelectedEntity(element.props.id, history));
  };

  const getLinksWithoutZeroCount = () => removeZeroCountLinks(getLinks());

  const getLinks = (): Link[] => {
    // For room sub categories
    if (subCatId)
      return level
        ? floors
            .find((floor) => floor.level === +level)
            .elements[Elements.Room].filter((room) => room.roomType.toString() === subCatId)
            .map((room) => ({
              to: `${pathname}/${room.props.id}`,
              primary:
                (room.props?.name && room.props.name.trim()) ||
                getEntityTypeAsString(+entityType, +catId, +subCatId),
              color:
                getRoomColor(+subCatId).getStyle() === 'rgb(221,221,221)'
                  ? '#fff'
                  : getRoomColor(+subCatId).getStyle(),
              secondary: levelName,
              onClick: () => onLinkClick(room),
            }))
        : floors
            .flatMap((floor) => floor.elements[Elements.Room])
            .filter((room) => room.roomType.toString() === subCatId)
            .map((room) => ({
              to: `${pathname}/${room.props.id}`,
              primary:
                (room.props?.name && room.props.name.trim()) ||
                getEntityTypeAsString(+entityType, +catId, +subCatId),
              color:
                getRoomColor(+subCatId).getStyle() === 'rgb(221,221,221)'
                  ? '#fff'
                  : getRoomColor(+subCatId).getStyle(),
              secondary: room.props.levelName,
              onClick: () => onLinkClick(room),
            }));
    else if (level) {
      switch (+entityType) {
        case Entities.elements:
          if (+catId === Elements.Room) {
            return Object.entries(EntityInfo[+entityType].type[+catId].type).map((entry) => ({
              to: `${pathname}/sub/${entry[0]}`,
              primary: entry[1].name,
              count: floors[floorIndex].elements[+catId].filter(
                (element) => (element as Room).roomType.toString() === entry[0]
              ).length,
              color:
                getRoomColor(+entry[0]).getStyle() === 'rgb(221,221,221)'
                  ? '#fff'
                  : getRoomColor(+entry[0]).getStyle(),
              icon: getEditorIcon(+entityType, +catId, +entry[0]),
            }));
          }

          return floors
            .find((floor) => floor.level === +level)
            .elements[+catId].map((element) => ({
              to: `${pathname}/${element.props.id}`,
              primary:
                (element.props?.name && element.props.name.trim()) ||
                getEntityTypeAsString(+entityType, +catId),
              secondary: levelName,
              icon: getEditorIcon(+entityType, +catId),
            }));

        case Entities.connections:
          return floors
            .find((floor) => floor.level === +level)
            [Connections[+catId]].map((element) => ({
              to: `${pathname}/${element.props.id}`,
              primary:
                (element.props?.name && element.props.name.trim()) ||
                getEntityTypeAsString(+entityType, +catId),
              secondary: levelName,
              color: CONNECTOR_COLOR.getStyle(),
              onClick: () => onLinkClick(element),
            }));
        case Entities.pois:
          return floors
            .find((floor) => floor.level === +level)
            .pois.filter((poi) => poi.poiType === +catId)
            .map((poi) => ({
              to: `${pathname}/${poi.props.id}`,
              primary:
                (poi.props?.name && poi.props.name.trim()) ||
                getEntityTypeAsString(+entityType, +catId),
              secondary: levelName,
              onClick: () => onLinkClick(poi),
            }));
        default:
          return [];
      }
    }

    switch (+entityType) {
      case Entities.elements:
        if (+catId === Elements.Room)
          return Object.entries(EntityInfo[+entityType].type[+catId].type).map((entry) => ({
            to: `${pathname}/sub/${entry[0]}`,
            primary: entry[1].name,
            count: floors
              .flatMap((floor) => floor.elements[+catId])
              .filter((element) => (element as Room).roomType.toString() === entry[0]).length,
            color:
              getRoomColor(+entry[0]).getStyle() === 'rgb(221,221,221)'
                ? '#fff'
                : getRoomColor(+entry[0]).getStyle(),
            icon: getEditorIcon(+entityType, +catId, +entry[0]),
          }));
        return floors
          .flatMap((floor) => floor.elements[+catId])
          .map((element) => ({
            to: `${pathname}/${element.props.id}`,
            primary:
              (element.props?.name && element.props.name.trim()) ||
              getEntityTypeAsString(+entityType, +catId),
            icon: getEditorIcon(+entityType, +catId),
            onClick: () => onLinkClick(element),
            secondary: element.props.levelName,
          }));

      case Entities.connections:
        return floors
          .flatMap((floor) => floor[Connections[+catId]])
          .map((element) => ({
            to: `${pathname}/${element.props.id}`,
            primary:
              (element.props?.name && element.props.name.trim()) ||
              getEntityTypeAsString(+entityType, +catId),
            color: CONNECTOR_COLOR.getStyle(),
            onClick: () => onLinkClick(element),
          }));
      case Entities.pois:
        return floors
          .flatMap((floor) => floor.pois)
          .filter((poi) => poi.poiType === +catId)
          .map((poi) => ({
            to: `${pathname}/${poi.props.id}`,
            primary:
              (poi.props?.name && poi.props.name.trim()) ||
              getEntityTypeAsString(+entityType, +catId),
            onClick: () => onLinkClick(poi),
            secondary: poi.props.levelName,
          }));
      default:
        return [];
    }
  };

  // Issues with pluralizing this title. One poi cat name is Uncategorized. Current code would make this Uncategorizeds
  // Other examples are easy to find, room type waiting -> waitings
  // Avoiding pluralizing subCats for now
  const getTitle = () => {
    if (+entityType === Entities.pois) return getEntityTypeAsString(+entityType, +catId);

    return subCatId
      ? getEntityTypeAsString(+entityType, +catId, +subCatId)
      : getPluralString(getEntityTypeAsString(+entityType, +catId));
  };

  return (
    <SubCategory
      title={getTitle()}
      links={getLinksWithoutZeroCount()}
      icon={getEditorIcon(+entityType, +catId, +subCatId)}
    />
  );
};
