import React from 'react';
import { useParams } from 'react-router-dom';
import Beacon from '../dummy/Beacon';
import { useSelector } from 'react-redux';
import BeaconType from 'components/floorplan/entities/Beacon';
import { ActiveEntity } from 'globals/Types';
import EditorPage from '../smart/EditorPage';
import { Entities, EntityInfo } from 'helpers/goodmaps-helper/GoodMaps';

export default () => {
  const allEntities: ActiveEntity[] = useSelector((state) => state.session.allEntities);
  const { entityId } = useParams();

  const entity: BeaconType = allEntities.find((e) => e.props.id === entityId) as BeaconType;

  return (
    <EditorPage title="Beacons" icon={EntityInfo[Entities.beacons].icon.editor}>
      <Beacon major={entity.major.toString()} minor={entity.minor.toString()} uuid={entity.uuid} />
    </EditorPage>
  );
};
