export default [
  // { dimensions: { width: 100, height: 100 }, distance: 120, level: 0 },
  { dimensions: { width: 60, height: 60 }, distance: 150, level: 0 },
  { dimensions: { width: 45, height: 45 }, distance: 120, level: 1 },
  { dimensions: { width: 30, height: 30 }, distance: 70, level: 2 },
  { dimensions: { width: 15, height: 15 }, distance: 60, level: 3 },
  { dimensions: { width: 10, height: 10 }, distance: 30, level: 4 },
  { dimensions: { width: 1, height: 1 }, distance: 10, level: 5 },
];

export const ZOOM_LEVEL_FOR_DOORS = 20;
