import { ICONS } from 'globals/Icons';
import * as gm from 'goodmaps-sdk';
import { ActiveEntity, Element, Room, POI, Connection, Door } from 'globals/Types';
import * as Material from 'styles/materials/Materials';
import { Color } from 'three';
import { isStringNumeric } from 'helpers/MathHelper';
import { RoomType, POIType, ConnectionType } from 'goodmaps-utils';
import Beacon from 'components/floorplan/entities/Beacon';

// TODO: We need to remove this. It's confusing and serves no purpose
// since we can get these values from the ElementType in utils
export enum Elements {
  'Room' = 1,
  'Area',
  'Wall',
  'Corridor',
  'Fixture' = 10,
}

// TODO: We need to remove this. It's confusing and serves no purpose
// since we can get these values from the ConnectionType in utils
export enum Connections {
  'stairs' = 1,
  'elevators',
  'escalators',
  'other',
}

export const RoomTypeInfo = {
  [RoomType.Undefined]: {
    name: 'Uncategorized',
    icon: { editor: ICONS.editor.public, map: ICONS.map.public },
  },
  [RoomType.Auditorium]: { name: 'Auditorium', icon: { editor: '', map: '' } },
  [RoomType.Bedroom]: {
    name: 'Bedroom',
    icon: { editor: ICONS.editor.bedroom, map: ICONS.map.bedroom },
  },
  [RoomType.Bathroom]: {
    name: 'Bathroom',
    icon: { editor: ICONS.editor.bathroom, map: ICONS.map.bathroom },
  },
  [RoomType.Classroom]: { name: 'Classroom', icon: { editor: '', map: '' } },
  [RoomType.Computer]: { name: 'Computer', icon: { editor: '', map: '' } },
  [RoomType.Conference]: {
    name: 'Conference',
    icon: { editor: ICONS.editor.conference, map: ICONS.map.conference },
  },
  [RoomType.Entrance]: {
    name: 'Entrance',
    icon: { editor: ICONS.editor.entrance, map: ICONS.map.entrance },
  },
  [RoomType.Firstaid]: { name: 'First Aid', icon: { editor: '', map: '' } },
  [RoomType.Fitness]: { name: 'Fitness', icon: { editor: '', map: '' } },
  [RoomType.Food_Service]: {
    name: 'Food Service',
    icon: { editor: ICONS.editor.food_service, map: ICONS.map.food_service },
  },
  [RoomType.Gallery]: { name: 'Gallery', icon: { editor: '', map: '' } },
  [RoomType.Kitchen]: {
    name: 'Kitchen',
    icon: { editor: ICONS.editor.kitchen, map: ICONS.map.kitchen },
  },
  [RoomType.Laboratory]: { name: 'Laboratory', icon: { editor: '', map: '' } },
  [RoomType.Library]: { name: 'Library', icon: { editor: '', map: '' } },
  [RoomType.Lobby]: {
    name: 'Lobby',
    icon: { editor: ICONS.editor.lobby, map: ICONS.map.lobby },
  },
  [RoomType.Lounge]: {
    name: 'Lounge',
    icon: { editor: ICONS.editor.lounge, map: ICONS.map.lounge },
  },
  [RoomType.Office]: { name: 'Office', icon: { editor: '', map: '' } },
  [RoomType.Shop]: { name: 'Shop', icon: { editor: ICONS.editor.shop, map: ICONS.map.shop } },
  [RoomType.Shower]: { name: 'Shower', icon: { editor: '', map: '' } },
  [RoomType.Storage]: { name: 'Storage', icon: { editor: '', map: '' } },
  [RoomType.Recreation]: { name: 'Recreation', icon: { editor: '', map: '' } },
  [RoomType.Restaurant]: {
    name: 'Restaurant',
    icon: { editor: ICONS.editor.food_service, map: ICONS.map.food_service },
  },
  [RoomType.Restroom]: {
    name: 'Restroom',
    icon: { editor: ICONS.editor.restroom, map: ICONS.map.restroom },
  },
  [RoomType.Restroom_Male]: {
    name: 'Restroom Male',
    icon: { editor: ICONS.editor.restroom_male, map: ICONS.map.restroom_male },
  },
  [RoomType.Restroom_Female]: {
    name: 'Restroom Female',
    icon: { editor: ICONS.editor.restroom_female, map: ICONS.map.restroom_female },
  },
  [RoomType.Restroom_Family]: {
    name: 'Restroom Family',
    icon: { editor: ICONS.editor.restroom_family, map: ICONS.map.restroom_family },
  },
  [RoomType.Restroom_Unisex]: {
    name: 'Restroom Unisex',
    icon: { editor: ICONS.editor.restroom, map: ICONS.map.restroom },
  },
  [RoomType.Waiting]: {
    name: 'Waiting',
    icon: { editor: ICONS.editor.waiting_area, map: ICONS.map.waiting_area },
  },
};

export const ConnectionTypeInfo = {
  [ConnectionType.Stairs]: {
    name: 'Stair',
    icon: { editor: ICONS.editor.stair, map: ICONS.map.stair },
  },
  [ConnectionType.Elevator]: {
    name: 'Elevator',
    icon: { editor: ICONS.editor.elevator, map: ICONS.map.elevator },
  },
  [ConnectionType.Escalator]: {
    name: 'Escalator',
    icon: { editor: ICONS.editor.escalator, map: ICONS.map.escalator },
  },
  [ConnectionType.Other]: {
    name: 'Other',
    icon: { editor: ICONS.editor.other, map: ICONS.map.other },
  },
};

export const POITypeInfo = {
  [POIType.Other]: { name: 'Other', icon: { editor: ICONS.editor.other, map: ICONS.map.other } },
  [POIType.Water]: { name: 'Water', icon: { editor: ICONS.editor.water, map: ICONS.map.water } },
  [POIType.Information]: {
    name: 'Information',
    icon: { editor: ICONS.editor.info, map: ICONS.map.info },
  },
  [POIType.Shop]: { name: 'Shop', icon: { editor: ICONS.editor.shop, map: ICONS.map.shop } },
  [POIType.Amenity]: {
    name: 'Amenity',
    icon: { editor: ICONS.editor.shop_amenity, map: ICONS.map.shop_amenity },
  },
  [POIType.Exhibit]: {
    name: 'Exhibit',
    icon: { editor: ICONS.editor.exhibit, map: ICONS.map.exhibit },
  },
};

export enum Entities {
  'elements' = 0,
  'connections' = 1,
  'pois' = 2,
  'doors' = 3,
  'beacons' = 4,
}

export type EntityInfoType = {
  [key: number]: EntityInfoItem;
};

export type EntityInfoItem = {
  name: string;
  type?: EntityInfoType;
  icon?: {
    editor: string;
    map: string;
  };
};

export const EntityInfo: EntityInfoType = {
  0: {
    name: 'Element',
    // The type key should match the respective gm.ElementType
    type: {
      // 1
      [gm.ElementType.Room]: { name: 'Room', type: RoomTypeInfo },
      // 2
      [gm.ElementType.Area]: { name: 'Area' },
      // 3
      // [gm.ElementType.Wall]: { name: 'Wall' },
      // 4
      [gm.ElementType.Corridor]: { name: 'Hallway' },
      // 10
      [gm.ElementType.Fixture]: { name: 'Fixture' },
    },
  },
  1: { name: 'Connection', type: ConnectionTypeInfo },
  2: { name: 'Point of Interest', type: POITypeInfo },
  3: { name: 'Door', icon: { editor: ICONS.editor.door, map: ICONS.map.door } },
  4: { name: 'Beacon', icon: { editor: ICONS.editor.beacons, map: ICONS.map.beacons } },
};

const StringIsNumber = (value: string) => isNaN(Number(value)) === false;

export const EnumToArray = (x) => {
  return Object.keys(x)
    .filter(StringIsNumber)
    .map((key) => x[key]);
};

export const getDefaultEntityIcon = (
  entityType: string,
  catId: string = null,
  subCatId: string = null
) => {
  return (
    getEditorIcon(
      isStringNumeric(entityType) ? +entityType : null,
      isStringNumeric(catId) ? +catId : null,
      isStringNumeric(subCatId) ? +subCatId : null
    ) || ''
  );
};

/**
 * Very simplistic function to quickly make singular strings plural
 * Won't work for things that need more than an 's' added on the end
 *  of the first word
 *
 * More thorough solutions can be found here
 * https://stackoverflow.com/questions/27194359/javascript-pluralize-an-english-string
 *
 * @param entityType
 * @returns entityName
 */
export const getPluralString = (s: string): string => {
  return s.split(' ').length > 1 ? s.split(' ')[0] + 's' + s.slice(s.indexOf(' ')) : s + 's';
};

export const getEntityTypeAsString = (
  entityType: number,
  catId?: number,
  subCatId?: number
): string => {
  try {
    // console.log(entityType, catId, subCatId);
    if (subCatId != null && !isNaN(subCatId))
      return EntityInfo[entityType].type[catId].type[subCatId].name || '';
    if (catId != null && !isNaN(catId)) return EntityInfo[entityType].type[catId].name || '';
    return EntityInfo[entityType].name || '';
  } catch (e) {
    console.log('entityType: ', entityType);
    console.log('catId: ', catId);
    console.log(e);
  }
};

export const getEditorIcon = (
  entityType: number,
  catId: number = null,
  subCatId: number = null
): string | undefined => {
  // console.log(entityType, catId, subCatId);
  if (subCatId != null && !isNaN(subCatId))
    return EntityInfo[entityType].type[catId].type[subCatId]?.icon
      ? EntityInfo[entityType].type[catId].type[subCatId]?.icon.editor
      : '';
  if (catId != null && !isNaN(catId))
    return EntityInfo[entityType].type[catId]?.icon
      ? EntityInfo[entityType].type[catId]?.icon.editor
      : '';
  return EntityInfo[entityType]?.icon ? EntityInfo[entityType]?.icon.editor : '';
};

export const getMapIcon = (
  entityType: number,
  catId?: number,
  subCatId?: number
): string | undefined => {
  let icon;

  if (subCatId != null && !isNaN(subCatId))
    icon = EntityInfo[entityType].type[catId].type[subCatId]?.icon
      ? EntityInfo[entityType].type[catId].type[subCatId]?.icon.map
      : '';
  else if (catId != null && !isNaN(catId))
    icon = EntityInfo[entityType].type[catId]?.icon
      ? EntityInfo[entityType].type[catId]?.icon.map
      : '';
  else icon = EntityInfo[entityType]?.icon ? EntityInfo[entityType]?.icon.map : '';

  return icon || ICONS.map.other;
};

export const getEntityMapIcon = (entityType: number) => (catId: number = null) => (
  subCatId: number = null
): string => {
  let icon;

  if (subCatId != null && !isNaN(subCatId))
    icon = EntityInfo[entityType].type[catId].type[subCatId]?.icon
      ? EntityInfo[entityType].type[catId].type[subCatId]?.icon.map
      : '';
  else if (catId != null && !isNaN(catId))
    icon = EntityInfo[entityType].type[catId]?.icon
      ? EntityInfo[entityType].type[catId]?.icon.map
      : '';
  else icon = EntityInfo[entityType]?.icon ? EntityInfo[entityType]?.icon.map : '';

  return icon || ICONS.map.other;
};

export const getEntityPath = (entity: ActiveEntity): string => {
  let path = `${entity.entityType}`;
  let catId: number;

  switch (entity.entityType) {
    case Entities.beacons:
    case Entities.doors:
      return (path += `/${entity.props.id}`);
    case Entities.elements:
      catId = (entity as Element).elementType;

      path += `/category/${catId}`;

      if (isStringNumeric((entity as Room).roomType?.toString())) {
        return (path += `/sub/${(entity as Room).roomType}/${entity.props.id}`);
      }

      return (path += `/${entity.props.id}`);
    case Entities.connections:
      catId = (entity as Connection).connectionType;
      return (path += `/category/${catId}/${entity.props.id}`);
    case Entities.pois:
      catId = (entity as POI).poiType;

      return (path += `/category/${catId}/${entity.props.id}`);
    default:
      return '';
  }
};

export const getElementMapIcon = getEntityMapIcon(0);
export const getConnectionsMapIcon = getEntityMapIcon(1);
export const getPOIMapIcon = getEntityMapIcon(2);
export const getRoomMapIcon = getEntityMapIcon(0)(1);

export const getRoomColor = (roomType: gm.RoomType): Color => {
  switch (roomType) {
    case RoomType.Shop:
      return Material.SHOP_COLOR;
    case RoomType.Food_Service:
    case RoomType.Restaurant:
      return Material.RESTAURANT_COLOR;
    case RoomType.Kitchen:
      return Material.KITCHEN_COLOR;
    case RoomType.Conference:
      return Material.CONFERENCE_COLOR;
    case RoomType.Waiting:
    case RoomType.Entrance:
    case RoomType.Lounge:
    case RoomType.Lobby:
      return Material.LOBBY_COLOR;
    case RoomType.Bathroom:
    case RoomType.Restroom:
    case RoomType.Restroom_Family:
    case RoomType.Restroom_Female:
    case RoomType.Restroom_Male:
    case RoomType.Restroom_Unisex:
      return Material.RESTROOM_COLOR;
    case RoomType.Bedroom:
      return Material.BEDROOM_COLOR;
    default:
      return Material.DEFAULT_COLOR;
  }
};

const getFixtureColor = (fixtureType: gm.FixtureType): Color => {
  switch (fixtureType) {
    case gm.FixtureType.PublicTransportShelter:
      return Material.BUS_STOP_COLOR;
    default:
      return Material.FIXTURE_COLOR;
  }
};

const getFixtureLineColor = (fixtureType: gm.FixtureType): Color => {
  switch (fixtureType) {
    case gm.FixtureType.PublicTransportShelter:
      return Material.BUS_STOP_LINE_COLOR;
    default:
      return new Color(Material.DEFAULT_LINE_COLOR);
  }
};

const getFixtureLineWidth = (fixtureType: gm.FixtureType): number => {
  switch (fixtureType) {
    case gm.FixtureType.PublicTransportShelter:
      return Material.BUS_STOP_LINE_WIDTH;
    default:
      return Material.FIXTURE_LINE_WIDTH;
  }
};

export const getElementColor = (element: gm.Element): Color => {
  switch (element.elementType) {
    case gm.ElementType.Corridor:
      return Material.CORRIDOR_COLOR;
    case gm.ElementType.Room:
      return getRoomColor((element as gm.Room).roomType);
    case gm.ElementType.Sidewalk:
      return Material.SIDEWALK_COLOR;
    case gm.ElementType.Parking:
      return Material.PARKING_COLOR;
    case gm.ElementType.GreenSpace:
      return Material.GREENSPACE_COLOR;
    case gm.ElementType.Crosswalk:
      return Material.CROSS_WALK_COLOR;
    case gm.ElementType.Area:
      return Material.AREA_COLOR;
    case gm.ElementType.Fixture:
      return getFixtureColor((element as gm.Fixture).fixtureType);
    case gm.ElementType.Road:
      return Material.ROAD_COLOR;
    default:
      return Material.DEFAULT_COLOR;
  }
};

export const getLineProps = (
  element: gm.Element
): { lineColor: Color; lineWidth: number; dashed: boolean } => {
  switch (element.elementType) {
    case gm.ElementType.Sidewalk:
      return {
        lineWidth: Material.SIDEWALK_LINE_WIDTH,
        lineColor: Material.SIDEWALK_LINE_COLOR,
        dashed: false,
      };
    case gm.ElementType.Parking:
      return {
        lineWidth: Material.PARKING_LINE_WIDTH,
        lineColor: Material.PARKING_LINE_COLOR,
        dashed: false,
      };
    case gm.ElementType.Crosswalk:
      return {
        lineWidth: Material.CROSS_WALK_LINE_WIDTH,
        lineColor: Material.CROSS_WALK_LINE_COLOR,
        dashed: false,
      };
    case gm.ElementType.Area:
      return {
        lineWidth: Material.AREA_LINE_WIDTH,
        lineColor: Material.AREA_LINE_COLOR,
        dashed: true,
      };
    case gm.ElementType.Fixture:
      const fixtureType = (element as gm.Fixture).fixtureType;
      return {
        lineWidth: getFixtureLineWidth(fixtureType),
        lineColor: getFixtureLineColor(fixtureType),
        dashed: false,
      };
    default:
      return {
        lineWidth: Material.DEFAULT_LINE_WIDTH,
        lineColor: Material.DEFAULT_LINE_COLOR,
        dashed: false,
      };
  }
};

export const hasLineProps = (element: gm.Element): Boolean => {
  switch (element.elementType) {
    case gm.ElementType.Sidewalk:
    case gm.ElementType.Parking:
    case gm.ElementType.Crosswalk:
    case gm.ElementType.Fixture:
      return true;
    default:
      return false;
  }
};

// Type Guards
export const isElement = (entity: ActiveEntity): entity is Element => {
  return (entity as Element).elementType !== undefined;
};

export const isRoom = (entity: ActiveEntity): entity is Room => {
  return (entity as Room).roomType !== undefined;
};

export const isPOI = (entity: ActiveEntity): entity is POI => {
  return (entity as POI).poiType !== undefined;
};

export const isConnection = (entity: ActiveEntity): entity is Connection => {
  return (entity as Connection).connectionType !== undefined;
};

export const isDoor = (entity: ActiveEntity): entity is Door => {
  return (entity as Door).entrance !== undefined;
};

export const isBeacon = (entity: ActiveEntity): entity is Beacon => {
  return (entity as Beacon).uuid !== undefined;
};

//EntityType is the first category
// Elements    -> Rooms   -> RoomType -> Entity
//             -> Areas   -> Entity
// Connections -> Stairs  -> Entity
// POI         -> POIType -> Entity
// Beacon      -> Entity
// Door        -> Entity

// export { getGoodMapsHelper, setGoodMapsHelper };
