import Floor from 'components/floorplan/structures/Floor';
import { renderBuildingPlanAsync, renderFloor } from 'renderer/Renderer';
import {
  GET_ALL_BUILDINGS,
  SET_CURRENT_BUILDING,
  SHOW_BEACONS,
  SHOW_POIS,
  SHOW_ROOMS,
  SHOW_DOORS,
  SHOW_ROUTES,
  SHOW_TESTPOINTS,
  SHOW_ALL_POIS,
  SHOW_GRID,
  RESET_VIEWER_STATE,
  USER_LOADED,
  CLEAR_BUILDING_SELECTION,
  GET_ALL_CAMPUSES,
  SET_IS_FORM_DIRTY,
} from './types';
import { selectFloor } from './ViewerActions';
import { User } from 'goodmaps-sdk';
import { getBuildingManager } from 'helpers/goodmaps-helper/BuildingManager';
import { getBuildingFloors, getCampusData } from 'helpers/goodmaps-helper/GoodMapsHelper';

export const clearBuildingSelection = () => {
  return { type: CLEAR_BUILDING_SELECTION };
};

export const setCurrentUser = (user: User) => {
  return { type: USER_LOADED, payload: user };
};

export const setListOfBuildings = () => {
  return async (dispatch) => {
    const buildingManager = getBuildingManager();
    const { buildings } = await buildingManager.getBuildings();
    await dispatch({
      type: GET_ALL_BUILDINGS,
      payload: buildings.sort((a, b) => a.name.localeCompare(b.name)),
    });
  };
};

export const setIsFormDirty = (isDirty: boolean) => {
  return { type: SET_IS_FORM_DIRTY, payload: isDirty };
};

export const setListOfCampuses = () => {
  return async (dispatch) => {
    const buildingManager = getBuildingManager();
    const { campuses } = await buildingManager.getCampuses();
    await dispatch({
      type: GET_ALL_CAMPUSES,
      payload: campuses.sort((a, b) => a.name.localeCompare(b.name)),
    });
  };
};

export const fetchBuilding = (
  id: string,
  params: { level: string; entityId: string; history: any } = null
) => {
  return async (dispatch) => {
    await dispatch({ type: RESET_VIEWER_STATE, payload: id });

    const buildingManager = getBuildingManager();
    const { buildings } = await buildingManager.getBuildings();
    const buildingMetaData = buildings.find((building) => building.id === id);
    const building = await buildingManager.createBuilding(buildingMetaData);

    const floors = getBuildingFloors(building);

    await dispatch({
      type: SET_CURRENT_BUILDING,
      payload: {
        floors,
        building,
        level: +params?.level,
        currentFloor: +params?.level,
        entityId: params?.entityId,
        buildingName: building.name,
        buildingUUID: building.uuid,
      },
    });

    await renderBuildingPlanAsync(floors, building, 'building');

    if (params?.entityId) {
      const allEntities = floors
        .map((floor: Floor) => [
          ...Object.values(floor.elements).flat(),
          ...floor.pois,
          ...floor.beacons,
          ...floor.doors,
          ...floor.stairs,
          ...floor.elevators,
          ...floor.escalators,
        ])
        .flat();

      return await dispatch(
        selectFloor(
          allEntities.find((entity) => entity.props.id === params.entityId).props.level,
          params.history,
          {
            id: params.entityId,
          }
        )
      );
    }

    if (params?.level) return await dispatch(selectFloor(+params.level, params.history));
  };
};

export const fetchCampus = (
  id: string,
  params: { level: string; entityId: string; history: any } = null
) => {
  return async (dispatch) => {
    await dispatch({ type: RESET_VIEWER_STATE, payload: id });

    const buildingManager = getBuildingManager();
    const { campuses } = await buildingManager.getCampuses();
    const campusMetaData = campuses.find((campus) => campus.id === id);
    const campus = await buildingManager.createCampus(campusMetaData);

    const floor = getCampusData(campus);

    await dispatch({
      type: SET_CURRENT_BUILDING,
      payload: {
        floors: [floor],
        building: campus,
        level: +params?.level,
        currentFloor: +params?.level,
        entityId: params?.entityId,
        buildingName: campus.name,
        buildingUUID: campus.uuid,
      },
    });

    await renderBuildingPlanAsync([floor], campus, 'campus');
    dispatch(selectFloor(0, params.history));
    await renderFloor(0);
  };
};

/**
 * Filter actions */

export const handleBeaconsVisibility = (isVisible: boolean) => {
  return (dispatch) => dispatch({ type: SHOW_BEACONS, payload: isVisible });
};

export const handlePoisVisibility = (isVisible: boolean) => {
  return (dispatch) => dispatch({ type: SHOW_POIS, payload: isVisible });
};

export const handleRoomsVisibility = (isVisible: boolean) => {
  return (dispatch) => dispatch({ type: SHOW_ROOMS, payload: isVisible });
};

export const handleDoorsVisibility = (isVisible: boolean) => {
  return (dispatch) => dispatch({ type: SHOW_DOORS, payload: isVisible });
};

export const handleTestPointsVisibility = () => {
  return (dispatch) => dispatch({ type: SHOW_TESTPOINTS });
};

export const handleRoutesVisibility = () => {
  return (dispatch) => dispatch({ type: SHOW_ROUTES });
};

export const handleUnnamedPoisVisibility = () => {
  return (dispatch) => dispatch({ type: SHOW_ALL_POIS });
};

export const handleGridVisibility = () => {
  return (dispatch) => dispatch({ type: SHOW_GRID });
};
