import React from 'react';
import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { SignIn } from 'aws-amplify-react';
import './SignIn.scss';
import ScrollingIcons from './ScrollingIcons';
import StudioLogo from 'components/StudioLogo';
import { sanitize } from 'goodmaps-utils';

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
    Hub.listen('auth', (res) => {
      this.handleIncorrectInput(res.payload);
    });
  }

  handleIncorrectInput(payload) {
    const errorMsg = payload.data?.message || null;
    if (errorMsg) console.log(errorMsg); // seeing this as well in console "Refresh Token has expired"
    this.setState((prevState) => ({
      ...prevState,
      errMsg: errorMsg,
      message: errorMsg,
    }));
  }

  private formRef = React.createRef<HTMLFormElement>();
  emailRef = React.createRef<HTMLInputElement>();
  passwordRef = React.createRef<HTMLInputElement>();

  showComponent() {
    const handleLogin = async (e) => {
      this.setState((prevState) => ({
        ...prevState,
        message: '',
        errMsg: '',
      }));

      if (this.emailRef.current?.value !== '' && this.passwordRef.current?.value !== '') {
        try {
          e.preventDefault();
          const user = await Auth.signIn(
            sanitize(this.getUsernameFromInput()),
            this.passwordRef.current.value
          );

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            super.changeState('requireNewPassword', user);
            return;
          }

          super.changeState('signedIn');
        } catch (error) {
          console.log(`error signing in: ${(error as any)?.message}`);
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          message: 'Email and password cannot be empty.',
          errMsg: true,
        }));
        e.preventDefault();
      }
    };

    const showErrorMessage = () => {
      // @ts-ignore
      if (!this.state?.errMsg || this.state?.errMsg === undefined) {
        return null;
      }
      // @ts-ignore
      return <div className="error-label">{this.state?.message || null}</div>;
    };

    return (
      <div className="login-container">
        <div className="login-body">
          <div className="login-title">
            <StudioLogo fontSize={72} color="white" />
          </div>

          <form name="login-form" ref={this.formRef}>
            <ScrollingIcons />

            <div className="form">
              <div className="form-header">
                <div className="header-label">Sign In</div>
              </div>
              <div className="form-body">
                <div className="form-formfield flex">
                  <div className="input-label">Email</div>
                  <input
                    ref={this.emailRef}
                    className="email-input"
                    id="email"
                    key="email"
                    name="email"
                    onChange={this.handleInputChange}
                    type="text"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="form-formfield flex">
                  <div className="input-label">Password</div>
                  <input
                    ref={this.passwordRef}
                    placeholder="Enter your password"
                    type="password"
                    name="password"
                    id="password"
                    className="password-input"
                    onChange={this.handleInputChange}
                  />
                  <div className="error-label-box flex">{showErrorMessage()}</div>
                </div>
              </div>
              <div className="sign-in-footer-section flex">
                <span className="footer-content"></span>
                <button type="submit" className="sign-in-button" onClick={(e) => handleLogin(e)}>
                  {this.state.loading ? 'Signing in...' : 'Sign in'}
                </button>
                <span
                  className="reset-link"
                  data-test="sign-in-forgot-password-link"
                  onClick={() => super.changeState('forgotPassword')}
                >
                  Forgot password?
                </span>
              </div>
            </div>
          </form>
        </div>
        <div className="footer-text">
          <p>
            Proudly built by{' '}
            <a target="blank" href="https://www.goodmaps.com/">
              GoodMaps
            </a>{' '}
            in Louisville, Kentucky
          </p>
        </div>
      </div>
    );
  }
}

export default CustomSignIn;
