import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import './Dropdown.scss';

export type DropdownProps = {
  children?: React.ReactNode;
  onSelect: (value: string | number) => void;
  disabled?: boolean;
  title?: React.ReactNode;
  className?: any;
};

export default ({
  children = null,
  onSelect,
  disabled = false,
  title = null,
  className = '',
}: DropdownProps) => {
  return (
    <DropdownButton
      className={`studio-dropdown ${className}`}
      title={title}
      disabled={disabled}
      onSelect={onSelect}
    >
      {children}
    </DropdownButton>
  );
};
