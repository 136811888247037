import React from 'react';
import './SignIn.scss';

const ResetConfirmationScreen = ({ changeState }) => {
  return (
    <div className="form">
      <div className="form-header">
        <div className="header-label">Password Reset</div>
        <div className="subheader-label">You have successfully reset your password.</div>
      </div>
      <div className="sign-in-footer-section flex">
        <span className="footer-content">
          <button type="submit" className="sign-in-button" onClick={changeState}>
            GO TO SIGN IN
          </button>
        </span>
      </div>
    </div>
  );
};

export default ResetConfirmationScreen;
