import React, { createContext, useContext, FC, useEffect, useRef, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';

interface IAuthErrors {
  handle409Error: () => void;
}

const AuthErrorContext = createContext({} as IAuthErrors);

const AuthErrorProvider: FC = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalBodyText, setModalBodyText] = useState(
    'Due to inactivity we are going to log you out.'
  );
  let timer = 1000 * 60 * 15;

  const signoutTimeout = useRef(null);

  const onTimeout = async () => {
    try {
      setModalVisible(true);
      setModalBodyText('Due to inactivity we are going to log you out.');
      await Auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startTimer = async () => {
    try {
      let user = await Auth.currentSession();
      if (!user) return;
      if (signoutTimeout.current) clearTimeout(signoutTimeout.current);
      signoutTimeout.current = setTimeout(() => {
        onTimeout();
      }, timer);
    } catch (error) {}
  };

  useEffect(() => {
    const checkLastUserSignin = async () => {
      let user = await Auth.currentSession();
      const windowClosedAtTime: number = +localStorage.getItem('lastUnloadTime');
      if (Date.now() - windowClosedAtTime > timer && user) onTimeout();
    };

    startTimer();
    checkLastUserSignin();

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 409) {
          handle409Error();
        }
        return error;
      }
    );

    window.addEventListener('mousemove', startTimer);
    window.addEventListener('beforeunload', (event) => {
      localStorage.setItem('lastUnloadTime', `${Date.now()}`);
    });

    return () => {
      window.removeEventListener('mousemove', startTimer);
    };
    //eslint-disable-next-line
  }, []);

  const handle409Error = async () => {
    setModalBodyText(
      'This account is signed in somewhere else, for security we have signed you out.'
    );
    setModalVisible(true);
    await Auth.signOut();
  };

  return (
    <AuthErrorContext.Provider value={{ handle409Error }}>
      <>
        <Modal centered={true} show={modalVisible}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBodyText}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setModalVisible(false)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        {children}
      </>
    </AuthErrorContext.Provider>
  );
};

export default AuthErrorProvider;
export const useUserActivity = () => useContext(AuthErrorContext);
