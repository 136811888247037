import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { handleBeaconsVisibility } from '../../actions/SessionActions';
import Floor from 'components/floorplan/structures/Floor';
import Category from '../dummy/Category';
import {
  Connections,
  POITypeInfo,
  Entities,
  getEntityTypeAsString,
  EntityInfo,
  getPluralString,
  getEditorIcon,
} from 'helpers/goodmaps-helper/GoodMaps';
import { ActiveEntity, Link } from 'globals/Types';
import { removeZeroCountLinks } from 'helpers/LinkHelper';
import { setSelectedEntity, selectFloor } from 'actions/ViewerActions';

type URLParams = {
  entityType: string;
  catId: string;
  level: string;
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { entityType, level }: URLParams = useParams();
  const { pathname } = useLocation();
  const showBeacons: boolean = useSelector((state) => state.viewer.showBeacons);
  const currentFloor: number = useSelector((state) => state.viewer.currentFloor);
  const floors: Floor[] = useSelector((state) => state.session.floors);

  const floorIndex = floors.findIndex((floor) => floor.level === +level);

  const onLinkClick = (element: ActiveEntity) => {
    if (currentFloor === null) {
      dispatch(selectFloor(element.props.level, history, { id: element.props.id }));
      return;
    }

    // dispatch(selectFloor(element.props.level));
    dispatch(setSelectedEntity(element.props.id, history));
  };

  const addReducer = (acc: number, cur: number): number => acc + cur;
  const getCountForElementsCat = (catId: number) => {
    return floors.map((floor) => floor.elements[catId].length).reduce(addReducer);
  };

  const getCountForElementsCatLevel = (catId: number, level: number) => {
    return floors[level].elements[catId].length;
  };

  const getCountForConnectionsCat = (catId: number) => {
    return floors.map((floor) => floor[Connections[catId].toLowerCase()].length).reduce(addReducer);
  };

  const getCountForPOICat = (catId: number) => {
    return floors.flatMap((floor) => floor.pois).filter((poi) => poi.poiType === catId).length;
  };

  const getLinksWithoutZeroCount = () => removeZeroCountLinks(getLinks());

  const getLinks = (): Link[] => {
    if (level) {
      switch (+entityType) {
        case Entities.elements:
          return Object.entries(EntityInfo[0].type).map((entry) => ({
            to: `${pathname}/category/${entry[0]}`,
            primary: getPluralString(entry[1].name),
            count: getCountForElementsCatLevel(+entry[0], floorIndex),
          }));
        case Entities.connections:
          return Object.entries(EntityInfo[1].type).map((entry) => ({
            to: `${pathname}/category/${entry[0]}`,
            primary: getPluralString(entry[1].name),
            count: floors[floorIndex].elements[+entry[0]].length,
            color: '#FFEDCE',
            icon: getEditorIcon(+entityType, +entry[0]),
          }));
        case Entities.pois:
          return Object.entries(POITypeInfo).map((entry) => ({
            to: `${pathname}/category/${entry[0]}`,
            primary: entry[1].name,
            count: floors[floorIndex].pois.filter((x) => x.poiType === +entry[0]).length,
            icon: getEditorIcon(+entityType, +entry[0]),
          }));
        case Entities.doors:
          return floors
            .find((floor) => floor.level === +level)
            .doors.map((door) => ({
              to: `${pathname}/${door.props.id}`,
              primary: `${
                (door.props?.name && door.props.name.trim()) || getEntityTypeAsString(3)
              }`,
              onClick: () => onLinkClick(door),
              secondary: door.props.levelName,
            }));
        case Entities.beacons:
          return floors
            .find((floor) => floor.level === +level)
            .beacons.map((beacon) => ({
              to: `${pathname}/${beacon.props.id}`,
              primary: `${
                (beacon.props?.name && beacon.props.name.trim()) || getEntityTypeAsString(4)
              }`,
              onClick: () => onLinkClick(beacon),
              secondary: beacon.props.levelName,
            }));
        default:
          return [];
      }
    }

    switch (+entityType) {
      case 0:
        return Object.entries(EntityInfo[0].type).map((entry) => ({
          to: `${pathname}/category/${entry[0]}`,
          primary: getPluralString(entry[1].name),
          count: getCountForElementsCat(+entry[0]),
          icon: getEditorIcon(+entityType, +entry[0]),
        }));
      case 1:
        return Object.entries(EntityInfo[1].type).map((entry) => ({
          to: `${pathname}/category/${entry[0]}`,
          primary: getPluralString(entry[1].name),
          count: getCountForConnectionsCat(+entry[0]),
          color: '#FFEDCE',
          icon: getEditorIcon(+entityType, +entry[0]),
        }));
      case 2:
        return Object.entries(EntityInfo[2].type).map((entry) => ({
          to: `${pathname}/category/${entry[0]}`,
          primary: entry[1].name,
          count: getCountForPOICat(+entry[0]),
          icon: getEditorIcon(+entityType, +entry[0]),
        }));
      case 3:
        return floors
          .flatMap((floor) => floor.doors)
          .map((door) => ({
            to: `${pathname}/${door.props.id}`,
            primary: (door.props?.name && door.props.name.trim()) || getEntityTypeAsString(3),
            secondary: door.props.levelName,
            onClick: () => onLinkClick(door),
          }));
      case 4:
        return floors
          .flatMap((floor) => floor.beacons)
          .map((beacon) => ({
            to: `${pathname}/${beacon.props.id}`,
            primary: (beacon.props?.name && beacon.props.name.trim()) || getEntityTypeAsString(4),
            secondary: beacon.props.levelName,
            onClick: () => onLinkClick(beacon),
          }));
      default:
        return [];
    }
  };

  const isBeacon = () => +entityType === Entities.beacons;

  const onClick = () => {
    dispatch(handleBeaconsVisibility(!showBeacons));
  };

  return (
    <Category
      title={getPluralString(getEntityTypeAsString(+entityType))}
      icon={getEditorIcon(+entityType)}
      showBeacons={showBeacons}
      links={getLinksWithoutZeroCount()}
      isBeacon={isBeacon}
      onClick={onClick}
    />
  );
};
