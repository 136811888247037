import React from 'react';
import Header from 'components/map-editor/Header';
import SavePrompt from 'components/map-editor/SavePrompt';
import { ICONS } from '../../globals/Icons';
import './EditorPage.scss';
import { SaveStateType } from 'globals/Types';
import { Form } from 'react-bootstrap';

type Props = {
  title: string;
  children: any;
  icon?: string;
  confirmSave?: boolean;
  onConfirmSave?: () => any;
  onCancelClick?: () => void;
  saveState?: SaveStateType;
  saveStateErrorMessage?: string;
  onClick: () => void;
  showAddPOI?: boolean;
  showSearch?: boolean;
  search?: string;
  setSearch?: (search: string) => void;
};

const EditEntityPanel = ({
  title,
  children,
  onConfirmSave = null,
  onCancelClick,
  saveState,
  icon,
  onClick,
  showAddPOI = false,
  saveStateErrorMessage,
  showSearch = false,
  search,
  setSearch,
}: Props) => {
  const addIcon = <img src={ICONS.editor.add_poi} alt="add-poi" />;
  return (
    <div className="editor-panel flex flex-column overflow-hidden">
      <Header title={title || ''} icon={icon} />
      <div className="editor-panel-content  overflow-auto">
        {showSearch ? (
          <div>
            <Form.Control
              className="entityInputField search-input"
              placeholder="Search"
              as="input"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </div>
        ) : null}
        {showAddPOI ? (
          <div className="add-poi-tooltip-box" onClick={onClick}>
            <img src={ICONS.editor.selected} alt="selected" className="add-poi-icon" />
            <div>
              <p className="add-poi-text">
                <strong>Right click</strong> anywhere on the map to {addIcon} Add POI.{' '}
                <strong>Right click</strong> on an existing POI on map to Move, Delete or {addIcon}{' '}
                Add POI.
              </p>
            </div>
          </div>
        ) : null}
        {children}
      </div>

      <SavePrompt
        onButtonClick={onConfirmSave}
        saveState={saveState}
        onCancelClick={onCancelClick}
        errorMessage={saveStateErrorMessage}
      />
    </div>
  );
};

export default EditEntityPanel;
