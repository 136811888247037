import * as THREE from 'three';
import Beacon from '../entities/Beacon';
import TestPoint from '../entities/TestPoint';
import Route from '../entities/Route';
import { Elements, POI, Door, Connection } from 'globals/Types';

export default class Floor {
  level: number;
  name: string;
  outline: THREE.Vector2[][];
  orientation: number = 0;
  pois: POI[];
  doors: Door[];
  beacons: Beacon[];
  stairs: Connection[];
  elevators: Connection[];
  escalators: Connection[];
  other: Connection[];
  elements: Elements;
  routes: Route[];
  testPoints: TestPoint[];

  constructor(
    level: number,
    name: string,
    outline: THREE.Vector2[][],
    orientation: number,
    pois: POI[],
    doors: Door[],
    beacons: Beacon[],
    stair: Connection[],
    elevators: Connection[],
    escalators: Connection[],
    other: Connection[],
    routes: Route[],
    testPoints: TestPoint[],
    elements: Elements
  ) {
    this.level = level;
    this.name = name;
    this.outline = outline;
    this.orientation = orientation;
    this.pois = pois;
    this.doors = doors;
    this.beacons = beacons;
    this.stairs = stair;
    this.elevators = elevators;
    this.escalators = escalators;
    this.other = other;
    this.routes = routes;
    this.testPoints = testPoints;
    this.elements = elements;
  }
}
