import Auth from '@aws-amplify/auth';
import axios, { AxiosResponse } from 'axios';
import config from 'globals/config/config';

const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    throw new Error(`${error.response.status} - ${error.response.data.message}`);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    throw new Error('Bad request');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    throw new Error(error.message);
  }
};

export const getUsers = async (token = '') => {
  let endpoint = `${config.API_URL}/users`;
  if (token) endpoint += '?token=' + encodeURIComponent(token);
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.get(endpoint, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const getContainers = async () => {
  let endpoint = `${config.API_URL}/containers`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.get(endpoint, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const getGroups = async (limit?: any) => {
  const endpoint = `${config.API_URL}/groups`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response = await axios.get(endpoint, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const getGroupsForUser = async (username: string) => {
  const endpoint = `${config.API_URL}/groupsForUser/${username}`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.get(endpoint, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const getUsersInGroup = async (groupName: string) => {
  const endpoint = `${config.API_URL}/usersInGroup/${groupName}`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.get(endpoint, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const createUser = async (email: string, auth: any) => {
  const endpoint = `${config.API_URL}/createUser`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  const data = {
    id: email,
    status: '0',
    auth,
  };

  try {
    const response: AxiosResponse<any> = await axios.post(endpoint, data, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const editUserMetaData = async (email: string, auth: any) => {
  const endpoint = `${config.API_URL}/users/${email}`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };
  try {
    const response: AxiosResponse<any> = await axios.put(endpoint, { auth }, apiConfig);
    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

/**
 * Can only be used to set attributes that are editable by a user in Cognito
 */
export const updateUserAttributes = async (attributeName: string, attributeValue: string) => {
  const endpoint = `${config.API_URL}/updateUser`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  const data = {
    accessToken: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    attributeName,
    attributeValue,
  };

  try {
    const response: AxiosResponse<any> = await axios.put(endpoint, data, apiConfig);

    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

// TODO: Add version that allows for updating multiple attributes
export const adminUpdateUserAttributes = async (
  userName: string,
  attributeName: string,
  attributeValue: string
) => {
  const endpoint = `${config.API_URL}/adminUpdateUser`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  const data = {
    attributeName,
    attributeValue,
    userName,
  };

  try {
    const response: AxiosResponse<any> = await axios.put(endpoint, data, apiConfig);

    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const adminDisableUser = async (userName: string) => {
  const endpoint = `${config.API_URL}/adminDisableUser/${userName}`;

  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.put(endpoint, {}, apiConfig);

    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const adminDeleteUser = async (userName: string) => {
  const endpoint = `${config.API_URL}/adminDeleteUser/${userName}`;

  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  try {
    const response: AxiosResponse<any> = await axios.delete(endpoint, apiConfig);

    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const adminAddUserToGroup = async (groupName: string, userName: string) => {
  const endpoint = `${config.API_URL}/adminAddUserToGroup`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  const data = {
    groupName,
    userName,
  };

  try {
    const response: AxiosResponse<any> = await axios.put(endpoint, data, apiConfig);

    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

export const adminRemoveUserFromGroup = async (groupName: string, userName: string) => {
  const endpoint = `${config.API_URL}/adminRemoveUserFromGroup`;
  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  const data = {
    groupName,
    userName,
  };

  try {
    const response: AxiosResponse<any> = await axios.put(endpoint, data, apiConfig);

    return { message: response.data.message, status: response.status };
  } catch (error) {
    handleError(error);
  }
};

/**
 *
 * @param userName string
 * @param complexId string
 * @param groupName string
 * @param buildingId string
 * @returns On success returns a User object generated by Cognito.
 */
export const resendConfirmation = async (userName: string) => {
  const endpoint = `${config.API_URL}/resendConfirmation/${userName}`;

  const apiConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };

  const data = {
    userName,
  };

  try {
    const response: AxiosResponse<any> = await axios.post(endpoint, data, apiConfig);
    console.log(response);

    return { message: response.data.message, status: response.status };
  } catch (error) {
    console.log(error);
    handleError(error);
  }
};
