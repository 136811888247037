let paused = false;

type GenericVoidFunction = () => void;

let queuedFunctions: GenericVoidFunction[] = [];

export const enqueue = (fn: GenericVoidFunction) => {
  if (paused) {
    queuedFunctions.push(fn);
  } else {
    fn();
  }
};

export const pause = () => {
  paused = true;
};

export const unpause = (executeQueue: boolean) => {
  if (executeQueue) {
    queuedFunctions.forEach((fn) => fn());
  }
  queuedFunctions = [];
  paused = false;
};

export const isPaused = () => paused;
