import React, { useEffect, useCallback, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { store } from 'reducers';
import Users from '../users-list/Users';
import BuildingViewer from '../building-viewer/BuildingViewer';
import TopNav from './top-nav/TopNav';
import LandingPage from '../landing-page/LandingPage';
import PartnerList from '../management-list/PartnerList';
import CustomerList from '../management-list/CustomerList';
import CampusList from '../management-list/CampusList';
import BuildingList from '../management-list/BuildingsList';
import Auth from '@aws-amplify/auth';
import {
  setCurrentUser,
  setIsFormDirty,
  setListOfBuildings,
  setListOfCampuses,
} from 'actions/SessionActions';
import LoadingScreen from './LoadingScreen';
import { initializeBuildingManager } from 'helpers/goodmaps-helper/BuildingManager';
import * as FunctionQueue from 'helpers/FunctionQueueHelper';
import BuildingPOIList from 'screens/management-list/BuildingPOIList';
interface Props {
  authState?: string;
}

const StudioRouter = () => {
  const dispatch = useDispatch();
  const [isInitializing, setIsInitializing] = useState(true);

  // setup initialization stuff here?

  const initAuth = useCallback(async () => {
    const session = await Auth.currentSession();
    initializeBuildingManager(session.getIdToken().getJwtToken());

    const cognitoUser = await Auth.currentAuthenticatedUser();
    const {
      name,
      email,
      'custom:complex': complexId,
      'custom:buildingId': buildingId,
    } = cognitoUser.attributes;

    dispatch(
      setCurrentUser({
        id: cognitoUser.username,
        name,
        email,
        complexId,
        buildingId,
        auth: JSON.parse(cognitoUser.signInUserSession.idToken.payload.auth),
      })
    );

    dispatch(setListOfBuildings());
    dispatch(setListOfCampuses());

    setIsInitializing(false);
  }, [dispatch]);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  return isInitializing ? (
    <LoadingScreen />
  ) : (
    <Router
      // captures prompt response, callback is an internal router function.
      getUserConfirmation={(message, callback) => {
        const allowTransition = window.confirm(message);

        if (FunctionQueue.isPaused() && allowTransition) {
          dispatch(setIsFormDirty(false));
          FunctionQueue.unpause(true);
        } else {
          FunctionQueue.unpause(false);
        }

        callback(allowTransition);
      }}
    >
      <TopNav />
      <Switch>
        <Route path="/users" component={Users} />
        <Route
          path="/partners/:partnerID/customers/:customerID/campuses/:campusID"
          component={BuildingList}
        />
        <Route path="/partners/:partnerID/customers/:customerID/campuses" component={CampusList} />
        <Route path="/partners/:partnerID/customers" component={CustomerList} />
        <Route path="/partners/poiList/:buildingId" component={BuildingPOIList} />
        <Route path="/partners" component={PartnerList} />

        <Route path="/building/:id" component={BuildingViewer} />
        <Route path="/campus/:id/level/0" component={BuildingViewer} />
        <Route path="/" exact component={LandingPage} />
      </Switch>
    </Router>
  );
};

const StudioRoot = ({ authState }: Props) => {
  return authState === 'signedIn' ? (
    <>
      <Provider store={store}>
        <StudioRouter />
      </Provider>
    </>
  ) : null;
};

export default StudioRoot;
