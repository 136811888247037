import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import BuildingHome from '../dummy/BuildingHome';
import {
  Entities,
  EntityInfo,
  getRoomColor,
  getEditorIcon,
  getEntityTypeAsString,
  getPluralString,
  isBeacon,
  isConnection,
  isDoor,
  isElement,
  isPOI,
  isRoom,
} from 'helpers/goodmaps-helper/GoodMaps';
import { ActiveEntity, Link } from 'globals/Types';
import Floor from 'components/floorplan/structures/Floor';
import { removeZeroCountLinks } from 'helpers/LinkHelper';
import { selectFloor } from 'actions/ViewerActions';
import { CONNECTOR_COLOR } from 'styles/materials/Materials';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const selectedBuildingName: string = useSelector((state) => state.session.selectedBuildingName);
  const selectedBuildingUUID: string = useSelector((state) => state.session.selectedBuildingUUID);
  const floors: Floor[] = useSelector((state) => state.session.floors);
  const allEntities: ActiveEntity[] = useSelector((state) => state.session.allEntities);

  const getCountForEntity = (entityId: number): number => {
    switch (entityId) {
      case 0:
        return floors.flatMap((floor) => Object.values(floor[Entities[entityId]]).flat()).length;
      case 1:
        return floors.flatMap((floor) => [
          ...floor.stairs,
          ...floor.elevators,
          ...floor.escalators,
          ...floor.other,
        ]).length;
      case 2:
      case 3:
      case 4:
        return floors.flatMap((floor) => floor[Entities[entityId]]).length;
      default:
        return 0;
    }
  };

  const generateCatLinks = (): Link[] => {
    return removeZeroCountLinks(
      Object.values(EntityInfo).map((name, index) => ({
        primary: getPluralString(name.name),
        to: `/building/${selectedBuildingUUID}/${index}`,
        count: getCountForEntity(index),
        color: index === 1 ? '#FFEDCE' : '',
        icon: getEditorIcon(index),
      }))
    );
  };

  const generateLevelLinks = (): Link[] => {
    return removeZeroCountLinks(
      floors.map((floor) => ({
        primary: floor.name,
        to: `/building/${selectedBuildingUUID}/level/${floor.level}`,
        onClick: () => dispatch(selectFloor(floor.level, history)),
      }))
    );
  };

  const generateSearchLinks = useCallback((): Link[] => {
    const onLinkClick = (entity: ActiveEntity) =>
      dispatch(selectFloor(entity.props.level, history, { id: entity.props.id }));

    return allEntities
      .map((entity: ActiveEntity) => {
        if (isBeacon(entity)) {
          return {
            primary: (entity.props?.name && entity.props.name.trim()) || getEntityTypeAsString(4),
            secondary: entity.props.levelName,
            to: `${pathname}/4/${entity.props.id}`,
            onClick: () => onLinkClick(entity),
          };
        } else if (isDoor(entity)) {
          return {
            primary: (entity.props?.name && entity.props.name.trim()) || getEntityTypeAsString(3),
            secondary: entity.props.levelName,
            to: `${pathname}/3/${entity.props.id}`,
            onClick: () => onLinkClick(entity),
          };
        } else if (isPOI(entity)) {
          return {
            primary:
              (entity.props?.name && entity.props.name.trim()) ||
              getEntityTypeAsString(+entity.entityType, +entity.poiType),
            secondary: entity.props.levelName,
            to: `${pathname}/2/category/${entity.poiType}/${entity.props.id}`,
            onClick: () => onLinkClick(entity),
          };
        } else if (isConnection(entity)) {
          return {
            primary:
              (entity.props?.name && entity.props.name.trim()) ||
              getEntityTypeAsString(+entity.entityType, +entity.connectionType),
            secondary: entity.props.levelName,
            to: `${pathname}/1/category/${entity.connectionType}/${entity.props.id}`,
            onClick: () => onLinkClick(entity),
            color: CONNECTOR_COLOR.getStyle(),
          };
        } else if (isElement(entity)) {
          // Wall
          if (entity.elementType === 3) return undefined;

          if (isRoom(entity)) {
            return {
              primary:
                (entity.props?.name && entity.props.name.trim()) ||
                getEntityTypeAsString(+entity.entityType, +entity.elementType, +entity.roomType),
              secondary: entity.props.levelName,
              to: `${pathname}/0/category/${entity.elementType}/sub/${entity.roomType}/${entity.props.id}`,
              onClick: () => onLinkClick(entity),
              color:
                getRoomColor(+entity.roomType).getStyle() === 'rgb(221,221,221)'
                  ? '#fff'
                  : getRoomColor(+entity.roomType).getStyle(),
            };
          }

          return {
            primary:
              (entity.props?.name && entity.props.name.trim()) ||
              getEntityTypeAsString(+entity.entityType, +entity.elementType),
            secondary: entity.props.levelName,
            to: `${pathname}/0/category/${entity.elementType}/${entity.props.id}`,
            onClick: () => onLinkClick(entity),
          };
        }

        return undefined;
      })
      .filter((x) => x !== undefined);
  }, [allEntities, dispatch, history, pathname]);

  const memoizedSearchLinks = useMemo(() => generateSearchLinks(), [generateSearchLinks]);

  return (
    <BuildingHome
      selectedBuildingName={selectedBuildingName}
      levelLinks={generateLevelLinks()}
      catLinks={generateCatLinks()}
      searchLinks={memoizedSearchLinks}
    />
  );
};
