import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import BuildingHome from 'components/smart/BuildingHome';
import Category from 'components/smart/Category';
import SubCategory from 'components/smart/SubCategory';
import Beacon from 'components/smart/Beacon';
import Door from 'components/smart/Door';
import Poi from './PoiEditor';
import Room from './ElementEditor';
import Connection from './ConnectionEditor';
import LevelHome from 'components/smart/LevelHome';
import NavigationHeader from 'components/map-editor/NavigationHeader';
import './animation.scss';
import './EditorRoot.scss';
import { EditModeType } from 'globals/Types';
import Floor from 'components/floorplan/structures/Floor';
import { hideContextMenu } from 'actions/EditEntityActions';
import CampusHome from 'components/smart/CampusHome';

const EditEntityRoot = () => {
  const location = useLocation();
  const currentScreen = location.pathname.split('/').length;
  const [previousScreen, setPreviousScreen] = useState(-1);
  const editMode: EditModeType = useSelector((state) => state.viewer.editMode);
  const floors: Floor[] = useSelector((state) => state.session.floors);
  const contextMenuIsVisible: boolean = useSelector((state) => state.viewer.contextMenuIsVisible);
  const dispatch = useDispatch();
  const animationClassNames = currentScreen >= previousScreen ? 'slide-forward' : 'slide-backward';

  useEffect(() => {
    // if (!location.pathname.includes('new') && editMode !== 'none') dispatch(setEditMode('none'));
  }, [dispatch, editMode, location]);

  useEffect(() => {
    const editorRootElement = document.getElementsByClassName('editor-root')[0] as HTMLElement;
    const onMouseDown = (e) => {
      dispatch(hideContextMenu());
    };
    editorRootElement.addEventListener('mousedown', onMouseDown);

    return () => {
      editorRootElement.removeEventListener('mousemove', onMouseDown);
    };
  }, [contextMenuIsVisible, dispatch]);

  return (
    <div className="editor-root">
      <TransitionGroup
        childFactory={(child) =>
          React.cloneElement(child, {
            classNames: animationClassNames,
          })
        }
      >
        <NavigationHeader
          isHomeDisabled={
            currentScreen <= 3 ||
            location.pathname.split('/').slice(-1)[0] === 'new' ||
            (floors.length === 1 && currentScreen <= 5)
          }
          isBackDisabled={
            currentScreen <= 3 ||
            location.pathname.split('/').slice(-1)[0] === 'new' ||
            // buildings.length <= 1) ||
            (floors.length === 1 && currentScreen < 5) // This locks buildings with one level to that level
          }
          setPreviousScreen={(previousURL) => setPreviousScreen(previousURL.split('/').length)}
        />
        <CSSTransition key={location.key} classNames={animationClassNames} timeout={500}>
          <Switch location={location}>
            {/* Level Routes */}
            <Route
              path="/building/:buildingId/level/:level/0/category/:catId/sub/:subCatId/:entityId"
              component={Room}
            />
            <Route
              path="/campus/:buildingId/level/:level/0/category/:catId/sub/:subCatId/:entityId"
              component={Room}
            />

            <Route
              path="/building/:buildingId/level/:level/:entityType/category/:catId/sub/:subCatId"
              component={SubCategory}
            />
            <Route
              path="/campus/:buildingId/level/:level/:entityType/category/:catId/sub/:subCatId"
              component={SubCategory}
            />
            <Route
              path="/category/:buildingId/level/:level/:entityType/category/:catId/sub/:subCatId"
              component={SubCategory}
            />
            <Route
              path="/building/:buildingId/level/:level/1/category/:catId/:entityId"
              component={Connection}
            />
            <Route
              path="/building/:buildingId/level/:level/2/category/:catId/:entityId"
              component={Poi}
            />
            <Route
              path="/building/:buildingId/level/:level/0/category/:catId/:entityId"
              component={Room}
            />
            <Route
              path="/campus/:buildingId/level/:level/1/category/:catId/:entityId"
              component={Connection}
            />
            <Route
              path="/campus/:buildingId/level/:level/2/category/:catId/:entityId"
              component={Poi}
            />
            <Route
              path="/campus/:buildingId/level/:level/0/category/:catId/:entityId"
              component={Room}
            />

            <Route
              exact
              path="/building/:buildingId/level/:level/:entityType/category/:catId"
              component={SubCategory}
            />
            <Route
              exact
              path="/campus/:buildingId/level/:level/:entityType/category/:catId"
              component={SubCategory}
            />

            <Route
              exact
              path="/building/:buildingId/level/:level/:entityType/new"
              component={Poi}
            />
            <Route
              exact
              path="/building/:buildingId/level/:level/1/:entityId"
              component={Connection}
            />
            <Route exact path="/building/:buildingId/level/:level/4/:entityId" component={Beacon} />
            <Route exact path="/building/:buildingId/level/:level/3/:entityId" component={Door} />
            <Route exact path="/campus/:buildingId/level/:level/3/:entityId" component={Door} />
            <Route
              path="/building/:buildingId/level/:level/:entityType/:entityId"
              component={Room}
            />
            {/* <Route path="/campus/:buildingId/level/:level/:entityType/:entityId" component={Room} /> */}

            <Route path="/building/:buildingId/level/:level/:entityType" component={Category} />
            <Route path="/campus/:buildingId/level/:level/:entityType" component={Category} />
            <Route exact path="/building/:buildingId/level/:level" component={LevelHome} />

            {/* Category Routes */}
            <Route
              path="/building/:buildingId/:entityType/category/:catId/sub/:subCatId/:entityId"
              component={Room}
            />
            <Route
              path="/campus/:buildingId/:entityType/category/:catId/sub/:subCatId/:entityId"
              component={Room}
            />

            <Route
              path="/building/:buildingId/:entityType/category/:catId/sub/:subCatId"
              component={SubCategory}
            />
            <Route
              exact
              path="/building/:buildingId/1/category/:catId/:entityId"
              component={Connection}
            />
            <Route exact path="/building/:buildingId/2/category/:catId/:entityId" component={Poi} />
            <Route
              path="/building/:buildingId/:entityType/category/:catId/:entityId"
              component={Room}
            />

            <Route
              path="/building/:buildingId/:entityType/category/:catId"
              component={SubCategory}
            />

            <Route exact path="/building/:buildingId/3/:entityId" component={Door} />
            <Route exact path="/building/:buildingId/4/:entityId" component={Beacon} />

            <Route path="/building/:buildingId/:entityType/:entityId" component={Room} />

            <Route path="/building/:buildingId/:entityType" component={Category} />
            <Route exact path="/building/:buildingId" component={BuildingHome} />
            <Route exact path="/campus/:campusId/level/0" component={CampusHome} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default EditEntityRoot;
