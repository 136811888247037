import { TextureLoader, RepeatWrapping, Color } from 'three';

export const EMPTY_TEXTURE = new TextureLoader().load('/blank.png');
EMPTY_TEXTURE.wrapS = RepeatWrapping;
EMPTY_TEXTURE.wrapT = RepeatWrapping;
EMPTY_TEXTURE.rotation = Math.PI / 6;
EMPTY_TEXTURE.repeat.set(0.5, 0.5);

export const STAIR_LINES_TEXTURE = new TextureLoader().load('/stairs-tileable.png');
STAIR_LINES_TEXTURE.wrapS = RepeatWrapping;
STAIR_LINES_TEXTURE.wrapT = RepeatWrapping;
STAIR_LINES_TEXTURE.rotation = Math.PI / 6;
STAIR_LINES_TEXTURE.repeat.set(3, 3);

// Note with Color, need to use all 6 digits

export const SCENE_COLOR = new Color(0x091262);
export const OUTLINE_COLOR = new Color(0xffffff);
export const CORRIDOR_COLOR = new Color(0xffffff);
export const PUBLIC_ROOM_COLOR = new Color(0xdddddd);
export const NON_PUBLIC_ROOM_COLOR = new Color(0xffffff);
export const SHOP_COLOR = new Color(0xcaedf6);
export const RESTAURANT_COLOR = new Color(0xffe1b8);
export const KITCHEN_COLOR = new Color(0xffd4b7);
export const CONFERENCE_COLOR = new Color(0xdbe7ff);
export const LOBBY_COLOR = new Color(0xcdf2e8);
export const RESTROOM_COLOR = new Color(0xeee5f8);
export const CONNECTOR_COLOR = new Color(0xffedce);
export const BEACON_COLOR = new Color(0xffe658);
export const ENTITY_SELECTED_COLOR = new Color(0x414143);
export const ENTITY_HOVERED_COLOR = new Color(0xbbbbbb);
export const BEDROOM_COLOR = new Color(0xfae3f7);
export const PARKING_COLOR = new Color(0xf4f4f4);
export const GREENSPACE_COLOR = new Color(0xc9f2cd);
export const SIDEWALK_COLOR = new Color(0xffffff);
export const BUS_STOP_COLOR = new Color(0x0948ad);
export const CROSS_WALK_COLOR = new Color(0xffffff);
export const ROAD_COLOR = new Color(0xf4f4f4);

export const DEFAULT_COLOR = new Color(0xdddddd);
export const DEFAULT_LINE_COLOR = new Color(0xffffff);
export const DEFAULT_LINE_WIDTH = 0;

export const FIXTURE_LINE_WIDTH = 0;
export const FIXTURE_COLOR = new Color(0xcccccc);

export const PARKING_LINE_COLOR = new Color(0xdbdbdb);
export const PARKING_LINE_WIDTH = 0.15;

export const SIDEWALK_LINE_COLOR = new Color(0xb6b6b6);
export const SIDEWALK_LINE_WIDTH = 0.15;

export const CROSS_WALK_LINE_COLOR = new Color(0xb6b6b6);
export const CROSS_WALK_LINE_WIDTH = 0.15;

export const BUS_STOP_LINE_COLOR = new Color(0x0e70e5);
export const BUS_STOP_LINE_WIDTH = 0.15;

export const AREA_COLOR = new Color(0xeeeeee);
export const AREA_LINE_COLOR = new Color(0xaaaaaa);
export const AREA_LINE_WIDTH = 0.1;
