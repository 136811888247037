import {
  Mesh,
  Vector2,
  Vector3,
  MeshBasicMaterial,
  Shape,
  ShapeGeometry,
  PlaneGeometry,
  Geometry,
  Line,
  LineBasicMaterial,
  Object3D,
  Path,
  RepeatWrapping,
  Color,
  CircleGeometry,
  TextureLoader,
  DoubleSide,
  Texture,
} from 'three';
import * as gm from 'goodmaps-sdk';
import {
  getDirectionFromPoints,
  maxPointX,
  maxPointY,
  minPointX,
  minPointY,
} from 'helpers/MathHelper';
import { DEFAULT_LINE_COLOR, DEFAULT_LINE_WIDTH } from 'styles/materials/Materials';

const loader = new TextureLoader();
loader.crossOrigin = '';

const createLine = (
  a: Vector2 | Vector3,
  b: Vector2 | Vector3,
  width: number = 0.2,
  color: number = 0xff0000,
  z: number = 0.3,
  dashed: boolean = false
) => {
  const point1 = new Vector3(a.x, a.y, z);
  const point2 = new Vector3(b.x, b.y, z);

  const vector12 = new Vector3().copy(point2).sub(point1);

  if (vector12.length() === 0) return new Object3D();
  const point3 = new Vector3().copy(vector12).multiplyScalar(0.5).add(point1);

  const plane = new PlaneGeometry(width, 1, 1);

  let texture: Texture;

  if (dashed) {
    texture = loader.load('/dashed.png');
    texture.anisotropy = 16;
    texture.center.set(0.5, 0.5);
    texture.rotation = Math.PI / 2;
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat.set(vector12.length() * 1.1, 1);
    texture.flipY = true;
  }

  const wall = new Mesh(
    plane,
    dashed
      ? new MeshBasicMaterial({
          color,
          alphaMap: texture,
          transparent: true,
          opacity: 1,
          depthWrite: false,
          side: DoubleSide,
        })
      : new MeshBasicMaterial({
          color,
        })
  );
  wall.position.copy(point3);
  wall.position.z = z;
  wall.scale.y = vector12.length();
  wall.rotation.z = -Math.atan2(vector12.x, vector12.y);
  return wall;
};

const createCircle = (
  position: Vector2 | Vector3,
  size: number,
  color: number,
  zPosition = 0.5
) => {
  const geometry = new CircleGeometry(size, 5);
  const material = new MeshBasicMaterial({ color });
  const mesh = new Mesh(geometry, material);
  mesh.position.copy(new Vector3(position.x, position.y, zPosition));
  return mesh;
};

export const renderTestPoint = (point: gm.TestPoint, building: gm.Building | gm.Campus) => {
  const pointMarker = new Object3D();

  const geometry1 = new PlaneGeometry(0.5, 0.1);
  const material1 = new MeshBasicMaterial({ color: 0xc91a00 });
  const mesh1 = new Mesh(geometry1, material1);
  mesh1.rotateZ(Math.PI / 4);
  pointMarker.add(mesh1);

  const geometry2 = new PlaneGeometry(0.5, 0.1);
  const material2 = new MeshBasicMaterial({ color: 0xc91a00 });
  const mesh2 = new Mesh(geometry2, material2);
  mesh2.rotateZ(-Math.PI / 4);
  pointMarker.add(mesh2);

  const { x, y } = building.calcProjection(point);
  pointMarker.position.set(x, y, 0.05);
  return pointMarker;
};

export const renderRoute = (route: gm.Route, building: gm.Building | gm.Campus) => {
  const lines = renderElementBorder(
    route.getNodes().map((n) => {
      const { x, y } = building.calcProjection(n);
      return new Vector2(x, y);
    }),
    0.1,
    0x800000,
    0.01
  );
  return lines;
};

export const renderElevator = (points: Vector2[]) => {
  const elevatorObject3D = new Object3D();

  const [A, B, C, D] = [minPointX(points), maxPointY(points), maxPointX(points), minPointY(points)];
  const horizUnit = A.clone().sub(B).normalize().multiplyScalar(0.25);
  const vertUnit = B.clone().sub(C).normalize().multiplyScalar(0.25);

  A.sub(horizUnit);
  A.sub(vertUnit);

  B.add(horizUnit);
  B.sub(vertUnit);

  C.add(horizUnit);
  C.add(vertUnit);

  D.sub(horizUnit);
  D.add(vertUnit);

  const border: Vector3[] = [A, B, C, D, A];
  const diag1: Vector3[] = [A, C];
  const diag2: Vector3[] = [B, D];

  const borderMaterial = new LineBasicMaterial({ color: 0xdec7b0, linewidth: 4 });
  const borderGeometry = new Geometry();
  border.forEach((b) => borderGeometry.vertices.push(new Vector3(b.x, b.y, 0.1)));
  const borderMesh = new Line(borderGeometry, borderMaterial);
  elevatorObject3D.add(borderMesh);

  const diag1Material = new LineBasicMaterial({ color: 0xdec7b0, linewidth: 4 });
  const diag1Geometry = new Geometry();
  diag1.forEach((d) => diag1Geometry.vertices.push(new Vector3(d.x, d.y, 0.1)));
  const diag1Mesh = new Line(diag1Geometry, diag1Material);
  elevatorObject3D.add(diag1Mesh);

  const diag2Material = new LineBasicMaterial({ color: 0xdec7b0, linewidth: 4 });
  const diag2Geometry = new Geometry();
  diag2.forEach((d) => diag2Geometry.vertices.push(new Vector3(d.x, d.y, 0.1)));
  const diag2Mesh = new Line(diag2Geometry, diag2Material);
  elevatorObject3D.add(diag2Mesh);

  return elevatorObject3D;
};

export const renderArea = (
  textures: {},
  id: string,
  textureType: string,
  points: Vector2[],
  holes: Vector2[][],
  color = new Color(0xefefef),
  zPosition?: number,
  enclosed = true,
  borderWidth = 0,
  borderColor = 0xaaaaaa,
  dashedBorder = false
): { container: Object3D; floor?: Mesh; lines? } => {
  const areaObject3D = new Object3D();

  if (points.length <= 2) {
    return { container: areaObject3D };
  }

  const floorMaterial = textures[textureType]
    ? new MeshBasicMaterial({ color, map: textures[textureType], toneMapped: false })
    : new MeshBasicMaterial({ color, toneMapped: false });
  // : new MeshBasicMaterial({ color, map: new Texture(), toneMapped: false });

  const direction = getDirectionFromPoints(points);
  if (textures[textureType]) {
    floorMaterial.map.center.set(0.5, 0.5);
    floorMaterial.map.wrapS = RepeatWrapping;
    floorMaterial.map.wrapT = RepeatWrapping;
    floorMaterial.map.rotation = direction;
    floorMaterial.map.repeat.set(2, 2);
  }

  const floorShape = new Shape(points);
  floorShape.holes = holes.map((p) => new Path(p));
  const floorGeometry = new ShapeGeometry(floorShape);
  const floorMesh = new Mesh(floorGeometry, floorMaterial);
  floorMesh.name = id;
  floorMesh.position.z = zPosition - 0.01;

  if (enclosed) {
    areaObject3D.add(floorMesh);
  }

  if (borderWidth) {
    const lines = renderElementBorder(points, borderWidth, borderColor, 0.1, false, dashedBorder);
    areaObject3D.add(lines);
  }

  return {
    container: areaObject3D,
    floor: floorMesh,
  };
};

export const renderElementBorder = (
  points: Vector2[],
  lineWidth: number = DEFAULT_LINE_WIDTH,
  lineColor: number = DEFAULT_LINE_COLOR.getHex(),
  zPosition?: number,
  roundCorners: boolean = false,
  dashed: boolean = false
) => {
  const container = new Object3D();
  for (let i = 0; i < points.length - 1; i++) {
    const a = points[i];
    const b = points[i + 1];

    const line = createLine(a, b, lineWidth, lineColor, zPosition, dashed);
    if (roundCorners) {
      const corner = createCircle(a, lineWidth / 2, lineColor, zPosition + 0.02);
      container.add(corner);
    }
    container.add(line);
  }

  return container;
};
