import { Mesh, Vector2, Vector3, MeshBasicMaterial, PlaneGeometry } from 'three';
import * as LabelRenderer from './LabelRenderer';
import * as TWEEN from '@tweenjs/tween.js';

let topViewIsEnabled = false;

export const generateCoverPlane = (initalOpacity: number) => {
  const planeGeometry = new PlaneGeometry(1000, 1000);
  const material = new MeshBasicMaterial({
    color: '#bcbfc2',
    transparent: true,
    opacity: initalOpacity,
  });
  const plane = new Mesh(planeGeometry, material);
  return plane;
};

export const flyBuildingPlanInAsync = (
  scene,
  controls,
  defaultPosition,
  buildingPlan,
  currentFloor,
  floorRoutes,
  floorTestPoints,
  onComplete,
  selectedFloor: number
): Promise<void> => {
  return new Promise((resolve, reject) => {
    LabelRenderer.removeAllLabels();
    floorRoutes && currentFloor.remove(floorRoutes);
    floorTestPoints && currentFloor.remove(floorTestPoints);

    const plane = generateCoverPlane(1);
    plane.position.set(0, 0, -50);
    scene.add(plane);

    new TWEEN.Tween(plane.material).to({ opacity: 0 }).easing(TWEEN.Easing.Quadratic.InOut).start();

    const planeEndPos = new Vector3(0, 0, selectedFloor * 5 - 1);
    new TWEEN.Tween(plane.position)
      .to(planeEndPos, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
      .onComplete(() => {
        scene.remove(plane);
      });

    for (let level in buildingPlan) {
      const { levelObject } = buildingPlan[level];
      const { position } = levelObject;
      if (parseFloat(level) !== selectedFloor) {
        scene.add(levelObject);
        position.set(0, 0, parseFloat(level) < selectedFloor ? -270 : 270);
        new TWEEN.Tween(position).to(new Vector3(0, 0, parseFloat(level) * 5)).start();
      } else {
        new TWEEN.Tween(position)
          .to(new Vector3(0, 0, parseFloat(level) * 5))
          .start()
          .onComplete(onComplete);
      }
    }

    // Tween the camera back to the default position
    new TWEEN.Tween(controls.target)
      .to(new Vector3(0, 0, 0), 700)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
      .onComplete(() => {});

    new TWEEN.Tween(controls.object.position)
      .to(defaultPosition, 700)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
      .onComplete(() => resolve());
  });
};

export const flyBuildingPlanOutAsync = (
  scene,
  buildingPlan,
  currentFloor,
  selectedFloor: number,
  onBeforeSwitch?: (levelObject, level) => void,
  onFinished?: () => void
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const plane = generateCoverPlane(0);
    plane.position.set(0, 0, selectedFloor * 5 - 1);
    scene.add(plane);

    new TWEEN.Tween(plane.material).to({ opacity: 1 }).easing(TWEEN.Easing.Quadratic.InOut).start();

    const planeEndPos = new Vector3(0, 0, -50);
    new TWEEN.Tween(plane.position)
      .to(planeEndPos, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
      .onComplete(() => {
        scene.remove(plane);
      });

    for (let level in buildingPlan) {
      const { levelObject } = buildingPlan[level];
      const { position } = levelObject;
      if (parseFloat(level) !== selectedFloor) {
        new TWEEN.Tween(position)
          .to(new Vector3(0, 0, parseFloat(level) < selectedFloor ? -270 : 270))
          .start()
          .onComplete(() => {
            scene.remove(levelObject);
          });
      } else {
        onBeforeSwitch(levelObject, level);
        new TWEEN.Tween(position)
          .to(new Vector3(0, 0, 0))
          .start()
          .onComplete(() => {
            // const zoomLevel = LabelRenderer.getZoomLevel(BUILDING_MAX_ZOOM);
            // LabelRenderer.renderLabelsPipeline(zoomLevel.level);
            if (onFinished) onFinished();
            resolve();
          });
      }
    }
  });
};

export const flyFloorOutAsync = (
  scene,
  controls,
  currentFloor,
  previousFloor,
  direction: string,
  level: number
): Promise<void> => {
  return new Promise((resolve, reject) => {
    TWEEN.removeAll();
    controls.enabled = false;
    controls.maxPolarAngle = Infinity;
    controls.minPolarAngle = 0;

    // Create plane to hide floors animating up or down
    const planeGeometry = new PlaneGeometry(1000, 1000);
    const material = new MeshBasicMaterial({
      color: '#bcbfc2',
      transparent: true,
      opacity: direction === 'down' ? 0 : 1,
    });
    const plane = new Mesh(planeGeometry, material);
    plane.position.set(0, 0, direction === 'down' ? 1 : -50);
    scene.add(plane);
    //

    // Setting TWEEN to positions
    const currentFloorEndPos = new Vector3(0, 0, 0);
    const previousFloorEndPos = new Vector3(0, 0, direction === 'down' ? -270 : 270);
    const planeEndPos = new Vector3(0, 0, direction === 'down' ? -50 : 1);
    currentFloor.position.set(0, 0, direction === 'down' ? 270 : -270);
    //

    // Cover plane TWEENS
    new TWEEN.Tween(plane.material)
      .to({ opacity: direction === 'down' ? 1 : 0 })
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();

    new TWEEN.Tween(plane.position)
      .to(planeEndPos)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
      .onComplete(() => {
        scene.remove(plane);
      });
    //

    // Floor animating out TWEEN
    new TWEEN.Tween(previousFloor.position)
      .to(previousFloorEndPos)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
      .onComplete(() => {
        scene.remove(previousFloor);
      });
    //

    // Floor animating in TWEEN
    new TWEEN.Tween(currentFloor.position)
      .to(currentFloorEndPos)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
      .onComplete(() => {
        controls.maxPolarAngle = Math.PI / 5;
        controls.minPolarAngle = -Math.PI / 2;
        controls.enabled = true;

        // const zoomLevel = LabelRenderer.getZoomLevel(BUILDING_MAX_ZOOM);
        // LabelRenderer.renderLabelsPipeline(zoomLevel.level);
        resolve();
      });
    //
  });
};

export const flyToAsync = (
  camera,
  controls,
  position: Vector3,
  reduceAnimation: boolean
): Promise<void> => {
  return new Promise((resolve, reject) => {
    TWEEN.removeAll();
    controls.enabled = false;
    if (!topViewIsEnabled) {
      controls.maxPolarAngle = Infinity;
      controls.minPolarAngle = 0;
    }

    const setOrbitControlAttributes = () => {
      controls.maxPolarAngle = topViewIsEnabled ? -Math.PI / 2 : Math.PI / 5;
      controls.minPolarAngle = -Math.PI / 2;
      controls.enabled = true;
    };

    const targetPosition = topViewIsEnabled || reduceAnimation ? controls.target : position;

    const targetUnitVector = new Vector2(
      targetPosition.x - controls.object.position.x,
      targetPosition.y - controls.object.position.y
    ).normalize();

    new TWEEN.Tween(controls.target)
      .to(new Vector3(position.x, position.y, 0), 700)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
      .onComplete(() => {});

    if (!reduceAnimation) {
      const camPos1 = new Vector3(
        position.x - targetUnitVector.x * (topViewIsEnabled ? 2 : 20),
        position.y - targetUnitVector.y * (topViewIsEnabled ? 2 : 20),
        100
      );

      const camPos2 = new Vector3(camPos1.x, camPos1.y, 30);

      new TWEEN.Tween(controls.object.position)
        .to(camPos1, 700)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start()
        .onComplete(() => {
          new TWEEN.Tween(camera.position)
            .to(camPos2, 600)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start()
            .onComplete(() => {
              setOrbitControlAttributes();

              resolve();
            });
        });
    } else {
      const camPos = new Vector3(
        position.x - targetUnitVector.x * 10,
        position.y - targetUnitVector.y * 10,
        controls.object.position.z
      );

      new TWEEN.Tween(controls.object.position)
        .to(camPos, 700)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start()
        .onComplete(() => {
          setOrbitControlAttributes();

          resolve();
        });
    }
  });
};

export const zoom = (controls, direction: number) => {
  TWEEN.removeAll();
  const { position } = controls.object;

  if (position.distanceTo(controls.target) <= 10) {
    return;
  }

  let zoomAmount = (direction * position.distanceTo(controls.target)) / 3.5;

  controls.enabled = false;

  const zoomVector: Vector3 = new Vector3(
    controls.target.x - position.x,
    controls.target.y - position.y,
    controls.target.z - position.z
  ).normalize();

  let camPos: Vector3 = new Vector3(
    position.x + zoomVector.x * zoomAmount,
    position.y + zoomVector.y * zoomAmount,
    position.z + zoomVector.z * zoomAmount
  );

  if (camPos.distanceTo(controls.target) < 10) {
    const zoomAdjust = position.distanceTo(controls.target) - 10;
    camPos = new Vector3(
      position.x + zoomVector.x * zoomAdjust,
      position.y + zoomVector.y * zoomAdjust,
      position.z + zoomVector.z * zoomAdjust
    );
  }

  new TWEEN.Tween(position)
    .to(camPos, 200)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .start()
    .onComplete(() => {
      controls.enabled = true;
    });
};

export const toggleTopView = (controls, toggle: boolean) => {
  TWEEN.removeAll();
  controls.enabled = false;
  topViewIsEnabled = toggle;

  const targetUnitVector: Vector2 = new Vector2(
    controls.target.x - controls.object.position.x,
    controls.target.y - controls.object.position.y
  ).normalize();
  const camPos = new Vector3(
    controls.target.x - targetUnitVector.x * (toggle ? 2 : 50),
    controls.target.y - targetUnitVector.y * (toggle ? 2 : 50),
    controls.object.position.z
  );

  if (!toggle) {
    controls.maxPolarAngle = Math.PI / 5;
  }

  new TWEEN.Tween(controls.object.position)
    .to(camPos, 400)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .start()
    .onComplete(() => {
      if (toggle) controls.maxPolarAngle = -Math.PI / 2;
      controls.enabled = true;
    });
};
