import React, { useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import config from './globals/config/config';
import StudioAuthenticator from 'screens/root/StudioAuthenticator';
import SignUpConfirmation from 'screens/sign-in/SignUpConfirmation';
import Branch from 'branch-sdk';
import ForgotPasswordConfirmation from 'screens/sign-in/ForgotPasswordConfirmation';

Auth.configure({
  userPoolId: config.userPoolId,
  userPoolWebClientId: config.userPoolWebClientId,
  region: config.region,
});

const App = () => {
  useEffect(() => {
    if (config.NAME === 'Release') ReactGA.initialize(config.TRACKING_ID);
    Branch.init('key_live_go6wPPcGcrgtep3118Macikdqxg2LN7c');
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/explore-confirm" component={SignUpConfirmation} />
        <Route path="/explore-reset" component={ForgotPasswordConfirmation} />
        <Route path="*" component={StudioAuthenticator} />
      </Switch>
    </Router>
  );
};

export default App;
