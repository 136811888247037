import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { setEditMode } from 'actions/ViewerActions';
import { SaveStateType } from 'globals/Types';
import EditorPage from '../dummy/EditorPage';
import { Entities } from 'helpers/goodmaps-helper/GoodMaps';

type Props = {
  title: string;
  children: any;
  icon?: string;
  confirmSave?: boolean;
  onConfirmSave?: () => any;
  onCancelClick?: () => void;
  saveState?: SaveStateType;
  saveStateErrorMessage?: string;
  showSearch?: boolean;
  search?: string;
  setSearch?: (search: string) => void;
};

const EditEntityPanel = (props: Props) => {
  const dispatch = useDispatch();
  const { entityType, entityId, level } = useParams();
  const history = useHistory();

  const pathnameArray = history.location.pathname.split('/');

  const onClick = () => {
    dispatch(setEditMode('add'));
  };

  return (
    <EditorPage
      {...props}
      onClick={onClick}
      showAddPOI={
        +entityType === Entities.pois && level && pathnameArray.slice(-1)[0] !== 'new' && !entityId
      }
    />
  );
};

export default EditEntityPanel;
