import React from 'react';
import "./MobileView.scss";

const MobileView = () => {
  return (
    <div className="mobile-container">
        <div className='mobile-heading-container'>
      <h1 className='mobile-heading'>
        <span>Good</span>Maps
      </h1>
      </div>
      <div className='mobile-inner-container'>
        <p className='inner-heading'>GoodMaps Studio works best on a larger screen, like a desktop or laptop.</p>
        <p className='inner-content'>Please visit goodmaps.io on a desktop or laptop device.</p>
      </div>
    </div>
  );
};

export default MobileView;
