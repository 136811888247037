import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { User } from 'goodmaps-utils';
import { resendConfirmation } from 'helpers/AdminAPIHelper';
import Alert from 'components/dummy/Alert';

type Props = {
  user: User;
  onClose: () => void;
};

const ResendModal = ({ user, onClose }: Props) => {
  const [response, setResponse] = React.useState({ text: '', status: '' });
  const [submitting, setSubmitting] = React.useState(false);

  const onSubmit = async () => {
    try {
      const result = await resendConfirmation(user.email);
      console.log(result);
      if (result.status === 200)
        setResponse({ text: 'Success! The user has been resent an invite.', status: '200' });
      else throw new Error('500 - The server failed to resend confirmation');

      setSubmitting(false);
    } catch (err) {
      console.log(err);
      setResponse({
        text: `There was a problem resending an invite to the user. Please try again. Error: ${
          (err as Error).message
        }`,
        status: '500',
      });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal.Body>
        <p>Resend an invite to the email "{user.email}"?</p>
        {response.text ? (
          <Alert variant={response.status === '200' ? 'success' : 'danger'}>{response.text}</Alert>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        {response.status === '200' ? (
          <Button variant="success" onClick={onClose}>
            Close
          </Button>
        ) : (
          <Button
            disabled={submitting}
            variant="primary"
            onClick={() => {
              setSubmitting(true);
              onSubmit();
            }}
          >
            {submitting ? 'Sending..' : 'Confirm'}
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default ResendModal;
