import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setShowDeletePOIDialogue } from 'actions/ViewerActions';
import { ActiveEntity } from 'globals/Types';
import './DeletePOIDialogue.scss';
import { deletePOI, clearHighlightedEntity } from 'actions/EditEntityActions';
import { Modal, Button } from 'react-bootstrap';
import { Building, User } from 'goodmaps-sdk';

const DeletePOIDialogue = () => {
  const highlightedEntity: ActiveEntity = useSelector((state) => state.viewer.highlightedEntity);
  const user: User = useSelector((state) => state.session.user);
  const building: Building = useSelector((state) => state.session.building);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <Modal.Header closeButton={false}>
        <Modal.Title>Delete the POI "{highlightedEntity?.props.name}"?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Users will no longer be able to access it in GoodMaps Explore.</Modal.Body>
      <Modal.Footer>
        <div className="cancel-button">
          <Button
            variant="light"
            onClick={() => {
              dispatch(setShowDeletePOIDialogue(false));
            }}
          >
            CANCEL
          </Button>
        </div>
        <div className="delete-button">
          <Button
            variant="danger"
            onClick={async () => {
              try {
                await dispatch(deletePOI(highlightedEntity, user.email, building, history));
                await dispatch(setShowDeletePOIDialogue(false));
                dispatch(clearHighlightedEntity());
              } catch (e) {
                window.alert(e);
              }
            }}
          >
            DELETE
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DeletePOIDialogue;
