import React from 'react';
import './Zoom.scss';
import { Add, Remove } from '@material-ui/icons';
import { zoom } from 'renderer/Renderer';

const ZoomControl = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      <div className="zoom-control">
        <button onClick={() => zoom(1)}>
          <Add fontSize="inherit" />
        </button>
        <button onClick={() => zoom(-1)}>
          <Remove fontSize="inherit" />
        </button>
      </div>
    </div>
  );
};

export default ZoomControl;
