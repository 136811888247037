import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  handleTestPointsVisibility,
  handleRoutesVisibility,
  handleUnnamedPoisVisibility,
} from '../../actions/SessionActions';
import Floor from 'components/floorplan/structures/Floor';
import LevelHome from 'components/dummy/LevelHome';
import {
  Entities,
  EntityInfo,
  getEditorIcon,
  getPluralString,
} from 'helpers/goodmaps-helper/GoodMaps';
import { Link } from 'globals/Types';
import {
  checkRoutesVisibility,
  checkTestPointsVisibility,
  checkUnnamedPoiVisibility,
} from 'actions/ViewerActions';
import { User } from 'goodmaps-sdk';

type URLParams = {
  level: string;
};

export default () => {
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState('');
  const user: User = useSelector((state) => state.session.user);
  const currentFloor: number = useSelector((state) => state.viewer.currentFloor);
  const showRoutes: boolean = useSelector((state) => state.viewer.showRoutes);
  const showTestPoints: boolean = useSelector((state) => state.viewer.showTestPoints);
  const showUnnamedPois: boolean = useSelector((state) => state.viewer.showUnnamedPois);
  const selectedBuildingUUID: string = useSelector((state) => state.session.selectedBuildingUUID);
  const selectedBuildingName: string = useSelector((state) => state.session.selectedBuildingName);
  const floors: Floor[] = useSelector((state) => state.session.floors);
  const { level }: URLParams = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (floors.length === 1) {
      setTitle(selectedBuildingName);
    }
  }, [dispatch, floors, floors.length, selectedBuildingName]);

  const shouldShowTestPoints = useCallback(() => {
    dispatch(handleTestPointsVisibility());
  }, [dispatch]);

  const shouldShowRoutes = useCallback(() => {
    dispatch(handleRoutesVisibility());
  }, [dispatch]);

  const shouldShowUnnamedPois = useCallback(() => {
    dispatch(handleUnnamedPoisVisibility());
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkRoutesVisibility());
    dispatch(checkTestPointsVisibility());
    dispatch(checkUnnamedPoiVisibility());
  }, [
    currentFloor,
    dispatch,
    floors,
    shouldShowRoutes,
    shouldShowTestPoints,
    shouldShowUnnamedPois,
    showRoutes,
    showTestPoints,
    showUnnamedPois,
  ]);

  const floor = useMemo(() => {
    const f = floors.length !== 1 ? floors.find((floor) => floor.level === +level) : floors[0];

    if (!f) {
      history.replace(`/building/${selectedBuildingUUID}`);
    }
    return [f];
  }, [floors, level, history, selectedBuildingUUID]);

  const getCountForEntity = (entityId: number): number => {
    switch (entityId) {
      case 0:
        return Object.values(floor[0].elements).flat().length;
      case 1:
        return [
          ...floor[0].stairs,
          ...floor[0].elevators,
          ...floor[0].escalators,
          ...floor[0].other,
        ].length;
      case 2:
      case 3:
      case 4:
        return floor[0][Entities[entityId]].length;
      default:
        return 0;
    }
  };

  const getLinks = (): Link[] => {
    const links: Link[] = Object.entries(EntityInfo).map((entry, index) => ({
      primary: getPluralString(entry[1].name),
      to: `${pathname}/${entry[0]}`,
      count: getCountForEntity(+entry[0]),
      color: +entry[0] === 1 ? '#FFEDCE' : '',
      icon: getEditorIcon(index),
    }));
    if (getCountForEntity(2) === 0) {
      links[2] = { primary: 'Add Point of Interest', to: `${pathname}/2` };
    }
    return links;
  };

  return (
    <LevelHome
      title={title}
      directoryTitle={floors.length === 1 ? `Directory - Floor ${floor[0]?.name || ''}` : ''}
      links={getLinks()}
      currentFloor={currentFloor}
      showRoutes={showRoutes}
      showTestPoints={showTestPoints}
      showUnnamedPois={showUnnamedPois}
      floor={floor}
      shouldShowTestPoints={shouldShowTestPoints}
      shouldShowRoutes={shouldShowRoutes}
      shouldShowUnnamedPois={shouldShowUnnamedPois}
      user={user}
    />
  );
};
