import * as gm from 'goodmaps-sdk';
import Floor from 'components/floorplan/structures/Floor';
import { ICONS } from 'components/floorplan/scene-objects/LabelIcons';
import { Room as ROOM, POI, ActiveEntity, Element } from 'globals/Types';
import * as THREE from 'three';
import {
  minPointX,
  maxPointX,
  minPointY,
  maxPointY,
  getDistanceBetweenPoints,
} from 'helpers/MathHelper';
import ZOOMLEVELS from 'globals/ZoomLevels';
import { Elements } from 'helpers/goodmaps-helper/GoodMaps';

export const generateLabelGroups = (floors: Floor[]) => {
  const { minX, minY } = getBuildingDimensions(floors);

  const labelGroups = floors.map((floor) => {
    const sortedLabels = [
      ...Object.values(floor.elements).flat(),
      ...floor.pois,
      ...floor.elevators,
      ...floor.escalators,
      ...floor.stairs,
    ]
      .map((label) => {
        // Use absolute position
        const positionialData = getLabelPosition(label, minX, minY, 1, 1);
        return { entity: label, positionialData };
      })
      .sort((a, b) => {
        return (
          a.positionialData.position.toArray([])[1] - b.positionialData.position.toArray([])[1]
        );
      });

    return {
      [`floor${floor.level}`]: getGroups(sortedLabels, minX, minY),
    };
  });

  return labelGroups;
};

const getLabelPosition = (label: ActiveEntity, minX, minY, width: number, height: number) => {
  let position: THREE.Vector2, x: number, y: number;

  if (label && (label.entityType === 1 || label.entityType === 0)) {
    position = getLabelPositionForRoom((label as Element).points);
    x = Math.floor((position.x + Math.abs(minX)) / width);
    y = Math.floor((position.y + Math.abs(minY)) / height);
  } else {
    position = new THREE.Vector2((label as POI).position.x, (label as POI).position.y);
    x = Math.floor((position.x + Math.abs(minX)) / width);
    y = Math.floor((position.y + Math.abs(minY)) / height);
  }

  return { position, x, y };
};

type Label = {
  entity: ActiveEntity;
  positionialData: {
    position: THREE.Vector2;
    x: number;
    y: number;
  };
};

const getGroups = (labels: Label[], minX: number, minY: number) => {
  let remainingLabels: Label[] = labels;
  const selectedLabel: { label; position: THREE.Vector2 }[] = [];
  const groups = ZOOMLEVELS.map(({ dimensions, level }) => {
    let group = [];
    const { width, height } = dimensions;
    const MAXIMUM_DISTANCE_FROM_CENTER = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
    const MAXIMUM_DISTANCE_FROM_LABEL = MAXIMUM_DISTANCE_FROM_CENTER / 3;

    labels.forEach((obj) => {
      const { entity: label } = obj;

      // Don't add rooms without a name to sorted tiers of labels
      if (label.props.name === '' || label.props.name == null) return;

      const { position, x, y } = obj.positionialData;

      const distance = getDistanceBetweenPoints(
        new THREE.Vector2(position.x, position.y),
        new THREE.Vector2(minX + width / 2 + x * width, minY + height / 2 + y * height) // midpoint
      );

      const labelWithinMaximumDistance = !!selectedLabel.find(
        (obj) => getDistanceBetweenPoints(obj.position, position) < MAXIMUM_DISTANCE_FROM_LABEL
      );

      if (labelWithinMaximumDistance) return;

      const midpoint = new THREE.Vector2(
        minX + width / 2 + x * width,
        minY + height / 2 + y * height
      );

      if (group[level] == null) {
        group.push({ label, distance, position, midpoint });

        selectedLabel.push({ label, position });
        return;
      }

      group.push({ label, distance, position, midpoint });
      selectedLabel.push({ label, position });
    });

    return group.map((obj) => ({
      position: obj.position,
      id: obj.label.props.id,
      icon: getEditorIcon(obj.label),
      name: obj.label.props.name,
    }));
  });

  remainingLabels = labels.filter((obj) => {
    if (groups.flat().find((label) => label.id === obj.entity.props.id)) return false;

    // don't add rooms without a name
    if (obj.entity.props.name === '' || obj.entity.props.name == null) return false;

    return true;
  });

  // push any remaining labels into the last group for all to be displayed,
  remainingLabels.forEach((label) => {
    const { entity } = label;
    const { width, height } = ZOOMLEVELS[ZOOMLEVELS.length - 1].dimensions;

    const { position } = getLabelPosition(entity, minX, minY, width, height);

    groups[groups.length - 1].push({
      position,
      id: entity.props.id,
      icon: getEditorIcon(entity),
      name: entity.props.name,
    });
  });

  return groups;
};

export const getBuildingDimensions = (floors: Floor[]) => {
  const buildingPoints = floors
    .map((floor) => {
      return floor.elements[Elements.Room]
        .map(({ points }) => points.map((point) => point).flat())
        .flat();
    })
    .flat();

  return buildingPoints.length > 0
    ? {
        minX: minPointX(buildingPoints).x,
        maxX: maxPointX(buildingPoints).x,
        minY: minPointY(buildingPoints).y,
        maxY: maxPointY(buildingPoints).y,
      }
    : {
        minX: 0,
        maxX: 1000,
        minY: 0,
        maxY: 1000,
      };
};

export const getLabelPositionForRoom = (points: THREE.Vector2[]) => {
  const sortedX = points.map((p) => p.x).sort((a, b) => a - b);
  const sortedY = points.map((p) => p.y).sort((a, b) => a - b);

  const minX = sortedX[0];
  const maxX = sortedX[sortedX.length - 1];
  const minY = sortedY[0];
  const maxY = sortedY[sortedX.length - 1];

  const x = minX + (maxX - minX) / 2;
  const y = minY + (maxY - minY) / 2;
  return new THREE.Vector2(x, y);
};

export const getEditorIcon = (entity: ActiveEntity): string => {
  let iconSrc = '';

  if (entity.entityType === 0 && (entity as Element).elementType === Elements.Room) {
    const room = entity as ROOM;
    switch (room.roomType) {
      case gm.RoomType.Shop:
        iconSrc = ICONS.STORE;
        break;
      case gm.RoomType.Restaurant:
      case gm.RoomType.Food_Service:
        iconSrc = ICONS.RESTAURANT;
        break;
      case gm.RoomType.Kitchen:
        iconSrc = ICONS.KITCHEN;
        break;
      case gm.RoomType.Conference:
        iconSrc = ICONS.CONFERENCE_ROOM;
        break;
      case gm.RoomType.Lobby:
      case gm.RoomType.Lounge:
      case gm.RoomType.Waiting:
      case gm.RoomType.Entrance:
        iconSrc = ICONS.WAITING_AREA;
        break;
      case gm.RoomType.Restroom:
      case gm.RoomType.Restroom_Unisex:
        iconSrc = ICONS.RESTROOM;
        break;
      case gm.RoomType.Restroom_Family:
        iconSrc = ICONS.RESTROOM_FAMILY;
        break;
      case gm.RoomType.Restroom_Female:
        iconSrc = ICONS.RESTROOM_WOMEN;
        break;
      case gm.RoomType.Restroom_Male:
        iconSrc = ICONS.RESTROOM_MEN;
        break;
      case gm.RoomType.Bathroom:
        iconSrc = ICONS.BATHROOM;
        break;
      case gm.RoomType.Bedroom:
        iconSrc = ICONS.BEDROOM;
        break;
      default:
        iconSrc = '';
    }
  }

  if (entity.entityType === 2) {
    const poi = entity as POI;

    switch (poi.poiType) {
      case gm.POIType.Exhibit:
        iconSrc = ICONS.POI.EXHIBIT;
        break;
      case gm.POIType.Information:
        iconSrc = ICONS.POI.INFO;
        break;
      case gm.POIType.Shop:
      case gm.POIType.Amenity:
        iconSrc = ICONS.POI.SHOP;
        break;
      case gm.POIType.Water:
        iconSrc = ICONS.POI.WATER;
        break;
      default:
        iconSrc = ICONS.POI.OTHER;
    }
  }

  return iconSrc;
};

/**
 * Gets the icon src based on the gm.POIType from the goodmaps-sdk
 * @param poiType this should be a string that will be used to map back to the gm.POIType
 */
export const getEditorIconFromPOIType = (poiType: string): { icon: string; typeNum: number } => {
  let iconSrc = '';

  const typeNum = gm.POIType[poiType];

  switch (typeNum) {
    case gm.POIType.Exhibit:
      iconSrc = ICONS.POI.EXHIBIT;
      break;
    case gm.POIType.Information:
      iconSrc = ICONS.POI.INFO;
      break;
    case gm.POIType.Shop:
    case gm.POIType.Amenity:
      iconSrc = ICONS.POI.SHOP;
      break;
    case gm.POIType.Water:
      iconSrc = ICONS.POI.WATER;
      break;
    default:
      iconSrc = ICONS.POI.OTHER;
  }

  return { icon: iconSrc, typeNum };
};
