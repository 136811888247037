import React, { useState, useEffect } from 'react';
import { getContainers, getUsers } from '../../helpers/AdminAPIHelper';
import CircularProgressIndicator from 'components/dummy/CircularProgressIndicator';
import './Users.scss';
import { useSelector } from 'react-redux';
import { Button, Table } from 'react-bootstrap';
import { Auth } from 'globals/Types';
import AddUser from './AddUser';
import ModalComponent from 'components/dummy/Modal';
import DeleteUser from './DeleteUser';
import ResendInvite from './ResendInvite';
import Alert from 'components/dummy/Alert';
import { ROLES, User, userIsGoodMapsAdmin } from 'goodmaps-utils';
import ClientAdminUsers from './ClientAdminUsers';
import { Form } from 'react-bootstrap';
import DisableUser from './DisableUser';

interface UserRow extends User {
  roles: {
    role: number;
    name: string;
  }[];
}

const transformToUserRow = (users: User[], group?: string, containers?: any): UserRow[] => {
  return users.map((u) => {
    const row: UserRow = { ...u, roles: [] };
    if (u.status === 'CONFIRMED') row.status = 'Active';
    else if (u.status === 'UNCONFIRMED') row.status = 'Unconfirmed';
    else if (u.status === 'FORCE_CHANGE_PASSWORD') row.status = 'Pending';
    else if (u.status === 'DISABLED') row.status = 'Inactive';

    row.auth?.buildings?.forEach((b) => {
      if (!b.roles?.length) return;
      row.roles.push({ role: b.roles[0], name: containers[b.id] + ' (Building)' });
    });
    row.auth?.campuses?.forEach((b) => {
      if (!b.roles?.length) return;
      row.roles.push({ role: b.roles[0], name: containers[b.id] + ' (Campus)' });
    });
    row.auth?.customers?.forEach((b) => {
      if (!b.roles?.length) return;
      row.roles.push({ role: b.roles[0], name: containers[b.id] + ' (Customer)' });
    });
    row.auth?.partners?.forEach((b) => {
      if (!b.roles?.length) return;
      row.roles.push({ role: b.roles[0], name: containers[b.id] + ' (Partner)' });
    });
    return row;
  });
};

type Mode = 'Add' | 'Delete' | 'Edit' | 'Resend' | 'Default' | 'Disable';

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { auth }: { auth: Auth } = useSelector((state) => state.session.user);
  const [users, setUsers] = useState<UserRow[]>([
    {
      id: '1',
      name: 'John Doe',
      email: 'johndoe@gmail.com',
      status: 'Pending',
      auth: null,
      roles: [],
    },
  ]);
  const [filteredUsers, setFilteredUsers] = useState<UserRow[]>([
    {
      id: '1',
      name: 'John Doe',
      email: 'johndoe@gmail.com',
      status: 'Pending',
      auth: null,
      roles: [],
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserRow>({
    id: '',
    name: '',
    email: '',
    status: '',
    auth: null,
    roles: [],
  });
  const [mode, setMode] = useState<Mode>('Default');
  const [error, setError] = useState({ isError: false, text: '' });
  const [search, setSearch] = useState('');

  const openModal = (user: UserRow, mode: Mode) => {
    setMode(mode);
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  // eslint-disable-next-line
  const loadUsers = async () => {
    try {
      const { message: users } = await getUsers();
      const containers = await getContainers();
      const containerMap = {};
      containers.message.forEach((c) => (containerMap[c.id] = c.name));

      const transformedUsers = transformToUserRow(users, null, containerMap);

      // Remove inactive users and try to sort by name
      const sortedUsers = transformedUsers
        .filter((user) => user.status && user.status !== 'Inactive')
        .sort((a: UserRow, b: UserRow) => {
          if (a.status !== b.status) {
            return a.status.localeCompare(b.status);
          }

          if (!a.id && b.id) {
            return -1;
          }

          if (b.id && !a.id) {
            return 1;
          }

          return a.id.localeCompare(b.id);
        });
      setUsers(sortedUsers);
      setFilteredUsers(
        sortedUsers.filter(
          (u) =>
            u.name?.toLowerCase().includes(search.toLowerCase()) ||
            u.email?.toLowerCase().includes(search.toLowerCase())
        )
      );
    } catch (error) {
      setError({ isError: true, text: (error as any)?.message });
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line
  }, []);

  const userIsCustomerAdmin = () => {
    if (auth.partners.some((x) => x.roles.includes(ROLES.member))) return true;
    if (auth.customers.some((x) => x.roles.includes(ROLES.member))) return true;
    if (auth.campuses.some((x) => x.roles.includes(ROLES.member))) return true;
    if (auth.buildings.some((x) => x.roles.includes(ROLES.member))) return true;
    return false;
  };

  const renderModal = () => {
    switch (mode) {
      case 'Add':
        return (
          <AddUser onSubmitCallback={() => loadUsers()} onClose={() => setIsModalOpen(false)} />
        );
      case 'Disable':
        return (
          <DisableUser
            user={selectedUser}
            onSubmitCallback={() => loadUsers()}
            onClose={() => setIsModalOpen(false)}
          />
        );
      case 'Delete':
        return (
          <DeleteUser
            user={selectedUser}
            onSubmitCallback={() => loadUsers()}
            onClose={() => setIsModalOpen(false)}
          />
        );
      case 'Edit':
        return (
          <AddUser
            existingUser={selectedUser}
            onSubmitCallback={() => loadUsers()}
            onClose={() => setIsModalOpen(false)}
          />
        );
      case 'Resend':
        return <ResendInvite user={selectedUser} onClose={() => setIsModalOpen(false)} />;
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading ? (
        <CircularProgressIndicator full />
      ) : (
        <>
          {userIsCustomerAdmin() ? (
            <ClientAdminUsers />
          ) : (
            <div className="users-table-wrapper">
              {error.isError ? <Alert variant="danger">{error.text}</Alert> : null}
              <div className="users-table">
                <div className="users-table-header flex flex-row items-center space-between">
                  <div className="flex flex-row items-center w-100">
                    <div className="flex flex-column">
                      <h1>User Management</h1>
                      <Form.Control
                        className="entityInputField search-input"
                        placeholder="Search"
                        as="input"
                        value={search}
                        onChange={(event) => {
                          const searchText = event.target.value || '';
                          setSearch(searchText);

                          if (!searchText) {
                            setFilteredUsers(users);
                            return;
                          } else
                            setFilteredUsers(
                              users.filter(
                                (u) =>
                                  u.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                                  u.email?.toLowerCase().includes(searchText.toLowerCase())
                              )
                            );
                        }}
                      />
                    </div>
                    {userIsGoodMapsAdmin(auth) && (
                      <div className="button-action flex items-center justify-center">
                        <Button
                          style={{ width: 140 }}
                          onClick={() =>
                            openModal(
                              {
                                id: '',
                                name: '',
                                email: '',
                                status: '',
                                auth: null,
                                roles: [],
                              },
                              'Add'
                            )
                          }
                        >
                          + Add User
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <Table bordered className="background" size="sm">
                  <thead>
                    <tr>
                      <td style={{ width: 240 }}>Name</td>
                      <td>Email</td>
                      <td style={{ width: 100 }}>Status</td>
                      <td style={{ width: 400 }}>Role</td>
                      <td style={{ width: 175 }}>Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user, index) => (
                      <tr key={user.email} className={user.status === 'Pending' ? 'pending' : ''}>
                        <td>
                          <div className="inner truncate">{user.name}</div>
                        </td>
                        <td>
                          <div className="inner truncate">{user.email}</div>
                        </td>
                        <td>
                          <div className="inner">{user.status}</div>
                        </td>
                        <td>
                          <div className="inner">
                            <div key={user.roles[0]?.role + user.roles[0]?.name}>
                              <b>{user.roles[0]?.role}</b> - {user.roles[0]?.name}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="inner">
                            <button className="button" onClick={() => openModal(user, 'Edit')}>
                              Edit
                            </button>
                            <button className="button" onClick={() => openModal(user, 'Delete')}>
                              Delete
                            </button>
                            <button className="button" onClick={() => openModal(user, 'Disable')}>
                              Disable
                            </button>
                            {user.status === 'Pending' ? (
                              <button className="button" onClick={() => openModal(user, 'Resend')}>
                                Resend
                              </button>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <ModalComponent
                show={isModalOpen}
                size="lg"
                title={mode !== 'Resend' ? `${mode} User` : `${mode} Invite`}
                onClose={() => setIsModalOpen(false)}
              >
                {renderModal()}
              </ModalComponent>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Users;
