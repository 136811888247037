import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

type props = {
  name: string;
  title: string;
  onClose: () => void;
  updateObjectList: (name?: string) => Promise<void>;
};

const ParentModal = ({ name, title, onClose, updateObjectList }: props) => {
  const [newName, setNewName] = React.useState(name);
  const [isValidated, setIsValidated] = React.useState(false);

  return (
    <>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {title} {name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          id="pop-up-form"
          validated={isValidated}
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();

            const form = event.currentTarget;
            if (form.checkValidity() === false) {
              setIsValidated(true);
              return;
            }

            updateObjectList(newName);
          }}
        >
          <Form.Group>
            <Form.Label>Name*</Form.Label>
            <Form.Control
              required
              value={newName}
              onChange={(event) => setNewName(event.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name.
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
        <Button variant="primary" type="submit" form="pop-up-form">
          Save
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ParentModal;
