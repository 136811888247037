import * as gm from 'goodmaps-sdk';
import React, { useRef, useEffect } from 'react';
import * as LabelRenderer from '../../renderer/LabelRenderer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Renderer from '../../renderer/Renderer';
import * as THREE from 'three';
import './BuildingViewer.scss';
import { setNewPOICoords, setSelectedEntity } from 'actions/ViewerActions';
import { hideContextMenu, setHighlightedEntity, showContextMenu } from 'actions/EditEntityActions';
import { EditModeType } from 'globals/Types';
import { convertClientCoords } from 'helpers/EditorHelper';
import { ICONS } from 'globals/Icons';
import { getSelectedEntity } from '../../renderer/Renderer';

export default () => {
  const container = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const poiToMove = useRef<gm.POI>();
  const editMode: EditModeType = useSelector((state) => state.viewer.editMode);
  const currentBuilding: gm.Building = useSelector((state) => state.session.building);
  const editModeRef = useRef<EditModeType>(null);

  useEffect(() => {
    editModeRef.current = editMode;
  }, [editMode]);

  useEffect(() => {
    container.current.innerHTML = '';
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
    });
    container.current.appendChild(renderer.domElement);
    const { clientWidth, clientHeight } = container.current;

    const onMouseMove = (e) => {
      convertClientCoords(e.clientX, e.clientY);
    };
    window.addEventListener('mousemove', onMouseMove, false);

    Renderer.init(renderer as any, {
      width: clientWidth,
      height: clientHeight,
      enableLabels: true,
      domElement: container.current,
      onInit: (scene: THREE.Scene) => {},
      onRender: (scene: THREE.Scene, camera: THREE.PerspectiveCamera) => {},
      onEntityClicked: (id) => {
        try {
          dispatch(setSelectedEntity(id, history, true));
        } catch (err) {
          console.log(err);
        }
      },
    });

    return () => {
      Renderer.cleanup();
      renderer.dispose();
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [dispatch, history]);

  useEffect(() => {
    const labelRendererDOMElement = LabelRenderer.getLabelRenderer().domElement;

    let mapXStart, mapYStart;
    const onMouseDown = (e) => {
      e.preventDefault();
      dispatch(hideContextMenu(true));
      mapXStart = e.x;
      mapYStart = e.y;
    };
    labelRendererDOMElement.onpointerdown = onMouseDown;
    const onMouseUp = async (e) => {
      try {
        if (mapXStart !== e.x || mapYStart !== e.y) return;

        if (editModeRef.current === 'move') {
          if (e.button === 0) {
            e.preventDefault();
            const x = e.clientX;
            const y = e.clientY - 64;

            const poiName = poiToMove.current?.name;
            const coords = convertClientCoords(x, y, 384, 10);
            dispatch(setNewPOICoords(coords, null, poiName || ''));

            LabelRenderer.updateLabelPosition(poiToMove.current?.id, coords);
            LabelRenderer.updateLabelIcon(poiToMove.current?.id, ICONS.map.selected);
          }
        } else {
          if (e.button === 2) {
            e.preventDefault();
            const x = e.clientX;
            const y = e.clientY - 64;

            const highlightedEntityID = Renderer.getHighlightedEntity();

            const element = currentBuilding
              .getAllEntities()
              .find((e) => e.id === highlightedEntityID);
            const hoveringOverPOI = element.baseType === gm.BaseType.POI;

            const selectedEntityID = getSelectedEntity();

            if (hoveringOverPOI) {
              poiToMove.current = element as gm.POI;

              if (highlightedEntityID === 'newPOI') return;

              if (selectedEntityID !== element.id) {
                Renderer.selectEntity(highlightedEntityID, 'none');
              }
              dispatch(setHighlightedEntity(highlightedEntityID, history));
            }

            dispatch(showContextMenu(x, y, hoveringOverPOI));
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    labelRendererDOMElement.onpointerup = onMouseUp;
  }, [currentBuilding, dispatch, history]);

  return <div className="map-viewer" ref={container} />;
};
