import React, { useState, useEffect, useRef } from 'react';
import EditorPage from 'components/smart/EditorPage';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { updateEntity, createPOI, setHighlightedEntity } from 'actions/EditEntityActions';
import { ActiveEntity, POI, POIChange } from 'globals/Types';
import ReactGA from 'react-ga';
import {
  getEntityTypeAsString,
  getDefaultEntityIcon,
  Entities,
} from 'helpers/goodmaps-helper/GoodMaps';
import { Change, User } from 'goodmaps-sdk';
import LanguageDictionary from 'globals/Languages';
import { FormLabel, FormControl, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import Label from 'components/dummy/Label';
import Dropdown from 'components/dummy/Dropdown';
import DropdownOption from 'components/dummy/DropdownOption';
import { createChange } from 'goodmaps-sdk';
import URLExtraInfo, { isURLValid } from './URLExtraInfo';
import {
  isEqual,
  EIURL,
  EIMarkupText,
  Position,
  POIType,
  ExtraInfoType,
  AuthRole,
  Building,
} from 'goodmaps-utils';
import { InfoOutlined } from '@material-ui/icons';
import { BEFORE_UNLOAD_MESSAGE } from 'globals/Constants';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { setIsFormDirty } from 'actions/SessionActions';
import { getValidAuthList } from 'helpers/EditorHelper';

const defaultLanguage = 'en';

// POIs only
const PoiEditor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { catId, entityId }: { catId: string; entityId: string } = useParams();
  const newPOICoords: Position = useSelector((state) => state.viewer.newPOICoords);
  const [selectedLanguage, setSelectedLanguage] = useState<'en' | 'fr' | 'es'>('en');
  const [change, setChange] = useState<POIChange>({
    mlName: {},
    mlShortName: {},
    mlUrl: [],
    mlGenInfo: {},
    authList: [AuthRole.Public],
    poiType: POIType.Other,
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [formWasChanged, setFormWasChanged] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [languageWasChanged, setLanguageWasChanged] = useState({
    en: false,
    fr: false,
    es: false,
  });
  const currentBuilding: Building = useSelector((state) => state.session.building);
  const currentFloor: number = useSelector((state) => state.viewer.currentFloor);
  const selectedBuildingUUID: string = useSelector((state) => state.session.selectedBuildingUUID);
  const allEntities: ActiveEntity[] = useSelector((state) => state.session.allEntities);
  const user: User = useSelector((state) => state.session.user);
  const newPOICoordsRef = useRef<Position>(null);
  const originalPOIRef = useRef<POIChange>({
    mlName: {},
    mlShortName: {},
    mlUrl: [],
    mlGenInfo: {},
    authList: [AuthRole.Public],
    poiType: POIType.Other,
  });

  useBeforeUnload({
    when: formWasChanged,
    message: BEFORE_UNLOAD_MESSAGE,
  });

  useEffect(() => {
    newPOICoordsRef.current = newPOICoords;
  }, [newPOICoords]);

  useEffect(() => {
    if (newPOICoordsRef.current == null && history.location.pathname.split('/')[6] === 'new')
      history.goBack();
  }, [history]);

  useEffect(() => {
    const e = allEntities.find((entity) => entity.props.id === entityId);

    if (e) {
      // if no default language set then try and set a default
      if (!e?.props.mlName[defaultLanguage])
        e.props.mlName[defaultLanguage] = e.props.mlName?.default;
      if (!e?.props.mlShortName[defaultLanguage]) e.props.mlShortName[defaultLanguage] = '';

      // if no mlUrl or no default Language then setup ml object with empty string for default
      if (!e?.props.mlGenInfo || !e?.props?.mlGenInfo[defaultLanguage])
        e.props.mlGenInfo = { [defaultLanguage]: '' };
    }

    const defaultChange = {
      mlName: e?.props.mlName || {},
      mlShortName: e?.props.mlShortName || {},
      mlUrl: e?.props.mlUrl || [],
      mlGenInfo: e?.props.mlGenInfo || {},
      authList: getValidAuthList(e?.props.authList),
      poiType: (e as POI)?.poiType || 1, //or other by default
    };

    setChange(defaultChange);

    originalPOIRef.current = defaultChange;
  }, [allEntities, catId, entityId]);

  const resetForm = () => {
    setChange({
      mlName:
        {
          ...change.mlName,
          [selectedLanguage]: originalPOIRef.current.mlName[selectedLanguage],
        } || {},
      mlShortName:
        {
          ...change.mlShortName,
          [selectedLanguage]: originalPOIRef.current.mlShortName[selectedLanguage],
        } || {},
      mlUrl: originalPOIRef.current.mlUrl[selectedLanguage] || [],
      mlGenInfo:
        {
          ...change.mlGenInfo,
          [selectedLanguage]: originalPOIRef.current.mlGenInfo[selectedLanguage],
        } || {},
      authList: originalPOIRef.current.authList,
      poiType: originalPOIRef.current.poiType || 1, //or null by default
    });

    setLanguageWasChanged({
      en: false,
      fr: false,
      es: false,
    });
    setFormWasChanged(false);
    dispatch(setIsFormDirty(false));
  };

  const renderLanguageFieldText = (key: string) => {
    const { name } = LanguageDictionary[key];

    // if default language, missing required, unsaved changes
    if (
      key === defaultLanguage &&
      change.mlName[defaultLanguage] === '' &&
      languageWasChanged[key]
    ) {
      return (
        <>
          {name}{' '}
          <span className="sub-label-italicized">
            (Default Language, (1) required field, Unsaved changes)
          </span>
        </>
      );
    }

    // if default language, unsaved changes
    if (key === defaultLanguage && languageWasChanged[key]) {
      return (
        <>
          {name} <span className="sub-label-italicized">(Default Language, Unsaved changes)</span>
        </>
      );
    }

    // if default language and missing required fields - name, icon
    if (key === defaultLanguage && change.mlName[defaultLanguage] === '') {
      return (
        <>
          {name}{' '}
          <span className="sub-label-italicized">(Default Language, (1) required field)</span>
        </>
      );
    }

    // if default language
    if (key === defaultLanguage)
      return (
        <>
          {name} <span className="sub-label-italicized">(Default Language)</span>
        </>
      );

    // if unsaved changes
    if (languageWasChanged[key])
      return (
        <>
          {name} <span className="sub-label-italicized">(Unsaved changes)</span>
        </>
      );

    return name;
  };

  const getSaveButtonState = () => {
    if (!formWasChanged) return 'none';
    if (formIsLoading) return 'saving';

    if (!formIsValid) return 'disabled';

    return 'save';
  };

  const validate = (newChange: POIChange, language?: string) => {
    const formChanged = !isEqual(newChange, originalPOIRef.current);

    setFormWasChanged(formChanged);

    if (formChanged) {
      setLanguageWasChanged({ ...languageWasChanged, [language]: true });
      setFormIsValid(
        !!newChange.poiType &&
          !!newChange.mlName[selectedLanguage]?.trim() &&
          isURLValid(newChange.mlUrl)
      );
      dispatch(setIsFormDirty(true));
    } else {
      setLanguageWasChanged({ ...languageWasChanged, [language]: false });
      dispatch(setIsFormDirty(false));
    }
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="extra-info-tooltip" className="left-align" {...props}>
        Announced in GoodMaps Explore as “extra information” and shown with an information icon.
      </Tooltip>
    );
  };

  return (
    <EditorPage
      title={change.mlName.en || 'POI'}
      icon={getDefaultEntityIcon(Entities.pois.toString(), change.poiType.toString())}
      saveState={getSaveButtonState()}
      saveStateErrorMessage={`You must complete required fields before submitting in English`}
      onConfirmSave={async () => {
        // Update
        if (entityId) {
          ReactGA.event({
            category: 'Engagement',
            action: 'Saved building changes',
            label: `${user.name} updated building ${selectedBuildingUUID}, entity ${entityId}`,
          });
          setFormIsLoading(true);

          try {
            await dispatch(
              updateEntity(
                {
                  id: entityId,
                  mlName: change.mlName,
                  mlShortName: change.mlShortName,
                  authRole: change.authList,
                  mlUrl: change.mlUrl,
                  mlGenInfo: change.mlGenInfo,
                  poiType: change.poiType,
                  type: Entities.pois,
                },
                user.email,
                currentBuilding,
                currentFloor
              )
            );
            setFormWasChanged(false);
            setLanguageWasChanged({
              en: false,
              fr: false,
              es: false,
            });
          } catch (e) {
            window.alert(e);
          } finally {
            setFormIsLoading(false);
            dispatch(setIsFormDirty(false));
          }
        }
        // Add new
        else {
          ReactGA.event({
            category: 'Engagement',
            action: 'Saved new poi',
            label: `${user.name} created a new poi`,
          });
          setFormIsLoading(true);

          try {
            const extraInfo: (EIMarkupText | EIURL)[] = [...change.mlUrl];

            if (Object.keys(change.mlGenInfo).length) {
              extraInfo.push({
                type: ExtraInfoType.MarkupText,
                name: '',
                mlName: {
                  en: 'General Information',
                },
                markup: '',
                mlMarkup: {
                  ...change.mlGenInfo,
                },
              });
            }

            let update: Change = createChange();

            update = update
              .initAddPOI(user.email, Date.now(), newPOICoords, change.poiType, currentBuilding)
              .setName(change.mlName)
              .setShortName(change.mlShortName)
              .setExtraInfo(extraInfo)
              .setAuthList(change.authList);
            //beforeUnload hook gets called to prevent a history edit within the highlight entity function
            const createdPoiId = await dispatch(
              createPOI(update, history, currentFloor, change.poiType, newPOICoords)
            );
            setFormWasChanged(false);
            setLanguageWasChanged({
              en: false,
              fr: false,
              es: false,
            });
            //set highlighted entity to the previously created entity
            await dispatch(setHighlightedEntity(createdPoiId, history));
          } catch (e) {
            window.alert(e);
          } finally {
            setTimeout(() => {
              setFormIsLoading(false);
              dispatch(setIsFormDirty(false));
            }, 150);
          }
        }
      }}
      onCancelClick={resetForm}
    >
      <Prompt when={formWasChanged} message={BEFORE_UNLOAD_MESSAGE} />
      <div className="entity-body">
        <>
          <div>
            <label className="inline-label">
              Entity Type: <span>POI</span>
            </label>
          </div>
          <Label key="poi">
            <span>POI Type</span>
            <span className="required">*</span>{' '}
            <span className="sub-label-italicized">(Required)</span>
          </Label>
          <Dropdown
            title={getEntityTypeAsString(Entities.pois, change.poiType) || ''}
            onSelect={(value) => {
              const newChange = {
                ...change,
                poiType: parseInt(value as string),
              };
              validate(newChange);

              setChange(newChange);
            }}
          >
            <DropdownOption key={1} value={1}>
              Other
            </DropdownOption>
            <DropdownOption key={2} value={2}>
              Water
            </DropdownOption>
            <DropdownOption key={3} value={3}>
              Information
            </DropdownOption>
            <DropdownOption key={4} value={4}>
              Shop
            </DropdownOption>
            <DropdownOption key={5} value={5}>
              Amenity
            </DropdownOption>
            <DropdownOption key={6} value={6}>
              Exhibit
            </DropdownOption>
          </Dropdown>

          <Label key="security">Security Role</Label>
          <Form.Check
            type="switch"
            id="role-switch"
            onChange={(event) => {
              const newChange = {
                ...change,
                authList: event.target.checked ? [AuthRole.Private] : [AuthRole.Public],
              };
              validate(newChange);
              setChange(newChange);
              ReactGA.event({
                category: 'Engagement',
                action: 'Edit security role',
                label: `${user.name} updated building ${selectedBuildingUUID}, Entity ${entityId}`,
              });
            }}
            checked={change.authList[0] === AuthRole.Private}
            label={change.authList[0] === AuthRole.Private ? AuthRole[1] : AuthRole[0]}
          />
        </>

        <hr />

        <p>
          Choose a language. You must enter required information in{' '}
          {LanguageDictionary[defaultLanguage].name} to save.
        </p>

        <Dropdown
          onSelect={(value) => {
            setSelectedLanguage(value as any);
          }}
          title={renderLanguageFieldText(selectedLanguage)}
        >
          {Object.keys(LanguageDictionary).map((key) => (
            <DropdownOption key={key} value={key} selected={key === selectedLanguage}>
              {renderLanguageFieldText(key)}
            </DropdownOption>
          ))}
        </Dropdown>

        <p style={{ marginTop: '-13px' }}>
          Define the fields below in {LanguageDictionary[selectedLanguage].name}.
        </p>

        <FormLabel className="label-text" id="entityNameLabel">
          <span>Name</span>
          <span className="required">*</span>{' '}
          <span className="sub-label-italicized">(Required)</span>
        </FormLabel>
        <FormControl
          name="mlName"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Name`}
          value={change.mlName[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlName: { ...change.mlName, [selectedLanguage]: e.target.value },
            };
            validate(newChange, selectedLanguage);
            setChange(newChange);
          }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Name`}
        />

        <FormLabel className="label-text" id="entitySimplifiedNameLabel">
          Simplified Name
        </FormLabel>
        <FormControl
          name="mlSimplifiedName"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Simplified Name`}
          value={change.mlShortName[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlShortName: { ...change.mlShortName, [selectedLanguage]: e.target.value },
            };
            validate(newChange, selectedLanguage);
            setChange(newChange);
          }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} Simplified Name`}
        />

        <div>
          <FormLabel
            className="label-text no-bottom-margin"
            id="GenInfoLabel"
            style={{ marginRight: '8px' }}
          >
            Extra Information
          </FormLabel>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 250 }}
            overlay={renderTooltip}
          >
            <InfoOutlined style={{ height: 20, width: 20 }} />
          </OverlayTrigger>
        </div>

        <FormLabel className="extra-info-header" id="GenInfoLabel">
          General Information
        </FormLabel>

        <FormControl
          as="textarea"
          name="mlGenInfo"
          className="entityInputField"
          placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} General Information`}
          value={change.mlGenInfo[selectedLanguage] || ''}
          onChange={(e) => {
            const newChange = {
              ...change,
              mlGenInfo: { ...change.mlGenInfo, [selectedLanguage]: e.target.value },
            };
            validate(newChange, selectedLanguage);
            setChange(newChange);
          }}
          aria-label="entityNameLabel"
          aria-placeholder={`Enter ${LanguageDictionary[selectedLanguage].name} General Information`}
        />

        <URLExtraInfo
          defaultLanguage={defaultLanguage}
          selectedLanguage={selectedLanguage}
          initialValue={change.mlUrl}
          onChange={(eiURLs: EIURL[]) => {
            const newChange = {
              ...change,
              mlUrl: eiURLs,
            };

            validate(newChange, selectedLanguage);
            setChange(newChange);
          }}
        />
      </div>
    </EditorPage>
  );
};

export default PoiEditor;
