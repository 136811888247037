import * as THREE from 'three';
import * as gm from 'goodmaps-sdk';

export default class TestPoint {
  id: string;
  type: gm.TestType;
  level: number;
  position: THREE.Vector2;

  constructor(id: string, level: number, type: gm.TestType, position: gm.Position) {
    this.id = id;
    this.type = type;
    this.level = level;
    this.position = new THREE.Vector2(position.x, position.y);
  }
}
