import { SET_EVENT_OBJECT } from '../actions/types';

export type EventState = {
  eventObject: {
    onScroll: any;
  };
};

const initialState: EventState = {
  eventObject: {
    onScroll: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_OBJECT:
      return {
        ...state,
        eventObject: action.payload,
      };
    default:
      return state;
  }
};
