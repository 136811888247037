import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import {
  Greetings,
  Authenticator,
  SignIn,
  ForgotPassword,
  RequireNewPassword,
} from 'aws-amplify-react';
import ReactGA from 'react-ga';
import StudioRoot from 'screens/root/StudioRoot';
import { UsernameAttributes } from 'aws-amplify-react/lib-esm/Auth/common/types';
import CustomSignIn from 'screens/sign-in/SignIn';
import CustomForgotPassword from 'screens/sign-in/ForgotPassword';
import CustomRequireNewPassword from 'screens/sign-in/RequireNewPassword';
import { isMobile } from 'react-device-detect';
import pack from '../../../package.json';
import MobileView from 'screens/mobile-view/MobileView';
import MyTheme from 'styles/LoginTheme';
import config from 'globals/config/config';
import { I18n } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { updateBuildingManagerToken } from 'helpers/goodmaps-helper/BuildingManager';
import AuthErrorProvider from 'hooks/useAuthErrors';
import { Environment } from 'goodmaps-utils';

const StudioAuthenticator = () => {
  const history = useHistory();
  const refreshTimer = useRef(null);
  const [loginTimestamp, setLoginTimestamp] = useState(0);

  useEffect(() => {
    const startJWTRefreshTimer = () => {
      refreshTimer.current = setInterval(async () => {
        console.log('JWT refresh timer');
        try {
          const session = await Auth.currentSession();

          updateBuildingManagerToken(session.getIdToken().getJwtToken());
        } catch (e) {
          console.log(e);
        }
      }, 5 * 60 * 1000 + 500); // ~5 minutes
    };

    const handleUserAuthChange = async (data?: any) => {
      try {
        switch (data?.payload.event) {
          case 'signIn':
            ReactGA.event({
              category: 'signin',
              action: 'User signed in',
            });
            const session2 = await Auth.currentSession();
            setLoginTimestamp(session2.getIdToken().payload.loginTimestamp);

            break;

          case 'signUp':
          case 'signOut':
          case 'signIn_failure':
          case 'configured':
            break;

          case 'tokenRefresh':
            const session = await Auth.currentSession();

            if (
              config.ENV_ID !== Environment.Dev &&
              loginTimestamp !== session.getIdToken().payload.loginTimestamp
            ) {
              await Auth.signOut();
            }

            updateBuildingManagerToken(session.getIdToken().getJwtToken());
            break;

          case 'tokenRefresh_failure':
            console.error('token refresh failed', data);
            await Auth.signOut().catch((err) => console.log(err));
            history.push('/');
            break;
        }
        console.log('handle auth change');
      } catch (e) {
        console.log('error setting JWT', e);
        clearInterval(refreshTimer.current);
      }
    };

    // Auth change listener for sign in or pre-authenticated user entering app
    Hub.listen('auth', handleUserAuthChange);
    // Initial auth check on load
    handleUserAuthChange();
    startJWTRefreshTimer();
    window.addEventListener('storage', async (event) => {
      if (event.key.includes('CognitoIdentityServiceProvider') && event.newValue === null) {
        await Auth.signOut();
      }
    });
    return () => {
      // Clean up listeners/timers
      Hub.remove('auth', handleUserAuthChange);
      window.removeEventListener('storage', async (event) => {
        if (event.key.includes('CognitoIdentityServiceProvider') && event.newValue === null) {
          await Auth.signOut();
        }
      });
      clearInterval(refreshTimer.current);
    };
  }, [history, loginTimestamp, refreshTimer]);

  return (
    <>
      {isMobile ? (
        <MobileView />
      ) : (
        <>
          <AuthErrorProvider>
            <Authenticator
              hide={[Greetings, SignIn, RequireNewPassword, ForgotPassword]}
              usernameAttributes={UsernameAttributes.EMAIL}
              theme={MyTheme}
            >
              <CustomSignIn />
              <CustomRequireNewPassword />
              <CustomForgotPassword />

              <StudioRoot />
            </Authenticator>
          </AuthErrorProvider>
          <div className="version">
            {config.NAME} {pack.version}
          </div>
        </>
      )}
    </>
  );
};

const authScreenLabels = {
  en: {
    'Sign In': 'Login',
    Username: 'Email',
    'Enter your username': 'Enter your email',
    'Username cannot be empty': 'Email cannot be empty',
    'User does not exist': 'Incorrect email and password',
    'Custom auth lambda trigger is not configured for the user pool.': 'Password cannot be empty',
    'Sign in to your account': 'GoodMaps',
  },
};

I18n.putVocabularies(authScreenLabels);

export default StudioAuthenticator;
