import { Environment } from 'goodmaps-sdk';

const config = {
  TRACKING_ID: 'UA-148349387-3',
  NAME: 'Release',
  API_URL: 'https://prod.api.goodmaps.io/servicesv2-services',
  BUILDING_URL: 'https://prod.api.goodmaps.io/servicesv2-buildingmanagement/building',
  LINT_BUILDING_URL: 'https://prod.api.goodmaps.io/servicesv2-lint',
  IMDF_BUILDING_URL: 'https://prod.api.goodmaps.io/servicesv2-imdf',
  ENV_ID: Environment.Prod,
  userPoolId: 'us-east-2_5VoCCcrYb',
  userPoolWebClientId: '29b66risvrh27tdkqg4gd40p49',
  region: 'us-east-2',
};

export default config;
