import { useEffect } from 'react';

const useBeforeUnload = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Needed for legacy reasons
      event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeprint', handleBeforeUnload);
  }, [when, message]);
};

export default useBeforeUnload;
