import React from 'react';
import './Tab.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

export default ({ children, path = '' }) => {
  const history = useHistory();
  const route = useLocation();

  let selected = route.pathname.startsWith(path);
  if (path === '/') {
    selected = route.pathname === '/' || route.pathname.startsWith('/building');
  }

  return (
    <Nav.Link
      onClick={() => history.push(path)}
      className={`flex items-center topnav-tab b-nav-link ${selected ? 'selected' : ''}`}
    >
      <div className="tab-label">{children}</div>
    </Nav.Link>
  );
};
