import React from 'react';

const StudioLogo = ({ fontSize = 18, color = 'black', signInScreen = false }) => {
  return (
    <div style={{ fontSize, color }}>
      <span style={{ fontWeight: 900 }}>Good</span>
      <span style={{ fontWeight: 400 }}>Maps</span>
      {!signInScreen && <span style={{ fontWeight: 400 }}> Studio</span>}
    </div>
  );
};

export default StudioLogo;
