import React from 'react';
import ToggleIcon from 'material-ui-toggle-icon';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import { CheckBox } from '@material-ui/icons';
import './RowCheckbox.scss';

type Props = {
  label: string;
  disabled?: boolean;
  toggle?: boolean;
  shortcut?: string;
  containerStyle?: any;
  onButtonClick?: () => void;
};

const RowCheckbox = ({
  label,
  disabled,
  toggle,
  shortcut,
  containerStyle,
  onButtonClick,
}: Props) => {
  const onToggle = () => {
    onButtonClick && onButtonClick();
  };
  return (
    <div
      onClick={!disabled ? onToggle : null}
      className="row-checkbox-box"
      style={containerStyle && containerStyle}
    >
      <div className="label" style={{ color: disabled ? '#979797' : '#000000' }}>
        {label}
      </div>
      <div className="checkbox-box flex">
        <div
          className="label flex"
          style={{ marginRight: 20, alignItems: 'center', color: disabled ? '#979797' : '#000000' }}
        >
          {shortcut}
        </div>
        <ToggleIcon on={toggle} onIcon={<CheckBox />} offIcon={<CheckBoxOutlineBlank />} />
      </div>
    </div>
  );
};

export default RowCheckbox;
