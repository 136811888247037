import React, { useState, useEffect } from 'react';

const ScrollingIcons = () => {
  const [areIconsLoaded, setIconsLoaded] = useState(false);
  const [iconSets, setIconSets] = useState([]);

  useEffect(() => {
    const sets = [];
    const iconNames = [
      'accessible-forward',
      'account-balance',
      'computer',
      'directions-walk',
      'flight',
      'house',
      'local-hospital',
      'location-city',
      'map',
      'museum',
      'place',
    ];

    for (let i = 0; i < 3; i++) {
      const icons = [];
      let loadedCounter = 0;

      const randomizedIcons = [
        ...iconNames.sort(() => 0.5 - Math.random()),
        ...iconNames.sort(() => 0.5 - Math.random()),
      ];

      // Wait until first full set of icons is loaded to fade in
      const handleIconLoaded = () => {
        loadedCounter += 1;
        setIconsLoaded(loadedCounter === randomizedIcons.length);
      };

      for (let j = 0; j < randomizedIcons.length; j++) {
        icons.push(
          <img
            style={j === 0 ? { marginLeft: `calc(${100 / randomizedIcons.length}% - 24px` } : {}}
            src={`/icons/${randomizedIcons[j]}.svg`}
            alt={randomizedIcons[j].replace('-', ' ')}
            key={`${randomizedIcons[j]}-${j}`}
            onLoad={i === 0 ? () => handleIconLoaded() : () => {}}
          />
        );
      }

      sets.push(
        <div className={`icons-${i + 1}`} key={i}>
          {icons}
        </div>
      );
    }

    setIconSets(sets);
  }, []);

  return (
    <div className="icons-container" style={areIconsLoaded ? { opacity: 1 } : {}}>
      {iconSets}
    </div>
  );
};

export default ScrollingIcons;
