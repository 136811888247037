import * as gm from 'goodmaps-sdk';
import config from 'globals/config/config';

let BuildingManager: gm.BuildingManager;

export const initializeBuildingManager = (idToken: string) => {
  BuildingManager = gm.createBuildingManager({
    userType: 'studio',
    userId: 'studio',
    environment: config.ENV_ID,
    language: 'en',
    jwt: idToken,
  });

  return BuildingManager;
};

export const getBuildingManager = () => BuildingManager;

export const updateBuildingManagerToken = (idToken: string) => (BuildingManager.bmc.jwt = idToken);
