import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Entities,
  EntityInfo,
  getEditorIcon,
  getPluralString,
} from 'helpers/goodmaps-helper/GoodMaps';
import { Link } from 'globals/Types';
import Floor from 'components/floorplan/structures/Floor';
import { removeZeroCountLinks } from 'helpers/LinkHelper';
import { checkRoutesVisibility, checkTestPointsVisibility } from 'actions/ViewerActions';
import CampusHome from 'components/dummy/CampusHome';
import { handleRoutesVisibility, handleTestPointsVisibility } from 'actions/SessionActions';
import { Campus } from 'goodmaps-sdk/dist/goodmaps';

export default () => {
  const dispatch = useDispatch();
  const selectedBuildingName: string = useSelector((state) => state.session.selectedBuildingName);
  const campus: Campus = useSelector((state) => state.session.building);
  const floors: Floor[] = useSelector((state) => state.session.floors);
  const showRoutes: boolean = useSelector((state) => state.viewer.showRoutes);
  const showTestPoints: boolean = useSelector((state) => state.viewer.showTestPoints);
  const { pathname } = useLocation();

  const shouldShowTestPoints = useCallback(() => {
    dispatch(handleTestPointsVisibility());
  }, [dispatch]);

  const shouldShowRoutes = useCallback(() => {
    dispatch(handleRoutesVisibility());
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkRoutesVisibility());
    dispatch(checkTestPointsVisibility());
  }, [dispatch, floors, shouldShowRoutes, shouldShowTestPoints, showRoutes, showTestPoints]);

  // const floor = useMemo(() => {
  //   const f = floors.length !== 1 ? floors.find((floor) => floor.level === +level) : floors[0];

  //   if (!f) {
  //     history.replace(`/campus/${selectedBuildingUUID}`);
  //   }
  //   return [f];
  // }, [floors,  history, selectedBuildingUUID]);

  const getCountForEntity = (entityId: number): number => {
    switch (entityId) {
      case 0:
        return Object.values(floors[0].elements).flat().length;
      case 1:
        return [
          ...floors[0].stairs,
          ...floors[0].elevators,
          ...floors[0].escalators,
          ...floors[0].other,
        ].length;
      case 2:
      case 3:
      case 4:
        return floors[0][Entities[entityId]].length;
      default:
        return 0;
    }
  };

  const getCategoryLinks = (): Link[] => {
    const links: Link[] = removeZeroCountLinks(
      Object.entries(EntityInfo).map((entry, index) => ({
        primary: getPluralString(entry[1].name),
        to: `${pathname}/${entry[0]}`,
        count: getCountForEntity(+entry[0]),
        color: +entry[0] === 1 ? '#FFEDCE' : '',
        icon: getEditorIcon(index),
      }))
    );
    if (getCountForEntity(2) === 0) {
      links.push({ primary: 'Add Point of Interest', to: `${pathname}/2` });
    }
    return links;
  };

  const getBuildingLinks = () => {
    const links: Link[] = campus
      .getBuildingOutlinesForCampus()
      .filter((c) => c.mappedBuildingId)
      .map((campus, index) => ({
        primary: campus.name,
        to: `/building/${campus.mappedBuildingId}`,
      }));
    return links;
  };

  return (
    <CampusHome
      selectedCampusName={selectedBuildingName}
      buildingLinks={getBuildingLinks()}
      catLinks={getCategoryLinks()}
      searchLinks={[]}
    />
  );
};
