import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import './FloorSelector.scss';
import Floor from '../../components/floorplan/structures/Floor';
// import { Link } from 'react-router-dom';

type Props = {
  selectedFloor: number;
  floors: Floor[];
  onFloorSelected: (level: number) => void;
};

export default ({ selectedFloor, floors, onFloorSelected }: Props) => {
  const renderButtons = () => {
    return floors
      .map(({ level, name }) => {
        return (
          <button
            key={level.toString()}
            id={`${level}`}
            onClick={(e) => onFloorSelected(level)}
            className={selectedFloor === level ? 'selected' : ''}
          >
            {name}
          </button>
        );
      })
      .reverse();
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <h3 style={{ marginBottom: 0 }}>Floor</h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <FormControl className="floor-selector" variant="filled">
          {renderButtons()}
        </FormControl>
      </div>
    </div>
  );
};
