import React from 'react';
import RowCheckbox from 'components/map-editor/RowCheckbox';
import EditorPage from 'components/smart/EditorPage';
import Floor from 'components/floorplan/structures/Floor';
import Directory from 'components/smart/Directory';
import { Link } from 'globals/Types';
import { User, userIsGoodMapsAdmin } from 'goodmaps-sdk';

type Props = {
  links: Link[];
  currentFloor: number;
  showRoutes: boolean;
  showTestPoints: boolean;
  showUnnamedPois: boolean;
  floor: Floor[];
  shouldShowTestPoints: () => void;
  shouldShowRoutes: () => void;
  shouldShowUnnamedPois: () => void;
  user: User;
  title?: string;
  directoryTitle?: string;
};

export default ({
  links,
  currentFloor,
  showRoutes,
  showTestPoints,
  showUnnamedPois,
  floor,
  shouldShowTestPoints,
  shouldShowRoutes,
  shouldShowUnnamedPois,
  user,
  title,
  directoryTitle,
}: Props) => {
  return (
    <EditorPage title={title || floor[0]?.name || ''}>
      <Directory links={links} title={directoryTitle} />
      <RowCheckbox
        label="Display routes"
        shortcut="R"
        disabled={currentFloor === null}
        toggle={showRoutes}
        onButtonClick={shouldShowRoutes}
      />

      <h3 style={{ marginTop: 30, marginBottom: 12 }}>{'Developer Tools'}</h3>
      <RowCheckbox
        label="Display test points"
        shortcut="T"
        disabled={currentFloor === null}
        toggle={showTestPoints}
        onButtonClick={shouldShowTestPoints}
      />
      {userIsGoodMapsAdmin(user.auth) ? (
        <RowCheckbox
          label="Display Unnamed Pois"
          shortcut="H"
          disabled={currentFloor === null}
          toggle={showUnnamedPois}
          onButtonClick={shouldShowUnnamedPois}
        />
      ) : null}
    </EditorPage>
  );
};
